import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksCommunity from "components/Header/HeaderLinksCommunity.js";

import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link,useParams } from "react-router-dom";
import isEmpty from "./../lib/isEmpty";
import CKEditor from 'ckeditor4-react';
import { SecondsToDay } from '../helper/dateFormat';
import { getTopices } from "./../actions/community";

import Select from 'react-select';
import config from "../lib/config";
import { toast } from 'react-toastify';
toast.configure();
  let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  }
const dashboardRoutes = [];
var IMG_URL = config.IMG_URL;
const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  return null;
}
const initialFormValue = {
  topic:"",
  description:"",
  cate:"",
  catename:"",
};


export default function TopicList(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const { id } = useParams();

  const [topicList, settopicList] = useState([]);
  const [page, setpage] = useState(0);
  const [showload, seshowload] = useState(true);

  useEffect(() => {
    getList();
  }, []);

  
  const getList = async (e) => {

    var hash = window.location.hash.substr(1);

    let { list,skip } = await getTopices({value:hash,page:page});
    settopicList(list); 
    setpage(skip);
    if(list && list.length==0){
        seshowload(false);
    }

  }

  const loadMore = async (e) => {

    var hash = window.location.hash.substr(1);
    let { list,skip } = await getTopices({value:hash,page:page});
    
    setpage(skip);
    if(list && list.length > 0){
        var totalList = topicList.concat(list);
        settopicList(totalList); 
    }
    if(list.length==0){
        seshowload(false);
    }

  }


  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinksCommunity />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} className="m-auto">
              <div className="d-flex align-items-center">
                <Link to="/community" className="goback_link">
                  <i class="bi bi-chevron-left"></i>
                  <span>Go back</span>
                </Link>
              </div>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="inner_title">Discussion</h2>
                {localStorage.community_token && 
                <Button className="create_btn" data-toggle="modal" data-target="#new_top_modal">New Topic</Button>
                }
              </div>
            </GridItem> */}
          </GridContainer>
        </div>
        <div className="container mt-3">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            
              <div className="tab-content explore_tab_content mt-0" id="nav-tabContent">
                

                <div className="tab-pane show active" id="latest" role="tabpanel" aria-labelledby="following-tab">
                  <div className="proposal_panel_overall">

                    <div className="table-responsive">
                      <table class="table table_style_1">
                        <thead>
                          <tr>
                            <th>
                              <div class="form-group mb-0">
                              </div>
                              Topic
                            </th>
                            <th className="text-right"> Replies </th>
                            <th className="text-right"> Views </th>
                            <th className="text-right"> Activity </th>

                          </tr>
                        </thead>
                        <tbody>
                        {topicList && topicList.length >0 && topicList.map((row,index) => {
                            return (
                          <tr>
                            <td>
                              <Link to={"/discussion-detail/"+row._id}><p className="cat_title mb-0">{row.topic}</p></Link>
                              <Link><p className="cat_link cat_link_green mb-0">{row.category_name}</p></Link>
                            </td>
                            <td className="text-right"> {row.reply_count} </td>
                            <td className="text-right"> {row.views_count} </td>
                            <td className="text-right"> {SecondsToDay(row.created_date)} </td>

                          </tr> )})}
                          
                        </tbody>
                      </table>
                      {showload &&
                      <div className="text-center mt-3">
                          <Button className="create_btn" onClick={loadMore}>Load More</Button>
                        </div>
                        }
                    </div>

                  </div>
                </div>
                
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />

    </div>
  );
}
