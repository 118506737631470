import React, {  
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CountUp from 'react-countup';
// @material-ui/icons
import Slider from "react-slick";
import { MenuItem, Select } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import LoderImg from '../assets/dev/images/loader.gif'
// dev
import $ from 'jquery';
import axios from 'axios';
import config from '../lib/config';
import DETH_ABI from '../ABI/DETH.json';
import BOUI_ABI from '../ABI/BOUI.json';
import { Link,useLocation,useHistory } from "react-router-dom";
import {
  getCurAddr,
  halfAddrShow,
  Topbuyer_List_Action,
  Topseller_List_Action,
  ParamAccountAddr_Detail_Get
} from '../actions/v1/user';

import {
  CollectiblesList_All,
  gettop3lsit,
  getcmsdetails,
  convertion,
  BurnField,
  Bidding_Detail_Action,
  GetCategoryAction,
  getBuyerSeller,
  getsettinglist
} from '../actions/v1/token';

import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import Recentnft  from './separate/Recentnft';
import TokenItem from './separate/Token-Item';
import BidPopup from './separate/Bid-Popup';
import ConnectWallet from './separate/Connect-Wallet';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Countdown, { zeroPad } from "react-countdown";

import Web3 from 'web3';
import '@metamask/legacy-web3'

import { toast } from 'react-toastify';
toast.configure();
let toasterOption     = config.toasterOption;
const dashboardRoutes = [];
const IPFS_IMGurl     = config.IPFS_IMG;

const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const topbuyerseller  = [
  { value: 'buyer', label: 'Buyer' },
  { value: 'seller', label: 'Seller' },
]
const intialState ={
  "TimedAuction":false,
  "FixedPrice":false,
  "UnLimitedAuction":false,
}

export default function Home(props) {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      }
    ]
  };
  const classes = useStyles();
  const location  = useLocation();
  const { ...rest } = props;
  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  const [cmslist,setcmsdetails] = React.useState([]);
  const [top3list, settop3list] = useState([]);
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [HitItem, Set_HitItem] = useState({});
  const [Categorylist, setCategorylist] = React.useState([]);
  const [TokenList, setTokenList] = React.useState([]);
  const [CatName, setCatName] = React.useState('All');
  const [CatBasedTokenList, setCatBasedTokenList] = React.useState({'loader':false,'All':{page:1,list:[],onmore:true}});
  const [Page, setPage] = React.useState(1);
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [providerss,set_providers]=useState(null)
  const [Creatorcount,setCreatorcount] = React.useState(0);
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [item, Set_item] = useState({}); 
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [buyerList, setbuyerList] = React.useState([]);
  const [sellerList, setsellerList] = React.useState([]);
  const [TopSelection, set_TopSelection] = useState('buyer');
  const [filterday,setfilterday]         = useState('today');
  const [buyerSeller, setBuyerSeller]    = useState([]);
  const [filterType, setFilterType]      = useState('');
  const [Tabname,Set_Tabname]            = useState('Home');
  const [onsaletype,setOnsale]            = useState('');
  const [bouiusdvalue, setBouiusdval] = useState(0);


  const [fullLoader, Set_fullLoader] = useState(false);
//muthu
const [MyTokenDetail, Set_MyTokenDetail]    = useState({});
const [convertVal, setConvertVal]           = useState(0);
const [totaluserbidAmt,setTotaluserbidAmt]  = useState(0);
const [Artcount,setArtcount]                = useState(0);
const [CategoryOption, setCategoryOption]   = useState(0);
const [TokenCategory, setTokenCategory]     = useState({label:''});
const [rangeMax, setRangeMax]               = useState(0);
const [rangeMin, setRangeMin]               = useState(0);
const [tokenFav, setTokenFav]               = useState([]);
const [tokenFavstatus, settokenFavstatus]   = useState(false);
const [burnFav, setBurnFav]               = useState([]);
const [burnFavstatus, setburnFavstatus]   = useState(false);
const[MyItemAccountAddr_Details,Set_MyItemAccountAddr_Details]=useState({})

const [settingdata, setsettingsData]   = useState(settings);
const [usdval,setusd]     = useState(0);
const [saleType,setsaleType]     = useState("");

  async function BidApply_ApproveCall() {
    PlaceABidForwardRef.current.BidApply_ApproveCall();
  }
  async function BidApply_SignCall() {
    PlaceABidForwardRef.current.BidApply_ApproveCall();
  }

  async function BiddingAmout(){
    try{
      var currAddr = window.web3.eth.defaultAccount;
      var payload={currAddr}
      var Resp = await Bidding_Detail_Action(payload);
      if(Resp&&Resp.data){
        setTotaluserbidAmt(Resp.data.res1)
      }
    }catch(err){
    }
  }

  async function CategoryListCall() {
    axios
    .get(`${config.vUrl}/token/category/list`)
    .then(response => {
      if (response && response.data && response.data.list) {
        setCategorylist(response.data.list);
      }
    })
    .catch(e => console.log(e))
  }

const onLoadMore = () => {
    CatBasedTokenList[CatName].page = CatBasedTokenList[CatName].page+1;
    setCatBasedTokenList(CatBasedTokenList);
    TokenListCall({
      page : CatBasedTokenList[CatName].page+1
    });
  }
  async function catChange(name) {
    if (name != CatName) {
      setCatName(name);
      if (typeof CatBasedTokenList[name] == 'undefined') {
        var value = []
        if(saleType !=""){
          var value = [saleType]
        }
       
        CatBasedTokenList[name] = { page: 1, list: [], onmore: true };
        setCatBasedTokenList(CatBasedTokenList);
        
        TokenListCall({ CatName: name, page: 1 , Type : filterType , Min : rangeMin, Max : rangeMax,Sale:value});
      }
    }
    $("#nav-tab-dropdown").hide()
  }
  async function cmsdetails(){
    var results = await getcmsdetails();
    setcmsdetails(results.result)
  }

  async function TokenListCall(data = {}) {    
    var currAddr = await getCurAddr();
    var name = CatName;
    if (data.CatName) {
      name = data.CatName
    }


    var Type = {};
    if(data.Type){
      Type = data.Type;
    }else{
      Type = "";
    }

    var Sale = {};
    if(data.Sale){
      Sale = data.Sale;
    }else{
      Sale = "";
    }

    var Min = {};
    if(data.Type){
      Min = data.Min;
    }else{
      Min = 0;
    }

    var Max = {};
    if(data.Max){
      Max = data.Max;
    }else{
      Max = 0;
    }

    var payload = {
      limit: config.limit,
      page: (CatBasedTokenList[name] && CatBasedTokenList[name].page) ? CatBasedTokenList[name].page : 1,
      currAddr: currAddr,
      CatName: name,
      from: 'Home',
      Type : Type,
      Min : Min,
      Max : Max,
      Sale : Sale,

    }
    if(CatBasedTokenList  && CatBasedTokenList[name] && CatBasedTokenList[name].onmore == false){
      payload.page=1
    }
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);
    var resp = await CollectiblesList_All(payload);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    if (resp && resp.data && resp.data.from == 'token-collectibles-list-home' && resp.data.list&&resp.data.list!=undefined && resp.data.list.length > 0) {
      setTokenList(TokenList.concat(resp.data.list));
      if (typeof CatBasedTokenList[name] == 'undefined') {
        CatBasedTokenList[name] = { page: 1, list: [] };
      }
      if(data.onloading == "onloading"){
       CatBasedTokenList[name].list = CatBasedTokenList[name].list.concat(resp.data.list); 
      }
      else{
         CatBasedTokenList[name].list = resp.data.list;
       }      
      // CatBasedTokenList[name].list = resp.data.list;
      setCatBasedTokenList([]);
      CatBasedTokenList[name].onmore =true
      setCatBasedTokenList(CatBasedTokenList);
    }
    else {
      CatBasedTokenList[name].onmore = false;
      setCatBasedTokenList([]);
      setCatBasedTokenList(CatBasedTokenList);
    }
  }

  useEffect(() => {
    getInit();
    getTopbuyerSeller();
    getBuyerSellerFun('buyer');
    getTokenFav();
    getBurnFav();
    getUsd();
    var hash = window.location.hash.substr(1);
    if(hash=="explore_12"){
      document.getElementById('explore_12').scrollIntoView(true);
    }
  }, []);

  async function getBouiUsd() {
    try {
      var { result } = await getsettinglist();
      if (result && result.data) {
      console.log("homeusdval",result.data.bouiusd)
        setBouiusdval(result.data.bouiusd)
      }
    } catch (err) { }
  }

  async function getUsd(){
    await axios.get("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
          .then(async (response2) => {
           setusd(response2.data.USD)
      })
  }

  async function getTokenFav() {
    axios
      .get(`${config.vUrl}/token/tokenfavourite`)
      .then(response => {
        if (response && response.data && response.data.data) {
          settingdata.slidesToShow = (response.data.data)?response.data.data.length:0
          setsettingsData(settingdata)
          setTokenFav(response.data.data);
          settokenFavstatus(true)
        }
      })
      .catch(e => console.log(e))
  }

  async function getBurnFav() {
    try{
      var currAddr = window.web3.eth.defaultAccount;
    var payload={
      currAddr:currAddr
    } 
    axios
      .post(`${config.vUrl}/token/burnfavourite`,payload)
      .then(response => {
        if (response && response.data) {
          setBurnFav(response.data.data);
          setburnFavstatus(true)
        }
      })
      .catch(e => console.log(e))
    }catch(err){}
  }
  async function getTopbuyerSeller() {

    var resp = await Topbuyer_List_Action();
    var list = (resp && resp.data && resp.data.list) ? resp.data.list : []
    setbuyerList(list)

    setTimeout(async function () {
      var sellerList = await Topseller_List_Action();
      var list1 = (sellerList && sellerList.data && sellerList.data.list) ? sellerList.data.list : []
      setsellerList(list1)
    }, 500)
  }
  async function getInit() {
    CategoryListCall();
    TokenListCall();
    top3listcall();
    cmsdetails();
    BiddingAmout();
    //tokenbal();
  }
  async function AfterWalletConnected() {
    var curAddr = await getCurAddr();
    if (window.ethereum) {
      var web3              = new Web3(window.ethereum);
      var BouiContract   = new web3.eth.Contract(DETH_ABI, config.tokenAddress);
      var decimal        = await BouiContract.methods.decimals().call();
      var tokenBal       = await BouiContract.methods.balanceOf(curAddr).call();
      var tokenBalance      = tokenBal / config.decimalvalues;
      Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
    }
    LikeForwardRef.current.getLikesData();
    GetCategoryCall();
  }

  
  // async function AfterWalletConnected() {
  //   Get_MyItemAccountAddr_Details();
  //   LikeForwardRef&&LikeForwardRef.current&&LikeForwardRef.current.getLikesData();
  // }

  // const Get_MyItemAccountAddr_Details = async (payload) => {
  //   var Resp = await ParamAccountAddr_Detail_Get(payload);
  //   if(
  //     Resp
  //     && Resp.data
  //     && Resp.data.User
  //     && Resp.data.User.curraddress
  //   ) {
  //     Set_MyItemAccountAddr(Resp.data.User.curraddress);
  //     if(Resp.data.User) {
  //       Set_MyItemAccountAddr_Details(Resp.data.User);
  //     }
  //   }
    
  // }
  async function GetCategoryCall() {
    var resp = await GetCategoryAction();
    if (resp && resp.data && resp.data.list) {
      var CategoryOption = [];
      resp.data.list.map((item) => {
        CategoryOption.push({
          name:'TokenCategory',
          value: item._id,
          label: item.name
        })
      })
      setCategoryOption(CategoryOption)
    }
  }

  async function top3listcall(){
    var Resp = await gettop3lsit();
    if(Resp && Resp.data && Resp.data.list){
    settop3list(Resp.data.list)
    }
  }
  const selectChange = (e) => {
    if(e && e.name && e.label && e.value) {
      switch(e.name) {
        case 'TokenCategory':
          setTokenCategory(e);
          break;
        default:
          // code block
      }
    }
  }
  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }
    async function TopNewSelector(value){
    set_TopSelection(value)
    getBuyerSellerFun({filterBuyerSeller : value,filterTime :filterday});
  }
  const getBuyerSellerFun = async(filter ={}) => {
    var filterBuyerSeller = {}
    var filterTime = {}
    var Buyer = {};
    if(filter.filterBuyerSeller){
      Buyer = filter.filterBuyerSeller
    }else{
      Buyer = 'buyer'
    }
    var filterTime = {};
    if(filter.filterTime){
      filterTime = filter.filterTime
    }else{
      filterTime = 'today'
    }
   var payload = {
      buyerSellerFilter : Buyer,
      buyerSellerTimeFilter : filterTime,
    }
    const response = await getBuyerSeller(payload);
    if (response && response.data && response.data.success ) {
      // console.log("BuyerSeller........>>>>",response.data.list);
      setBuyerSeller(response.data.list);
    }
  }
  
  const [state, setState] = useState(intialState);
  const {TimedAuction, FixedPrice, UnLimitedAuction} = state;

  async function changeSale(e) {
    // console.log("vvvvvvv",e.target.value,checked)
   var value=e;
   // setState({ ...state,...{"setsaleType":value}})
    setsaleType(value)
  }

  async function  onSale() {    
    var value = []
    if(saleType !=""){
      var value = [saleType];
    }
    
    console.log("location.pathname",location.pathname)
    
setOnsale(state)
    TokenListCall({ Type : filterType , Min : rangeMin , Max : rangeMax , Sale : value
    })
    $("#nav-tab-sale").hide()
  }
  async function onCancel() {
    setState(intialState)
    $("#nav-tab-dropdown").hide()
   $("#nav-tab-recently").hide()
   $("#nav-tab-price").hide()
   $("#nav-tab-sale").hide()
 }
 async function onSaleChange(){
  $("#nav-tab-dropdown").hide()
  $("#nav-tab-recently").hide()
  $("#nav-tab-price").hide()
  $("#nav-tab-sale").show()
}
async function OnHideRecent(){
  $("#nav-tab-dropdown").hide()
  $("#nav-tab-price").hide()
  $("#nav-tab-sale").hide()
  $("#nav-tab-recently").show()
}

  const options = [
    { label: "Timed auction ", value: "TimedAuction" },
    { label: "Fixed price ", value: "FixedPrice" },
    // { label: "Not for sale ", value: "Not for sale" },
    { label: "Open for offers ", value: "UnLimitedAuction"},
  ]
  const day_filter  = [
    { value: 'today', label: '1 day' },
    { value: 'sevendays', label: '7 days' },
    { value: 'thirtydays', label: '30 days' }
  ]
  const BuySellFilter= (filter) =>{
    setfilterday(filter)
    var tok = filter
    getBuyerSellerFun({filterBuyerSeller : TopSelection,filterTime :tok});
  }
  async function  onRecent(value) {
    setFilterType(value)
    var value1=[];
    if(saleType !=""){
      var value1= [saleType]
    }
   TokenListCall({ Type : value , Min : rangeMin, Max : rangeMax,Sale:value1 })
   $("#nav-tab-recently").hide()
  }
  async function onRangeMax(e) {
    setRangeMax(e.target.value)
  }
  async function onRangeMin(e) {
    setRangeMin(e.target.value)
  }
  

  // Countdown Timer
  const currentDate = new Date();
  const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">d</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">h</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">m</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">s</span></span>
      </div>
    );
  };

  return (
    
    <div className="home_header">
      <ConnectWallet
      Set_UserAccountAddr={Set_UserAccountAddr}
      Set_UserAccountBal={Set_UserAccountBal}
      Set_WalletConnected={Set_WalletConnected}
      Set_AddressUserDetails={Set_AddressUserDetails}
      Set_Accounts={Set_Accounts}
      WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
        set_providers={set_providers}
        providerss={providerss}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected = {Set_WalletConnected}
        Set_UserAccountAddr = {Set_UserAccountAddr}
        Set_UserAccountBal = {Set_UserAccountBal}
        Set_AddressUserDetails = {Set_AddressUserDetails}
        Set_Accounts = {Set_Accounts}
        Set_MyItemAccountAddr = {Set_MyItemAccountAddr}
        Set_tokenCounts = {Set_tokenCounts}
        Set_item = {Set_item}
        Set_tokenCounts_Detail = {Set_tokenCounts_Detail}
        Set_MyTokenBalance = {Set_MyTokenBalance}
        Set_Bids = {Set_Bids}
        Set_AccepBidSelect = {Set_AccepBidSelect}
        Set_tokenBidAmt = {Set_tokenBidAmt}
        Set_NoOfToken = {Set_NoOfToken}
        Set_ValidateError = {Set_ValidateError}
        Set_TokenBalance = {Set_TokenBalance}
        Set_YouWillPay = {Set_YouWillPay}
        Set_YouWillPayFee = {Set_YouWillPayFee}
        Set_YouWillGet = {Set_YouWillGet}
        Set_BidApply_ApproveCallStatus = {Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus = {Set_BidApply_SignCallStatus}
        WalletConnected = {WalletConnected}
        UserAccountAddr = {UserAccountAddr}
        UserAccountBal = {UserAccountBal}
        AddressUserDetails = {AddressUserDetails}
        Accounts = {Accounts}
        MyItemAccountAddr = {MyItemAccountAddr}
        tokenCounts = {tokenCounts}
        item = {item}
        tokenCounts_Detail = {tokenCounts_Detail}
        MyTokenBalance = {MyTokenBalance}
        Bids = {Bids}
        AccepBidSelect = {AccepBidSelect}
        tokenBidAmt = {tokenBidAmt}
        NoOfToken = {NoOfToken}
        ValidateError = {ValidateError}
        TokenBalance = {TokenBalance}
        YouWillPay = {YouWillPay}
        YouWillPayFee = {YouWillPayFee}
        YouWillGet = {YouWillGet}
        BidApply_ApproveCallStatus = {BidApply_ApproveCallStatus}
        BidApply_SignCallStatus = {BidApply_SignCallStatus}
        AllowedQuantity = {AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
      <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
       <ReportNowRef
        UserAccountAddr={UserAccountAddr}
        ref={ReportForwardRef}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid brnadLogo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      
      <div className="main mar_top_home margin_new">
          {/*  Explore Section */}
         <section className="section explore_section section_bg_back" id="explore_121" >
              <div className="container">
                <div className="homeFlexTitle">
                  <div className="exploreSelect">
{(location.pathname=="/home") ?
                  <a href="#explore_121" id="aaaa"  className={classes.navLink} >
                    <h2 data-aos="slide-up">
                      Search
                      </h2>
                    </a>
                    :
                    <Link className={classes.navLink} id="bbb" to="/home#explore_12">Search</Link>
                    }
                    <div className="exploreSortBy customSelectDrop" data-aos="slide-up">
                      
                    <Select defaultValue={0}>
                        <MenuItem  value={0}>Category</MenuItem>
                        <MenuItem onClick={() => catChange('All')} value={1}>All{CatName && CatName=="All" ? <i class="fas fa-check"></i> : "" }</MenuItem>
                        {
                        Categorylist.map((item) => {
                          return (
                            <>
                         <MenuItem onClick={()=>catChange(item.name)}>{item.name}{CatName && CatName==item.name ? <i class="fas fa-check"></i> : "" }</MenuItem>
                         </>
                          )
                        })
                      }
                    </Select>
               
                 </div>
                <div className="exploreSortBy customSelectDrop" data-aos="slide-up">
                <p className="mb-0">
                <a class="btn btn-primary explore_menue_nutton saleTypeButton" onClick={onSaleChange} data-toggle="collapse" href="#Sale_type" role="button" aria-expanded="false" aria-controls="Sale_type">
                Sale type<i class="fas fa-chevron-down"></i>
                </a>
                  </p>
                <div class="collapse drobdow_toggle_menu" id="Sale_type">
                  <div class="card card-body" id="nav-tab-sale">
                     
                     <form>
                     <div class="form-group_1">
                      <input type="radio" onChange={()=>changeSale("TimedAuction")} value={TimedAuction} id="css" name="TimedAuction"/>
                      <label for="css"> <span className="span_drob_down_text">
                        {/* <img src={require("../assets/images/user_02.png")} alt="" /> */}
                        <span>Timed auction</span></span></label>
                      </div>
                      <div class="form-group_1">
                      <input type="radio" onChange={()=>changeSale("FixedPrice")} value={FixedPrice} id="css1" name="TimedAuction"/>
                      <label for="css1"> <span className="span_drob_down_text">
                        {/* <img src={require("../assets/images/user_02.png")} alt="" /> */}
                        <span>Fixed price</span></span></label>
                      </div>
                      
                      </form>
                     <div >
                       <div className="card_fotter">
                         <button onClick={onCancel}>cancel</button>
                         <button className="apply_qw" onClick={onSale}>Apply</button>
                       </div>
                     </div>
                     </div>
                  </div>
                </div>
                  </div>
                  <div className="exploreSortBy customSelectDrop" data-aos="slide-up">
             
                   <Select defaultValue={0}>
                        <MenuItem value={0}>Sort By</MenuItem>
                        <MenuItem value={10} onClick={() => onRecent("All")}>All</MenuItem>
                        <MenuItem value={20} onClick={() => onRecent("recentlyadded")}>Recently Added</MenuItem>
                        <MenuItem value={30} onClick={() => onRecent("lowtohigh")}>Price Low to High</MenuItem>
                        <MenuItem value={40} onClick={() => onRecent("hightolow")}>Price High to Low</MenuItem>
                        {/* <MenuItem value={50} onClick={() => onRecent("auctionsoon")}>Auction Ending Soon</MenuItem> */}
                    </Select> 
                </div>
                </div>
              <div className="explore_sec_img" data-aos="slide-up">
                  <div className="masonry">
                  {
                    (CatBasedTokenList && CatName && CatBasedTokenList[CatName] && CatBasedTokenList[CatName].list &&
                       CatBasedTokenList[CatName].list.length > 0)?
                      (CatBasedTokenList[CatName].list.map((item) => {
                        console.log("list_image",CatBasedTokenList[CatName].list.length)
                      return (
                        <TokenItem
                          bouiusdvalue={bouiusdvalue}
                          setBouiusdval={setBouiusdval}
                          Tabname={Tabname}
                          Set_Tabname={Set_Tabname}
                          item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current.hitLike}
                          UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {Bids}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          WalletConnected={WalletConnected}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                        />
                      )
                    })):('No record Found')
                  } 
                  </div>
                 
                </div>
           
          </div>

        </section>
      </div>
      <Footer />



      {/* place_bid Modal */}
      <div class="modal fade primary_modal" id="place_bid_modal" tabindex="-1" role="dialog" aria-labelledby="place_bid_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="place_bid_modalLabel">Place a bid</h5>
              <p className="text-center place_bit_desc">You are about to place a bid for</p>
              <p className="place_bit_desc_2"><span className="text_red mr-2">Uniaqua</span>by<span className="text_red ml-2">NFTbuyer</span></p>


              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body px-0 pt-0">
              <form className="px-4 bid_form">
                <label for="bid">Your bid</label>
                <div class="input-group mb-3 input_grp_style_1">
                  <input type="text" id="bid" class="form-control" placeholder="Enter your display name" aria-label="bid" aria-describedby="basic-addon2" />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">ETH</span>
                  </div>
                </div>

                {/* <label for="qty">Enter quantity <span className="label_muted pl-2">(30 available)</span></label>
                        <div class="mb-3 input_grp_style_1">
                      <input type="text" id="qty" class="form-control" placeholder="1"  />
                    
                    </div> */}
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">10.25 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your bidding balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">0.012 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>

                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal">Place a bid</Button>

                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal */}

      {/* proceed_bid Modal */}
      <div class="modal fade primary_modal" id="proceed_bid_modal" tabindex="-1" role="dialog" aria-labelledby="proceed_bid_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="proceed_bid_modalLabel">Follow Steps</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Done</Button>
                </div>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Signature</p>
                    <p className="mt-0 approve_desc">Create a signatute to place a bid</p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" disabled>Start</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed_bid modal */}

      {/* accept bid Modal */}
      <div class="modal fade primary_modal" id="accept_modal" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="accept_modalLabel">Accept bid</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body px-0">
              <div className="img_accept text-center">
                <img src={require("../assets/images/img_info.png")} alt="Collections" className="img-fluid" />
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to accept bid for</span>
                <span className="buy_desc_sm_bold pl-2">Uniaqua</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold pl-2">0xb6631a76645...b7de</span>
              </p>
              <p className="info_title text-center">0 ETH for 1 edition(s)</p>


              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">0.012 ETH</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will get</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">1.112 ETH</p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close">Accept bid</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}


      {/* Share Modal */}
      <div class="modal fade primary_modal" id="share_modal" tabindex="-1" role="dialog" aria-labelledby="share_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="share_modalLabel">Share link to this page</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="share_nft_modal">
                <a href="#" target="_blank">
                  <i class="fab fa-telegram-plane"></i>
                  <p>Telegram</p>
                </a>
                <a href="#" target="_blank">
                  <i class="fab fa-twitter"></i>
                  <p>Twitter</p>
                </a>
                <a href="#" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                  <p>Facebook</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Share Modal */}

      {/* Report Modal */}
      <div class="modal fade primary_modal" id="report_modal" tabindex="-1" role="dialog" aria-labelledby="report_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="report_modalLabel">Why are you reporting?</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Tell us how this user violates the rules of the site</p>
              <form>
                <label className="primary_label" htmlFor="name">Message</label>
                <textarea className="form-control primary_inp" rows="3" placeholder="Tell us some details"></textarea>
                <div className="report_btn_grp mt-3">
                  <Button className="create_btn">Report</Button>
                  <Button className="cancel_btn ml-2">Cancel</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Report Modal */}


    </div>
  );
}
