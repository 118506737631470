import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import HeaderLinksCommunity from "components/Header/HeaderLinksCommunity.js";
import Header from "components/Header/Header.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link,useParams,useHistory } from "react-router-dom";
import {changepassword } from '../actions/community';
import Web3 from 'web3';
import '@metamask/legacy-web3';
import isEmpty from '../lib/isEmpty';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const initialFormValue = {
  'new_password': "",
  'confirm_password':"",
}
 toast.configure();
  let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  }
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ResetPassword(props) {
  const classes = useStyles();
  const { id}           = useParams();
  console.log("tokenOwner",id)
  const history                           = useHistory();
  const [formValue, setFormValue]         = useState(initialFormValue);
  const [errorValue, seterrorValue]       = useState({});
  const { ...rest } = props;

  const onChange = (e) => {
      e.preventDefault();
      const { id, value } = e.target;
      let formData = { ...formValue, ...{ [id]: value } }
      setFormValue(formData)
    }
      const {
       new_password,
        confirm_password,
    } = formValue
     const handleSubmit = async (e) => {
     e.preventDefault();
      var web3        = new Web3(window.ethereum);
      var currAddr    = window.web3.eth.defaultAccount;

       let reqData={
            new_password,
            confirm_password,
            id
        }
        console.log("reqData:",reqData)
        const {error} =await changepassword(reqData);
        console.log("likepassword:",error)
        if (error!=undefined) {
          console.log("error:",error)
        if (isEmpty(error.errors)) {
       // console.log("error:",error)
     //  toast.success("Password has been changed successfully", toasterOption)
      }else{
         seterrorValue(error.errors)
      }
    }else{
      if (isEmpty(error)) {
       // console.log("error:",error)
       toast.success("Password has been changed successfully", toasterOption);
       history.push("/community")
      }else{
         seterrorValue(error.errors)
      }
    }
   }
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinksCommunity />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-center">
                <h2 className="inner_title mb-4">Reset Password</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="create_box w-50 reset_box mx-auto">
                <form className="login_form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label for="new_password">New Password</label>
                    <input type="password" id="new_password" name="new_password" onChange={onChange} class="form-control" placeholder="Enter New Password" />
                  <span style={{color:"red"}}>{errorValue.new_password}</span>
                  </div>
                  <div className="form-group">
                    <label for="confirm_password">Confirm Password</label>
                    <input type="password" id="confirm_password" name="confirm_password" onChange={onChange} class="form-control" placeholder="Enter Confirm Password" />
                  <span style={{color:"red"}}>{errorValue.confirm_password}</span>
                  </div>
                  <div className="text-center">
                    <Button className="primary_btn" type="submit">Submit</Button>
                  </div>
                </form>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
