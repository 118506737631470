import React, { useEffect,useRef,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link,useParams,useHistory } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import Web3 from 'web3';
import moment from 'moment';
import config from '../lib/config';
import DETH from '../ABI/DETH.json';
import BOUI_ABI from '../ABI/BOUI.json';
import BEP721 from '../ABI/BEP721.json';
import BEP1155 from '../ABI/BEP1155.json';
import isEmpty from "../lib/isEmpty";
import axios from 'axios';
import ReactLoading from "react-loading";

import {
  getCurAddr,
  halfAddrShow,
  Activity_List_Action,
  HistoryActivity_List_Action,
  getWallet,
} from '../actions/v1/user';

import {
  TokenCounts_Get_Detail_Action,
  BidApply_ApproveAction,
  acceptBId_Action,
  Bidding_Detail_Action
} from '../actions/v1/token';
import {
  getsettinglist
} from '../actions/v1/token';
import ConnectWallet from './separate/Connect-Wallet';
import { WalletRef } from './separate/WalletRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { PurchaseNowRef } from "./separate/PurchaseNowRef";
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { LikeRef } from './separate/LikeRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { ShareNowRef } from './separate/ShareNowRef';
import { toast } from 'react-toastify';
import { ownerPrice } from "actions/info";
const dashboardRoutes = [];
toast.configure();
let toasterOption     = config.toasterOption;
const singleContract  = config.singleContract;
const multipleContract= config.multipleContract;
const tokenAddress    = config.tokenAddress;
const IPFS_IMGurl     = config.IPFS_IMG;


const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}



export default function Info(props) {
  const classes = useStyles();

  var LikeForwardRef        = useRef();
  var PlaceABidForwardRef   = useRef();
  var PutOnSaleForwardRef   = useRef();
  var PurchaseNowForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef      = useRef();
  var ShareForwardRef       = useRef();

  async function BidApply_ApproveCall() {
    PlaceABidForwardRef.current.BidApply_ApproveCall();
  }
  async function BidApply_SignCall() {
    PlaceABidForwardRef.current.BidApply_ApproveCall();
  }

  var { tokenidval } = useParams();

  // wallet related : common state
  const [WalletConnected, Set_WalletConnected]       = useState('false');
  const [UserAccountAddr, Set_UserAccountAddr]       = useState('');
  const [UserAccountBal, Set_UserAccountBal]         = useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts]                     = useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr]   = useState('');
  const [tokenCounts, Set_tokenCounts]               = useState(tokenidval);
  const [item, Set_item]                             = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity]       = useState({});
  const [MyTokenBalance, Set_MyTokenBalance]         = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail]           = useState({});
  const [Bids, Set_Bids]                             = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect]         = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt]               = useState(0);
  const [NoOfToken, Set_NoOfToken]                   = useState(1);
  const [ValidateError, Set_ValidateError]           = useState({});
  const [TokenBalance, Set_TokenBalance]             = useState(0);
  const [YouWillPay, Set_YouWillPay]                 = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee]           = useState(0);
  const [YouWillGet, Set_YouWillGet]                 = useState(0);
  const [servicefee, setservicefee]                  = useState(0);
  const [LikedTokenList, setLikedTokenList]          = useState([]);
  const [ActivityList, Set_ActivityList]             = useState([]);
  const [Activitycount, Set_Activitycount]           = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = useState('init');
  const [HitItem, Set_HitItem]                       = useState({});
  const [BuyOwnerDetail, Set_BuyOwnerDetail]         = useState({});
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [totaluserbidAmt,setTotaluserbidAmt]         = useState(0);
  const [usdval,setusd]                              = useState(0);
  const [onwer_price,set_owner_price]                = useState({}); 
  const [displayimage,setDisplayimg]                 = useState("");
  const [Loaderimage,setLoaderimage]                 = useState(false);
  const [Loaderstatus, Set_Loaderstatus] = React.useState(false);
  const [Tabname,Set_Tabname]                     = useState('Info');
  const [bouiusdval,setBouiusdval]     = useState(0);
  const [totcollection,Set_Totalcollection]     = useState(0);
  const [walletaddr, setWalletaddr]               = useState("");
  const [fullLoader, Set_fullLoader] = useState(false);
  const [providerss, set_providers] = useState(null)



  useEffect(() => {
  myfunction()
  }, []);

  async function myfunction(){
    let web3 =new Web3(window.web3.currentProvider)
    let chainid =  await web3.eth.getChainId();
    chainid= chainid.toString()
    console.log("chaindid",chainid,config.networkVersion)
    if(window.web3 && chainid === config.networkVersion){
      console.log("web3 found");
    }else{
    AfterWalletConnected()
    }
  }

  async function  getwalletlist(){
    try{
      var currAddr = await getCurAddr();
      var payload={
        address:currAddr
      }
      var {result} = await getWallet(payload)
      if(result&&result.data){
        setWalletaddr(result.data.address)
      }
    }catch(err){}
  }

  const AfterWalletConnected = async () => {
    let curAddr = await getCurAddr();
    var payload = {
      curAddr:curAddr,
      tokenCounts:tokenidval
    };
    console.log(payload,"payload")
    TokenCounts_Get_Detail_Call(payload);
    BiddingAmout();
    getServicefees()
    getwalletlist();
    getUsd();
    Get_Activity_list()
    getBouiUsd()
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      var CoursetroContract = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
      var tokenBal = await CoursetroContract.methods.balanceOf(curAddr).call();
      var tokenBalance = tokenBal / config.decimalvalues;
      Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
    }

    LikeForwardRef.current.getLikesData();
  }
  async function getBouiUsd(){
    try{
      var {result} = await getsettinglist();
        console.log("getsettinglist",result)
        if(result&&result.data){
          setBouiusdval(result.data.bouiusd)
        }
    }catch(err){}
  }

  async function Get_Activity_list(){
    try{
      var resp = await HistoryActivity_List_Action({
        tokenCounts:tokenidval
      });
      if(
        resp
        && resp.data
        && resp.data.list
      ) {
        var List = resp.data.list;
        console.log("getnotification111111:",List)
        if (List.length>0) {
          Set_Activitycount(List.length)
        }
        Set_ActivityList(List);
      }
      else {
        Set_ActivityList([]);
      }
      console.log("HistoryActivity_List_Action",HistoryActivity_List_Action)
    //   var currAddr = window.web3.eth.defaultAccount;
    // var resp = await Activity_List_Action({
    //   addr:currAddr
    // });
   
    // if(
    //   resp
    //   && resp.data
    //   && resp.data.list
    // ) {
    //   var List = resp.data.list;
    //   console.log("getnotification111111:",List)
    //   if (List.length>0) {
    //     Set_Activitycount(List.length)
    //   }
    //   Set_ActivityList(List);
    // }
    // else {
    //   Set_ActivityList([]);
    // }
    }catch(err){}
  }
 
  async function getUsd() {
    let result = await axios.get(`${config.vUrl}/user/getUSD`);
    if(result){
      let currency = result.data;
      console.log(currency.data[0].Current_USD,'resss')
      let usd =currency.data[0].Current_USD;
      setusd(usd)
    }
  }
    const TokenCounts_Get_Detail_Call = async (payload) => {
      console.log('------test halfAddrShow : ');
      var curAddr = await getCurAddr();
      console.log(curAddr,"tokencounts___");
      var Resp = await TokenCounts_Get_Detail_Action(payload);
      if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
  
        var TokenResp = Resp.data.Detail.Resp;
        console.log("responsedata:",TokenResp)
        if(
          TokenResp
          && TokenResp.Token
          && TokenResp.Token[0]
          && TokenResp.Token[0].tokenowners_current
        ) {
  
          for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
            const element = TokenResp.Token[0].tokenowners_current[i];
            console.log("owner_price:",element)
            set_owner_price(element)
            if(element.balance > 0 && element.tokenPrice > 0 && element.tokenOwner != curAddr) {
              Set_BuyOwnerDetailFirst(element);
              break;
            }
          }
  
  
        }
        Set_tokenCounts_Detail(TokenResp);
  
        if(TokenResp.Bids) {
          Set_Bids(TokenResp.Bids);
        }
        let ageSum = 0;
        let totalSum=0;
      var tokenowners_all = TokenResp.Token[0].tokenowners_all;
      console.log("balnce:",tokenowners_all)
      if (tokenowners_all && tokenowners_all.length > 0) {
        for (let i = 0; i < tokenowners_all.length; i++) {
          if(tokenowners_all[i].balance>0&&tokenowners_all[i].burnorboui!="burn"&&tokenowners_all[i].tokenOwner!=curAddr){
            ageSum += tokenowners_all[i].balance;
          }
          if(tokenowners_all[i].tokenPrice>0&&tokenowners_all[i].burnorboui!="burn"){
            totalSum += tokenowners_all[i].balance;
          }
        }
      }
      Set_Totalcollection(totalSum)
  
        var IndexVal = -1;
        if(TokenResp&&TokenResp.Token.length>0){
          if(TokenResp.Token[0].tokenowners_all && curAddr) {
            var tokenowners_all = TokenResp.Token[0].tokenowners_all;
            IndexVal = tokenowners_all.findIndex(val => val.tokenOwner == curAddr);
          }
        }
        

        var newMyTokenBalance = 0;
        if(IndexVal > -1) {
          newMyTokenBalance = tokenowners_all[IndexVal].balance
          Set_MyTokenBalance(newMyTokenBalance);
          Set_MyTokenDetail(tokenowners_all[IndexVal]);
        }
        else {
          newMyTokenBalance = 0;
          Set_MyTokenBalance(0);
          Set_MyTokenDetail({});
        }
  
        if(ageSum) {
          console.log("check_balance:",ageSum,newMyTokenBalance)
          Set_AllowedQuantity(ageSum);
        }
        else {
          Set_AllowedQuantity(0);
        }
        if(TokenResp.Token && TokenResp.Token[0]) {
          console.log("biddingtoken",TokenResp&&TokenResp.Token[0]&&TokenResp.Token[0].tokenowners_current[0].biddingtoken)
            try{
              if (window.ethereum) {
                var web3 = new Web3(window.ethereum);
                if(TokenResp.Token[0].type==721){
                  var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
                  if(TokenResp&&TokenResp.Token[0]&&TokenResp.Token[0].tokenowners_current[0].biddingtoken==config.currencySymbol){
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  }else  if(TokenResp&&TokenResp.Token[0]&&TokenResp.Token[0].tokenowners_current[0].biddingtoken==config.owntokenSymbol){
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  }else  if(TokenResp&&TokenResp.Token[0]&&TokenResp.Token[0].tokenowners_current[0].biddingtoken==config.usdtokenSymbol){
                    var fee = await CoursetroContract.methods.getBUSDFee().call();
                  }
                  else{
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  }
                }else{
                  var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
                  if(TokenResp&&TokenResp.Token[0]&&TokenResp.Token[0].tokenowners_current[0].biddingtoken==config.currencySymbol){
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  }else  if(TokenResp&&TokenResp.Token[0]&&TokenResp.Token[0].tokenowners_current[0].biddingtoken==config.owntokenSymbol){
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  }else  if(TokenResp&&TokenResp.Token[0]&&TokenResp.Token[0].tokenowners_current[0].biddingtoken==config.usdtokenSymbol){
                    var fee = await CoursetroContract.methods.getBUSDFee().call();
                  }else{
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  }
                }
                var feeValue = fee/config.decimalvalues;
                setservicefee(feeValue);
              }
            }catch(err){
            }
          // }
          console.log("list_item:",TokenResp.Token[0])
          Set_Loaderstatus(true)
          Set_item(TokenResp.Token[0]);
        } 
      }
    }
    async function getServicefees(){
    }
  try{
    var currDate = new Date();
    var startdate = new Date(item.clocktime);
    var enddate = new Date(item.endclocktime);
    var auction = "false";
    var finish = "";
    var enddate1 = "";
    var showlist = "true";
    var display_item = 0;
    if (item.type==721&&item.PutOnSaleType=="TimedAuction") {
        auction = "true";
        var a = moment(item.clocktime);
        var b = moment(item.endclocktime);
        var c = moment();
        a.diff(b); // 86400000
        var diffInMs = a.diff(c);
        display_item = a.diff(c)
        finish = b.diff(c);
        enddate1 = parseFloat(diffInMs);
       if (finish > 0) {
        showlist = "true";
        } else {
        var auctionTxt = "Ended";
        showlist = "false";
        }
    }
  }catch(err){}
  
  async function BiddingAmout(){
    try{
      var currAddr = window.web3.eth.defaultAccount;
      var payload={currAddr}
      var Resp = await Bidding_Detail_Action(payload);
      if(Resp&&Resp.data){
        setTotaluserbidAmt(Resp.data.res1)
      }
    }catch(err){
    }
  }
  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  async function showAllwithPro(data) {
    var curAddr = await getCurAddr();
    var payload = {
      curAddr:curAddr,
      tokenCounts:data.tokenCounts
    };
    TokenCounts_Get_Detail_Call(payload);
}
async function imagedisplay(imgs){
  setDisplayimg(imgs)
}

  // Countdown Timer
  const currentDate = new Date();
  const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span></span>
    } else {
        return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
};
const renderer1 = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
  if (completed) {
      return <span></span>
  } else {
      return <span>{days}d  {hours}h {minutes}m {seconds}s to start</span>;
  }
};
  const { ...rest } = props;
  function hideDetail() {
    document.getElementById("image_div").classList.toggle('expand_img');
    document.getElementById("img_des_owner").classList.toggle('show_des');
    document.getElementById("detai_div").classList.toggle('hide_detail');
    document.getElementById("arrow_icon").classList.toggle('fa-shrink');

  }

  function hideDetailowner() {
    document.getElementById("image_div_owner").classList.toggle('expand_img');
    document.getElementById("img_des_owner").classList.toggle('show_des');
    document.getElementById("detai_div_owner").classList.toggle('hide_detail');
    document.getElementById("arrow_icon_owner").classList.toggle('fa-shrink');
  }
  return (
    <div className="inner_header">
       <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
        set_providers={set_providers}
        providerss={providerss}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        walletaddr={walletaddr}
        setWalletaddr={setWalletaddr}
        Set_AddressUserDetails={Set_AddressUserDetails}
        AddressUserDetails={AddressUserDetails}
        AllowedQuantity={AllowedQuantity}
        Set_AllowedQuantity={Set_AllowedQuantity}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
       <ShareNowRef
        ref={ShareForwardRef}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_Tabname={Set_Tabname}
        Tabname={Tabname}
        Set_WalletConnected = {Set_WalletConnected}
        Set_UserAccountAddr = {Set_UserAccountAddr}
        Set_UserAccountBal = {Set_UserAccountBal}
        Set_AddressUserDetails = {Set_AddressUserDetails}
        Set_Accounts = {Set_Accounts}
        Set_MyItemAccountAddr = {Set_MyItemAccountAddr}
        Set_tokenCounts = {Set_tokenCounts}
        Set_item = {Set_item}
        Set_tokenCounts_Detail = {Set_tokenCounts_Detail}
        Set_MyTokenBalance = {Set_MyTokenBalance}
        Set_Bids = {Set_Bids}
        Set_AccepBidSelect = {Set_AccepBidSelect}
        Set_tokenBidAmt = {Set_tokenBidAmt}
        Set_NoOfToken = {Set_NoOfToken}
        Set_ValidateError = {Set_ValidateError}
        Set_TokenBalance = {Set_TokenBalance}
        Set_YouWillPay = {Set_YouWillPay}
        Set_YouWillPayFee = {Set_YouWillPayFee}
        Set_YouWillGet = {Set_YouWillGet}
        Set_BidApply_ApproveCallStatus = {Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus = {Set_BidApply_SignCallStatus}
        WalletConnected = {WalletConnected}
        UserAccountAddr = {UserAccountAddr}
        UserAccountBal = {UserAccountBal}
        AddressUserDetails = {AddressUserDetails}
        Accounts = {Accounts}
        MyItemAccountAddr = {MyItemAccountAddr}
        tokenCounts = {tokenCounts}
        item = {item}
        tokenCounts_Detail = {tokenCounts_Detail}
        MyTokenBalance = {MyTokenBalance}
        Bids = {Bids}
        AccepBidSelect = {AccepBidSelect}
        tokenBidAmt = {tokenBidAmt}
        NoOfToken = {NoOfToken}
        ValidateError = {ValidateError}
        TokenBalance = {TokenBalance}
        YouWillPay = {YouWillPay}
        YouWillPayFee = {YouWillPayFee}
        YouWillGet = {YouWillGet}
        BidApply_ApproveCallStatus = {BidApply_ApproveCallStatus}
        BidApply_SignCallStatus = {BidApply_SignCallStatus}
        AllowedQuantity={AllowedQuantity}
        Set_AllowedQuantity={Set_AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
        walletaddr={walletaddr}
        setWalletaddr={setWalletaddr}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        Set_AddressUserDetails={Set_AddressUserDetails}
        AddressUserDetails={AddressUserDetails}
        AllowedQuantity={AllowedQuantity}
        Set_AllowedQuantity={Set_AllowedQuantity}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid brnadLogo" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />

      <div className={classes.pageHeader + " inner_pageheader info_header"}>
        {/* info row */}
       {Loaderstatus==false?
         <div className="loader_section_">
         <ReactLoading type={"spinningBubbles"} color="#f14b8a" className="loading" />
      </div>
      :
        <div className="row info_row mx-0 buyer_div">
          <div className="col-12 col-md-8" id="image_div">

            <div className="flex_center py-5">
              <div className="float-right arrow_expand" onClick={hideDetail}>
                <i class="fas fa-arrows-alt" aria-hidden="true" id="arrow_icon"></i>
              </div>
              <div className="clearfix"></div>
              {displayimage?
               <div className="flex-img">
               {/* <img src={require("../assets/images/noimage.png")} alt="User" className="img-fluid mr-3" /> */} 
                    {
                    displayimage && displayimage.name.split('.').pop() == 'mp4' ?
                      <video src={`${config.Back_URL}nftImg/${displayimage.name}`} type="video/mp4" alt="Collections" className="img-fluid mr-3" controls />
                      :
                      displayimage && displayimage.name.split('.').pop() == "mp3" ?
                      <div className="music_img_info_div">
                      <img src={`${config.Back_URL}/images/music.png`} alt="Collections" className="img-fluid mr-3" />
                      <audio src={`${config.Back_URL}nftImg/${displayimage.name}`} type="audio/mp3" controls className="audio" autostart="off">
                      </audio>
                      </div>
                      :
                    <img src={`${config.Back_URL}nftImg/${displayimage.name}`} alt="Collections" className="img-fluid mr-3" />
                } 
              </div>
              :
              <div className="flex-img">
              {/* <img src={require("../assets/images/noimage.png")} alt="User" className="img-fluid mr-3" /> */} 
                   {
                   item && item.image && item.image.split('.').pop() == 'mp4' ?
                     <video src={`${IPFS_IMGurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid mr-3" controls />
                     :
                     item && item.image && item.image.split('.').pop() == "mp3" ?
                     <div className="music_img_info_div">
                     <img src={`${config.Back_URL}/images/music.png`} alt="Collections" className="img-fluid mr-3" />
                     <audio src={`${IPFS_IMGurl}/${item.ipfsimage}`} type="audio/mp3" controls className="audio" autostart="off">
                     </audio>
                     </div>
                     :
                   <img src={`${IPFS_IMGurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid mr-3" />
               } 
             </div>
            }

                      {/* new multiple images*/}
        <div  className="list_of_img">
          {item&&item.multiimage&&item.multiimage.map((imgs) => {
          return(


            <div>
              {
                imgs&&imgs.name.split('.').pop() == 'mp4' ?
                  <video src={`${config.Back_URL}nftImg/${imgs&&imgs.name}`} onClick={()=>imagedisplay(imgs)} type="video/mp4" alt="Collections" className="img-fluid img_info" controls />
                  :
                  imgs&&imgs.name.split('.').pop() == "mp3" ?
                  <div className="music_img_info_div">
                    <img src={`${config.Back_URL}/images/music.png`} alt="Collections" onClick={()=>imagedisplay(imgs)} className="img-fluid img_info music_img_info " />
                  
                  </div>
                  :
                  <img src={`${config.Back_URL}nftImg/${imgs&&imgs.name}`} onClick={()=>imagedisplay(imgs)} alt="Collections" className="img-fluid img_info" />
                }
            </div>
            )
          })
        }
          </div>
            </div>
       
          </div>
       
          
          <div className="col-12 col-md-4 bg_pink" id="detai_div">
            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className="info_title">{item.tokenName}</span>
                </div>
                <div className="d-flex">
                  <div class="masonry_likes">
                  {
                  (LikedTokenList.findIndex(tokenCounts => (tokenCounts.tokenCounts === item.tokenCounts)) > -1)
                  ?(<i className="fas fa-heart mr-2 liked" onClick={() => LikeForwardRef.current.hitLike(item)} style={{cursor:'pointer'}}></i>)
                  :(<i className="far fa-heart mr-2" onClick={() => LikeForwardRef.current.hitLike(item)} style={{cursor:'pointer'}}></i>)
                  }
                    <span  class={item.tokenCounts+'-likecount mr-2'}>{item.likecount}</span>
                  </div>
                  <div class="dropdown">
                    <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                      BuyOwnerDetailFirst && BuyOwnerDetailFirst.tokenOwner
                      ?
                      <a class="dropdown-item" href="javascript:void(0)" onClick= { () =>PurchaseNowForwardRef.current.PurchaseNow_Click(item, BuyOwnerDetailFirst)}>Buy Now</a>
                      :
                      MyTokenDetail && MyTokenDetail.balance > 0 && MyTokenDetail.tokenPrice > 0 &&MyTokenDetail.burnorboui=="boui"?
                      <div>
                      <a class="dropdown-item" href="javascript:void(0)" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, MyTokenDetail)}> Change Price</a>
                      <a class="dropdown-item" href="javascript:void(0)" onClick={()=>CancelOrderForwardRef.current.CancelOrder_Click(item, MyTokenDetail)}> Cancel Order</a>
                      </div>
                      :
                      MyTokenDetail && MyTokenDetail.balance > 0 && MyTokenDetail.tokenPrice == 0 &&MyTokenDetail.burnorboui=="boui"&&
                      <a class="dropdown-item" href="javascript:void(0)" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, MyTokenDetail)}> Put on Sale</a>
                }    
                      {(WalletConnected == true && item.tokenowners_current  && (item.PutOnSale==false || item.PutOnSaleType=='FixedPrice'|| (item.PutOnSaleType=='TimedAuction' && showlist == "true") || item.PutOnSaleType=='UnLimitedAuction' ) && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                         Bids
                         && Bids.myBid
                         && !Bids.myBid.status
                         ?
                        <div className="menu_itm dropdown-item">
                        </div>
                        :
                        Bids
                        && Bids.myBid
                        && Bids.myBid.status
                        && Bids.myBid.status == 'pending' ?
                        <div className="menu_itm dropdown-item">
                            <span onClick={() => PlaceABidForwardRef.current.PlaceABid_Click(item,MyTokenDetail)}>Edit Offer </span>
                        </div>
                        :
                        <div></div>
                        }
                      <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" onClick={() => ShareForwardRef.current.ShareSocial_Click(item)}>Share</a>          
                     {/*<a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                      <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                      <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                */}
                      </div>
                  </div>
                </div>
              </div>
              <h3 className="info_h3"><span className="pr-2">
              {
                item
                && item.tokenBid == true
                && item.PutOnSaleType != "TimedAuction"
                ?
                (onwer_price!==undefined&&
                (
                onwer_price.tokenPrice!=undefined
                && onwer_price.tokenPrice != null
                &&onwer_price.tokenPrice!=0 ?
                onwer_price.tokenPrice + ' ' + onwer_price.biddingtoken
                : item&&item.PutOnSaleType=="FixedPrice"&&item.tokenowners_current[0].tokenPrice?
                item.tokenowners_current[0].tokenPrice+''+onwer_price.biddingtoken
                :        
              'Not for Sale'
                ))
                :item&&item.PutOnSaleType=="FixedPrice"&&item.tokenowners_current[0].tokenPrice
                ?
                item.tokenowners_current[0].tokenPrice+''+onwer_price.biddingtoken
                :item&&item.PutOnSaleType=="TimedAuction"
                ?
                item.minimumBid + ' ' +(item&&item.tokenowners_current&&item.tokenowners_current[0].biddingtoken)
                :
                ""
                }
                </span>
                {totcollection>0&&
                <span className="pr-2">
                  {totcollection}/{tokenCounts_Detail.TotalQuantity}
                </span>}
                {
                  item
                  && item.tokenBid == true
                  && item.PutOnSaleType != "TimedAuction"
                  ?
                    (onwer_price!==undefined&&
                    (onwer_price.tokenPrice!=undefined&& onwer_price.tokenPrice != null&&onwer_price.type==721
                    &&onwer_price.tokenPrice!=0 
                    ?
                    <>
                    {onwer_price.biddingtoken==config.owntokenSymbol?
                      '$'+(bouiusdval*onwer_price.tokenPrice).toFixed(config.toFixed) 
                      :
                      <>{onwer_price.biddingtoken == config.currencySymbol?

                        <h3>
          
                          {'$'+(usdval*onwer_price.tokenPrice).toFixed(config.toFixed)}
          
                        </h3>
                        :
                        <h3>
                          {'$'+onwer_price.tokenPrice.toFixed(config.toFixed)}
                        </h3>
                        }</>
                    }
                      </>
                      :
                      item&&item.PutOnSaleType=="FixedPrice"&&item.tokenowners_current[0].tokenPrice?
                      <>
                      {item&&item.tokenowners_current[0].biddingtoken==config.owntokenSymbol?
                        '$'+(bouiusdval*item.tokenowners_current[0].tokenPrice).toFixed(config.toFixed)
                        :
                        '$'+(item.tokenowners_current[0].usdval).toFixed(config.toFixed)
                      }
                        </>
                      :        
                      ""
                    )
                    )
                  :
                  item&&item.PutOnSaleType=="FixedPrice"&&item.tokenowners_current[0].tokenPrice
                  ?
                  <>
                      {item&&item.tokenowners_current[0].biddingtoken==config.owntokenSymbol?
                        '$'+(bouiusdval*item.tokenowners_current[0].tokenPrice).toFixed(config.toFixed)
                        :
                        '$'+(item.tokenowners_current[0].usdval).toFixed(config.toFixed)
                      }
                        </>
                  :
                  <>
                  {item&&item.biddingtoken==config.owntokenSymbol?
                    '$'+(bouiusdval*item.minimumBid).toFixed(config.toFixed)
                    :
                    '$'+(item.minimumBid)
                  }
                    </>
                  // '$'+(item.minimumBid*item).toFixed(config.toFixed)
                }
                 </h3>
                <div className="proposal_panel_overall">
                <p className="info_des">Creator {item.tokenRoyality}% royalties</p>
                {(item.tokenCreatorInfo && item.tokenCreatorInfo.curraddress && item.tokenCreatorInfo.image!="") ? 
                  <div>
                  <div className="media follow_media info_media">
                    <img src={`${config.Back_URL}/profile/${item.tokenCreatorInfo.image}`} alt="User" className="img-fluid mr-3 owner" />
                    {(item.tokenCreatorInfo && item.tokenCreatorInfo.name !="") ? 
                      <span className="text_blk tect_s">{item.tokenCreatorInfo.name }</span>
                      :
                      <span className="text_blk tect_s">{item.tokenCreatorInfo && item.tokenCreatorInfo.curraddress}</span>
                      }
                   </div>

                  </div>

                  :
                  <div>
                    <div className="media follow_media info_media 123">
                   <img src={config.Back_URL+'/images/noimage.png'} alt="User" className="img-fluid mr-3 owner" />
                   {(item.tokenCreatorInfo && item.tokenCreatorInfo.name != "") ?
                      <span className="text_blk tect_s">{item.tokenCreatorInfo.name }</span>
                      :
                      <span className="text_blk tect_s">{item.tokenCreatorInfo && item.tokenCreatorInfo.curraddress}</span>
                  }
                   </div>

                  </div>
              }
                  </div>
              <p className="info_des">{item.tokenDesc}</p>
              {UserAccountAddr && item && item.tokenowners_current && item.tokenowners_current.findIndex(e => e.tokenOwner == UserAccountAddr) > -1 &&
                        <h6>{item.unlockcontent}</h6>
                    }
             
              
              <nav className="masonry_tab_nav mt-4 info_tab">
                <div className="nav nav-tabs masonry_tab" id="nav-tab" role="tablist">
                  {/* <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">Info</a> */}
                  <a className="nav-link active" id="owners-tab" data-toggle="tab" href="#owners" role="tab" aria-controls="owners" aria-selected="true">Owners</a>
                  <a className="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false">History</a>
                  <a className="nav-link" id="details-tab" data-toggle="tab" href="#details" role="tab" aria-controls="details" aria-selected="false">Details</a>
                  <a className="nav-link" id="bid-tab" data-toggle="tab" href="#bid" role="tab" aria-controls="bid" aria-selected="false">Offers</a>

                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
              {/* <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab">
              <div className="proposal_panel_overall">
                  {
                      item.tokenowners_current && item.tokenowners_current.length > 0 &&
                       item.tokenowners_current.map((itemCur, i) => {
                      return(
                    <div className="inner_div_info">
                      <div className="media follow_media info_media postedBy">
                        <img src={
                            (item&&item.tokenCreatorInfo.image[i] != ''
                            ? `${config.Back_URL}profile/${item.tokenCreatorInfo.image[i]}`
                            : `${config.Back_URL}/images/noimage.png`)}  
                            alt="User" className="img-fluid mr-3" />
                        <div className="media-body flex_body info_flex_body">
                          <div>
                            <p className="mt-0 media_num">{(item&&item.tokenCreatorInfo&&item.tokenCreatorInfo.name[i]!=''?
                              <span>{item.tokenCreatorInfo.name[i]}</span>
                              :
                              <span> {halfAddrShow(item.tokenCreatorInfo.curraddress[i])}</span>
                            )}</p>
                          </div>
                          <div className="ml-2 ml-cus">
                          {
                              itemCur.tokenPrice > 0 && itemCur.balance > 0 && itemCur.tokenOwner != UserAccountAddr && 
                            <Button className="create_btn mb-2" data-toggle="modal" onClick= { () =>PurchaseNowForwardRef.current.PurchaseNow_Click(item,itemCur)} >Buy</Button>
                          }
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    )
                  })
                   }
                  </div>
                </div> */}
                <div className="tab-pane fade33 show active" id="owners" role="tabpanel" aria-labelledby="owners-tab">
                  <div className="proposal_panel_overall show active">
                    <div className="inner_div_info">
                    {
                      item.tokenowners_current && item.tokenowners_current.length > 0 ?
                      item.tokenowners_current.map((itemCur, i) => {
                        return(
                          <div className={
                            itemCur.burnorboui=="burn"?
                            "burened_img img media follow_media info_media":"media follow_media info_media"}
                          >
                          <img src={
                            (item.tokenOwnerInfo.image[i] != ''
                            ? `${config.Back_URL}/profile/${item.tokenOwnerInfo.image[i]}`
                            : `${config.Back_URL}/images/noimage.png`)} alt="User" className="img-fluid mr-3 owner" />
                            <div className="media-body flex_body info_flex_body">
                            <div>
                          
                            <h5><p className="mt-0 media_num">
                              {
                                (item.tokenOwnerInfo.name[i] != ''
                                ? item.tokenOwnerInfo.name[i]
                                : halfAddrShow(itemCur.tokenOwner))
                                }
                              </p> 
                              {itemCur.balance}/{itemCur.quantity} on sale for   
                              {' '}
                              {itemCur.tokenPrice}{' '} {itemCur.biddingtoken} {itemCur.quantity > 0 && 'each'}
                            </h5>
                        
                            {
                            itemCur.tokenPrice > 0 && itemCur.balance > 0 && itemCur.tokenOwner != UserAccountAddr && 
                            <Button className="create_btn mb-2" onClick= { () =>PurchaseNowForwardRef.current.PurchaseNow_Click(item,itemCur)} >Buy Now</Button>
                          }
                          
                            </div>

                            </div>
                          </div>
                        )
                      })
                      :""
                      }
                    </div>
       
                  </div>
                </div>
         
                <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                  <div className="proposal_panel_overall">
                    <div className="inner_div_info">
                    { ActivityList.map((item) => {
                                var imageUrl =config.Back_URL+"/images/noimage.png";
                                var text = "";
                                var name = "";
                                try{
                                  var UserAccountAddr = window.web3.eth.defaultAccount;
                                }catch(err){
                                  var UserAccountAddr =""
                                }
                                if(item.actiontype=="following"){
                                  var followTxt = halfAddrShow(item.touseraddress);
                                  if(item.touseraddress==UserAccountAddr){
                                      followTxt = "You ";
                                  }
                                  text = "started following "+halfAddrShow(item.touseraddress);
                                  name = (item.userdetail && item.userdetail.name !="")?item.userdetail.name:halfAddrShow(item.userdetail.curraddress);
                                }
                                else if(item.actiontype=="listings"){
                                      if ((item&&item.tokenuser&&item.tokenuser.PutOnSaleType)=="FixedPrice") {
                                      text = "Listed for "+item.price+" "+(item.currency)+ " by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                      }else if ((item&&item.tokenuser&&item.tokenuser.PutOnSaleType)=="TimedAuction") {
                                      text = "Listed for "+item.price+" "+(item.currency)+ " by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                      }else{
                                      text = "Listed "+"by "+" "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                      }
                                      name = (item.name)?item.name:""
                                } else if(item.actiontype=="edit bid"){
                                      text="edit bid for "+(item.price)+" "+(item.currency)+" by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress)
                                      name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="place bid"){
                                  text="place bid for "+(item.price)+" "+(item.currency)+" by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress)
                                  // name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="sales"){
                                  text = "sales for "+item.price+" "+(item&&item.tokendetail&&item.tokendetail.biddingtoken)+ " by "+
                                  halfAddrShow(item&&item.userdetail.curraddress);
                                  name = (item.name)?item.name:""
                                } 
                                else if(item.actiontype=="acceptBid"){
                                      text = "acceptBid for "+(item.price)+" "+(item.currency)+ " to "+halfAddrShow(item&&item.useraddress);
                                      name = (item.name)?item.name:""
                                }
                               
                                else if(item.actiontype=="purchase"){
                                      text = "purchased for "+item.price+" "+(item.currency)+ " by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                      name = (item.name)?item.name:""
                                }           
                               
                                else if(item.actiontype=="burn"){
                                      text = "Burn by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                      // name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="like"){
                                    text = "liked by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                    // name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="unlike"){
                                    text = "unliked by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                    // name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="reporting"){
                                  text = "reporting by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                  // name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="sharing"){
                                  text = "Shared by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                  // name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="burn"){
                                    text = "Burn by "+halfAddrShow(item&&item.userdetail.curraddress);
                                    // name = (item.name)?item.name:""
                                }
                                var linkUrl = "/info/"+item.tokenCounts;
                                if(item.itemtype=="users"){
                                    linkUrl = "/user/"+halfAddrShow(item.useraddress);
                                }
                                if(name.length > 15) {
                                    name = name.substring(0, 18) + "...";
                                }
                               return(
                      <div className="media follow_media info_media">
                        <img src={imageUrl} alt="User" className="img-fluid mr-3 history" />
                        <div className="media-body flex_body">
                          <div>
                            <p className="mt-0 media_num"><small>{name}</small>{text}</p>
                            {/* <p className="mt-0 media_text  mb-0">Aqueento</p> */}
                          </div>
                        </div>
                      </div>
                               )
                    })
                  }
                      {/* // <div className="media follow_media info_media">
                      //   <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid mr-3" />
                      //   <div className="media-body flex_body info_flex_body">
                      //     <div>
                      //       <p className="mt-0 media_num">Creator</p>
                      //       <p className="mt-0 media_text  mb-0">Garybaldi</p>
                      //     </div>
                      //     <div className="ml-2 ml-cus">
                      //       <div className="card owner_card my-0">
                      //         <div className="card-body p-2">
                      //           <div className="flex_txt">
                      //             <div className="prce_red">10%</div>
                      //             <div className="prce_grey">
                      //               <p className="mb-0 price_1">
                      //                 Sales to
                      //               </p>
                      //               <p className="mb-0 price_2">
                      //                 the creator
                      //               </p>
                      //             </div>
                      //           </div>
                      //         </div>
                      //       </div>
                      //     </div>
                      //   </div>
                      // </div> */}
                      {/* <div className="media follow_media info_media">
                        <img src={require("../assets/images/follower_3.png")} alt="User" className="img-fluid mr-3" />
                        <div className="media-body flex_body">
                          <div>
                            <p className="mt-0 media_num">Collection</p>
                            <p className="mt-0 media_text  mb-0">CRYPTOxPINS</p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="details" role="tabpanel" aria-labelledby="details-tab">
                  <div className="proposal_panel_overall">
                    <div className="inner_div_info">
                      <div className="media follow_media info_media">
                        <div className="media-body flex_body">
                          <div className="detile_text_">
                            {(item.tokenProperty!="")&&
                              <div>
                              <p className="mt-0 media_num">Properties</p>
                              <p className="mt-0 media_text  mb-0">{item.tokenProperty}</p>
                              </div>
                            }
                          {item.Vendorname != '' &&
                           <div>
                            <p className="mt-0 media_num">Vendor Name</p>
                            <p className="mt-0 media_text  mb-0">{item.Vendorname}</p>
                            </div>
                          }
                          {item.Manufacture != '' &&
                           <div>
                            <p className="mt-0 media_num">Manufacture</p>
                            <p className="mt-0 media_text  mb-0">{item.Manufacture}</p>
                            </div>
                          }
                          {item.Model != '' &&
                           <div>
                            <p className="mt-0 media_num">Model</p>
                            <p className="mt-0 media_text  mb-0">{item.Model}</p>
                            </div>
                          }
                            {item.Rating != '' &&
                           <div>
                            <p className="mt-0 media_num">Condition Rating</p>
                            <p className="mt-0 media_text  mb-0">{item.Rating}</p>
                            </div>
                          }
                           {item.condition != '' &&
                           <div>
                            <p className="mt-0 media_num">Condition </p>
                            <p className="mt-0 media_text  mb-0">{item.condition}</p>
                            </div>
                          }
                       
                          </div>
                        </div>
                      </div>
                    
                    </div>
                  
                  </div>
                </div>
                <div className="tab-pane fade" id="bid" role="tabpanel" aria-labelledby="bid-tab">
                  <div className="proposal_panel_overall">
                    <div className="inner_div_info">
                    {Bids && Bids.pending && Bids.pending.length > 0 ?
                    Bids.pending.map((curBid) => {
                        console.log("bid_list:",UserAccountAddr)
                      return ( <div className="media follow_media info_media">
                        <img src={curBid && curBid.bidDetails && curBid.bidDetails.image != undefined &&
                                  curBid.bidDetails.image != ''
                                  ? `${config.Back_URL}/profile/${curBid.bidDetails.image}`
                                  : require("../assets/images/noimage.png") }
                                alt="User" className="img-fluid mr-3 owner" />
                        <div className="media-body flex_body">
                          <div>
                            <p className="mt-0 media_num">{curBid.tokenBidAmt} {curBid.BidToken} By {halfAddrShow(curBid.tokenBidAddress)} {tokenCounts_Detail.TotalQuantity > 0 && (<span>for {curBid.pending}/{curBid.NoOfToken} edition</span>)}</p>
                            <p className="mt-0 media_text  mb-0">at {moment(curBid.timestamp).format('MMMM Do YYYY, h:mm a')}</p>
                            {UserAccountAddr && UserAccountAddr != curBid.tokenBidAddress && item && item.tokenowners_current && item.tokenowners_current.findIndex(e => e.tokenOwner == UserAccountAddr) > -1 &&
                          <div className="ml-2 ml-cus">
                            <Button className="create_btn mb-2" onClick={() => PlaceABidForwardRef.current.AcceptBid_Select(curBid,item)}>Accept</Button>
                          </div>
                          }
                          {UserAccountAddr && UserAccountAddr == curBid.tokenBidAddress &&
                            <Button className="create_btn mb-2" onClick={() => PlaceABidForwardRef.current.CancelBid_Select(curBid)}>Cancel</Button>
                          }
                          </div>
                        </div>
                      </div>
                      ) }):"No active Offers yet. Be the first to make a Offer!"
                      
                      }
                 
                    </div>
                  </div>
                </div>
                {
                (
                  (tokenCounts_Detail.TotalQuantity > MyTokenBalance)
                  ||
                  (Bids && Bids.highestBid && Bids.highestBid.tokenBidAmt)
                )?(
                <div className="card info_big_card mb-0">
                  <div className="card-body">
                    {(Bids.highestBid && Bids.highestBid.tokenBidAmt > 0) ?
                     <div className="media follow_media">
          <a href={config.Front_URL+"/user/"+(Bids.highestBid&&Bids.highestBid.tokenBidAddress)}>
            <img src={Bids.highestBid && Bids.highestBid.bidDetails && Bids.highestBid.bidDetails.image != undefined &&
                              Bids.highestBid.bidDetails.image != ''
                              ? `${config.Back_URL}/profile/${Bids.highestBid.bidDetails.image}`
                              : require("../assets/images/noimage.png")
                            } alt="User" className="img-fluid mr-3 owner" />
                    </a>
                      <div className="media-body flex_body">
                        <div>
                          <span className="mt-0 media_text_big_1">{halfAddrShow(Bids.highestBid&&Bids.highestBid.tokenBidAddress)}</span>
                          <p className="mt-0 media_text_big_1">Highest Offer by 
                          <span className="text_blk">
                            {(Bids.highestBid.bidBy && Bids.highestBid.bidBy.name)?Bids.highestBid.bidBy.name:''}
                          </span>
                          </p>
                          <p className="mt-0 mb-0 media_text_big_2"><span className="text_blk">{Bids.highestBid.tokenBidAmt} {Bids.highestBid.BidToken}</span></p>
                        </div>
                      </div>
                    </div>:<p className="text-center">No active bids yet. Be the first to make a bid</p>}
                    {item && item.PutOnSaleType == "TimedAuction"&& showlist == "true" && display_item <=0 &&
                <div className="countdown_panel_timer">
                  <div className="countdown_panel_div countdown_panel_div_info infopage_info">
                    <Countdown
                        date={enddate}
                        autoStart={true}
                        onStart={() => startdate}
                        renderer={renderer}
                        ></Countdown>
                  </div>
              </div>
              }
           {item && item.PutOnSaleType == "TimedAuction"&& showlist == "true" && display_item >0 &&
                <div className="countdown_panel_timer">
                  <div className="countdown_panel_div countdown_panel_div_info infopage">
                  <Countdown
                date={startdate}
                autoStart={true}
                onStart={() => Date.now()}
                renderer={renderer1}
              ></Countdown>
                  </div>
              </div>
              }

                    {
                    (
                    item
                    &&item.PutOnSaleType=="TimedAuction"&&item.clocktime != null
                    && item.endclocktime != null
                    && new Date(item.endclocktime) < Date.now() ) ?
                    (<p className="mt-0 media_text_big_1 text-center">Auction Completed</p>)
                    :
                    tokenCounts_Detail.TotalQuantity > MyTokenBalance?
                    <div className="mt-3 text-center">     


                      {
                      BuyOwnerDetailFirst && BuyOwnerDetailFirst.tokenOwner
                      ?
                        <Button className="btn_outline_red mb-2" onClick= { () =>PurchaseNowForwardRef.current.PurchaseNow_Click(item, BuyOwnerDetailFirst)} >Buy Now</Button> 
                      :
                        MyTokenDetail && MyTokenDetail.balance > 0 && MyTokenDetail.tokenPrice > 0 &&MyTokenDetail.burnorboui=="boui"?
                          <Button className="btn_outline_red mb-2" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, MyTokenDetail)}>Change Price</Button>
                        :
                        MyTokenDetail && MyTokenDetail.balance > 0 && MyTokenDetail.tokenPrice == 0&&MyTokenDetail.burnorboui=="boui" &&
                          <Button className="btn_outline_red mb-2" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, MyTokenDetail)}>Put On Sale</Button>
                      }
                      {
                      Bids
                      && Bids.myBid
                      && !Bids.myBid.status
                      ?
                      <>{ 
                        item && (item.PutOnSale==false||item.PutOnSaleType=='FixedPrice'|| (item.PutOnSaleType=='TimedAuction' && showlist == "true" && display_item <=0 ) || item.PutOnSaleType=='UnLimitedAuction' )&&
                        <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.PlaceABid_Click(item,MyTokenDetail)}>
                          Offer now
                        </Button>
                      }
                      
                      </>
                      :
                        Bids
                        && Bids.myBid
                        && Bids.myBid.status
                        && Bids.myBid.status == 'pending' ?
                          <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.PlaceABid_Click(item,MyTokenDetail)}>
                            Edit a Offer 
                          </Button>
                        :
                          Bids
                          && Bids.myBid
                          && Bids.myBid.status
                          && Bids.myBid.status == 'partiallyCompleted' &&
                          <Button className="btn_outline_red ml-2 mb-2" onClick={() => PlaceABidForwardRef.current.CancelBid_Select(Bids.myBid)}>
                            Cancel a Offer
                          </Button>
                      }
                    </div>
                    :('')}

                    {tokenCounts_Detail.TotalQuantity > MyTokenBalance?
                    <p className="mt-0 media_text_big_1 text-center">Service fee {servicefee}%</p>
                    :('')}
                  </div>
                </div>):(showlist=='false'&&auction=="true" &&
                   <div className="card info_big_card mb-0">
                  <div className="card-body">
                    <div className="mt-3 text-center">
                      <div className="countdown_panel ">
                        <div className="countdown_panel_div countdown_panel info_ended_info">
                         <span className="info_end"> {auctionTxt}</span>
                        </div>
                      </div>
                  </div>
                  </div>
                  </div>)}
              </div>
            </div>

          </div>
        </div>
 }

        
        {/* end info row */}

        {/* info ownerrow */}
        <div className="row info_row owner_div mx-0 d-none">
          <div className="col-12 col-md-8" id="image_div_owner">

            <div className="flex_center py-5">
              <div className="float-right arrow_expand" onClick={hideDetailowner}>
                <i class="fas fa-arrows-alt" aria-hidden="true" id="arrow_icon_owner"></i>
              </div>
              <div className="clearfix"></div>
              <img src={require("../assets/images/img_info.png")} alt="Collections" className="img-fluid" />
              <div className="img_des" id="img_des_owner">
                <p className="info_title">Uniaqua</p>
                <h3 className="info_h3">by<span className="px-2">Aqueento</span>on<span className="pl-2">NFT</span></h3>

              </div>
            </div>

          </div>
          <div className="col-12 col-md-4 bg_pink" id="detai_div_owner">
            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className="info_title">Uniaqua</span>
                </div>
                <div class="masonry_likes">
                  <i className="fas fa-heart mr-2"></i>
                  <span className="mr-2">27</span>
                  <i className="fas fa-ellipsis-h"></i>
                </div>

              </div>
              <h3 className="info_h3"><span className="pr-2">0.061 BNB</span>$410.86  4 of 7</h3>
              <p className="info_des">From raging seas to sunset Landscapes, the Uniaqua is fit for all terrains. Where other Polkamon glory in their uniqueness, the Uniaqua takes its prestige in versatility.</p>
              <nav className="masonry_tab_nav mt-4 info_tab">
                <div className="nav nav-tabs masonry_tab" id="nav-tab" role="tablist">
                  {/* <a className="nav-link active" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="true">Info</a> */}
                  <a className="nav-link active" id="owners-tab" data-toggle="tab" href="#owners" role="tab" aria-controls="owners" aria-selected="true">Owners</a>
                  <a className="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false">History</a>
                  <a className="nav-link" id="details-tab" data-toggle="tab" href="#details" role="tab" aria-controls="details" aria-selected="false">Details</a>
                  <a className="nav-link" id="bid-tab" data-toggle="tab" href="#bid" role="tab" aria-controls="bid" aria-selected="false">Offers</a>

                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                {/* <div className="tab-pane fade " id="info" role="tabpanel" aria-labelledby="info-tab">
                  <div className="proposal_panel_overall">
                  {
                      item.tokenowners_current && item.tokenowners_current.length > 0 &&
                       item.tokenowners_current.map((itemCur, i) => {
                         console.log("owners_list:",itemCur)
                      return(
                    <div className="inner_div_info">
                      <div className="media follow_media info_media">
                        <img src={
                            (item&&item.tokenCreatorInfo.image[i] != ''
                            ? `${config.Back_URL}/profile/${item.tokenCreatorInfo.image[i]}`
                            : `${config.Back_URL}/images/noimage.png`)}  alt="User" className="img-fluid mr-3" />
                        <div className="media-body flex_body info_flex_body">
                          <div>
                            <p className="mt-0 media_num">{(item&&item.tokenCreatorInfo&&item.tokenCreatorInfo.name[i]!=''?
                                      <span>{item.tokenCreatorInfo.name[i]}</span>
                                      :
                                     <span> {halfAddrShow(item.tokenCreatorInfo.curraddress[i])}</span>
                                    )}</p>
                            <p className="mt-0 media_text  mb-0">by 0xb6631a76645...b7de</p>

                          </div>
                          <div className="ml-2 ml-cus">
                            <Button className="create_btn mb-2" data-toggle="modal" data-target="#accept_modal">Accept</Button>

                          </div>
                        </div>
                      </div>
                      
                    </div>
                    )
                  })
                   }
                  </div>
                </div> */}
                <div className="tab-pane fade2" id="owners" role="tabpanel" aria-labelledby="owners-tab">
                  <div className="proposal_panel_overall show active">
                    <div className="inner_div_info">
                    {
                      item.tokenowners_current && item.tokenowners_current.length > 0 && 
                      item.tokenowners_current.map((itemCur, i) => {

                      return(
                      <div className="media follow_media info_media">
                        <img src={
                            (item&&item.tokenOwnerInfo.image[i] != ''
                            ? `${config.Back_URL}/profile/${item.tokenOwnerInfo.image[i]}`
                            : `${config.Back_URL}/images/noimage.png`)} alt="User" className="img-fluid mr-3 owner" />
                        <div className="media-body flex_body info_flex_body">
                          <div>
                            <p className="mt-0 media_num">0 WETH</p>
                            <p className="mt-0 media_text  mb-0">by 
                             {
                              (item.tokenOwnerInfo.name[i] != ''
                              ? item.tokenOwnerInfo.name[i]
                              : halfAddrShow(itemCur.tokenOwner))
                              }</p>

                          </div>
                          <div className="ml-2 ml-cus">
                          {
                            itemCur.tokenPrice > 0 && itemCur.balance > 0 && itemCur.tokenOwner != UserAccountAddr && 
                            <Button className="create_btn mb-2" onClick= { () =>PurchaseNowForwardRef.current.PurchaseNow_Click(item,itemCur)} >Buy Now</Button>
                          }
                          
                            </div>
                        </div>
                      </div>)
                           })
                          }
                    </div>
       
                  </div>
                </div>
                <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                  <div className="proposal_panel_overall">
                    <div className="inner_div_info">
                    { ActivityList.map((item) => {
                                var imageUrl =config.Back_URL+"/images/noimage.png";
                                var text = "";
                                var name = "";
                                try{
                                  var UserAccountAddr = window.web3.eth.defaultAccount;
                                }catch(err){
                                  var UserAccountAddr =""
                                }
                                if(item.actiontype=="following"){
                                  var followTxt = halfAddrShow(item.touseraddress);
                                  if(item.touseraddress==UserAccountAddr){
                                      followTxt = "You ";
                                  }
                                  text = "started following "+halfAddrShow(item.touseraddress);
                                  name = (item.userdetail && item.userdetail.name !="")?item.userdetail.name:halfAddrShow(item.userdetail.curraddress);
                                }
                                else if(item.actiontype=="listings"){
                                      if ((item&&item.tokenuser&&item.tokenuser.PutOnSaleType)=="FixedPrice") {
                                      text = "Listed for "+item.price+" "+(item.currency)+ " by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                      }else if ((item&&item.tokenuser&&item.tokenuser.PutOnSaleType)=="TimedAuction") {
                                      text = "Listed for "+item.price+" "+(item.currency)+ " by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                      }else{
                                      text = "Listed "+"by "+" "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                      }
                                      name = (item.name)?item.name:""
                                } else if(item.actiontype=="edit bid"){
                                      text="edit bid for "+(item.price)+" "+(item.currency)+" by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress)
                                      name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="place bid"){
                                  text="place bid for "+(item.price)+" "+(item.currency)+" by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress)
                                  name = (item.name)?item.name:""
                                }
                                
                                else if(item.actiontype=="acceptBid"){
                                      text = "acceptBid for "+(item.price)+" "+(item.currency)+ " to "+halfAddrShow(item&&item.useraddress);
                                      name = (item.name)?item.name:""
                                }
                               
                                else if(item.actiontype=="purchase"){
                                      text = "purchased for "+item.price+" "+(item.currency)+ " by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                      name = (item.name)?item.name:""
                                }           
                               
                                else if(item.actiontype=="burn"){
                                      text = "Burn by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                      name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="like"){
                                    text = "liked by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                    name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="unlike"){
                                    text = "unliked by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                    name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="reporting"){
                                  text = "reporting by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                  name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="sharing"){
                                  text = "Shared by "+halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
                                  name = (item.name)?item.name:""
                                }
                                else if(item.actiontype=="burn"){
                                    text = "Burn by "+halfAddrShow(item&&item.userdetail.curraddress);
                                    name = (item.name)?item.name:""
                                }
                                var linkUrl = "/info/"+item.tokenCounts;
                                if(item.itemtype=="users"){
                                    linkUrl = "/user/"+halfAddrShow(item.useraddress);
                                }
                              //   try{
                              //     var UserAccountAddr = window.web3.eth.defaultAccount;
                              //   }catch(err){
                              //     var UserAccountAddr =""
                              //   }
                              //   if(item.actiontype=="following"){
                              //       var followTxt = halfAddrShow(item.touseraddress);
                              //       if(item.touseraddress==UserAccountAddr){
                              //           followTxt = "You ";
                              //       }
                              //       text = "started following "+halfAddrShow(item.touseraddress);
                              //       name = (item.userdetail && item.userdetail.name !="")?item.userdetail.name:halfAddrShow(item.userdetail.curraddress);
                              //   }
                              //   else if(item.actiontype=="listings"){
                              //       text = "listed by "+halfAddrShow(item.useraddress)+" for "+item.price+config.tokenSymbol;
                              //       name = (item.name)?item.name:""
                              //   }
                              //   else if(item.actiontype=="bidrequest"){
                              //       text = halfAddrShow(item.useraddress)+" offered "+item.price+config.tokenSymbol+"for 1 edition editions each";
                              //       name = (item.name)?item.name:""
                              //   }
                              //   else if(item.actiontype=="purchase"){
                              //       text = "purchased by "+halfAddrShow(item.useraddress)+" for "+ item.price+config.currencySymbol+"  from "+halfAddrShow(item.touseraddress);
                              //       name = (item.name)?item.name:""
                              //   }
                              //   else if(item.actiontype=="like"){
                              //       text = "liked by "+halfAddrShow(item.useraddress);
                              //       name = (item.name)?item.name:""
                              //   }
                              //   else if(item.actiontype=="sales"){
                              //     text = "sales by "+halfAddrShow(item.useraddress);
                              //     name = (item.name)?item.name:""
                              // }
                            
                              //   var linkUrl = "/info/"+item.tokenCounts;
                              //   if(item.itemtype=="users"){
                              //       linkUrl = "/user/"+halfAddrShow(item.useraddress);
                              //   }
                                
                                if(name.length > 15) {
                                    name = name.substring(0, 18) + "...";
                                }
                               return(
                      <div className="media follow_media info_media">
                        <img src={imageUrl} alt="User" className="img-fluid mr-3" />
                        <div className="media-body flex_body info_flex_body">
                          <div>
                            <p className="mt-0 media_num"><small>{name}</small>{text}</p>
                            {/* <p className="mt-0 media_text  mb-0">by 0xb6631a76645...b7de</p> */}

                          </div>
                          {/* <div className="ml-2 ml-cus">
                            <Button className="create_btn mb-2" data-toggle="modal" data-target="#accept_modal">Accept</Button>

                          </div> */}
                        </div>
                      </div>
                          )
                        })
                        }
      
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="details" role="tabpanel" aria-labelledby="details-tab">
                  <div className="proposal_panel_overall">
                    <div className="inner_div_info">
                      <div className="media follow_media info_media">
                        <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid mr-3" />
                        <div className="media-body flex_body info_flex_body">
                          <div>
                            <p className="mt-0 media_num">0 WETH</p>
                            <p className="mt-0 media_text  mb-0">by 0xb6631a76645...b7de</p>

                          </div>
                          <div className="ml-2 ml-cus">
                            <Button className="create_btn mb-2" data-toggle="modal" data-target="#accept_modal">Accept</Button>

                          </div>
                        </div>
                      </div>


                    </div>
                   
                  </div>
                </div>

                <div className="tab-pane fade" id="bid" role="tabpanel" aria-labelledby="bid-tab">
                  <div className="proposal_panel_overall">
                    <div className="inner_div_info">
                      <div className="media follow_media info_media">
                        <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid mr-3" />
                        <div className="media-body flex_body info_flex_body">
                          <div>
                            <p className="mt-0 media_num">0 WETH</p>
                            <p className="mt-0 media_text  mb-0">by 0xb6631a76645...b7de</p>

                          </div>
                          <div className="ml-2 ml-cus">
                            <Button className="create_btn mb-2" data-toggle="modal" data-target="#accept_modal">Accept</Button>

                          </div>
                        </div>
                      </div>

                      <div className="media follow_media info_media">
                        <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid mr-3" />
                        <div className="media-body flex_body info_flex_body">
                          <div>
                            <p className="mt-0 media_num">0 WETH</p>
                            <p className="mt-0 media_text  mb-0">by 0xb6631a76645...b7de</p>

                          </div>
                          <div className="ml-2 ml-cus">
                            <Button className="create_btn mb-2" data-toggle="modal" data-target="#accept_modal">Accept</Button>

                          </div>
                        </div>
                      </div>

                      <div className="media follow_media info_media">
                        <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid mr-3" />
                        <div className="media-body flex_body info_flex_body">
                          <div>
                            <p className="mt-0 media_num">0 WETH</p>
                            <p className="mt-0 media_text  mb-0">by 0xb6631a76645...b7de</p>

                          </div>
                          <div className="ml-2 ml-cus">
                            <Button className="create_btn mb-2" data-toggle="modal" data-target="#accept_modal">Accept</Button>

                          </div>
                        </div>
                      </div>

                      <div className="media follow_media info_media">
                        <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid mr-3" />
                        <div className="media-body flex_body info_flex_body">
                          <div>
                            <p className="mt-0 media_num">0 WETH</p>
                            <p className="mt-0 media_text  mb-0">by 0xb6631a76645...b7de</p>

                          </div>
                          <div className="ml-2 ml-cus">
                            <Button className="create_btn mb-2" data-toggle="modal" data-target="#accept_modal">Accept</Button>

                          </div>
                        </div>
                      </div>


                    </div>
                  
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* end info owner row */}

      </div>
      <Footer />
      {/* buy Modal */}
      <div class="modal fade primary_modal" id="buy_modal" tabindex="-1" role="dialog" aria-labelledby="buy_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="buy_modalLabel">Checkout</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row mx-0 bor_bot_modal">
                <div className="col-12 col-md-12 p-0">
                  <p className="buy_title_sm">Seller</p>
                  <p className="buy_title_md">Uniaqua</p>
                </div>
                <div className="col-12 col-md-12 p-0">
                  <p className="buy_title_sm">Buyer</p>
                  <p className="buy_title_md short_address">0x75Cf28f29266A413eC878CE5C4a1c9682C819586</p>
                </div>
              </div>
              <div className="bor_bot_modal mb-3">
                <p className="buy_title_md px-4 py-3 text-center mb-0">1.01 ETH</p>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Your balance</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">10.25 ETH</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">0.012 ETH</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 p-0">
                  <p className="buy_desc_sm">You will pay</p>
                </div>
                <div className="col-12 col-sm-6 p-0 text-sm-right">
                  <p className="buy_desc_sm_bold">1.112 ETH</p>
                </div>
              </div>
              <form className="p-0">
                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_modal">Proceed to payment</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end buy modal */}

      {/* proceed Modal */}
      <div class="modal fade primary_modal" id="proceed_modal" tabindex="-1" role="dialog" aria-labelledby="proceed_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="proceed_modalLabel">Follow Steps</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <p className="mt-0 purchase_text text-center">Purchase</p>
                <p className="mt-0 purchase_desc text-center">Send transaction with your wallet</p>

                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Inprogress</Button>
                </div>


              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed modal */}

      {/* place_bid Modal */}
      <div class="modal fade primary_modal" id="place_bid_modal" tabindex="-1" role="dialog" aria-labelledby="place_bid_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="place_bid_modalLabel">Place a offer</h5>
              <p className="text-center place_bit_desc">You are about to place a offer for</p>
              <p className="place_bit_desc_2"><span className="text_red mr-2">Uniaqua</span>by<span className="text_red ml-2">NFTbuyer</span></p>


              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="bid_form">
                <label for="bid">Your bid</label>
                <div class="input-group mb-3 input_grp_style_1">
                  <input type="text" id="bid" class="form-control" placeholder="Enter your display name" aria-label="bid" aria-describedby="basic-addon2" />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">ETH</span>
                  </div>
                </div>

                {/* <label for="qty">Enter quantity <span className="label_muted pl-2">(30 available)</span></label>
            <div class="mb-3 input_grp_style_1">
          <input type="text" id="qty" class="form-control" placeholder="1"  />
         
        </div> */}
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">10.25 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your offering balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">0.012 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>

                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal">Place a bid</Button>

                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal */}


      {/* place_bid multiple */}
      <div class="modal fade primary_modal" id="place_bid_multiple_modal" tabindex="-1" role="dialog" aria-labelledby="place_bid_multiple_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="place_bid_multiple_modalLabel">Place a offer</h5>
              <p className="text-center place_bit_desc">You are about to place a offer for</p>
              <p className="place_bit_desc_2"><span className="text_red mr-2">Uniaqua</span>by<span className="text_red ml-2">NFTbuyer</span></p>


              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="px-4 bid_form">
                <label for="bid">Your bid</label>
                <div class="input-group mb-3 input_grp_style_1">
                  <input type="text" id="bid" class="form-control" placeholder="Enter your display name" aria-label="bid" aria-describedby="basic-addon2" />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">ETH</span>
                  </div>
                </div>

                <label for="qty">Enter quantity <span className="label_muted pl-2">(30 available)</span></label>
                <div class="mb-3 input_grp_style_1">
                  <input type="text" id="qty" class="form-control" placeholder="1" />

                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">10.25 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your Offering balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">0.012 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 ETH</p>
                  </div>
                </div>

                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal">Place a bid</Button>

                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal multiple */}

      {/* proceed_bid Modal */}
      <div class="modal fade primary_modal" id="proceed_bid_modal" tabindex="-1" role="dialog" aria-labelledby="proceed_bid_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="proceed_bid_modalLabel">Follow Steps</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Done</Button>
                </div>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Signature</p>
                    <p className="mt-0 approve_desc">Create a signatute to place a Offer</p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" disabled>Start</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed_bid modal */}

      {/* accept bid Modal */}
      <div class="modal fade primary_modal" id="accept_modal" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="accept_modalLabel">Accept offer</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="img_accept text-center">
                <img src={require("../assets/images/img_info.png")} alt="Collections" className="img-fluid" />
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to accept offer for</span>
                <span className="buy_desc_sm_bold pl-2">Uniaqua</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold pl-2 short_address m-auto d-block">0x75Cf28f29266A413eC878CE5C4a1c9682C819586</span>
              </p>
              <p className="info_title text-center">0 ETH for 1 edition(s)</p>


              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">0.012 ETH</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will get</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">1.112 ETH</p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close">Accept offer</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}
      {/* new modal*/}
<div class="modal fade primary_modal profile_modal" id="profile_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="PurchaseStepCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="text-center">
                                    
                                      <a href={config.Front_URL+"/edit-profile"} className="primary_btn">
                                      
                                       Please fill the profile
                                       </a>
                                    
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
      {/* new modal*/}
    </div>
  );
}
