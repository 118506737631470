import React, { useEffect,useState,useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Select from "react-select";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  ReportRequest,
} from '../actions/items';
import { Link,useParams,useHistory } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import {
  getWallet,
  halfAddrShow,
  getCurAddr,
  FollowChange_Action,
  changeReceiptStatus_Action,
  ParamAccountAddr_Detail_Get,
  User_FollowList_Get_Action,
  User_Following_List_Action,
  User_Followers_List_Action,
  User_Follow_Get_Action,
  Activity_List_Action,
  UserProfile_Update_Action
} from '../actions/v1/user';

import {
  CollectiblesList_MyItems,
  Bidding_Detail_Action,
  getsettinglist
} from '../actions/v1/token';

import { LikeRef } from './separate/LikeRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { BurnRef } from './separate/BurnRef';
import TokenItem from './separate/Token-Item';
import BidPopup from './separate/Bid-Popup';
import ConnectWallet from './separate/Connect-Wallet';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import ActivityTab from './separate/ActivityTab';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { toast } from 'react-toastify';
import DETH from '../ABI/DETH.json';
import $ from 'jquery';
import axios from 'axios';
import Web3 from 'web3';
import config from '../lib/config';

toast.configure();
let toasterOption     = config.toasterOption;
const dashboardRoutes = [];


const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Myitems(props) {
  const classes             = useStyles();
  const history             = useHistory();
  const { ...rest }         = props;
  const [providerss,set_providers]=useState(null)

  var LikeForwardRef        = useRef();
  var PutOnSaleForwardRef   = useRef();
  var PurchaseNowForwardRef = useRef();
  var WalletForwardRef      = useRef();
  var ReportForwardRef      = useRef();
  var ShareForwardRef       = useRef();
  var PlaceABidForwardRef   = useRef();
  var CancelOrderForwardRef = useRef();
  var BurnForwardRef        = useRef();
  var { paramUsername, paramAddress } = useParams();
  if(typeof paramUsername == 'undefined') { paramUsername = ''; }
  if(typeof paramAddress == 'undefined') { paramAddress = ''; }
    const [email, setemail]             = React.useState("");
    const [description, setdescription] = React.useState("");
    const [itemId, Set_itemId] = React.useState("");
    const [reportBtn, Set_reportBtn] = React.useState(false);

  const [ParamAccountCustomUrl, Set_ParamAccountCustomUrl] = useState(paramUsername);
  const [ParamAccountAddr, Set_ParamAccountAddr]           = useState(paramAddress);
  const [UserNotFound, Set_UserNotFound]                   = useState(false);
  const [FollowingUserList, Set_FollowingUserList]         = useState([]);
  const [WalletConnected, Set_WalletConnected]             = useState('false');
  const [UserAccountAddr, Set_UserAccountAddr]             = useState('');
  const [UserAccountBal, Set_UserAccountBal]               = useState(0);
  const [AddressUserDetails, Set_AddressUserDetails]       = useState({});
  const [Accounts, Set_Accounts]                           = useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr]         = useState('');
  const [MyItemAccountAddr_Details, Set_MyItemAccountAddr_Details] = useState('');
  const [HitItem, Set_HitItem]                             = useState({});
  const [LikedTokenList, setLikedTokenList]                = useState([]);
  const [OnSale_Count, Set_OnSale_Count]                   = useState(0);
  const [OnSale_List, Set_OnSale_List]                     = useState([]);
  const [Collectibles_Count, Set_Collectibles_Count]       = useState(0);
  const [Collectibles_List, Set_Collectibles_List]         = useState([]);
  const [Created_Count, Set_Created_Count]                 = useState(0);
  const [Created_List, Set_Created_List]                   = useState([]);
  const [Owned_Count, Set_Owned_Count]                     = useState(0);
  const [Owned_List, Set_Owned_List]                       = useState([]);
  const [Liked_Count, Set_Liked_Count]                     = useState(0);
  const [Liked_List, Set_Liked_List]                       = useState([]);

  //by muthu
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [item, Set_item] = useState({}); 
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [followingCount, Set_followingCount]      = useState(0);
  const [followersCount, Set_followersCount]      = useState(0);
  const [followingStatus, Set_followingStatus]    = useState(true);
  const [loadMoreBtn, Set_loadMoreBtn]            = useState(true);
  const [followersPage, Set_followersPage]        = useState(1);
  const [followingPage, Set_followingPage]        = useState(1);
  const [followersStatus, Set_followersStatus]    = useState(true);
  const [FollowerUserList, Set_FollowerUserList]  = React.useState([]);
  const [loadMoreBtn1, Set_loadMoreBtn1]          = useState(true);
  const [is_follow, Set_is_follow]                = useState("no");
  const [ActivityList, Set_ActivityList]          = React.useState([]);
  const [Activitycount, Set_Activitycount]        = React.useState(0);
  const [CurTabName, Set_CurTabName]              = React.useState('owned');
  const [TokenFile, setTokenFile]                 = useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = useState("");
  const [TokenFilePreUrl, setTokenFilePreUrl]     = useState("");
  const [coverimage, setCoverimage]               = useState("");
  const [MyItemcoverimage,Set_MyItemcoverimage]   = useState("");
  const [totaluserbidAmt,setTotaluserbidAmt]      = useState(0);
  const [walletaddr, setWalletaddr]               = useState("");
  const [Tabname,Set_Tabname]                     = useState('My-Items');
  const [bouiusdvalue, setBouiusdval]             = useState(0);
  const [usdval, setusd] = useState(0);


  async function CorrectDataGet(Resp,Target) {
    var RetData = { count : 0, list : [] }
    if (
      Resp
      && Resp.data
      && Resp.data.Target
      && Resp.data.list
      && Resp.data.list[0]
    ) {
      if(Resp.data.Target == 'Count' && Resp.data.list[0].count) {
        RetData.count = Resp.data.list[0].count;
      }
      else if(Resp.data.Target == 'List' && Resp.data.list[0]) {
        RetData.list = Resp.data.list;
      }
    }
    if(
      Resp
      && Resp.data
      && Resp.data.Target
      && Resp.data.changeStatusList) {
        changeReceiptStatus_Call(Resp.data.changeStatusList);
    }
    return RetData;
  }

  const selectFileChange = async (e) => {
    var validExtensions = ["png", 'gif', 'webp', 'mp4', 'mp3']; //array of valid extensions
    if(e.target && e.target.files) {
      var reader = new FileReader()
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) == -1) {
        toast.error("Only these file types are accepted : "+validExtensions.join(', '), toasterOption);
        $("input[name='image']").val("");
        return false;
      }
      else {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 30) {
          toast.error("File size exceeds 30 MB", toasterOption);
          $("input[name='image']").val("");
          return false;
        } else {
          setTokenFile(file);
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            if(reader.result) {
              setTokenFilePreReader(reader.result);
            }
          }.bind(this);
          setTokenFilePreUrl(e.target.value);

          var currAddr = await getCurAddr();

          var formData = new FormData();
          if(file) {
            formData.append('CoverImage', file);
          }
          formData.append('addr', currAddr);
          var Resp = await UserProfile_Update_Action(formData);
          if (Resp && Resp.data && Resp.data.toast && Resp.data.toast.type && Resp.data.toast.type == 'success') {
            setCoverimage(config.Back_URL+"/cover/"+Resp.data.details.coverimage)
            setTimeout(() => { window.location.reload(false); }, 2000)
          }
        }
      }
    }
  }

  async function changeReceiptStatus_Call(list) {
    var web3 = new Web3(window.ethereum);
    list.map(async (item) => {
      if (item && typeof item.checkAdd != "undefined" && item.checkAdd.hashValue) {
        try {
          var data = await web3.eth.getTransactionReceipt(item.checkAdd.hashValue);
          var hashValue = item.checkAdd.hashValue;
          if (data == null) {
          } else {
            if(data.status == '0x0') {
            } else {
              var payload = {
                status: 'true',
                hashValue: hashValue
              }
              await changeReceiptStatus_Action(payload);
            }
          }
        }
        catch(err) {
          // console.log('err', err);
        }
      }
    })
  }

  async function Tab_Click(TabName) {
  //  alert(TabName)
    Set_CurTabName(TabName);
    await Tab_Data_Call('List',TabName);
    await Tab_Data_Call('Count',TabName);
  }
  async function Tab_Data_Call(Target, TabName, init=false) {
    if(MyItemAccountAddr ) {
      var ReqData = {
        Addr:MyItemAccountAddr,
        MyItemAccountAddr:MyItemAccountAddr,
        ParamAccountAddr:ParamAccountAddr,
        UserAccountAddr:UserAccountAddr,
        Target:Target,
        TabName:TabName,
        init:init,
        from:'My-Items'
      };
      var Resp = {};
      Resp = await CollectiblesList_MyItems(ReqData);
      var RespNew = await CorrectDataGet(Resp);

      if(
        (Target=='Count' && typeof RespNew.count != 'undefined')
        ||
        (Target=='List' && RespNew.list)
      ) {
        if(TabName=='collectibles') {
          if(Target=='Count') { Set_Collectibles_Count(RespNew.count); }
          if(Target == 'List') { Set_Collectibles_List(RespNew.list); }
        }
        else if(TabName=='onsale') {
          if(Target=='Count') { Set_OnSale_Count(RespNew.count); }
          if(Target == 'List') { Set_OnSale_List(RespNew.list); }
        }
        else if(TabName=='created') {
          if(Target=='Count') { Set_Created_Count(RespNew.count); }
          if(Target == 'List') { Set_Created_List(RespNew.list); }
        }
       else if(TabName=='owned') {
          if(Target=='Count') { Set_Owned_Count(RespNew.count); }
          if(Target == 'List') { Set_Owned_List(RespNew.list); }
        }
        else if(TabName=='liked') {
          if(Target=='Count') { Set_Liked_Count(RespNew.count); }
          if(Target == 'List') { Set_Liked_List(RespNew.list); }
        }
      }
    }
    return true;
  }

  async function FollowingTab(address, pos, isFollow) {
    var currAddr = await getCurAddr();
    var web3 = new Web3(window.ethereum);
    var Payload = {};
    Payload.currAddr = currAddr;
    Payload.ParamAccountAddr = address;

    var msg = "I would like to follow user: "+address
    if(isFollow=="yes"){
      msg = "I would like to stop following user: "+address
    }

    await  web3.eth.personal.sign(msg, currAddr)
    var Resp = await FollowChange_Action(Payload);
    var foll = (Resp && Resp.data && Resp.data.ChangeType)?Resp.data.ChangeType:"no";

    if((ParamAccountAddr !="" && currAddr !="" && currAddr==ParamAccountAddr) || (ParamAccountAddr=="" || !ParamAccountAddr)){
      //same user
      FollowingUserList.splice(pos, 1);
      Set_followingStatus(false);
      Set_FollowingUserList(FollowingUserList);
      Set_followingStatus(true);

      var followingcnt = (Resp && Resp.data && Resp.data.following)?Resp.data.following:"0";
      var followerscnt = (Resp && Resp.data && Resp.data.followers)?Resp.data.followers:"0";
      Set_followingCount(followingcnt);
      Set_followersCount(followerscnt);
    } else {
      //another user
      FollowingUserList[pos].isFollow = foll;
      Set_followingStatus(false);
      Set_FollowingUserList(FollowingUserList);
      Set_followingStatus(true);
    }
    if(foll=="yes"){
      toast.success("Follow successfully", toasterOption);
    }else{
      toast.success("Un-Follow successfully", toasterOption);
    }
    
  }
  async function FollowChange_Call() {

    var web3 = new Web3(window.ethereum);
    var currAddr = await getCurAddr();
    var Payload = {};
    Payload.currAddr = currAddr;
    Payload.ParamAccountAddr = ParamAccountAddr;

    var msg = "I would like to follow user: "+ParamAccountAddr
    if(is_follow=="yes"){
      msg = "I would like to stop following user: "+ParamAccountAddr
    }

    await  web3.eth.personal.sign(msg, currAddr)
    var Resp = await FollowChange_Action(Payload);

    var foll = (Resp && Resp.data && Resp.data.ChangeType)?Resp.data.ChangeType:"no";
    Set_is_follow(foll);

    if(foll=="yes"){
      toast.success("Follow successfully", toasterOption);
    } else {
      toast.success("Un-Follow successfully", toasterOption);
    }
    Check_follow();
    if(CurTabName == 'followers') {
      Get_Followers_List();
    }
    else if(CurTabName == 'following') {
      Get_Following_List();
    }
  }

  async function getBouiUsd() {
    try {
      var { result } = await getsettinglist();
      if (result && result.data) {
        setBouiusdval(result.data.bouiusd)
      }
    } catch (err) { }
  }
  async function getUsd() {
    let result = await axios.get(`${config.vUrl}/user/getUSD`);
    if(result){
      let currency = result.data;
      console.log(currency.data[0].Current_USD,'resss')
      let usd =currency.data[0].Current_USD;
      setusd(usd)
    }
  }

  const Get_MyItemAccountAddr_Details = async (payload) => {
    var Resp = await ParamAccountAddr_Detail_Get(payload);
    if(Resp.data&&Resp.data.User&&Resp.data.User.coverimage){
      Set_MyItemcoverimage(Resp.data.User.coverimage);
    }
    
    if(
      Resp
      && Resp.data
      && Resp.data.User
      && Resp.data.User.curraddress
    ) {
      Set_MyItemAccountAddr(Resp.data.User.curraddress);
      if(Resp.data.User) {
        Set_MyItemAccountAddr_Details(Resp.data.User);
      }
    }
    else {
      toast.warning('User not found', toasterOption);
      Set_UserNotFound(true);
    }
  }
  async function BiddingAmout(){
    try{
      var currAddr = await getCurAddr();
      var payload={currAddr}
      var Resp = await Bidding_Detail_Action(payload);
      if(Resp&&Resp.data){
        setTotaluserbidAmt(Resp.data.res1)
      }
    }catch(err){
    }
  }

  async function  getwalletlist(){
    try{
      var currAddr = await getCurAddr();
      var payload={
        address:currAddr
      }
      var {result} = await getWallet(payload)
      if(result&&result.data){
        setWalletaddr(result.data.address)
      }
    }catch(err){}
  }
  const AfterWalletConnected = async () => {
    var currAddr = await getCurAddr();
    BiddingAmout();
    getwalletlist();
    getBouiUsd();
    getUsd();
     if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      var CoursetroContract = new web3.eth.Contract(DETH, config.tokenAddress);
      var tokenBal = await CoursetroContract.methods.balanceOf(currAddr).call();
      var tokenBalance = tokenBal / config.decimalvalues;
      Set_TokenBalance(tokenBalance.toFixed(6));
    }
    
    //
    if(typeof currAddr != 'undefined'){
      if(ParamAccountAddr || ParamAccountCustomUrl) {
        if(ParamAccountAddr && ParamAccountAddr.toString() === currAddr.toString()) {
          Set_MyItemAccountAddr(ParamAccountAddr);
          Get_MyItemAccountAddr_Details({addr:currAddr});
        }
        else {
          var payload = {}
          if(ParamAccountAddr) {
            payload.addr = ParamAccountAddr;
          }
          else if(ParamAccountCustomUrl){
            payload.customurl = ParamAccountCustomUrl;
          }
          await Get_MyItemAccountAddr_Details(payload);
        }
      }
      else {
        Set_MyItemAccountAddr(currAddr);
        Get_MyItemAccountAddr_Details({addr:currAddr});
      }

      window.$('#AfterWalletConnected_two').click();
      // After set state value not getting so above click function i used.
      // After this onclick state value getting purfectly (By Raj)
    }
   
   
  }

  async function AfterWalletConnected_two() {
    // User_FollowList_Get_Call();
    Check_follow();
    Get_Activity_list();
    try{
      LikeForwardRef.current.getLikesData();
    }catch(err){

    }
  
    await Tab_Data_Call('Count','onsale',true);
    await Tab_Data_Call('List','onsale');
    await Tab_Data_Call('Count','created');
    await Tab_Data_Call('Count','owned');
    await Tab_Data_Call('Count','liked');
  }

  async function User_FollowList_Get_Call() {
    var resp = await User_FollowList_Get_Action({
      target:'following',
      addr:MyItemAccountAddr,
      follower:MyItemAccountAddr
    });
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var MyFollowingList = resp.data.list;
      Set_FollowingUserList(MyFollowingList);
      var FollowIndex = MyFollowingList.findIndex(e => e.follower == MyItemAccountAddr);
    }
    else {
      Set_FollowingUserList([]);
    }
  }
  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  const loadFollowing = async (e) => {

    var currAddr = await getCurAddr();
    var resp = await User_Following_List_Action({
      addr:MyItemAccountAddr,
      loginUser:currAddr,
      page: followingPage
    });
    
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var List = resp.data.list;
      if(List && List.length > 0){
        Set_followingStatus(false);
        var totalList = FollowingUserList.concat(List);
        Set_FollowingUserList(totalList);
        Set_followingStatus(true);
      }else{
        Set_loadMoreBtn(false);
      }
      
    }
  }
  async function Check_follow(){
    var data = {
      useraddress:UserAccountAddr,
      followaddress:MyItemAccountAddr,
    }
    var resp = await User_Follow_Get_Action(data);
    var foll = (resp && resp.data && resp.data.isFollow)?resp.data.isFollow:"no";
    var followingcnt = (resp && resp.data && resp.data.following)?resp.data.following:"0";
    var followerscnt = (resp && resp.data && resp.data.followers)?resp.data.followers:"0";
    Set_is_follow(foll);
    Set_followingCount(followingcnt);
    Set_followersCount(followerscnt);
  }

  async function FollowerTab(address, pos, isFollow) {

    var web3 = new Web3(window.ethereum);
    var currAddr = await getCurAddr();
    var currentUser ="no";
    if((ParamAccountAddr !="" && currAddr !="" && currAddr==ParamAccountAddr) || (ParamAccountAddr=="" || !ParamAccountAddr)){
      currentUser ="yes";
    }
    var msg = "I would like to follow user: "+address
    if(isFollow=="yes"){
      msg = "I would like to stop following user: "+address
    }

    var Payload = {};
    Payload.currAddr = currAddr;
    Payload.ParamAccountAddr = address;
    Payload.currentUser = currentUser;
    await  web3.eth.personal.sign(msg, currAddr)
    var Resp = await FollowChange_Action(Payload);

    var foll = (Resp && Resp.data && Resp.data.ChangeType)?Resp.data.ChangeType:"no";
    FollowerUserList[pos].isFollow = foll;
    Set_followersStatus(false);
    Set_FollowerUserList(FollowerUserList);
    Set_followersStatus(true);

    if(currentUser=="yes"){
      var followingcnt = (Resp && Resp.data && Resp.data.following)?Resp.data.following:"0";
      var followerscnt = (Resp && Resp.data && Resp.data.followers)?Resp.data.followers:"0";
      Set_followingCount(followingcnt);
      Set_followersCount(followerscnt);
    }
    if(foll=="yes"){
      toast.success("Follow successfully", toasterOption);
    }
    else{
      toast.success("Un-Follow successfully", toasterOption);
    }
    Check_follow();
  }

  const loadFollowers = async (e) => {
    var currAddr = await getCurAddr();
    var resp = await User_Followers_List_Action({
      addr:MyItemAccountAddr,
      loginUser:currAddr,
      page: followersPage
    });
    
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var MyFollowerList = resp.data.list;
      if(MyFollowerList && MyFollowerList.length > 0){
        Set_followersStatus(false);
        var totalList = FollowerUserList.concat(MyFollowerList);
        Set_FollowerUserList(totalList);
        Set_followersStatus(true);
      }else{
        Set_loadMoreBtn1(false);
      }
    }
  }

  async function Get_Followers_List() {
    Set_CurTabName('followers');
    var currAddr = await getCurAddr();
    var resp = await User_Followers_List_Action({
      addr:MyItemAccountAddr,
      loginUser:currAddr
    });
    
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var MyFollowingList = resp.data.list;
      Set_FollowerUserList(MyFollowingList);
    }
    else {
      Set_FollowerUserList([]);
    }
  }

  async function Get_Following_List() {
    Set_CurTabName('following');
    var currAddr = await getCurAddr();
    var resp = await User_Following_List_Action({
      addr:MyItemAccountAddr,
      loginUser:currAddr
    });
    
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var MyFollowingList = resp.data.list;
      Set_FollowingUserList(MyFollowingList);
    }
    else {
      Set_FollowingUserList([]);
    }
  }
  async function Get_Activity_list(){
    Set_CurTabName('activity');
    var resp = await Activity_List_Action({
      addr:MyItemAccountAddr
    });
   
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var List = resp.data.list;
      if (List.length>0) {
        Set_Activitycount(List.length)
      }
      Set_ActivityList(List);
    }
    else {
      Set_ActivityList([]);
    }

  }
  function copyText(a,b) {
    toast.success("Copied", toasterOption);
  }
  const ReportValidation = async (data={}) => {
    var ValidateError = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

    if(description == '' || typeof description=="undefined") {
        ValidateError.description = '"Description" is not allowed to be empty';
    }
    if(email == '' || typeof email=="undefined") {
        ValidateError.email = '"E-Mail" is not allowed to be empty';
    }else if (!(emailRegex.test(email))) {
        ValidateError.email = 'Please enter Valid E-Mail Id';
    }
    Set_ValidateError(ValidateError);
    return ValidateError;
}

async function submitReport(){
    var errors = await ReportValidation();
    var errorsSize = Object.keys(errors).length;
    if(errorsSize != 0) {
        toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
        return false;
    }
    var reqData ={
        reportuser:UserAccountAddr,
        description:description,
        itemId:itemId,
        tokenCounts:tokenCounts,
        email:email,
        type:"profile"
    }
    Set_reportBtn(true);
    var response = await ReportRequest(reqData);
    console.log("ReportRequest",response)
    if(response && response.status=="true"){
        toast.success("Successfully submit your report", toasterOption);
        setdescription("");
        setTimeout(function(){
            Set_reportBtn(false)
            window.location.reload(false)
            window.$('#report_modal').modal('hide');
        },100)
    }else{
        Set_reportBtn(false)
        toast.error('Oops something went wrong.!', toasterOption);
    }
}
const inputChange = (e) => {
  if(e && e.target && typeof e.target.value != 'undefined') {
      var value = e.target.value;
      switch (e.target.name) {
          case "description":
              if(e.target.value !=""){
                  Set_ValidateError({});
              }
              setdescription(value);
          break;
          case "email":
              if(e.target.value !=""){
                  Set_ValidateError({});
              }
              setemail(value);
          break;
      }
     
  }
}
async function cancelReport(){
  setdescription("");
  window.location.reload(false)
  window.$('#report_modal').modal('hide');
  Set_ValidateError({});
}
  // Countdown Timer
  const currentDate = new Date();
  const year = (currentDate.getMonth() === 11 && currentDate.getDate() > 23) ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span><span className="timer_time">{zeroPad(days)}</span><span className="timer_label">d</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(hours)}</span><span className="timer_label">h</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(minutes)}</span><span className="timer_label">m</span></span>
        <span className="timer_dots"> </span>
        <span><span className="timer_time">{zeroPad(seconds)}</span><span className="timer_label">s</span></span>
      </div>
    );
  };

  console.log(UserAccountAddr,"=>useraccount")

  return (
    <div className="inner_header">
      <div id="AfterWalletConnected_two" onClick={() => AfterWalletConnected_two()}>&nbsp;</div>
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
       <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        walletaddr={walletaddr}
        setWalletaddr={setWalletaddr}
        Set_AddressUserDetails={Set_AddressUserDetails}
        AddressUserDetails={AddressUserDetails}
        AllowedQuantity={AllowedQuantity}
        Set_AllowedQuantity={Set_AllowedQuantity}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected = {Set_WalletConnected}
        Set_UserAccountAddr = {Set_UserAccountAddr}
        Set_UserAccountBal = {Set_UserAccountBal}
        Set_AddressUserDetails = {Set_AddressUserDetails}
        Set_Accounts = {Set_Accounts}
        Set_MyItemAccountAddr = {Set_MyItemAccountAddr}
        Set_tokenCounts = {Set_tokenCounts}
        Set_item = {Set_item}
        Set_tokenCounts_Detail = {Set_tokenCounts_Detail}
        Set_MyTokenBalance = {Set_MyTokenBalance}
        Set_Bids = {Set_Bids}
        Set_AccepBidSelect = {Set_AccepBidSelect}
        Set_tokenBidAmt = {Set_tokenBidAmt}
        Set_NoOfToken = {Set_NoOfToken}
        Set_ValidateError = {Set_ValidateError}
        Set_TokenBalance = {Set_TokenBalance}
        Set_YouWillPay = {Set_YouWillPay}
        Set_YouWillPayFee = {Set_YouWillPayFee}
        Set_YouWillGet = {Set_YouWillGet}
        Set_BidApply_ApproveCallStatus = {Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus = {Set_BidApply_SignCallStatus}
        WalletConnected = {WalletConnected}
        UserAccountAddr = {UserAccountAddr}
        UserAccountBal = {UserAccountBal}
        AddressUserDetails = {AddressUserDetails}
        Accounts = {Accounts}
        MyItemAccountAddr = {MyItemAccountAddr}
        tokenCounts = {tokenCounts}
        item = {item}
        tokenCounts_Detail = {tokenCounts_Detail}
        MyTokenBalance = {MyTokenBalance}
        Bids = {Bids}
        AccepBidSelect = {AccepBidSelect}
        tokenBidAmt = {tokenBidAmt}
        NoOfToken = {NoOfToken}
        ValidateError = {ValidateError}
        TokenBalance = {TokenBalance}
        YouWillPay = {YouWillPay}
        YouWillPayFee = {YouWillPayFee}
        YouWillGet = {YouWillGet}
        BidApply_ApproveCallStatus = {BidApply_ApproveCallStatus}
        BidApply_SignCallStatus = {BidApply_SignCallStatus}
        AllowedQuantity = {AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
    <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        Set_AddressUserDetails={Set_AddressUserDetails}
        AddressUserDetails={AddressUserDetails}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
       <ReportNowRef
        UserAccountAddr={UserAccountAddr}
        ref={ReportForwardRef}
      />
      <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />
     <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader items_header"}>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="items_bg">
  
                {
                MyItemcoverimage && MyItemcoverimage!='' ?
                <img src={config.Back_URL+'/cover/'+MyItemcoverimage} alt="profile" className="img-fluid" />
                :
                <img src={require("../assets/images/logo.png")} alt="profile" id="imgPreview" className="img-fluid"/>
                }
             
                {
                (MyItemAccountAddr !='' && MyItemAccountAddr != UserAccountAddr) ?'':  
                <div className="container edit_cover_container">
                  <p className="edit_cover_text" data-toggle="modal" data-target="#edit_cover_modal">Edit cover</p>
                </div>
               }
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="items_bg_pink prifilre">
                <div className="item_prof">
                {
                  MyItemAccountAddr_Details && MyItemAccountAddr_Details.image && MyItemAccountAddr_Details.image!=''
                  ?
                  <img src={config.Back_URL+'/profile/'+MyItemAccountAddr_Details.image} alt="logo" id="imgPreview" className="img-fluid" width="100"/>
                  :
                  <img src={require("../assets/images/logo.png")} alt="profile" id="imgPreview" className="img-fluid items_profile"/>
                  }

                  {
                  (MyItemAccountAddr !='' && MyItemAccountAddr != UserAccountAddr) ?'':
                  <Link to="/edit-profile">
                    <div className="profile_edit_panel item_prof_panel">
                      <div className="profile_edit_icon">
                        <i class="fas fa-pencil-alt"></i>
                        {/* <input type="file" name="photograph" id="photo" required="true" /> */}
                      </div>
                      {/* <input type="file" name="photograph" id="photo" required="true" /> */}
                    </div>
                  </Link>
                }
                </div>
                {(MyItemAccountAddr_Details && (MyItemAccountAddr_Details.name!="") ) &&
                 <p><span className="address_text">{MyItemAccountAddr_Details.name}</span></p>
                }
                {(MyItemAccountAddr_Details && (MyItemAccountAddr_Details.customurl!="")) && 
                <p><span className="address_text">@{MyItemAccountAddr_Details.customurl}</span></p>
                }
                <div className="crd_link_ my_item_link">
                {(MyItemAccountAddr_Details && (MyItemAccountAddr_Details.instagram!="")) &&
                 <a href={MyItemAccountAddr_Details.instagram} target="_blank" className="address_text"><i className="fab fa-instagram notes_fa mr-2"></i></a>
                }
                {(MyItemAccountAddr_Details && (MyItemAccountAddr_Details.twitter!="")) &&
                 <a href={MyItemAccountAddr_Details.twitter} target="_blank" className="address_text"><i className="fab fa-twitter notes_fa mr-2"></i></a>
                }
                {(MyItemAccountAddr_Details && (MyItemAccountAddr_Details.facebook!="")) &&
                 <a href={MyItemAccountAddr_Details.facebook} target="_blank" className="address_text"><i className="fab fa-facebook-f notes_fa mr-2"></i></a>
                
                 }
                {(MyItemAccountAddr_Details && (MyItemAccountAddr_Details.youtube!="")) &&
                 <a href={MyItemAccountAddr_Details.youtube} target="_blank" className="address_text"><i className="fab fa-youtube  notes_fa mr-2"></i></a>
                 
                 }
                </div>
                <p>
            
                  <span className="address_text">{halfAddrShow(MyItemAccountAddr)}</span>
                  {/* <span>
                    <i className="fas fa-sticky-note notes_fa"></i>
                  </span> */}
                <CopyToClipboard text={MyItemAccountAddr} onCopy={() => copyText('Wallet address',MyItemAccountAddr)}>
                <i className="fas fa-sticky-note notes_fa"></i>
                  </CopyToClipboard>
                </p>
                <div className="mt-3">
                  {/* <Button className="btn_outline_red">Edit Profile</Button> */}
                  {
                  // (ParamAccountAddr !="" &&  UserAccountAddr !="" && ParamAccountAddr !=UserAccountAddr)?'':'';
                  (MyItemAccountAddr !='' && MyItemAccountAddr != UserAccountAddr)?
                  <Button className="btn_outline_red" onClick={FollowChange_Call}>
                  {(is_follow=="yes")?"Unfollow":"Follow"}
                </Button>
                  :
                  <Link className="btn_outline_red" to="/edit-profile">Edit Profile</Link>
                  }
                  <span className="bg_red_icon dropdown">
                  <span className=""  data-toggle="dropdown"  aria-haspopup="true" id="share_option" aria-expanded="false">
                  <i class="fas fa-share-alt" ></i></span>

                  <div class="dropdown-menu" aria-labelledby="share_option" >
                      <a class="dropdown-item" href="javascript:void(0)" onClick={() => ShareForwardRef.current.ShareSocial_Click(MyItemAccountAddr_Details)}>Share </a>

                    {/* <a class="dropdown-item" href="">Facebook</a>
                    <a class="dropdown-item" href="">Whatsapp</a> */}


                    </div>
                    
                  </span>
                  <span className="bg_red_icon dropdown">
                  <span className=""  data-toggle="dropdown" aria-haspopup="true" id="more_option" aria-expanded="false">
                  <i class="fas fa-ellipsis-h"></i></span>
                  <div class="dropdown-menu" aria-labelledby="more_option">
                  <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_page_modal">Report Page</a>
                    </div>
                  </span>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <nav className="masonry_tab_nav mt-4 items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab" id="nav-tab" role="tablist">
                {walletaddr==UserAccountAddr&&
                <>
                  <a className="nav-link active" id="onsale-tab" data-toggle="tab" href="#onsale" role="tab" aria-controls="onsale" aria-selected="true" onClick={() => Tab_Click('onsale')}>
                    <div className="tab_head">OnSale</div>
                    <div className="tab_count">{OnSale_Count}</div>
                  </a>
                
                  <a className="nav-link" id="created-tab" data-toggle="tab" href="#created" role="tab" aria-controls="created" aria-selected="false" onClick={() => Tab_Click('created')}>
                    <div className="tab_head">Created</div>
                    <div className="tab_count">{Created_Count}</div>
                  </a>
                  </>
                  }
                   <a className="nav-link" id="collectibles-tab" data-toggle="tab" href="#collectibles" role="tab" aria-controls="collectibles" aria-selected="false" onClick={() => Tab_Click('owned')}>
                    <div className="tab_head">NFTs</div>
                    <div className="tab_count">{Owned_Count}</div>
                  </a>
                  <a className="nav-link" id="liked-tab" data-toggle="tab" href="#liked" role="tab" aria-controls="liked" aria-selected="false" onClick={() => Tab_Click('liked')}>
                    <div className="tab_head">Liked</div>
                    <div className="tab_count">{Liked_Count}</div>
                  </a>
                  <a className="nav-link" id="activity-tab" data-toggle="tab" href="#activity" role="tab" aria-controls="activity" aria-selected="false" onClick={() => Get_Activity_list()}>
                    <div className="tab_head">Activity</div>
                    <div className="tab_count">{Activitycount}</div>
                  </a>
                  <a className="nav-link" id="following-tab" data-toggle="tab" href="#following" role="tab" aria-controls="following" aria-selected="false" onClick={() => Get_Following_List()}>
                    <div className="tab_head">Following</div>
                    <div className="tab_count">{followingCount}</div>
                  </a>
                  {walletaddr==UserAccountAddr&&
                  <a className="nav-link" id="followers-tab" data-toggle="tab" href="#followers" role="tab" aria-controls="followers" aria-selected="false" onClick={() => Get_Followers_List()}>
                    <div className="tab_head">Followers</div>
                    <div className="tab_count">{followersCount}</div>
                  </a>
                 }
                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="onsale" role="tabpanel" aria-labelledby="onsale-tab">
                  <div className="proposal_panel_overall">
                 <div className="masonry">
        
                 {(OnSale_Count > 0 || OnSale_List.length > 0)?(
                        <>
                              {
                                OnSale_List.map((item) => {
                                  return (
                                    <div>
                                            <TokenItem
                                              bouiusdvalue={bouiusdvalue}
                                              bnbusdvalue ={usdval}
                                              setBouiusdval={setBouiusdval}
                                              Tabname={Tabname}
                                              Set_Tabname={Set_Tabname}
                                              CurTabName={CurTabName}
                                              Set_CurTabName={Set_CurTabName}
                                              item={item}
                                              Set_item={Set_item}
                                              LikedTokenList={LikedTokenList}
                                              hitLike={LikeForwardRef.current.hitLike}
                                              UserAccountAddr={UserAccountAddr}
                                              UserAccountBal={UserAccountBal}
                                              PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                              PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                              PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                              Set_Bids={Set_Bids}
                                              Bids = {Bids}
                                              Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                              Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                              Set_MyTokenBalance = {Set_MyTokenBalance}
                                              Set_MyTokenDetail={Set_MyTokenDetail}
                                              Set_AllowedQuantity={Set_AllowedQuantity}
                                              Set_YouWillPay = {Set_YouWillPay}
                                              Set_YouWillPayFee = {Set_YouWillPayFee}
                                              Set_YouWillGet = {Set_YouWillGet}
                                              // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                                              Burn_Click={BurnForwardRef.current.Burn_Click}
                                              CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                              WalletConnected={WalletConnected}
                                              SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                              ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                          />
                                    </div>
                                  )
                                })
                              }
                              </>
                      ):(
                        <div className="tab-pane fade" id="onsale" role="tabpanel" aria-labelledby="onsale-tab">
                          <div className="proposal_panel_overall">
                            <div className="text-center py-5">
                              <p className="not_found_text">No items found</p>
                              <p className="not_found_text_sub">Explore the marketplace for your first NFT or subscribe for free drop notifications</p>
                              <div className="mt-3">
                                <Button className="create_btn">Browse Marketplace</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                   </div>
                  </div>
                </div>

                {/* collectibles*/}
                <div className="tab-pane fade" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                  <div className="proposal_panel_overall">
                    <div className="col-lg-12 content_row_nfts">
                      {/* <div className="width_name">
                      <Select
                      className="form-control primary_inp custom-select"
                      id="TokenCategory"
                      name="TokenCategory"
                     
                      
                      label="Single select"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                      </div> */}
                    
                    </div>
                    <div className="masonry">
                    {(Owned_Count > 0 || Owned_List.length > 0)?(
                          <>
                          {
                            Owned_List.map((item) => {
                              // console.log("collectibles-tab",item)
                                return (
                                  <TokenItem
                                  Tabname={Tabname}
                                  Set_Tabname={Set_Tabname}
                                  setBouiusdval={setBouiusdval}
                                  bouiusdvalue={bouiusdvalue}
                                  bnbusdvalue ={usdval}
                                  CurTabName={CurTabName}
                                  Set_CurTabName={Set_CurTabName}
                                  item={item}
                                  Set_item = {Set_item}
                                  LikedTokenList={LikedTokenList}
                                  hitLike={LikeForwardRef.current.hitLike}
                                  UserAccountAddr={UserAccountAddr}
                                  UserAccountBal={UserAccountBal}
                                  PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                  PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                  PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                  WalletConnected={WalletConnected}
                                  SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                  ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                  CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                  Set_Bids={Set_Bids}
                                  Bids = {Bids}
                                  Burn_Click={BurnForwardRef.current.Burn_Click}
                                  Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                  Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                  Set_MyTokenBalance = {Set_MyTokenBalance}
                                  Set_MyTokenDetail={Set_MyTokenDetail}
                                  Set_AllowedQuantity={Set_AllowedQuantity}
                                  Set_YouWillPay = {Set_YouWillPay}
                                  Set_YouWillPayFee = {Set_YouWillPayFee}
                                  Set_YouWillGet = {Set_YouWillGet}
                                />
                                  )
                              
                              })
                          }
                          </>
                      ):(
                        <div className="tab-pane fade" id="collectibles" role="tabpanel" aria-labelledby="collectibles-tab">
                        <div className="proposal_panel_overall">
                          <div className="text-center py-5">
                            <p className="not_found_text">No items found</p>
                            <p className="not_found_text_sub">Explore the marketplace for your first NFT or subscribe for free drop notifications</p>
                            <div className="mt-3">
                              <Button className="create_btn">Browse Marketplace</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      )}

                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="created" role="tabpanel" aria-labelledby="created-tab">
                  <div className="proposal_panel_overall">
                  <div className="masonry">

                  {(Created_Count > 0 || Created_List.length > 0)?(
                    <>
                      {
                        Created_List.map((item) => {
                          // console.log("Created_List",item)
                          return (
                            <TokenItem
                                      item={item}
                                      Set_item = {Set_item}
                                      Tabname={Tabname}
                                      Set_Tabname={Set_Tabname}
                                      setBouiusdval={setBouiusdval}
                                      bouiusdvalue={bouiusdvalue}
                                      bnbusdvalue ={usdval}
                                      CurTabName={CurTabName}
                                      Set_CurTabName={Set_CurTabName}
                                      LikedTokenList={LikedTokenList}
                                      hitLike={LikeForwardRef.current.hitLike}
                                      UserAccountAddr={UserAccountAddr}
                                      UserAccountBal={UserAccountBal}
                                      PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                                      PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                                      PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                                      WalletConnected={WalletConnected}
                                      SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                                      ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                                      CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                                      Burn_Click={BurnForwardRef.current.Burn_Click}
                                      //by muthu
                                      Set_Bids={Set_Bids}
                                      Bids = {Bids}
                                      Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                                      Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                      Set_MyTokenBalance = {Set_MyTokenBalance}
                                      Set_MyTokenDetail={Set_MyTokenDetail}
                                      Set_AllowedQuantity={Set_AllowedQuantity}
                                      Set_YouWillPay = {Set_YouWillPay}
                                      Set_YouWillPayFee = {Set_YouWillPayFee}
                                      Set_YouWillGet = {Set_YouWillGet}
                                   />
                            )
                          })
                        }
                    </>
                    ):(
                        ""
                    )}
                  </div>

                

                  </div>
                </div>

                <div className="tab-pane fade" id="liked" role="tabpanel" aria-labelledby="liked-tab">
                <div className="proposal_panel_overall">
                  <div className="masonry">
                  {(Liked_Count > 0 || Liked_List.length > 0)?(
                  <>
                      {
                        Liked_List.map((item) => {
                          return (
                            <TokenItem
                            item={item}
                            Set_item = {Set_item}
                            setBouiusdval={setBouiusdval}
                            bouiusdvalue={bouiusdvalue}
                            bnbusdvalue ={usdval}
                            LikedTokenList={LikedTokenList}
                            hitLike={LikeForwardRef.current.hitLike}
                            UserAccountAddr={UserAccountAddr}
                            UserAccountBal={UserAccountBal}
                            PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                            PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                            PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                            WalletConnected={WalletConnected}
                            SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                            ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                            CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                            Burn_Click={BurnForwardRef.current.Burn_Click}
                            //by muthu
                            Set_Bids={Set_Bids}
                            Bids = {Bids}
                            Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                            Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                            Set_MyTokenBalance = {Set_MyTokenBalance}
                            Set_MyTokenDetail={Set_MyTokenDetail}
                            Set_AllowedQuantity={Set_AllowedQuantity}
                            Set_YouWillPay = {Set_YouWillPay}
                            Set_YouWillPayFee = {Set_YouWillPayFee}
                            Set_YouWillGet = {Set_YouWillGet}
                         />
                          )
                        })
                      }
                      </>
                        ):(
<div className="tab-pane fade" id="liked" role="tabpanel" aria-labelledby="liked-tab">
      <div className="proposal_panel_overall">
        <div className="text-center py-5">
          <p className="not_found_text">No items found</p>
          <p className="not_found_text_sub">Explore the marketplace for your first NFT or subscribe for free drop notifications</p>
          <div className="mt-3">
            <Button className="create_btn">Browse Marketplace</Button>
          </div>
        </div>
      </div>
    </div>
                        )}
                   </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
                  <div className="proposal_panel_overall">
                  {
                        ActivityList.map((activitydata) => {
                          // console.log("@@@@@@@@@@",activitydata)
                          return (
                            <ActivityTab 
                              item={activitydata}
                              UserAccountAddr={UserAccountAddr}
                            />
                          )
                      })
                    }
                      {ActivityList && ActivityList.length == 0 &&
                    <div className="proposal_panel_overall">
                        <div className="text-center py-5">
                          <p className="not_found_text">No items found</p>
                          <p className="not_found_text_sub">Explore the marketplace for your first NFT or subscribe for free drop notifications</p>
                          <div className="mt-3">
                            <Button className="create_btn">Browse Marketplace</Button>
                          </div>
                        </div>
                      </div>
                   }
                  </div>
                </div>
                <div className="tab-pane fade" id="following" role="tabpanel" aria-labelledby="following-tab">
                  <div className="proposal_panel_overall">
                  {followingStatus && FollowingUserList && FollowingUserList.length > 0 &&
                      <div className="row">
                          
                          {
                            followingStatus && FollowingUserList.map((item, i) => {
                              console.log("UserAccountAddr:data",item,UserAccountAddr)
                              var name = (item && item.userdetail && item.userdetail.name && item.userdetail.name !="")?
                              item.userdetail.name:(item.userdetail && item.userdetail.curraddress)
                              ?item.userdetail.curraddress:"";

                              if (name.length > 15) {
                                name = name.substring(0, 18) + "...";
                              }

                              var followers = (item && item.userdetail && item.userdetail.followers)?
                              item.userdetail.followers:0;

                              var imageUrl = (item.image && item.image !="")?
                              config.Back_URL+"/images/noimage.png":config.Back_URL+"/images/noimage.png";

                            return (
                        <div className="col-12 col-md-6 col-xl-4 mb-4">
                          <div className="card my-0">
                            <div className="card-body">
                              <div className="media follow_media">
                              <a href={"/user/"+item.followaddress}>
                                <img src={imageUrl} alt="User" className="img-fluid mr-3" />
                              </a>
                                <div className="media-body flex_body">
                                  <div>
                                    <p className="mt-0 media_num mb-0">{item.numberOfFollower} followers</p>
                                    <p className="mt-0 media_text">{name}</p>
                                  </div>
                                  <div className="ml-2">
                                    {item.useraddress !=UserAccountAddr &&
                                      <div className="ml-2">
                                        <Button onClick={() => FollowerTab(item.followaddress,i,item.isFollow)} className="create_btn">{(item.isFollow=="yes")?"Unfollow":"Follow"}</Button>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        )
                            })
                        }
                      </div>}
                  {loadMoreBtn && FollowingUserList && FollowingUserList.length > 0 &&
                          <div className="text-center" id="spinBut">
                            <Button className="create_btn" onClick={()=>{loadFollowing()}} id="onmore" style={{ display: "flex" }}>Load More</Button>
                          </div>
                        }
                  {FollowingUserList && FollowingUserList.length == 0 &&
                    <div className="text-center py-5">
                      <p className="not_found_text">No items found</p>
                      <p className="not_found_text_sub">Explore the marketplace for your first NFT or subscribe for free drop notifications</p>
                      <div className="mt-3">
                        <Button className="create_btn">Browse Marketplace</Button>
                      </div>
                    </div>
                }

                  </div>
                </div>
                <div className="tab-pane fade" id="followers" role="tabpanel" aria-labelledby="followers-tab">
                  <div className="proposal_panel_overall">
                    {/* <div className="text-center py-5">
                  <p className="not_found_text">No items found</p>
                  <p className="not_found_text_sub">Explore the marketplace for your first NFT or subscribe for free drop notifications</p>
                  <div className="mt-3">
                  <Button className="create_btn">Browse Marketplace</Button>
                </div>             
                </div> */}

                    <div className="followers_overall py-5">
                    {followersStatus && FollowerUserList && FollowerUserList.length > 0 &&
                      <div className="row">
                          
                          {
                            followersStatus && FollowerUserList.map((item, i) => {
                              console.log("UserAccountAddr:data",item,UserAccountAddr)
                              var name = (item && item.userdetail && item.userdetail.name && item.userdetail.name !="")?
                              item.userdetail.name:(item.userdetail && halfAddrShow(item.userdetail.curraddress))
                              ?halfAddrShow(item.userdetail.curraddress):"";

                              if (name.length > 15) {
                                name = name.substring(0, 18) + "...";
                              }

                              var followers = (item && item.userdetail && item.userdetail.followers)?
                              item.userdetail.followers:0;

                              var imageUrl = (item.image && item.image !="")?
                              config.Back_URL+"/images/noimage.png":config.Back_URL+"/images/noimage.png";

                            return (
                        <div className="col-12 col-md-6 col-xl-4 mb-4">
                          <div className="card my-0">
                            <div className="card-body">
                              <div className="media follow_media new_flex">
                                <img src={imageUrl} alt="User" className="img-fluid followers" />
                                <div className="media-body flex_body">
                                  <div>
                                    <p className="mt-0 media_num mb-0">{item.numberOfFollower} followers</p>
                                    <p className="mt-0 media_text">{name}</p>
                                  </div>
                                  <div className="ml-3">
                                    {item.useraddress !=UserAccountAddr &&
                                      <div className="ml-2">
                                        <Button onClick={() => FollowerTab(item.useraddress,i,item.isFollow)} className="create_btn">{(item.isFollow=="yes")?"Unfollow":"Follow"}</Button>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        )
                            })
                        }
                      </div>}
                      {loadMoreBtn1 && FollowerUserList && FollowerUserList.length > 0 &&
                          <div className="text-center" id="spinBut">
                            <Button className="create_btn" onClick={()=>{loadFollowers()}} id="onmore" style={{ display: "flex" }}>Load More</Button>
                          </div>
                        }
                 {FollowerUserList && FollowerUserList.length == 0 &&
                  <div className="text-center py-5">
                      <p className="not_found_text">No items found</p>
                      <p className="not_found_text_sub">Explore the marketplace for your first NFT or subscribe for free drop notifications</p>
                      <div className="mt-3">
                        <Button className="create_btn">Browse Marketplace</Button>
                      </div>
                    </div>
                  }
                    </div>

                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />


      {/* edit_cover Modal */}
      <div class="modal fade primary_modal" id="edit_cover_modal" tabindex="-1" role="dialog" aria-labelledby="edit_cover_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="edit_cover_modalLabel_1">Update cover</h5>
              <h5 class="modal-title d-none" id="edit_cover_modalLabel_2">Follow Steps</h5>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">Upload new cover for your profile page. We recommended to upload images in 1140×260 resolution</p>
                <form className="text-center">
                  <div className="file_btn btn primary_btn">Choose image
                    <input className="inp_file" type="file" name="image"  onChange={selectFileChange}/>
                  </div>
                </form>
              </div>
              <div className="update_cover_div_2 d-none" id="update_cover_div_2">
                <div className="media approve_media">
                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Preferences</p>
                    <p className="mt-0 approve_desc">Updating cover</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Inprogress</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end edit_cover modal */}

      {/* Put Sale Modal */}
      <div class="modal fade primary_modal" id="put_sale_modal" tabindex="-1" role="dialog" aria-labelledby="put_sale_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content" id="hide"  >
            <div class="modal-header text-center">
              <h5 class="modal-title" id="put_sale_modalLabel_1">Put on Sale</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="update_cover_div_2" id="update_cover_div_2">
                <form>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <div className="input_group">
                        <input type="text" className="primary_inp form-control" id="price" name="price" placeholder="Enter price for one piece"/>
                        <select class="custom-select select_menu choose_price form-control">
                          <option selected>BNB</option>
                          <option>ETH</option>
                        </select>
                        </div>
                      <p class="form_note mb-0">Service fee <span className="text-dark">2.5%</span></p>
                      <p class="form_note mb-0">You will receive <span className="text-dark">0 ETH</span> $0.00</p>
                    </div>
                  </div>
                </form>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" data-toggle="modal" data-target="#follow_steps_modal">Next Step</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal">Cancel</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Put Sale modal */}

{/* new modal*/}
<div class="modal fade primary_modal profile_modal" id="profile_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="PurchaseStepCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="text-center">
                                    
                                      <a href={config.Front_URL+"/edit-profile"} className="primary_btn">
                                      
                                       Please fill the profile
                                       </a>
                                    
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
      {/* new modal*/}

      {/* Following Steps Modal */}
      <div class="modal fade primary_modal" id="follow_steps_modal" tabindex="-1" role="dialog" aria-labelledby="follow_steps_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content" id="hide"  >
            <div class="modal-header text-center">
              <h5 class="modal-title" id="follow_steps_modalLabel_1">Follow Steps</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="update_cover_div_2" id="update_cover_div_2">
                <div className="media approve_media">
                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Sign Sell Order</p>
                    <p className="mt-0 approve_desc">Sign sell order using your wallet</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Follow Wallet Instructions</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Following Steps modal */}
  {/* Choose Collection Modal */}
  <div class="modal fade primary_modal" id="report_page_modal" tabindex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="report_page_modalLabel">Report This Profile?</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form>
                <p className="font_16 font_600 line_20 mb-1">Tell us how this user violates the rules of the site</p>
                <div className="form-row">
                <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">E-Mail</label>
                    <input
                    type="email"
                    name="email"
                    id="email"
                    class="form-control"
                    placeholder="Enter the email address"
                    aria-label="email"
                    aria-describedby="basic-addon2"
                    onChange={inputChange}
                    autoComplete="off"
                    // value={tokenBidAmt}
                  />                   
            {ValidateError.email && <span className="text-danger">{ValidateError.email}</span>}
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Message</label>
                    <textarea onChange={inputChange} value={description} className="form-control primary_inp" rows="3" name="description" placeholder="Tell us some details"></textarea>
                    {ValidateError.description && <span className="text-danger">{ValidateError.description}</span>}
                  </div>
                </div>
                <div className="text-center mt-4">
                <Button disabled={reportBtn} className="create_btn" onClick={() => submitReport()}>Report</Button>
                    <Button className="cancel_btn ml-2" onClick={() => cancelReport()}>Cancel</Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
