import React, {useEffect,useState} from "react";
import { Link } from "react-router-dom";
import { Button, TextField } from '@material-ui/core';
import Countdown from "react-countdown";
import config from '../../lib/config';
import isEmpty from '../../lib/isEmpty';
import Loder from '../../assets/images/siteCollection.png'
import { TwitterIcon, FacebookIcon, FacebookShareButton, FacebookShareCount, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share'
const IPFS_IMGurl   = config.IPFS_IMG;
const moment        = require("moment");


export default function TokenItem(props) {
    async function showAllwithPro(data) {
    }
    async function buyToken(item) {
   
    }
    var {
        item,
        LikedTokenList,
        hitLike,
        UserAccountAddr,
        UserAccountBal,
        PutOnSale_Click,
        PurchaseNow_Click,
        Burn_Click,
        CancelOrder_Click,
        WalletConnected,
        ShareSocial_Click,
        SubmitReport_Click
    } = props;

    const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span></span>
        } else {
            return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
        }
    };
console.log("recent_item:",item)
var currDate = new Date();
var startdate = new Date(item.clocktime);
var enddate = new Date(item.endclocktime);
var auction = "false";
var finish = "";
var enddate1 = "";
var showlist = "true";
if (item.type==721&&item.PutOnSaleType=="TimedAuction") {
    auction = "true";
    var a = moment(item.clocktime);
    var b = moment(item.endclocktime);
    var c = moment();
    a.diff(b); // 86400000
    var diffInMs = a.diff(c);
    finish = b.diff(c);
    enddate1 = parseFloat(diffInMs);
  // if(currDate<startdate){
  //   var auctionTxt = "Start";
  // }else
   if (finish > 0) {
    showlist = "true";
    } else {
    var auctionTxt = "Ended";
    showlist = "false";
    }
}
    return (
        (item.tokenowners_current)?
<div className="recent_collection_single">
<div className="recent_collection_img">
  <p className="bid_price">
    {(item.tokenPrice>=0 && item.PutOnSaleType=="FixedPrice") ?
    <span>{item.tokenowners_current.tokenPrice} {config.currencySymbol}</span>
    :
    <span>{item.minimumBid} {config.tokenSymbol}</span>
}
</p>
 <Link to={"/info/"+item.tokenCounts}>
  {
    item.image.split('.').pop() == "mp4" ?
    <video src={`${IPFS_IMGurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid" controls/>
    :
    item.image.split('.').pop() == "mp3" ?
    <img src={require("../../assets/images/music.png")} style={{backgroundColor:"white"}} alt="" className="img-fluid" />
    :
    <img src={`${IPFS_IMGurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid " />
    }
         {item && item.PutOnSaleType == "TimedAuction"&& showlist == "true" &&
             <div className="countdown_panel">
            <div className="countdown_panel_div">
              <Countdown
                date={enddate}
                autoStart={true}
                onStart={() => startdate}
                renderer={renderer}
              ></Countdown>
            </div>
          </div>}
          {showlist=='false'&&auction=="true" &&
            <div className="countdown_panel">
            <div className="countdown_panel_div">
            {auctionTxt}
            </div>
                </div>
                }
</Link>
</div>
<div className="recent_collection_content">
  <div className="d-flex align-items-center justify-content-between">
    <p>
    {(item.PutOnSale == false || (item.PutOnSale == true && item.PutOnSaleType=='FixedPrice' && item.tokenowners_current.tokenPrice == 0)) && <span>Not for sale </span>}
                        {(item.PutOnSale == true && item.PutOnSaleType=='FixedPrice' && item.tokenowners_current.tokenPrice > 0) && <span>Place a Bid  </span>}
                        {(item.PutOnSale == true && item.PutOnSaleType=='TimedAuction') && <span>Minimum Bid </span>}
                        {(item.PutOnSale == true && item.PutOnSaleType=='UnLimitedAuction') && <span>Open for Bids </span>}
                        {
                        (
                          item.TabName!='' && (item.TabName=='onsale' || item.TabName=='created' || item.TabName=='owned')
                        )
                        ?
                          item.tokenowners_my_balance+' of '+item.tokenowners_my_quantity
                        :
                          (
                            item.TabName!=''
                          )
                          ?
                            item.tokenowners_all_balance+' of '+item.tokenQuantity
                          :
                            (
                              item.tokenowners_sale_balance > 0
                            )
                            ?
                            item.tokenowners_sale_balance+' of '+item.tokenQuantity
                            :
                            item.tokenowners_all_balance+' of '+item.tokenQuantity
                        }
    </p>
    <div className="masonry_likes" onClick={() => hitLike(item)} style={{cursor:'pointer'}}>
                    {
                    (LikedTokenList.findIndex(tokenCounts => (tokenCounts.tokenCounts === item.tokenCounts)) > -1) ?
                    (<i className="fas fa-heart mr-2 liked"></i>):
                    (<i className="far fa-heart mr-2"></i>)
                    }
                    <span class={item.tokenCounts+'-likecount'}>{item.likecount}</span>
                </div>
  </div>
  <h3>{item.tokenName}</h3>
  {/*<div className="text-center"><Button className="green_btn">Buy</Button></div>*/}
</div>
</div>
 :('')
    )
}