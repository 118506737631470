import React, {
  useEffect,
  forwardRef,
  useState,
  useImperativeHandle
} from 'react';
import {
  useHistory
} from "react-router-dom";
import { Button, TextField } from '@material-ui/core';

import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'

import BEP721 from '../../ABI/BEP721.json'
import BEP1155 from '../../ABI/BEP1155.json';
import BOUI from '../../ABI/BOUI.json';
import Select from "react-select";
import config from '../../lib/config';
import axios from 'axios';
import {
  AddLikeAction,
  GetLikeDataAction,
  TokenPriceChange_update_Action
} from '../../actions/v1/token';

import {
  getCurAddr,
  halfAddrShow
} from '../../actions/v1/user';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
const IPFS_IMGurl   = config.IPFS_IMG;

const exchangeAddress  = config.exchangeAddress;
const singleContract   = config.singleContract;
const multipleContract = config.multipleContract;
var web3              = new Web3(window.ethereum);
const BOUIContract = new web3.eth.Contract(BOUI,config.bouitokenAddr)
const BEP721Contract    = new web3.eth.Contract(BEP721, config.singleContract)
const BEP1155Contract   = new web3.eth.Contract(BEP1155, config.multipleContract)

const price = [
{ value: config.currencySymbol, label: config.currencySymbol },
{ value: config.owntokenSymbol, label: config.owntokenSymbol },
{ value: config.usdtokenSymbol, label: config.usdtokenSymbol }
]
export const PutOnSaleRef = forwardRef((props, ref) => {

  const history = useHistory();
  const [BuyerName, Set_BuyerName]                 = useState('');
  const [blns, Set_blns]                           = useState('');
  const [dethBln, Set_dethBln]                     = useState('');
  const [bidProfile1, Set_bidProfile1]             = useState([]);
  const [FormSubmitLoading, Set_FormSubmitLoading] = useState('');
  const [ValidateError, Set_ValidateError]         = useState({});
  const [YouWillGet, Set_YouWillGet]               = useState(0);
  const [intPrice, Set_intPrice]                   = useState(0);
  const [TokenPrice, Set_TokenPrice]               = useState(0);
  const [TokenPrice_Initial, Set_TokenPrice_Initial] = useState(0);
  const [servicefee, setservicefee]                = useState(0);
  const [TokenBalance, Set_TokenBalance]           = useState(0);
  const [showFees, Set_showFees]                   = useState(config.fee/1e18);
  const [biddingtoken, set_biddingtoken]           = useState("BNB")
  const [biddingfee, set_biddingfee]               = useState(config.fee/1e18);
  const [PurchaseCurrency, setPurchaseCurrency]    = useState("BOUI");
  const [usdval,setusd]                              = useState(0);

  const bidprice = [
    { value: config.tokenSymbol, label: config.tokenSymbol },
    { value: config.owntokenSymbol, label: config.owntokenSymbol },
    { value: config.usdtokenSymbol, label: config.usdtokenSymbol }
  ]
 
  const inputChange = async(e) => {
      if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
        var value = e.target.value;
        switch(e.target.name) {
          case 'TokenPrice':
              Set_TokenPrice(value);
              if(value != '' && isNaN(value) == false && value > 0) {
                  var weii = value * config.decimalvalues;
                    await axios.get("https://min-api.cryptocompare.com/data/price?fsym="+biddingtoken+"&tsyms=USD")
                        .then(async (response2) => {
                        console.log(response2.data.USD,"===================3")
                        setusd(response2.data.USD)
                    })
                  if(biddingtoken==config.owntokenSymbol){
                      var web3 = new Web3(window.ethereum);
                      if(item.type==721){
                          var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                          var fee = await CoursetroContract.methods.getBouiFee().call();
                        }else{
                          var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                          var fee = await CoursetroContract.methods.getBouiFee().call();
                        }
                      var per = (weii * fee) / 1e20;
                      console.log("!!!@@@@@@:",biddingtoken,value,weii)
                  Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );

                  }else if (biddingtoken==config.tokenSymbol){
                      var web3 = new Web3(window.ethereum);
                      if(item.type==721){
                          var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                          var fee = await CoursetroContract.methods.getServiceFee().call();
                        }else{
                          var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                          var fee = await CoursetroContract.methods.getServiceFee().call();
                        }
                      var per = (weii * fee) / 1e20; 
                      console.log("!!!@@@@@@:",biddingtoken,value,weii)
                  Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
                  }else if (biddingtoken==config.usdtokenSymbol){
                    var web3 = new Web3(window.ethereum);
                    if(item.type==721){
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                        var fee = await CoursetroContract.methods.getBUSDFee().call();
                      }else{
                        var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                        var fee = await CoursetroContract.methods.getBUSDFee().call();
                      }
                    var per = (weii * fee) / 1e20; 
                    console.log("!!!@@@@@@:",biddingtoken,value,weii)
                Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
                }
                  else{
                      var web3 = new Web3(window.ethereum);
                      if(item.type==721){
                          var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                            if(biddingtoken==config.currencySymbol){
                                var fee = await CoursetroContract.methods.getServiceFee().call();
                              } else if(biddingtoken==config.owntokenSymbol){
                                var fee = await CoursetroContract.methods.getBouiFee().call();
                              }else if(biddingtoken==config.usdtokenSymbol){
                                var fee = await CoursetroContract.methods.getBUSDFee().call();
                              }
                              else{
                                var fee = await CoursetroContract.methods.getServiceFee().call();
                              }
                              var per = (weii * fee) / 1e20;
                              console.log("!!!@@@@@@:",biddingtoken,value,weii)
                          Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
      
                        }else{
                          var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                            if(biddingtoken==config.currencySymbol){
                                var fee = await CoursetroContract.methods.getServiceFee().call();
                              } else if(biddingtoken==config.owntokenSymbol){
                                var fee = await CoursetroContract.methods.getBouiFee().call();
                              }else if(biddingtoken==config.usdtokenSymbol){
                                var fee = await CoursetroContract.methods.getBUSDFee().call();
                              }
                              else{
                                var fee = await CoursetroContract.methods.getServiceFee().call();
                              }
                              var per = (weii * fee) / 1e20;
                      console.log("!!!@@@@@@:",biddingtoken,value,weii)
                  Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );

                        }
                      
                  }
              }
              ItemValidation({TokenPrice:value});
            break;
          default:
            // code block
        }
      }
  }
  const SelectBidcurrency = (e) => {
    console.log("SelectBidcurrency:",e)
      var filter=e.value;
      set_biddingtoken(filter)
      getTokenval(filter)
    }
    async function getTokenval(filter){
      var web3              = new Web3(window.ethereum);
      if(filter==config.currencySymbol){
        await axios.get("https://min-api.cryptocompare.com/data/price?fsym="+filter+"&tsyms=USD")
        .then(async (response2) => {
            console.log(response2.data.USD,"===================3")
            setusd(response2.data.USD)
        })
          if(item.type==721){
              var BEP721Contract = new web3.eth.Contract(BEP721, config.singleContract);
              var fee = await BEP721Contract.methods.getServiceFee().call();    
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue)
              var weii=TokenPrice*config.decimalvalues;
              var per = (weii * fee) / 1e20;
            Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
          }else{
              var BEP1155Contract = new web3.eth.Contract(BEP1155, config.multipleContract);
              var fee = await BEP1155Contract.methods.getServiceFee().call();
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
              var weii=TokenPrice*config.decimalvalues;
              var per = (weii * fee) / 1e20;
            Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );

          }
      }else if(filter==config.usdtokenSymbol){
        if(item.type==721){
            var BEP721Contract = new web3.eth.Contract(BEP721, config.singleContract);
            var fee = await BEP721Contract.methods.getBUSDFee().call();    
            var feeValue = fee/config.decimalvalues;
            setservicefee(feeValue)
            var weii=TokenPrice*config.decimalvalues;
            var per = (weii * fee) / 1e20;
          Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );
        }else{
            var BEP1155Contract = new web3.eth.Contract(BEP1155, config.multipleContract);
            var fee = await BEP1155Contract.methods.getBUSDFee().call();
            var feeValue = fee/config.decimalvalues;
            setservicefee(feeValue);
            var weii=TokenPrice*config.decimalvalues;
            var per = (weii * fee) / 1e20;
          Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );

        }
    }
      else{
          if(item.type==721){
              var BEP721Contract = new web3.eth.Contract(BEP721, config.singleContract);
              if(filter==config.currencySymbol){
                  var fee = await BEP721Contract.methods.getServiceFee().call();
                } else if(filter==config.owntokenSymbol){
                  var fee = await BEP721Contract.methods.getBouiFee().call();
                }else if(filter==config.usdtokenSymbol){
                  var fee = await BEP721Contract.methods.getBUSDFee().call();
                }
                else{
                  var fee = await BEP721Contract.methods.getServiceFee().call();
                }
                var weii=TokenPrice*config.decimalvalues;
                var feeValue = fee/config.decimalvalues;
                setservicefee(feeValue)
                var per = (weii * fee) / 1e20;
            Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );

          }else{
              var BEP1155Contract = new web3.eth.Contract(BEP1155, config.multipleContract);
              if(filter==config.currencySymbol){
                  var fee = await BEP1155Contract.methods.getServiceFee().call();
                } else if(filter==config.owntokenSymbol){
                  var fee = await BEP1155Contract.methods.getBouiFee().call();
                }else if(filter==config.usdtokenSymbol){
                  var fee = await BEP1155Contract.methods.getBUSDFee().call();
                }
                else{
                  var fee = await BEP1155Contract.methods.getServiceFee().call();
                }
                var weii=TokenPrice*config.decimalvalues;
                var feeValue = fee/config.decimalvalues;
                setservicefee(feeValue)
                var per = (weii * fee) / 1e20;
              Set_YouWillGet( parseFloat((weii - per) / config.decimalvalues).toFixed(config.toFixed) );

          }
          var BouiContract   = new web3.eth.Contract(BOUI, config.bouitokenAddr);
          var currAddr = window.web3.eth.defaultAccount;
          var decimal        = await BouiContract.methods.decimals().call();
          var tokenBal       = await BouiContract.methods.balanceOf(currAddr).call();
          var tokenBalance      = tokenBal / config.decimalvalues;
          Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
      }
    }

    const ItemValidation = async (data={}) => {
      var ValidateError = {};

      var Chk_TokenPrice = (typeof data.TokenPrice!='undefined')?data.TokenPrice:TokenPrice;
  
      if(Chk_TokenPrice == '') {
          ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
      }
      else if(Chk_TokenPrice == 0) {
          ValidateError.TokenPrice = '"Token Price" must be greater than 0';;
      } 
      else if(isNaN(Chk_TokenPrice) == true) {
        ValidateError.TokenPrice = '"Token Price" must be a number';
      }
      else if(Math.sign( TokenPrice)<0) {
          ValidateError.TokenPrice = 'Token Price must be a possitive number';
        }
      // else if(TokenPrice_Initial > 0 && Chk_TokenPrice >= TokenPrice_Initial) {
      //   ValidateError.TokenPrice = '"Token Price" must be less than '+TokenPrice_Initial;
      // }
      else{
          delete ValidateError.TokenPrice;
      }
      Set_ValidateError(ValidateError);
      return ValidateError;
  }

  async function getUsd(){
    try{
   await axios.get("https://min-api.cryptocompare.com/data/price?fsym="+biddingtoken+"&tsyms=USD")
         .then(async (response2) => {
          setusd(response2.data.USD)
     })
    }catch(err){}
 }
  async function FormSubmit(){
    console.log("price value ---->1")
      var connectwallet=localStorage.getItem("tfniuobnft");
      if(!connectwallet){
        window.location.href="/connect";
        return false;
      }
      var errors = await ItemValidation();
      var errorsSize = Object.keys(errors).length;
      if(errorsSize != 0) {
          toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
          return false;
      }
      console.log("price value ---->2")
      if(window.ethereum) {
          var web3 = new Web3(window.ethereum)
          if(
              web3
              && web3.eth
          ) {
              if(item.type==721){
                console.log(web3.utils.toWei(TokenPrice, 'ether') ,"price value---->3")
                let tprice = web3.utils.toWei(TokenPrice, 'ether');
                  var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
                      Set_FormSubmitLoading('processing');
                      CoursetroContract.methods
                      .orderPlace(
                          props.item.tokenCounts,
                          (tprice).toString()
                      )
                      .send({ from: props.Accounts })
                      .then(async (result) => {
                          Set_FormSubmitLoading('done');
                          console.log('result : ', result);
                          var postData = {
                              tokenOwner: UserAccountAddr,
                              tokenCounts: props.item.tokenCounts,
                              tokenPrice: TokenPrice,
                              blockHash: result.blockHash,
                              transactionHash: result.transactionHash,
                              BidToken: biddingtoken,
                              Usdval:(usdval*TokenPrice)
                          }
                          var Resp = await TokenPriceChange_update_Action(postData)
                          if (Resp.data && Resp.data.RetType && Resp.data.RetType=='success') {
                              toast.success("Collectible price changed successfully", toasterOption)
                              window.$('#PutOnSale_modal').modal('hide');
                             setTimeout(() => { history.push('/'); }, 2000);
                          }
                      })
                      .catch((error) => {
                          Set_FormSubmitLoading('error');
                          console.log('error : ', error);
                          toast.error('Order not placed', toasterOption)
                      })
              }else{
                let tprice =web3.utils.toWei(TokenPrice, 'ether')
                  var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
                  Set_FormSubmitLoading('processing');
                  CoursetroContract.methods
                  .orderPlace(
                      props.item.tokenCounts,
                      (tprice * config.decimalvalues).toString()
                  )
                  .send({ from: props.Accounts })
                  .then(async (result) => {
                      Set_FormSubmitLoading('done');
                      console.log('result : ', result);
                      var postData = {
                          tokenOwner: UserAccountAddr,
                          tokenCounts: props.item.tokenCounts,
                          tokenPrice: TokenPrice,
                          blockHash: result.blockHash,
                          transactionHash: result.transactionHash,
                          BidToken: biddingtoken,
                          Usdval:(usdval*TokenPrice)
                      }
                      var Resp = await TokenPriceChange_update_Action(postData)
                      if (Resp.data && Resp.data.RetType && Resp.data.RetType=='success') {
                          toast.success("Collectible price changed successfully", toasterOption)
                          window.$('#PutOnSale_modal').modal('hide');
                          setTimeout(() => { history.push('/'); }, 2000);
                      }
                  })
                  .catch((error) => {
                      Set_FormSubmitLoading('error');
                      console.log('error : ', error);
                      toast.error('Order not placed', toasterOption)
                  })
              }
          }
      }
  }

  var {
      item,
      UserAccountAddr,
      UserAccountBal,
  } = props;
  useEffect(() => {
      Set_ValidateError({});
      getServicefees();
      getUsd();
  }, []);

  async function getServicefees(){
      try{
        if (window.ethereum) {
          var web3 = new Web3(window.ethereum);
          if(item.type==721){
            var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
            if(PurchaseCurrency==config.currencySymbol){
              var fee = await CoursetroContract.methods.getServiceFee().call();
            } else if(PurchaseCurrency==config.owntokenSymbol){
              var fee = await CoursetroContract.methods.getBouiFee().call();
            }else if(PurchaseCurrency==config.usdtokenSymbol){
              var fee = await CoursetroContract.methods.getBUSDFee().call();
            }
            else{
              var fee = await CoursetroContract.methods.getServiceFee().call();
            }
          }else{
            var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
            if(PurchaseCurrency==config.currencySymbol){
              var fee = await CoursetroContract.methods.getServiceFee().call();
            } else if(PurchaseCurrency==config.owntokenSymbol){
              var fee = await CoursetroContract.methods.getBouiFee().call();
            }else if(PurchaseCurrency==config.usdtokenSymbol){
              var fee = await CoursetroContract.methods.getBUSDFee().call();
            }
            else{
              var fee = await CoursetroContract.methods.getServiceFee().call();
            }
          }
          var feeValue = fee/config.decimalvalues;
          setservicefee(feeValue);
        }
      }catch(err){
      }
    }
    const onKeyUp = (e) => {
      var charCode = e.keyCode;
      if((charCode>47 && charCode <58) || (charCode>95 && charCode <106)){
        var ValidateError = {};
        Set_ValidateError(ValidateError);
      }else{
        var ValidateError = {};
        ValidateError.TokenPrice = '"TokenPrice" must be a number';
        Set_TokenPrice("");
        Set_ValidateError(ValidateError);
      }
    }

  useImperativeHandle( 
      ref,
      () => ({
        
          async PutOnSale_Click(item,ownerdetail) {
           
              props.Set_HitItem(item);
              console.log("ownerdetail",ownerdetail)
              if(ownerdetail && ownerdetail.biddingtoken) {
                  setPurchaseCurrency(ownerdetail.biddingtoken)
                  set_biddingtoken(ownerdetail.biddingtoken)
                  console.log('krishna',ownerdetail.biddingtoken)
                }
                if(ownerdetail.type==721){
                  var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
                  if(ownerdetail && ownerdetail.biddingtoken==config.currencySymbol){
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  } else if(ownerdetail && ownerdetail.biddingtoken==config.owntokenSymbol){
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  }else if(ownerdetail && ownerdetail.biddingtoken==config.usdtokenSymbol){
                    var fee = await CoursetroContract.methods.getBUSDFee().call();
                  }
                  else{
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  }
                  var feeValue = fee/config.decimalvalues;
                  setservicefee(feeValue);
                  Set_TokenPrice_Initial(ownerdetail.tokenPrice);
                  Set_ValidateError({});
                  window.$('#PutOnSale_modal').modal('show');
                }else{
                  var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
                  if(ownerdetail && ownerdetail.biddingtoken==config.currencySymbol){
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  } else if(ownerdetail && ownerdetail.biddingtoken==config.owntokenSymbol){
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  }else if(ownerdetail && ownerdetail.biddingtoken==config.usdtokenSymbol){
                    var fee = await CoursetroContract.methods.getBUSDFee().call();
                  }
                  else{
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  }
                  var feeValue = fee/config.decimalvalues;
                  setservicefee(feeValue);
                  Set_TokenPrice_Initial(ownerdetail.tokenPrice);
                  Set_ValidateError({});
                  window.$('#PutOnSale_modal').modal('show');
                }
             
          }
      }),
  )
  return (
      <div class="modal fade primary_modal" id="PutOnSale_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
              <div class="modal-content">
                  <div class="modal-header text-center">
                      <h5 class="modal-title" id="accept_modalLabel">{TokenPrice_Initial==0 || TokenPrice_Initial==null ?'Put On Sale':'Change Price'}</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close9" onClick={()=>window.location.reload(false)}>
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body px-0">
                      <div className="img_accept text-center">
                      {
                item&&item.multiimage&&item.multiimage[0]&&item.multiimage[0].name.split('.').pop() == "mp4" ?
                <video src={`${IPFS_IMGurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid explore_img" controls />
                :
                item&&item.multiimage&&item.multiimage[0]&&item.multiimage[0].name.split('.').pop() == "mp3" ?
                <img src={require("../../assets/images/music.png")} alt="Collections" className="img-fluid explore_img" style={{backgroundColor:"white"}}/>
                :
                <img src={`${IPFS_IMGurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid explore_img" />
                }
                      </div>
                      <p className="text-center accept_desc" >
                          <span className="buy_desc_sm" styel={{ fontSize: 12 }}>You are about to Place Order for</span>
                          <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{item.tokenName}</span>
                          <span className="buy_desc_sm pl-2" styel={{ fontSize: 12 }} >for</span><br />
                          <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>
                              {
                              item.userprofile && item.userprofile.name ?
                              <span >{item.userprofile.name}</span>
                              :
                              item&&item.tokenowners_current&&item.tokenowners_current.tokenOwner && <span >{halfAddrShow(item&&item.tokenowners_current&&item.tokenowners_current.tokenOwner)}</span>
                              }
                          </span>
                      </p>
                      <form className="bid_form" action="#">
                          <div className="bor_bot_modal mb-3 px-4 ">
                              <div className="mx-0 pb-3"></div>
                              <label for="qty">Enter Price</label>
                              <div class="mb-3 input_grp_style_1 input-group">
                                  <input
                                      type="number"
                                      className="form-control primary_inp text-center"
                                      name="TokenPrice"
                                      id="TokenPrice"
                                      onChange={inputChange}
                                      placeholder="e.g. 10"
                                      autoComplete="off"
                                      onKeyUp={onKeyUp}
                                      min="0"
                                  />
                                  <div class="input-group-append">
                    <Select
                          className="form-control primary_inp custom-select"
                          id="TokenCategory1"
                          name="TokenCategory"
                          onChange={SelectBidcurrency}
                          defaultValue={{ label: biddingtoken, value:biddingtoken}}
                          value={{label: biddingtoken, value:biddingtoken}}
                          options={price} />
                        </div>
                                  {/* <div className="input-group-append">

                                  <select value={biddingtoken} className="form-control primary_inp custom-select" id="TokenCategory1" name="TokenCategory" onChange={SelectBidcurrency}>
                                  {price.map((option) => (
                                      <option value={option.value}>
                                          {option.label}
                                      </option>
                                  ))}
                                  </select>
                                </div> */}
                              </div>
                              {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                          </div>
                      </form>
                      <div className="row mx-0 pb-3">
                          <div className="col-12 col-sm-6 px-4">
                              <p className="buy_desc_sm">Service fee</p>
                          </div>
                          <div className="col-12 col-sm-6 px-4 text-sm-right">
                              <p className="buy_desc_sm_bold">{servicefee}% <span>{biddingtoken}</span></p>
                          </div>
                      </div>
                      <div className="row mx-0 pb-3">
                          <div className="col-12 col-sm-6 px-4">
                              <p className="buy_desc_sm">You will get</p>
                          </div>
                          <div className="col-12 col-sm-6 px-4 text-sm-right">
                              <p className="buy_desc_sm_bold">{YouWillGet} <span>{biddingtoken}</span></p>
                          </div>
                      </div>
                      <form className="px-4">
                          <div className="text-center">
                              <Button
                                  type="button"
                                  className="create_btn btn-block"
                                  onClick={() => FormSubmit()}
                                  disabled={(FormSubmitLoading=='processing')}
                              >
                                  {FormSubmitLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                  Sign sell order
                              </Button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  )
})

