import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Link } from "react-router-dom";
import { Button, TextField } from '@material-ui/core';
import Countdown from "react-countdown";

import config from '../../lib/config';
import isEmpty from '../../lib/isEmpty';
import {
  getCurAddr
} from '../../actions/v1/user';

import {
  TokenCounts_Get_Detail_Action,
  BidApply_ApproveAction,
  acceptBId_Action,
  getsettinglist
} from '../../actions/v1/token';
import axios from "axios";
import Loder from '../../assets/images/siteCollection.png'
import { TwitterIcon, FacebookIcon, FacebookShareButton, FacebookShareCount, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share'
import { func } from "prop-types";
const moment = require("moment");
const IPFS_IMGurl = config.IPFS_IMG;
const Img_URL = config.Img_URL;

// useEffect(() => {
//   getUSD();
// }, [])
// const getUSD=()=>{
// console.log('how are you')
// }
const TokenItem = forwardRef((props, ref) => {
  console.log(props.UserAccountAddr,"itemuser")
  async function showAllwithPro(data) {
    var curAddr = await getCurAddr();
    var payload = {
      curAddr: curAddr,
      tokenCounts: data.tokenCounts
    };
    // console.log("items:",payload)
    TokenCounts_Get_Detail_Call(payload);
  }
  
  const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = await getCurAddr();
    var Resp = await TokenCounts_Get_Detail_Action(payload);
    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
      var TokenResp = Resp.data.Detail.Resp;
      if (
        TokenResp
        && TokenResp.Token
        && TokenResp.Token[0]
        && TokenResp.Token[0].tokenowners_current
      ) {
        for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
          const element = TokenResp.Token[0].tokenowners_current[i];
          if (element.balance > 0 && element.tokenPrice > 0 && element.tokenOwner != curAddr) {
            Set_BuyOwnerDetailFirst(element);
            break;
          }
        }
      }
      Set_tokenCounts_Detail(TokenResp);
      if (TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }
      let ageSum = 0;
      var tokenowners_all = TokenResp.Token[0].tokenowners_all;
       console.log("balnce:",tokenowners_all)
      if (tokenowners_all && tokenowners_all.length > 0) {
        for (let i = 0; i < tokenowners_all.length; i++) {
          if(tokenowners_all[i].balance>0&&tokenowners_all[i].burnorboui!="burn"){
            ageSum += tokenowners_all[i].balance;
          }
        }
      }
      var IndexVal = -1;
      if (TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex(val => val.tokenOwner.toString() == curAddr.toString());
      }
      var newMyTokenBalance = 0;
      if (IndexVal > -1) {
        newMyTokenBalance = tokenowners_all[IndexVal].balance
        Set_MyTokenBalance(newMyTokenBalance);
        Set_MyTokenDetail(tokenowners_all[IndexVal]);
      }
      else {
        newMyTokenBalance = 0;
        Set_MyTokenBalance(0);
        Set_MyTokenDetail({});
      }
console.log("own_bal:",ageSum,newMyTokenBalance)
      if (ageSum) {
        Set_AllowedQuantity(ageSum - newMyTokenBalance);
      }
      else {
        Set_AllowedQuantity(0);
      }
      if (TokenResp.Token && TokenResp.Token[0]) {
        // console.log("items_list:",TokenResp.Token[0])
        Set_item(TokenResp.Token[0]);
      }
    }
  }

  async function buyToken() {
    console.log('buyToken')
  }

  var {
    item,
    LikedTokenList,
    hitLike,
    UserAccountAddr,
    UserAccountBal,
    PutOnSale_Click,
    PlaceABid_Click,
    PurchaseNow_Click,
    Burn_Click,
    CancelOrder_Click,
    WalletConnected,
    ShareSocial_Click,
    SubmitReport_Click,
    Set_item,
    Set_Bids,
    Set_BuyOwnerDetailFirst,
    Set_tokenCounts_Detail,
    Set_MyTokenBalance,
    Set_MyTokenDetail,
    Set_AllowedQuantity,
    Bids,
    Set_Tabname,
    Tabname,
    Set_CurTabName,
    CurTabName,
    bouiusdvalue,
    bnbusdvalue
  } = props;

  try {
    var currDate = new Date();
    var startdate = new Date(item.clocktime);
    var enddate = new Date(item.endclocktime);
    var auction = "false";
    var finish = "";
    var enddate1 = "";
    var showlist = "true";
    var display_item = 0;
    if (item.type == 721 && item.PutOnSaleType == "TimedAuction") {
      auction = "true";
      var a = moment(item.clocktime);
      var b = moment(item.endclocktime);
      var c = moment();
      a.diff(b); // 86400000
      var diffInMs = a.diff(c);
      display_item = a.diff(c)
      finish = b.diff(c);
      enddate1 = parseFloat(diffInMs);
      if (finish > 0) {
        showlist = "true";
      } else {
        var auctionTxt = "Ended";
        showlist = "false";
      }
    }
  } catch (err) { }
  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span></span>
    } else {
      return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
    }
  };
  const renderer1 = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span></span>
    } else {
      return <span>{days}d  {hours}h {minutes}m {seconds}s to start</span>;
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      async initialCall() {
      
      },
    }),
  )

  return (

    <div className={Tabname == "My-Items" && CurTabName != "onsale"
      && item && item.tokenowners_current &&
      item.tokenowners_current.burnorboui == "burn" ?
       "burned item" :"item item_multiple"
  }
      // "burened_img img" : "item item_multiple"}
    >
      <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids p-3" onClick={() => showAllwithPro(item)}>
        <div className="d-flex creators_details">
          {/* collection */}
          {/* {!isEmpty(item.usercontract) && item.usercontract.conAddr ?
            <a href="#">
              <img src={`${config.Back_URL}/userContract/${item.usercontract.conAddr}/${item.usercontract.imageUser}`}
                alt="Collection" className="img-fluid imgRound imgRound" />
              <img src={require("../../assets/images/verifiedTick.png")} alt="User" className="img-fluid imgVerify" />
            </a>
            :
            <a href="#">
              <img src={Loder} alt="Collection" className="img-fluid" />
            </a>
          } */}
          {/* owner */}
          {
            item
              && item.type == 1155
              && item.tokenowners_current_count
              && item.tokenowners_current_count.count
              && item.tokenowners_current_count.count > 1
              ?
              ('')
              :
              (!isEmpty(item.tokenOwnerInfo) && item.tokenOwnerInfo.curraddress && item.tokenOwnerInfo.image)
                ?
                <a href={config.Front_URL + "/user/" + item.tokenOwnerInfo.curraddress} title={`Owner : ${(item&&item.tokenOwnerInfo&&item.tokenOwnerInfo.curraddress)}`}>
                  <img src={config.Back_URL + "/profile/" + item.tokenOwnerInfo.image} alt="User" className="img-fluid imgRound" />
                  <img src={require("../../assets/images/verifiedTick.png")} alt="User"
                    className="img-fluid imgVerify" />
                </a>
                :
                <a href={`${config.Front_URL}/user/${item && item.tokenowners_current && item.tokenowners_current.tokenOwner}`} title={`Owner : ${item && item.tokenowners_current && item.tokenowners_current.tokenOwner}`}>
                  <img src={`${config.Back_URL}/images/noimage.png`} alt="User" className="img-fluid imgRound" />
                  <img src={require("../../assets/images/verifiedTick.png")} alt="User"
                    className="img-fluid imgVerify" />
                </a>
          }
          {/* creator */}
          {
            (item.tokenCreatorInfo && item.tokenCreatorInfo.curraddress && item.tokenCreatorInfo.image) ?
              <a href={`${config.Front_URL}/user/${item.tokenCreatorInfo.curraddress}`} data-toggle="tooltip" data-placement="top" title={`Creator : ${(item && item.tokenCreator)}`}>
                <img src={`${config.Back_URL}/profile/${item.tokenCreatorInfo.image}`} alt="User" className="img-fluid imgRound" />
              </a>
              :
              <a href={`${config.Front_URL}/user/${item && item.tokenCreator}`} data-toggle="tooltip" data-placement="top" title={`Creator : ${item.tokenCreator}`}>
                <img src={`${config.Back_URL}/images/noimage.png`} alt="User" className="img-fluid imgRound" />
              </a>
          }
        </div>
        {Tabname == "My-Items" && CurTabName != "onsale" && item && item.tokenowners_current
          && item.tokenowners_current.burnorboui == "burn" ?
          "burned"
          :
          <div class="dropdown" >
            
            <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" id={"dropdownMenuButton" + item.tokenCounts} aria-expanded="false"><i class="bi bi-three-dots"></i></span>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {(WalletConnected == true && item.PutOnSaleType == 'FixedPrice' && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.balance > 0 && item.tokenowners_current.tokenOwner == UserAccountAddr) &&
                <>
                  {item && item.tokenowners_current && item.tokenowners_current.tokenPrice > parseFloat(0) ?
                    <a class="dropdown-item" href="javascript:void(0)" onClick={() => PutOnSale_Click(item, item.tokenowners_current)}>
                      Change Price
                    </a>
                    :
                    <>
                      {item && item.tokenowners_current.burnorboui == "boui" ?
                        <a class="dropdown-item" href="javascript:void(0)" onClick={() => PutOnSale_Click(item, item.tokenowners_current)}>
                          Put on Sale
                        </a>
                        :
                        ""
                      }
                    </>
                  }
                </>
              }{console.log("metamask==>",UserAccountAddr,"owner=>",item.tokenowners_current.tokenOwner)}
              {(WalletConnected == true && (item.PutOnSaleType == 'TimedAuction' || item.PutOnSaleType == 'UnLimitedAuction') && item.tokenowners_current && item.tokenowners_current.tokenOwner &&
                item.tokenowners_current.balance > 0 && item.tokenowners_current.tokenOwner == UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => PutOnSale_Click(item, item.tokenowners_current)}>
                  {item && item.tokenowners_current && item.tokenowners_current.tokenPrice > parseFloat(0) ? 'Change price' : 'Put on sale'}
                </a>

              }
              {(WalletConnected == true && item.PutOnSale == false && item.tokenowners_current && item.tokenowners_current.tokenOwner &&
                item.tokenowners_current.balance > 0 && item.tokenowners_current.tokenOwner == UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => PutOnSale_Click(item, item.tokenowners_current)} >Put on sale</a>

              }


              {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner == UserAccountAddr)
               && item.tokenowners_current.tokenPrice > 0 &&item.tokenowners_current.balance > 0 &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => CancelOrder_Click(item, item.tokenowners_current)}>Cancel Order</a>
              }
              {(WalletConnected == true && item.tokenowners_current &&
                (item.PutOnSaleType == 'FixedPrice' || item.PutOnSaleType == 'TimedAuction' || item.PutOnSaleType == 'UnLimitedAuction')
                && item.tokenowners_current.tokenOwner && item.tokenowners_current.balance > 0 && item.tokenowners_current.tokenOwner == UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => Burn_Click(item, item.tokenowners_current)}>Burn </a>
              }
              {(WalletConnected == true && item.tokenowners_current && item.PutOnSaleType == 'FixedPrice' && item && item.tokenowners_current.tokenPrice > parseFloat(0) && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => PurchaseNow_Click(item, item.tokenowners_current)} >Purchase Now</a>
              }
              {(WalletConnected == true && item.tokenowners_current && (item.PutOnSale == false || item.PutOnSaleType == 'FixedPrice' || (item.PutOnSaleType == 'TimedAuction' && showlist == "true" && display_item <= 0) || item.PutOnSaleType == 'UnLimitedAuction') && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                Bids
                && Bids.myBid
                && !Bids.myBid.status
                ?
                <div className="menu_itm dropdown-item">
                  <span onClick={() => PlaceABid_Click(item, item.tokenowners_current)}>New Offer</span>
                </div>
                :
                Bids
                  && Bids.myBid
                  && Bids.myBid.status
                  && Bids.myBid.status == 'pending'&&item.tokenowners_current.tokenOwner != UserAccountAddr ?
                  <div className="menu_itm dropdown-item">
                    <span onClick={() => PlaceABid_Click(item, item.tokenowners_current)}>Edit Offer </span>
                  </div>
                  :
                  <div></div>
              }
              {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => SubmitReport_Click(item)}>Report </a>
              }
              {(item.tokenowners_current && item.tokenowners_current.tokenOwner) &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => ShareSocial_Click(item)}>Share </a>
              }
              {/* {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner == UserAccountAddr) &&
                <a class="dropdown-item" href="javascript:void(0)" onClick={() => ShareSocial_Click(item)}>Share </a>
              } */}
            </div>
          </div>

        }

      </div>
      <div className="nft_img_panel">

        {/* <a href={(Tabname == "My-Items" && CurTabName != "onsale"
          && item && item.tokenowners_current &&
          item.tokenowners_current.burnorboui == "burn" ?
          "javascript:void(0)" : config.Front_URL + "/info/" + item.tokenCounts)}> */}

         { (Tabname == "My-Items" && item && item.tokenowners_current &&
          item.tokenowners_current.burnorboui == "burn")?

           <a href="javascript:void(0)">
          {
            item.multiimage && item.multiimage[0] && item.multiimage[0].name.split('.').pop() == "mp4" ?
              <video preload="metadata" src={`${Img_URL}/${item.multiimage[0].name}`} type="video/mp4" alt="Collections" className="img-fluid explore_img" controls />
              :
              item.multiimage && item.multiimage[0] && item.multiimage[0].name.split('.').pop() == "mp3" ?
                <img src={require("../../assets/images/music.png")} alt="" className="img-fluid explore_img" style={{ backgroundColor: "white" }} />
                :
                <img loading="lazy" src={`${IPFS_IMGurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid explore_img" />
          }

       </a>
       :
        (Tabname == "My-Items" && item && item.tokenowners_current &&
       item.tokenowners_current.burnorboui != ""&&item.tokenowners_current.tokenOwner == UserAccountAddr&&item.tokenowners_current.balance==0)?

        <a href="javascript:void(0)">
       {
         item.multiimage && item.multiimage[0] && item.multiimage[0].name.split('.').pop() == "mp4" ?
           <video preload="metadata" src={`${Img_URL}/${item.multiimage[0].name}`} type="video/mp4" alt="Collections" className="img-fluid explore_img" controls />
           :
           item.multiimage && item.multiimage[0] && item.multiimage[0].name.split('.').pop() == "mp3" ?
             <img src={require("../../assets/images/music.png")} alt="" className="img-fluid explore_img" style={{ backgroundColor: "white" }} />
             :
             <img loading="lazy" src={`${Img_URL}/${item.multiimage[0].name}`} alt="Collections" className="img-fluid explore_img" />
       }

    </a>
    :
    (Tabname == "My-Items" &&item && item.tokenowners_current &&
    item.tokenowners_current.burnorboui == ""&&item.tokenowners_current.balance==0)?
      <a href="javascript:void(0)">
        {
         item.multiimage && item.multiimage[0] && item.multiimage[0].name.split('.').pop() == "mp4" ?
           <video preload="metadata" src={`${Img_URL}/${item.multiimage[0].name}`} type="video/mp4" alt="Collections" className="img-fluid explore_img" controls />
           :
           item.multiimage && item.multiimage[0] && item.multiimage[0].name.split('.').pop() == "mp3" ?
             <img src={require("../../assets/images/music.png")} alt="" className="img-fluid explore_img" style={{ backgroundColor: "white" }} />
             :
             <img loading="lazy" src={`${Img_URL}/${item.multiimage[0].name}`} alt="Collections" className="img-fluid explore_img" />
       }

      </a>
       :
       (Tabname == "My-Items" &&item && item.tokenowners_current &&
       item.tokenowners_current.burnorboui != ""&&item.tokenowners_current.balance>0)?
         <a href={config.Front_URL + "/info/" + item.tokenCounts}>
           {
            item.multiimage && item.multiimage[0] && item.multiimage[0].name.split('.').pop() == "mp4" ?
              <video preload="metadata" src={`${Img_URL}/${item.multiimage[0].name}`} type="video/mp4" alt="Collections" className="img-fluid explore_img" controls />
              :
              item.multiimage && item.multiimage[0] && item.multiimage[0].name.split('.').pop() == "mp3" ?
                <img src={require("../../assets/images/music.png")} alt="" className="img-fluid explore_img" style={{ backgroundColor: "white" }} />
                :
                <img loading="lazy" src={`${Img_URL}/${item.multiimage[0].name}`} alt="Collections" className="img-fluid explore_img" />
          }

         </a>
          :
        <a href={config.Front_URL + "/info/" + item.tokenCounts}>
           {
            item.multiimage && item.multiimage[0] && item.multiimage[0].name.split('.').pop() == "mp4" ?
              <video preload="metadata" src={`${Img_URL}/${item.multiimage[0].name}`} type="video/mp4" alt="Collections" className="img-fluid explore_img" controls />
              :
              item.multiimage && item.multiimage[0] && item.multiimage[0].name.split('.').pop() == "mp3" ?
                <img src={require("../../assets/images/music.png")} alt="" className="img-fluid explore_img" style={{ backgroundColor: "white" }} />
                :
                <img loading="lazy" src={`${Img_URL}/${item.multiimage[0].name}`} alt="Collections" className="img-fluid explore_img" />
          }
        </a>
      }
       
        {item && item.PutOnSaleType == "TimedAuction" && showlist == "true" && display_item <= 0 &&
          <div className="countdown_panel_timer">
            <div className="countdown_panel_div countdown_panel_div_info">
              <Countdown
                date={enddate}
                autoStart={true}
                onStart={() => startdate}
                renderer={renderer}
              ></Countdown>
            </div>
          </div>
        }
        {item && item.PutOnSaleType == "TimedAuction" && showlist == "true" && display_item > 0 &&
          <div className="countdown_panel_timer">
            <div className="countdown_panel_div countdown_panel_div_info">
              <Countdown
                date={startdate}
                autoStart={true}
                onStart={() => Date.now()}
                renderer={renderer1}
              ></Countdown>
            </div>
          </div>
        }
        {showlist == 'false' && auction == "true" &&
          <div className="countdown_panel">
            <div className="countdown_panel_div">
              <span>{auctionTxt}</span>
            </div>
          </div>
        }
      </div>
      <div className="pt-0 p-3 paddin_but_152">


        {item.Model != "" ?
          <h2> 
            { (Tabname == "My-Items" && item && item.tokenowners_current &&
          item.tokenowners_current.burnorboui == "burn")?
              <a href= "javascript:void(0)">
                {item.tokenName} #{item.Model}
              </a> 
              :
              (Tabname == "My-Items" && item && item.tokenowners_current &&
              item.tokenowners_current.burnorboui != ""&&item.tokenowners_current.balance>0)?
                  <a href={config.Front_URL + "/info/" + item.tokenCounts}>
                    {item.tokenName} #{item.Model}
                  </a> 
              :
              <a href= "javascript:void(0)">
              {item.tokenName} #{item.Model}
            </a> 
          }
          </h2>
          :
          <h2>  
            { (Tabname == "My-Items" && item && item.tokenowners_current &&
          item.tokenowners_current.burnorboui == "burn")?
              <a href= "javascript:void(0)">
                {item.tokenName} 
              </a> 
              :
              (Tabname == "My-Items" && item && item.tokenowners_current &&
              item.tokenowners_current.burnorboui != ""&&item.tokenowners_current.balance>0)?
                  <a href={config.Front_URL + "/info/" + item.tokenCounts}>
                    {item.tokenName} 
                  </a> 
              :
              <a href={config.Front_URL + "/info/" + item.tokenCounts}>
              {item.tokenName} 
            </a> 
          }
              </h2>
        }

        <h3>From <span>

          {(item.PutOnSale == true && item.PutOnSaleType == 'FixedPrice' && item && item.tokenowners_current &&
            item.tokenowners_current.tokenPrice > 0) &&
            <span>{item && item.tokenowners_current && item.tokenowners_current.tokenPrice} {item && item.tokenowners_current && item.tokenowners_current.biddingtoken} </span>
          }
          {(item.PutOnSale == true && item.PutOnSaleType == 'TimedAuction') &&
            <span>{item.minimumBid} 
            {item && item.tokenowners_current && item.tokenowners_current.biddingtoken} 
            </span>}
        </span>
          {
            (
              item.TabName != '' && (item.TabName == 'onsale' || item.TabName == 'created' || item.TabName == 'owned')
            )
              ?
              item.tokenowners_my_balance + ' of ' + item.tokenowners_my_quantity
              :
              (
                item.TabName != ''
              )
                ?
                item.tokenowners_all_balance + ' of ' + item.tokenQuantity
                :
                (
                  item.tokenowners_sale_balance > 0
                )
                  ?
                  item.tokenowners_sale_balance + ' of ' + item.tokenQuantity
                  :
                  item.tokenowners_all_balance + ' of ' + item.tokenQuantity
          }
        </h3>

        {item && item.tokenowners_current && item.tokenowners_current.tokenPrice > 0 ?
          <>
            {item && item.tokenowners_current && item.tokenowners_current.biddingtoken == config.owntokenSymbol ?
              <h3>

                ${(bouiusdvalue * item.tokenowners_current.tokenPrice).toFixed(config.toFixed)}

              </h3>
              :<>{item && item.tokenowners_current && item.tokenowners_current.biddingtoken == config.currencySymbol?

              <h3>

                ${(bnbusdvalue*item.tokenowners_current.tokenPrice  ).toFixed(config.toFixed)}

              </h3>
              :
              <h3>
                ${(item.tokenowners_current.tokenPrice).toFixed(config.toFixed)}
              </h3>
              }</>
            }
          </>
          :
          item.minimumBid > 0 ?
            <>
              {item && item.tokenowners_current && item.tokenowners_current.biddingtoken == config.owntokenSymbol ?
                <h3 className="timedPriceItem">
                  ${(bouiusdvalue*item.minimumBid ).toFixed(config.toFixed)}
                </h3>
                :
                <h3 className="timedPriceItem">
                  ${((item.usdval)).toFixed(config.toFixed)}
                </h3>
              }
            </>
            : ""
        }
        <div className="exploreTitle" onClick={() => showAllwithPro(item)}>


          {(WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner &&
           item.tokenowners_current.tokenOwner != UserAccountAddr && 
           (item.PutOnSale == true && item.PutOnSaleType == 'FixedPrice'&&item.tokenowners_current.tokenPrice>0)) ?
            // <button className="exploreBidLink" data-toggle="modal" 
            // data-target="#place_bid_modal" >Place a Bid </button>
            <button onClick={() => PurchaseNow_Click(item, item.tokenowners_current)} className="exploreBidLink" data-toggle="modal"
            >Buy Now </button>
            :
            (WalletConnected == true && item.tokenowners_current && item.tokenowners_current.tokenOwner &&
              item.tokenowners_current.tokenOwner != UserAccountAddr && 
              (item.PutOnSale == true && item.PutOnSaleType == 'FixedPrice'&&item.tokenowners_current.tokenPrice==0)) ?
              <button className="exploreBidLink" data-toggle="modal"
              data-target="#place_bid_modal">Open for Offer </button>
              :
            <></>

          }

          {(item.PutOnSale == true && item.PutOnSaleType == 'TimedAuction') &&
            <button className="exploreBidLink" data-toggle="modal"
              data-target="#place_bid_modal">Minimum Offer </button>
          }
          {(item.PutOnSale == true && item.PutOnSaleType == 'UnLimitedAuction') &&
            <button className="exploreBidLink" data-toggle="modal"
              data-target="#place_bid_modal">Open for Offer </button>
          }
          <a href="javascript:void(0)" className="masonry_likes" onClick={() => hitLike(item)} style={{ cursor: 'pointer' }}>
            {
              (LikedTokenList.findIndex(tokenCounts => (tokenCounts.tokenCounts === item.tokenCounts)) > -1) ?
                (<i className="fas fa-heart mr-2 liked"></i>) :
                (<i className="far fa-heart mr-2"></i>)
            }
            <span class={item.tokenCounts + '-likecount'}>{item.likecount}</span>
          </a>
        </div>
      </div>

    </div>

  )
})

export default TokenItem;