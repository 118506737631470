import React,{lazy} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './index.css';
// pages 

import Home from "views/Home.js";

 import Create from "views/Create.js";
import CreateSingleAndMultiple from "views/Create-singleandMultiple.js";
import EditProfile from "views/edit-profile.js";
import Proposals from "views/proposals.js";
import Myitems from "views/my-items.js";
import Following from "views/following.js";
import Info from "views/info.js";
import Activity from "views/activity.js";
import Discussion from "views/discussion.js";
import DiscussionDetail from "views/discussion-detail.js";
import Community from "views/community.js";
import Terms from "views/Termsofservice.js";
import ShippingPolicy from "views/ShippingPolicy.js";
import Privacypolicy from "views/Privacypolicy.js";
import HowItWorks from "views/how-it-works.js";
import connect from "views/connect-wallet.js"; 
import Search from "views/Search.js"
import ResetPassword from "views/reset-password.js";
import TopicList from "views/TopicList.js";
import CommunityCategory from "views/CommunityCategory.js";
import EditProfileCommunity from "views/edit-profile-community.js";
import Allimages from "views/Allimages.js";
import About from "views/aboutpage";
import store from './store';
import { Provider } from 'react-redux';
import { ToastContainer} from "react-toastify";

ReactDOM.render(
  <Provider store={store} >
  <BrowserRouter basename="/" >
  <ToastContainer/>
    <Switch>
      <Route path="/connect" component={connect} />
      <Route path="/how-it-works" component={HowItWorks} />
      <Route path="/privacy" component={Privacypolicy} />
      <Route path="/about" component={About} />
      <Route path="/terms" component={Terms} />
      <Route path="/shipping-policy" component={ShippingPolicy} />
      <Route path="/edit-profile-community" component={EditProfileCommunity} />
      <Route path="/community" component={Discussion} />
      <Route path="/topic-list" component={TopicList} />
      <Route path="/category/:id" component={CommunityCategory} />
      <Route path="/discussion-detail/:id" component={DiscussionDetail} />
      <Route path="/discussion" component={Discussion} />
      <Route path="/reset-password/:id" component={ResetPassword} />
      <Route path="/activity" component={Activity} />
      <Route path="/activate/:userId" component={Discussion} />
      <Route path="/info/:tokenidval" component={Info} />
      <Route path="/following" component={Following} />
      <Route path="/my-items" component={Myitems} />
      <Route path="/search" component={Search} />
      <Route path="/user/:paramAddress" component={Myitems} />
      <Route path="/proposals" component={Proposals} />
      <Route path="/edit-profile" component={EditProfile} />
      <Route path="/create-single" component={CreateSingleAndMultiple} />
      {/* <Route path="/create-multiple" component={CreateSingleAndMultiple} /> */}
      {/* <Route path="/create" component={Create} /> */}
      <Route path="/home" component={Home} />
      
      <Route path="/allimages" component={Allimages} />
      <Route exact path="/*" component={Home}>
        <Redirect to="/home" />
      </Route>
    </Switch>
  </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
