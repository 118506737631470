import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import ConnectWallet from './separate/Connect-Wallet';
import ActivityTab from './separate/ActivityTab';

import {
  getCurAddr,
  Activity_List_Action
} from '../actions/v1/user';
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Activity(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel1');
    
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = React.useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [filterType, setfilterType] = React.useState('');
  const [activityType, setactivityType] = React.useState('all');
  const [ActivityList, Set_ActivityList] = React.useState([]);
  const [page, setpage] = React.useState(1);
  const [loadMoreBtn, setloadMoreBtn] = React.useState(true);

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    Get_Activity_list();
  }, []);

  const AfterWalletConnected = async () => {
    var currAddr = await getCurAddr();
    if(typeof currAddr != 'undefined'){
      window.$('#AfterWalletConnected_two').click();
    }
  }

  async function changeTab(type) {

    var address = "";
    if(type=="my"){
      var currAddr = await getCurAddr();
      address = currAddr;
    }
    setactivityType(type)

    var resp = await Activity_List_Action({
      addr:address,
      actiontype:type,
      filter:filterType
    });
    
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var List = resp.data.list;
      console.log("!!@@@@@@@",List)
      setloadMoreBtn(true)
      Set_ActivityList(List);
    }
    else {
      Set_ActivityList([]);
    }
  
  }

  async function Get_Activity_list(){

    var address = "";
    if(activityType=="my"){
      var currAddr = await getCurAddr();
      address = currAddr;
    }

    var resp = await Activity_List_Action({
      addr:address,
      actiontype:activityType,
      filter:filterType
    });
    
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var List = resp.data.list;
      Set_ActivityList(List);
    }
  }

  async function changeFilter(type){

    var address = "";
    if(activityType=="my"){
      // var currAddr = await getCurAddr();
      var currAddr = window.web3.eth.defaultAccount;
      address = currAddr;
    }

    var resp = await Activity_List_Action({

      addr:window.web3.eth.defaultAccount,
      actiontype:activityType,
      filter:type
    });
    setfilterType(type);
    
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var List = resp.data.list;
      Set_ActivityList(List);
    }
    else {
      Set_ActivityList([]);
    }

  }

  const loadMoreActivity = async (e) => {

    var address = "";
    if(activityType=="my"){
      var currAddr = await getCurAddr();
      address = currAddr;
    }

    var resp = await Activity_List_Action({
      addr:address,
      actiontype:activityType,
      filter:filterType,
      page:page
    });
    
    if(
      resp
      && resp.data
      && resp.data.list && resp.data.list.length >0 
    ) {
      var nxtPage = (resp.data && resp.data.page)?resp.data.page:0
      var List = resp.data.list;
      var totalList = ActivityList.concat(List);
      Set_ActivityList(totalList);
      setpage(nxtPage)
    }
    else {
      setloadMoreBtn(false)
    }


  }

  async function resetFilter(){

    var address = "";
    if(activityType=="my"){
      var currAddr = await getCurAddr();
      address = currAddr;
    }
    setfilterType("");
    var resp = await Activity_List_Action({
      addr:address,
      actiontype:activityType,
      filter:""
    });
    
    if(
      resp
      && resp.data
      && resp.data.list && resp.data.list.length >0 
    ) {
      var nxtPage = (resp.data && resp.data.page)?resp.data.page:0
      var List = resp.data.list;
      var totalList = ActivityList.concat(List);
      Set_ActivityList(totalList);
      setpage(nxtPage)
    }
    else {
      setloadMoreBtn(false)
    }

  }

  return (
    <div className="inner_header">
        <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="bg_red_1">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="d-flex justify-content-between">
                  <h2 className="inner_title">Activity</h2>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className="container mt-3">
          <GridContainer className="align-items-start">
            <GridItem xs={12} sm={8} md={8}>
              <nav className="masonry_tab_nav mt-4 items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab" id="nav-tab" role="tablist">
                  <a className="nav-link active" onClick={() => changeTab("all")} id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">
                    All
                  </a>
                  <a className="nav-link" onClick={() => changeTab("following")}  id="following-tab" data-toggle="tab" href="#following" role="tab" aria-controls="following" aria-selected="false">
                    Following
                  </a>
                  <a className="nav-link" onClick={() => changeTab("my")}  id="activity-tab" data-toggle="tab" href="#activity" role="tab" aria-controls="activity" aria-selected="false">
                    My Activity
                  </a>
                </div>
              </nav>
              <div className="tab-content explore_tab_ccurraddressontent mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                  <div className="proposal_panel_overall">
                    <div className="followers_overall py-3">
                      <div className="row">
                      {
                            ActivityList.map((activitydata) => {
                              console.log("all_list",activitydata)
                              return (
                                <ActivityTab 
                                  item={activitydata}
                                  UserAccountAddr={UserAccountAddr}
                                />
                              )
                          })
                        }

                 
                      </div>

                    </div>

                  </div>
                </div>

                <div className="tab-pane fade" id="following" role="tabpanel" aria-labelledby="following-tab">
                  <div className="proposal_panel_overall">
                    <div className="followers_overall py-3">
                      <div className="row">
                       
                      {
                            ActivityList.map((activitydata) => {
                              return (
                                <ActivityTab 
                                  item={activitydata}
                                  UserAccountAddr={UserAccountAddr}
                                />
                              )
                            })
                        }

                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <div className="filter_panel">
                <h2>Filter</h2>
                <div className="filter_panel_grp">
                  <Button className="filter_btn" type="button" onClick={() => changeFilter("listings")} >Listings</Button>
                  <Button className="filter_btn" type="button" onClick={() => changeFilter("purchase")}>Purchases</Button>
                  <Button className="filter_btn" type="button" onClick={() => changeFilter("sales")}>Sales</Button>
                  <Button className="filter_btn" type="button" onClick={() => changeFilter("like")}>Likes</Button>
                 
                  {/* <Button className="filter_btn">Transfers</Button> */}
                  <Button className="filter_btn" onClick={() => changeFilter("burn")}>Burns</Button>
                  <Button className="filter_btn" onClick={() => changeFilter("place bid")}>Bids</Button>
                  <Button className="filter_btn" onClick={() => changeFilter("following")}>Followings</Button>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
