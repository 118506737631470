/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { Link } from "react-router-dom";
import Config from '../../lib/config';
const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
         <GridContainer className="justify-content-center mt-5 mt-0im">
                <GridItem xs={12} sm={12} md={12} lg={4} className="text-left">
                  <div className="footerContent">
                    <a href="#"><img src={require("../../assets/images/logo.png")} alt="" className="footerlogo_12 mb-4" /></a>
                    <p> Discover and purchase exclusively curated non-fungible token (NFT) digital assets, providing ownership rights to physical goods.</p>
                  {/*  <div className="language">
                      <div>
                      <img src={require("../../assets/images/inglish.jpg")} alt="" className="footerlogo_12 mb-4" /><select>
                        <option>English</option>
                        <option>Japanese</option>

                      </select>
                      </div>
                   </div>*/}
                  </div>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={2} className="text-left">
            <h4 className="text-left">NTF Marketplace</h4>
            <List className={classes.list + " footer_links fooytere_link"}>
              <ListItem className={classes.inlineBlock}>
              {(location.pathname=="/home") ?
          <a href="#explore_12" id="aaaa"  className={classes.navLink} >Explore</a>
          :
          <Link className={classes.navLink} id="bbb" to="/home#explore_12">Explore</Link>
        }              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/activity" className={classes.block}>Activity</Link>
              </ListItem>
              {/* <ListItem className={classes.inlineBlock}>
                <a href="#" className={classes.block}>Privacy Policy</a>
              </ListItem>
              */}
            </List> 
        </GridItem>
      
        <GridItem xs={12} sm={6} md={3} lg={2} className="text-left">
            <h4 className="text-left text_cerbny">Support</h4>
            <List className={classes.list + " footer_links fooytere_link"}>
            <ListItem className={classes.inlineBlock}>
                <Link to="/about" className={classes.block}>About us</Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/privacy" className={classes.block}>Privacy Policy</Link>
              </ListItem>
               <ListItem className={classes.inlineBlock}>
                <Link to="/shipping-policy" className={classes.block}>Shipping Policy</Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/terms" className={classes.block}>Terms & Conditions</Link>
              </ListItem>
            </List>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={2} className="text-left">
            <h4 className="text-left text_cerbny">Contact Us</h4>
            <List className={classes.list + " footer_links fooytere_link"}>
              <ListItem className={classes.inlineBlock}>
                <a href="mailto:info@boui.io"  className={classes.block}>info@boui.io</a>
              </ListItem>
         
            </List>
        </GridItem>


        {/* <GridItem xs={12} sm={6} md={3} lg={2} className="text-left">
        <a href={Config.Front_URL+"/community"} >
          <h4 className="text-left text_cerbny">Community</h4>
          </a> */}
        {/* <h4 className="text-left text_cerbny">Community</h4>
           <List className={classes.list + " footer_links fooytere_link"}> */}
              {/*<ListItem className={classes.inlineBlock}>
                <a href="#" className={classes.block}>Our Blog</a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="#" className={classes.block}>Help Center</a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="#" className={classes.block}>Contacts</a>
            </ListItem>*/}
            {/* </List> */}
        {/* </GridItem> */}
        <GridItem xs={12} sm={6} md={3} lg={2} className="text-left">
          <div className="text_cerbny">
            <a href="#"><img src={require("../../assets/images/footerlogo.png")} alt="" className="img-fluid" /></a>
          </div>
     </GridItem>
   </GridContainer>
     <div className="subfooter_18">
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} lg={5} className="text-left">
            <p className="copyright_txt">© Copyright ©2021 All rights reserved</p>  
          </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={7} className="text-left">
              <ul className="social_links">
              <li><a href="https://twitter.com/bouimarketplace" target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href="https://www.instagram.com/bouiinc/" target="_blank"><i class="fab fa-instagram"></i></a></li>
              <li><a href="https://discord.gg/x7ZkK9fTwf" target="_blank"><i class="fab fa-discord"></i></a></li>

                {/* <li><a href="#" target="_blank"><i className="fab fa-telegram-plane"></i></a></li> 
                 <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li> 
               <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                <li><a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a></li> 
                <li><a href="#" target="_blank"><i className="fab fa-medium-m"></i></a></li>*/}
              </ul>
           </GridItem>
        </GridContainer>
      </div>
      </div>
      
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
