import React, {
    forwardRef,
    useImperativeHandle
} from 'react';
import '@metamask/legacy-web3';
import { InlineShareButtons, InlineFollowButtons } from 'sharethis-reactjs';
import config from '../../lib/config';
import {
    ShareRequest,
} from '../../actions/items';
import { toast } from 'react-toastify';
import CopyToClipboard from "react-copy-to-clipboard";
toast.configure();
let toasterOption = config.toasterOption;
var Front_URL = config.Front_URL;


export const ShareNowRef = forwardRef((props, ref) => {

    const [ShareUrl, setShareUrl] = React.useState("");
    const [Show, setShow] = React.useState("");
    async function getShareoption(item) {
        if (item && item._id) {
            var currAddr ="";
            if(window.web3){
            currAddr = window.web3.eth.defaultAccount;
            }
            var url = Front_URL + "/info/" + item.tokenCounts;

        } else {
            var url = Front_URL + "/user/" + item.curraddress;
            // var reqData ={
            //     shareuser:currAddr,
            //     // tokenCounts:item.tokenCounts,
            // }
            // var response = await ShareRequest(reqData);
        }
        setShareUrl(url);
        setShow(true)
        window.$('#share_modal').modal('show');
        var reqData = {
            shareuser: currAddr,
            tokenCounts: item.tokenCounts,
        }
        var response = await ShareRequest(reqData);
    }
    useImperativeHandle(
        ref,
        () => ({
            async ShareSocial_Click(item) {
                console.log("ShareSocial_Click", config.Front_URL, item)
                getShareoption(item)
            }
        }),
    )

    function copyText() {
        toast.success("Copied", toasterOption);
    }

    return (
        <div>
            <div class="modal fade primary_modal" id="share_modal" tabindex="-1" data-backdrop="static" role="dialog" aria-labelledby="share_modalCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <h5 class="modal-title" id="share_modalLabel">Share link to this page</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className='social_first_div'>
                                <InlineShareButtons
                                    config={{
                                        alignment: 'center',
                                        color: 'social',
                                        enabled: true,
                                        font_size: 16,
                                        language: 'en',
                                        networks: [
                                            'telegram',
                                            'facebook',
                                            'twitter',
                                            'instagram'
                                        ],
                                        padding: 12,
                                        radius: 9,
                                        show_total: false,
                                        size: 40,
                                        url: ShareUrl,
                                        image: 'https://bit.ly/2CMhCMC',
                                        description: 'BOUI NFT',
                                        title: 'BOUI NFT',
                                        message: 'Hi visit BOUI NFT and buy Article what You want',
                                        subject: 'Hi visit BOUI NFT and buy Article what You want',
                                        username: 'BOUI NFT'
                                    }}
                                />

                                <div className="share_copy_btn">
                                    <CopyToClipboard text={ShareUrl} onCopy={() => copyText()}>
                                        <i className="fas fa-sticky-note notes_fa"></i>
                                    </CopyToClipboard>
                                </div>

                                {/* <div className='insta_icon'>
                                <i class="fab fa-instagram"></i>
                                </div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

