import React, {
    forwardRef,
    useImperativeHandle
} from 'react';
import { Button } from '@material-ui/core';
import '@metamask/legacy-web3'

import config from '../../lib/config';
import {
    ReportRequest,
} from '../../actions/items';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

export const ReportNowRef = forwardRef((props, ref) => {

    const [description, setdescription] = React.useState("");
    const [ValidateError, Set_ValidateError] = React.useState({});
    const [itemId, Set_itemId] = React.useState("");
    const [tokenCounts, Set_tokenCounts] = React.useState("");
    const [reportBtn, Set_reportBtn] = React.useState(false);
    const [email, setemail]             = React.useState("");

    var {
        UserAccountAddr
    } = props;

    useImperativeHandle(
        ref,
        () => ({
            async SubmitReport_Click(item) {
                console.log("report_item_list",item)
                if(item && item._id){
                    Set_itemId(item._id);
                    Set_tokenCounts(item.tokenCounts);
                    window.$('#report_modal').modal('show');
                }
            }
        }),
    )

    const inputChange = (e) => {
        if(e && e.target && typeof e.target.value != 'undefined') {
            var value = e.target.value;
            switch (e.target.name) {
                case "description":
                    if(e.target.value !=""){
                        Set_ValidateError({});
                    }
                    setdescription(value);
                break;
                case "email":
                    if(e.target.value !=""){
                        Set_ValidateError({});
                    }
                    setemail(value);
                break;
            }
           
        }
    }

    const ReportValidation = async (data={}) => {
        var ValidateError = {};
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

        if(description == '' || typeof description=="undefined") {
            ValidateError.description = '"Description" is not allowed to be empty';
        }
        if(email == '' || typeof email=="undefined") {
            ValidateError.email = '"E-Mail" is not allowed to be empty';
        }else if (!(emailRegex.test(email))) {
            ValidateError.email = 'Please enter Valid E-Mail Id';
        }
        Set_ValidateError(ValidateError);
        return ValidateError;
    }


    async function submitReport(){
        var errors = await ReportValidation();
        var errorsSize = Object.keys(errors).length;
        if(errorsSize != 0) {
            toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
            return false;
        }
        var reqData ={
            reportuser:UserAccountAddr,
            email:email,
            description:description,
            itemId:itemId,
            tokenCounts:tokenCounts,
            type:"collection"
        }
        Set_reportBtn(true);
        var response = await ReportRequest(reqData);
        if(response && response.status=="true"){
            toast.success("Successfully submit your report", toasterOption);
            setdescription("");
            setTimeout(function(){
                Set_reportBtn(false)
                window.$('#report_modal').modal('hide');
            },100)
        }else{
            Set_reportBtn(false)
            toast.error('Oops something went wrong.!', toasterOption);
        }
    }

    async function cancelReport(){
        setdescription("");
        window.$('#report_modal').modal('hide');
        Set_ValidateError({});
        window.location.reload(false)
    }

    return (
        <div>
        <div class="modal fade primary_modal" id="report_modal" tabindex="-1" role="dialog" aria-labelledby="report_modalCenteredLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                <h5 class="modal-title" id="report_modalLabel">Why are you reporting?</h5>
                <button type="button" class="close" onClick={() => window.location.reload(false)} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                <form>
                <p>Tell us how this user violates the rules of the site</p>
                <label className="primary_label" htmlFor="name">E-Mail</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    class="form-control"
                    placeholder="Enter the email address"
                    aria-label="email"
                    aria-describedby="basic-addon2"
                    onChange={inputChange}
                    autoComplete="off"
                    // value={tokenBidAmt}
                  />
            {ValidateError.email && <span className="text-danger">{ValidateError.email}</span>}
               
                
                    <label className="primary_label" htmlFor="name">Message</label>
                    <textarea onChange={inputChange} value={description} className="form-control primary_inp" rows="3" name="description" placeholder="Tell us some details"></textarea>
                    {ValidateError.description && <span className="text-danger">{ValidateError.description}</span>}
                    <div className="report_btn_grp mt-3">
                    <Button disabled={reportBtn} className="create_btn" onClick={() => submitReport()}>Report</Button>
                    <Button className="cancel_btn ml-2" onClick={() => cancelReport()}>Cancel</Button>
                    </div>
                </form>
                </div>
            </div>
            </div>
      </div>
        </div>
    )
})

