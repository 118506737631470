import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksCommunity from "components/Header/HeaderLinksCommunity.js";

import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link,useParams } from "react-router-dom";
import isEmpty from "./../lib/isEmpty";
import CKEditor from 'ckeditor4-react';
import { SecondsToDay } from '../helper/dateFormat';
import { createtopic,getcategory,lastesttopic,toptopic,communityCategory} from "./../actions/community";
import { toast } from 'react-toastify';
import Select from 'react-select';
import config from "../lib/config";
toast.configure();
  let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  }
const dashboardRoutes = [];
var IMG_URL = config.IMG_URL;
const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  return null;
}
const initialFormValue = {
  topic:"",
  description:"",
  cate:"",
  catename:"",
};


export default function CommunityCategory(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const { id } = useParams();
console.log("params_id:",id)
  const [formValue, setFormValue] = useState(initialFormValue);
  const [description, setdescription] = useState({description:""});
  const { topic, cate, catename} = formValue;
  const [validateError, setValidateError] = useState({});
  const [categories, setcategories] = useState([]);
  const [toptopics, settoptopics] = useState([]);
  const [latesttopics, setlatesttopics] = useState([]);
  const [catdrop, setcatdrop] = useState([]);
  const [catCommunity, setcatCommunity] = useState([]);

  useEffect(() => {
    getallcategory()
    getlastesttopic()
    gettoptopic();
    getList();
  }, []);

  
  const getList = async (e) => {

    let { error, list } = await communityCategory({id:id});
    console.log("communityCategory:",list)
    setcatCommunity(list); 
  }

  const getallcategory = async (e) => {
    let { error, result } = await getcategory();
   // console.log(result,'resultresultresultresultresultresult')
    
    if (isEmpty(error)) {
     setcategories(result); 
      var cat = result.map(opt => ({ label: opt.name, value: opt._id }));
      setcatdrop(cat);
    } else { 
      
    
    }
    }

    const getlastesttopic = async (e) => {
      let { error, result } = await lastesttopic();
     // console.log(result,'resultresultresultresultresultresult')
      
      if (isEmpty(error)) {
       setlatesttopics(result); 
      } else { 
        
      
      }
      }

      const gettoptopic = async (e) => {
        let { error, result } = await toptopic();
        
        if (isEmpty(error)) {
         settoptopics(result); 
        } else { 
          
        
        }
        }



   
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;

    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
  };

  const handlecat = e => {
    console.log(e,"saran");

    let formData = { ...formValue, ...{ ['cate']: e.value,['catename']: e.label } };
    console.log(formData,"formDataformDataformDataformData");
    setFormValue(formData);
  //this.setState({ deleteemailid: e.value });
  }

  // function
  const handletopicSubmit = async (e) => {
    e.preventDefault();
    let reqData = {
      topic,
      description,
      cate,
      catename
      
    };
    console.log(reqData,'reqDatareqData')
    let { error, result } = await createtopic(reqData);
    console.log(error,'errr');
    console.log(reqData, "reqData");

    if (isEmpty(error)) {
     
      setFormValue(initialFormValue); 
      toast.success('Topic Created Successfully.', 'success')
      //history.push('/discussion')
     window.location.href="/discussion"
    } else { 
      setValidateError(error);
    
    }
  };

  const onEditorChange = (evt) => {
    var description_text = evt.editor.getData();   
    setdescription(description_text)
    
}
   

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinksCommunity />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} className="m-auto">
              <div className="d-flex align-items-center">
                <Link to="/community" className="goback_link">
                  <i class="bi bi-chevron-left"></i>
                  <span>Go back</span>
                </Link>
              </div>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="inner_title">Discussion</h2>
                {localStorage.community_token && 
                <Button className="create_btn" data-toggle="modal" data-target="#new_top_modal">New Topic</Button>
                }
              </div>
            </GridItem> */}
          </GridContainer>
        </div>
        <div className="container mt-3">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            
              <div className="tab-content explore_tab_content mt-0" id="nav-tabContent">
                

                <div className="tab-pane show active" id="latest" role="tabpanel" aria-labelledby="following-tab">
                  <div className="proposal_panel_overall">

                    <div className="table-responsive">
                      <table class="table table_style_1">
                        <thead>
                          <tr>
                            <th>
                              <div class="form-group mb-0">
                              </div>
                              Category
                            </th>
                            <th className="text-right"></th>
                            <th className="text-right"> Replies </th>
                            <th className="text-right"> Views </th>
                            <th className="text-right"> Activity </th>

                          </tr>
                        </thead>
                        <tbody>
                        {catCommunity && catCommunity.length >0 && catCommunity.map((row,index) => {
                          console.log("profiles:",row)
                            return (
                          <tr>
                            <td>
                              <Link to={"/discussion-detail/"+row._id}><p className="cat_title mb-0">{row.topic}</p></Link>
                              <Link><p className="cat_link cat_link_green mb-0">{row.category_name}</p></Link>
                            </td>
                            <td className="text-right">
                              <div className="d-flex creators_details_td justify-content-end">
                              {/* { 
                                 row.userList && row.userList.length > 0 &&  row.userList.map((sub, subindex) =>

                                      <img src={(sub.profile_image && sub.profile_image !="")?
                                      config.Back_URL+"images/communityprofile/"+sub.profile_image
                                      :config.Back_URL+"/images/communityprofile/avatar.png"} alt="User" className="img-fluid profile" />
                               
                                )} */}
                              </div>
                            </td>
                            <td className="text-right"> {row.reply_count} </td>
                            <td className="text-right"> {row.views_count} </td>
                            <td className="text-right"> {SecondsToDay(row.created_date)} </td>

                          </tr> )})}
                          {catCommunity && catCommunity.length == 0 &&
                              <tr>No results found..!</tr>
                          }
                          
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />

      {/* New Topic Modal */}
      <div class="modal fade primary_modal" id="new_top_modal" tabindex="-1" role="dialog" aria-labelledby="new_top_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" id="hide"  >        
            <div class="modal-header text-center">
              <h5 class="modal-title" id="new_top_modalLabel_1">Create New Topic</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="login_form mt-3" onSubmit={handletopicSubmit}>
               <div className="form-row">
                <div className="col-12">
                  <label for="title">Title</label>
                  <div class="mb-3">
                    <input type="text" onChange={onChange} value={topic} id="topic" class="form-control" placeholder="Title" />
                  </div>
                </div>
                <div className="col-12">
                  <label for="category">Choose Category</label>
                  <Select id="category" options={catdrop}
                    onChange={handlecat}
                    />
                                 
                </div>
               </div>
                <label className="mt-2" for="message">Message</label>
                <div class="my-3">
                  <CKEditor
                  id="description"
                    data="<p></p>"
                    onChange={onEditorChange}
                  />
                </div>
                <div className="mt-3">
                  <Button type="submit" className="create_btn">Create Topic</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
