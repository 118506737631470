import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle
} from 'react';
import {
    useHistory
} from "react-router-dom";
import { Button, Select, TextField } from '@material-ui/core';

import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'

import BEP721 from '../../ABI/BEP721.json';
import DETH from '../../ABI/DETH.json'
import BEP1155 from '../../ABI/BEP1155.json';
import config from '../../lib/config';
import BOUI_ABI from '../../ABI/BOUI.json';
import BUSD_ABI from '../../ABI/BUSD.json';

import {
    AddLikeAction,
    GetLikeDataAction,
    TokenPriceChange_update_Action,
    PurchaseNow_Complete_Action,
    
} from '../../actions/v1/token';

import {
    halfAddrShow,
    getCurAddr,
    AddressUserDetails_GetOrSave_Action
} from '../../actions/v1/user';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

const exchangeAddress  = config.exchangeAddress;
const contractAddr     = config.smartContract;
const singleContract   = config.singleContract;
const multipleContract = config.multipleContract;
const tokenAddress     = config.tokenAddress;
var web3                = new Web3(window.ethereum);
const BUSDContract      = new web3.eth.Contract(BUSD_ABI, config.busdtokenAddr);
const BOUIContract      = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
const BEP721Contract    = new web3.eth.Contract(BEP721, config.singleContract);
const BEP1155Contract   = new web3.eth.Contract(BEP1155, config.multipleContract);
const DETHContract      = new web3.eth.Contract(DETH, config.tokenAddress);


export const PurchaseNowRef = forwardRef((props, ref) => {
console.log(props.item,'purchaseref')
    const history = useHistory();

    var {
        UserAccountAddr,
        UserAccountBal,
        TokenBalance,
        MyItemAccountAddr,
        buytoken,
        walletaddr,
        setWalletaddr,
        AddressUserDetails,
        AllowedQuantity,
        Set_AllowedQuantity
    } = props;
    const [ApproveCallStatus, setApproveCallStatus]   = useState('init');
    const [PurchaseCallStatus, setPurchaseCallStatus] = useState('init');
    const [price, Set_Price]                          = useState(0);
    const [PurchaseCurrency, setPurchaseCurrency]     = useState();
    const [itemtype, setItemtype]                     = useState('');



    var feepercval = 0;
    var biddingtoken = ""
    var PurchaseBalancenew =0
    if(buytoken!=undefined){
        feepercval = buytoken!==undefined && buytoken == config.currencySymbol ? 
         config.fee/config.decimalvalues 
        :
        buytoken!==undefined && buytoken == config.owntokenSymbol?
         config.fee/config.decimalvalues: config.fee/config.decimalvalues;

        // biddingtoken = buytoken == config.tokenSymbol?config.wethfee:buytoken == 'VERSE'?
        //  config.AIDItokenAddr:""

        // PurchaseBalancenew = buytoken == config.tokenSymbol?
        //     wethbalance:buytoken == config.tokenSymbol?aidibalance:""

    }
    else {
        feepercval = config.fee/config.decimalvalues;
    }

  
    const [saletokenbal, setSaletoken]              = useState(0);
    const [BuyerName, Set_BuyerName]     = useState('');
    const [blns, Set_blns]               = useState('');
    const [dethBln, Set_dethBln]         = useState('');
    const [bidProfile1, Set_bidProfile1] = useState([]);
    const [MultipleWei, Set_MultipleWei] = useState(0);
    const [NoOfToken, Set_NoOfToken]     = useState(1);
    const [FormSubmitLoading, Set_FormSubmitLoading] = useState('');
    const [ValidateError, Set_ValidateError] = useState({});
    const [YouWillPay, Set_YouWillPay]     = useState(0);
    const [TokenPrice, Set_TokenPrice]     = useState(0);
    const [MetaMaskAmt, setMetaMaskAmt]    = useState(0);
    const [item, Set_item]                 = useState(props.item);
    const [servicefee, setservicefee]      = useState(0);
    const [busdbal,setBusdbal]             = useState(0);
    const [discountAmt,setdiscountAmt]     = useState(0);
    const [discountPer,setdiscountPercent] = useState(0);
    const [burnorboui, setBurnorboui]      = useState("boui");
    const [feeperc, set_feeperc]                    = useState(feepercval)
    const [biddingtokenaddress,set_biddingtokenaddress]=useState(biddingtoken);
    const [PurchaseBalance,set_PurchaseBalance]        =useState(0);

    async function buymodal(){
        setTimeout(() => window.$('#PurchaseNow_modal').modal('hide'), 600);
        setTimeout(() => window.location.reload(false), 900);
    }
    const PriceCalculate = async (data={}) => {
         try{
             if (window.ethereum) {
              var web3 = new Web3(window.ethereum);
              if(data.itemtype==721){
                var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                
                if(data.PurchaseCurrency==config.currencySymbol){
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  } else if(data.PurchaseCurrency==config.owntokenSymbol){
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  }else if(data.PurchaseCurrency==config.usdtokenSymbol){
                    var fee = await CoursetroContract.methods.getBUSDFee().call();
                  }else{
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  }
                  var price       = (typeof data.price != 'undefined') ? data.price : TokenPrice;
                  var quantity    = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
                  var newPrice    =  price*config.decimalvalues;
                  var per         = (newPrice * fee) / 1e20;
                  var mulWei      = newPrice + per;
                  Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                  Set_MultipleWei(mulWei);
                  Set_Price(newPrice)
              }else{
                
                var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                if(data.PurchaseCurrency==config.currencySymbol){
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  } else if(data.PurchaseCurrency==config.owntokenSymbol){
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  }else if(data.PurchaseCurrency==config.usdtokenSymbol){
                    var fee = await CoursetroContract.methods.getBUSDFee().call();
                  }else{
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  }

                    var feeValue    = fee/config.decimalvalues;
                    var price       = (typeof data.price != 'undefined') ? data.price : TokenPrice;
                    var quantity    = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
                    var newPrice    = quantity *(price*config.decimalvalues);
                    var per         = (newPrice * fee) / 1e20;
                    var mulWei      = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                    Set_MultipleWei(mulWei);
                    Set_Price(newPrice) 
              }
            }
          }catch(err){
          }
            }
    
    const onKeyUp = (e) => {
        var charCode = e.keyCode;
        if((charCode>47 && charCode <58) || (charCode>95 && charCode <106)){
          var ValidateError = {};
          Set_ValidateError(ValidateError);
        }else{
          var ValidateError = {};
          ValidateError.NoOfToken = '"Quantity" must be a number';
          Set_NoOfToken("");
          Set_ValidateError(ValidateError);
        }
      }
      
    const inputChange = (e) => {
        if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
            var value = e.target.value;
            switch(e.target.name) {
                case 'NoOfToken':
                    Set_NoOfToken(value);
                    PriceCalculate({quantity:value,PurchaseCurrency:PurchaseCurrency,itemtype:itemtype});
                    break;
                case 'TokenPrice':
                    Set_TokenPrice(value);
                    if(value != '' && isNaN(value) == false && value > 0) {
                        PriceCalculate({quantity:value,PurchaseCurrency:PurchaseCurrency,itemtype:itemtype});
                    }
                    break;
                default:
                // code block
            }
            // ItemValidation({TokenPrice:value});
        }
    }
    const ItemValidation = async (data={}) => {
        var ValidateError = {};

        var Chk_TokenPrice = (typeof data.TokenPrice!='undefined')?data.TokenPrice:TokenPrice;
        var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;

        var Collectible_balance = '';
        if(item && item.tokenowners_current && item.tokenowners_current.balance) {
            Collectible_balance = item.tokenowners_current.balance;
        }

        if(quantity == '') {
            ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
        }
        else if(quantity == 0) {
            ValidateError.NoOfToken = '"Quantity" must be greater than 0';;
        } 
        else if(isNaN(quantity) == true) {
          ValidateError.NoOfToken = '"Quantity" must be a number';
        }
        // if(quantity > Collectible_balance) {
        //     ValidateError.NoOfToken = '"Quantity" must be below on '+Collectible_balance;
        // }

        if(Chk_TokenPrice == '') {
            ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
        }
        else if(Chk_TokenPrice == 0) {
            ValidateError.TokenPrice = '"Token Price" must be greater than 0';;
        } 
        else if(isNaN(Chk_TokenPrice) == true) {
          ValidateError.TokenPrice = '"Token Price" must be a number';
        }
        // else if(YouWillPay > PurchaseBalance) {
        //     ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
        // }
        // else {
        //     await props.GetUserBal();
        //     if(YouWillPay > PurchaseBalance) {
        //         ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
        //     }
        //     else {
        //         delete ValidateError.TokenPrice;
        //     }
        // }
        Set_ValidateError(ValidateError);
        return ValidateError;
    }
    function convert(n){
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead,decimal,pow] = n.toString()
            .replace(/^-/,"")
            .replace(/^([0-9]+)(e.*)/,"$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
    }

    async function FormSubmit(){
        var errors = await ItemValidation();
        if (PurchaseBalance==0) {
            toast.error("Insufficient balance", toasterOption);
            return false;
        }
        if (TokenPrice>PurchaseBalance) {
            toast.error("Insufficient balance", toasterOption);
            return false;
        }
        var connectwallet=localStorage.getItem("tfniuobnft");
        if(!connectwallet){
          window.location.href="/connect";
          return false;
        }
        var Collectible_balance = '';
        if(item && item.tokenowners_current && item.tokenowners_current.balance) {
            Collectible_balance = item.tokenowners_current.balance;
        }
          if(NoOfToken > Collectible_balance) {
            toast.error('"Quantity" must be below on '+Collectible_balance, toasterOption);
            return false;
        }
        var errorsSize = Object.keys(errors).length;
        if(errorsSize != 0) {
            toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
            return false;
        }

        if(window.ethereum) {
            var web3 = new Web3(window.ethereum)
            if(
                web3
                && web3.eth
            ) {

                if (PurchaseCurrency != config.currencySymbol) {
                    if (PurchaseCurrency == config.owntokenSymbol) {
                        var bouibalwei = await BOUIContract.methods.balanceOf(props.Accounts).call()
                        var bouibalance = bouibalwei / config.decimalvalues
                        if (bouibalance >= parseFloat(YouWillPay)) {
                            window.$('.modal').modal('hide');
                            window.$('#PurchaseStep_modal').modal('show');
                        } else {
                            toast.error("Insufficient balance", toasterOption);
                        }

                    }else if (PurchaseCurrency == config.usdtokenSymbol) {
                        var busdbalwei = await BUSDContract.methods.balanceOf(props.Accounts).call()
                        var busdbalance = busdbalwei / config.decimalvalues
                        if (busdbalance >= parseFloat(YouWillPay)) {
                            window.$('.modal').modal('hide');
                            window.$('#PurchaseStep_modal').modal('show');
                        } else {
                            toast.error("Insufficient balance", toasterOption);
                        }

                    }
                     else if (PurchaseCurrency == config.currencySymbol) {
                        if(item.type==721){
                            var wethbalanceinwei = await DETHContract.methods.balanceOf(props.Accounts).call()
                            var wethbalance = wethbalanceinwei / config.decimalvalues
                        }else{
                            var wethbalanceinwei = await DETHContract.methods.balanceOf(props.Accounts).call()
                            var wethbalance = wethbalanceinwei / config.decimalvalues
                        }
                  
                        if (wethbalance >= parseFloat(YouWillPay)) {
                            window.$('.modal').modal('hide');
                            window.$('#PurchaseStep_modal').modal('show');
                        } else {
                            toast.error("Insufficient balance", toasterOption);
                        }
                    }
                }
                else {
                    var tokenContractAddress = item.contractAddress.toString();
                    var tokenType           = item.type.toString();
                    var bal                 = parseInt(item.balance);
                    var web3                = new Web3(window.ethereum);
                    const BUSDContract = new web3.eth.Contract(BUSD_ABI, item.contractAddress)
                    const BOUIContract = new web3.eth.Contract(BOUI_ABI, item.contractAddress)
                    const BEP721Contract    = new web3.eth.Contract(BEP721, item.contractAddress)
                    const BEP1155Contract   = new web3.eth.Contract(BEP1155, item.contractAddress)
                    const newAidicontract   = item.contractAddress

                    var tokenPrice = (item.tokenowners_current && 
                        item.tokenowners_current.tokenPrice) ?
                         item.tokenowners_current.tokenPrice : 0

                    var owneradd = item.tokenowners_current.tokenOwner
                    var sendAmount = (tokenPrice * config.decimalvalues).toString();

                    var temp = 1025000000000000
                    temp = temp.toString()

                    if (tokenPrice && tokenPrice > 0) {
                        Set_FormSubmitLoading('processing');
                        if (item.type == 721) {
                            
                            var tokenaddress= PurchaseCurrency==config.currencySymbol?
                            config.singleContract
                            :
                            PurchaseCurrency==config.owntokenSymbol?
                            config.bouitokenAddr
                            :
                            PurchaseCurrency==config.usdtokenSymbol?
                            config.busdtokenAddr
                            :
                            "";

                            BEP721Contract.methods
                                .saleToken(
                                    item.tokenowners_current.tokenOwner,
                                    item.tokenCounts,
                                    convert(sendAmount),
                                    tokenaddress
                                )
                                .send({
                                    from: props.Accounts,
                                    //value: temp
                                    value: MultipleWei

                                })
                                .then(async (result) => {
                                    Set_FormSubmitLoading('done');
                                    var postData = {
                                        tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                        UserAccountAddr: UserAccountAddr, // new owner
                                        tokenCounts: item.tokenCounts,
                                        tokenType: item.type,
                                        NoOfToken: item.type == 721 ? 1 : NoOfToken,
                                        transactionHash: result.transactionHash,
                                        PurchaseCurrency: PurchaseCurrency,
                                        burnorboui:burnorboui
                                    }
                                    var Resp = await PurchaseNow_Complete_Action(postData);
                                    if (Resp.data && Resp.data.toast && Resp.data.toast.type == 'success') {
                                        toast.success("Collectible purchase successfully", toasterOption)
                                        window.$('.modal').modal('hide');
                                        setTimeout(() => { history.push("/my-items") }, 2000);
                                    }
                                })
                                .catch((error) => {
                                    Set_FormSubmitLoading('error');
                                    toast.error('Order not placed', toasterOption);
                                })
                        } else {
                            var tokenaddress= PurchaseCurrency==config.currencySymbol?
                            config.multipleContract
                            :
                            PurchaseCurrency==config.owntokenSymbol?
                            config.bouitokenAddr
                            :
                            PurchaseCurrency==config.usdtokenSymbol?
                            config.busdtokenAddr
                            :"";
                            var sendAmount = (tokenPrice * config.decimalvalues).toString();
                            var lastAmt=(sendAmount * NoOfToken).toString()
                            BEP1155Contract.methods
                                .saleToken(
                                    item.tokenowners_current.tokenOwner,
                                    item.tokenCounts,
                                    convert(lastAmt),
                                    NoOfToken,
                                    tokenaddress
                                )
                                .send({
                                    from: props.Accounts,
                                    value: MultipleWei
                                })
                                .then(async (result) => {
                                    Set_FormSubmitLoading('done');
                                    var postData = {
                                        tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                        UserAccountAddr: UserAccountAddr, // new owner
                                        tokenCounts: item.tokenCounts,
                                        tokenType: item.type,
                                        NoOfToken: item.type == 721 ? 1 : NoOfToken,
                                        transactionHash: result.transactionHash,
                                        burnorboui:burnorboui
                                    }
                                    var Resp = await PurchaseNow_Complete_Action(postData);
                                    if (Resp.data && Resp.data.toast && Resp.data.toast.type == 'success') {
                                        toast.success("Collectible purchase successfully", toasterOption)
                                        window.$('.modal').modal('hide');
                                        setTimeout(() => { history.push("/my-items") }, 2000);
                                    }
                                })
                                .catch((error) => {
                                    Set_FormSubmitLoading('error');
                                    toast.error('Order not placed', toasterOption);
                                })
                        }
                    } else {
                        toast.error('Oops something went wrong.!', toasterOption);
                    }
                }
            }
        }
    }

    async function FormSubmit_StepOne(){
        if(window.ethereum) {
            var web3 = new Web3(window.ethereum)
            if(
                web3
                && web3.eth
            ) {
                setApproveCallStatus('processing');
                if(item.type==721){
                var CoursetroContract = PurchaseCurrency == config.owntokenSymbol ?
                                         BOUIContract 
                                        :
                                        PurchaseCurrency == config.usdtokenSymbol ?
                                         BUSDContract 
                                        :
                                        PurchaseCurrency == config.currencySymbol ?
                                        BEP721Contract 
                                        : 
                                        BOUIContract
                }else{
                    var CoursetroContract = PurchaseCurrency == config.owntokenSymbol ?
                    BOUIContract 
                   :
                   PurchaseCurrency == config.usdtokenSymbol ?
                    BUSDContract 
                   :
                   PurchaseCurrency == config.currencySymbol ?
                   BEP1155Contract 
                   : 
                   BOUIContract
                }

                var currAddr           = await getCurAddr();
                console.log(currAddr,"current address");
                    if(item.type==721){
                    if(PurchaseCurrency==config.owntokenSymbol){
                        var getAllowance       = await BOUIContract.methods.allowance(currAddr,config.singleContract).call();
                    }else if(PurchaseCurrency==config.usdtokenSymbol){
                        var getAllowance       = await BUSDContract.methods.allowance(currAddr,config.singleContract).call();
                    }
                    else{
                        var getAllowance       = await DETHContract.methods.allowance(currAddr,config.singleContract).call();
                    }
                    }else{
                    if(PurchaseCurrency==config.owntokenSymbol){
                        var getAllowance       = await BOUIContract.methods.allowance(currAddr,config.multipleContract).call();
                    }else if(PurchaseCurrency==config.usdtokenSymbol){
                        var getAllowance       = await BUSDContract.methods.allowance(currAddr,config.singleContract).call();
                    }
                    else{
                        var getAllowance       = await DETHContract.methods.allowance(currAddr,config.multipleContract).call();
                    }                
            }
                const Contractaddress  = item.contractAddress;
                console.log(MultipleWei,convert(MultipleWei),convert(getAllowance),"input");
                var sendVal=parseInt(convert(MultipleWei))
                if(getAllowance > 0 ) {
                        var sendVal=parseInt(convert(MultipleWei))+parseInt(convert(getAllowance));

                }
                // var sendVal            = parseInt(MultipleWei)+parseInt(getAllowance);
                // console.log(sendVal.toString(),"sendVal");
                // sendVal = sendVal.toString();
                // let data = web3.utils.toWei(sendVal,'ether');
                console.log(MultipleWei,getAllowance,convert(sendVal),"data");
                await CoursetroContract.methods
                    .approve(
                        Contractaddress,
                       convert(sendVal)
                    )
                    .send({
                        from: props.Accounts,
                    })
                    .then(async (result) => {
                        setApproveCallStatus('done');
                    })
                    .catch((error) => {
                        setApproveCallStatus('tryagain');
                        console.log('error : ', error);
                        toast.error('Order not approved', toasterOption);
                    })
            }
        }
    }

    async function FormSubmit_StepTwo(){
        if(window.ethereum) {
            var web3 = new Web3(window.ethereum)
            if(
                web3
                && web3.eth
            ) {
                var web3                 = new Web3(window.ethereum);
               if(PurchaseCurrency==config.owntokenSymbol){
                var BOUIContract         = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
                var currAddr             = window.web3.eth.defaultAccount;
                var tokenBal             = await BOUIContract.methods.balanceOf(currAddr).call();
                var tokenBalance         = tokenBal / config.decimalvalues;
               }else if(PurchaseCurrency==config.usdtokenSymbol){
                var BUSDContract         = new web3.eth.Contract(BUSD_ABI, config.busdtokenAddr);
                var currAddr             = window.web3.eth.defaultAccount;
                var tokenBal             = await BUSDContract.methods.balanceOf(currAddr).call();
                var tokenBalance         = tokenBal / config.decimalvalues;
               }
               else{
                var DETHContract         = new web3.eth.Contract(DETH, config.tokenAddress);
                var currAddr             = window.web3.eth.defaultAccount;
                var tokenBal             = await DETHContract.methods.balanceOf(currAddr).call();
                var tokenBalance         = tokenBal / config.decimalvalues;
               }
             
                if(tokenBalance==0){
                    toast.error(PurchaseCurrency+" Token Balance Insufficient", toasterOption)
                    return false;
                }
                if(YouWillPay>=tokenBalance){
                    toast.error(PurchaseCurrency+" Token Balance Insufficient", toasterOption)
                    return false;
                }
                if (item.type == 721) {
                    const BEP721Contract = new web3.eth.Contract(BEP721, item.contractAddress)
                    var sendAmount = (item && item.tokenowners_current.tokenPrice * config.decimalvalues).toString();
                    setPurchaseCallStatus('processing');
                    var tokenaddress= PurchaseCurrency==config.tokenSymbol?
                    config.tokenAddress
                    :
                    PurchaseCurrency==config.usdtokenSymbol?
                    config.busdtokenAddr
                    :
                    PurchaseCurrency==config.owntokenSymbol?
                    config.bouitokenAddr:"";
                    BEP721Contract.methods
                        .salewithToken(
                            PurchaseCurrency==config.owntokenSymbol?
                            config.owntokenSymbol
                            :
                            PurchaseCurrency==config.usdtokenSymbol?
                            config.usdtokenSymbol
                            :
                            config.currencySymbol,
                            item.tokenowners_current.tokenOwner,
                            item.tokenCounts,
                            convert(sendAmount),
                            tokenaddress
                        )
                        .send({
                            from: props.Accounts
                        })
                        .then(async (result) => {
                            setPurchaseCallStatus('done');
                            var postData = {
                                tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                UserAccountAddr: UserAccountAddr, // new owner
                                tokenCounts: item.tokenCounts,
                                tokenType: item.type,
                                NoOfToken: item.type == 721 ? 1 : NoOfToken,
                                transactionHash: result.transactionHash,
                                PurchaseCurrency: PurchaseCurrency,
                                burnorboui:burnorboui
                            }
                            var Resp = await PurchaseNow_Complete_Action(postData);
                            if (Resp.data && Resp.data.toast && Resp.data.toast.type == 'success') {
                                toast.success("Collectible purchase successfully", toasterOption)
                                window.$('.modal').modal('hide');
                                setTimeout(() => { history.push("/my-items") }, 2000);
                            }
                        })
                        .catch((error) => {
                            setPurchaseCallStatus('tryagain');
                            toast.error('Order not placed', toasterOption);
                        })
                } else {
                    const BEP1155Contract = new web3.eth.Contract(BEP1155, item.contractAddress)
                    var sendAmount = (item && item.tokenowners_current.tokenPrice * config.decimalvalues).toString();
                    var lastAmt=(sendAmount * NoOfToken).toString();
                    setPurchaseCallStatus('processing');
                    var tokenaddress= PurchaseCurrency==config.tokenSymbol?
                    config.tokenAddress
                    :
                    PurchaseCurrency==config.usdtokenSymbol?
                    config.busdtokenAddr
                    :
                    PurchaseCurrency==config.owntokenSymbol?
                    config.bouitokenAddr:"";
                    BEP1155Contract.methods
                        .saleWithToken(
                            item.tokenowners_current.tokenOwner,
                            item.tokenCounts,
                            convert(lastAmt),
                            NoOfToken,
                            biddingtokenaddress==config.bouitokenAddr?
                            config.owntokenSymbol
                            :
                            biddingtokenaddress==config.busdtokenAddr?
                            config.usdtokenSymbol
                            :
                            config.currencySymbol,
                            tokenaddress
                            )
                        .send({
                            from: props.Accounts
                        })
                        .then(async (result) => {
                            setPurchaseCallStatus('done');
                            var postData = {
                                tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                UserAccountAddr: UserAccountAddr, // new owner
                                tokenCounts: item.tokenCounts,
                                tokenType: item.type,
                                NoOfToken: NoOfToken,
                                transactionHash: result.transactionHash,
                                PurchaseCurrency: PurchaseCurrency,
                                burnorboui:burnorboui

                            }
                            var Resp = await PurchaseNow_Complete_Action(postData);
                            if (Resp.data && Resp.data.toast && Resp.data.toast.type == 'success') {
                                toast.success("Collectible purchase successfully", toasterOption)
                                window.$('.modal').modal('hide');
                                setTimeout(() => { history.push("/my-items") }, 2000);
                            }
                        })
                        .catch((error) => {
                            setPurchaseCallStatus('tryagain');
                            toast.error('Order not placed', toasterOption);
                        })
                }
            }
        }
    }


    async function getServicefees(){
        try{
          if (window.ethereum) {
            var web3 = new Web3(window.ethereum);
            if(item.type==721){
              var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                if(PurchaseCurrency==config.currencySymbol){
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  } else if(PurchaseCurrency==config.owntokenSymbol){
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  }
                  else if(PurchaseCurrency==config.usdtokenSymbol){
                    var fee = await CoursetroContract.methods.getBUSDFee().call();
                  }
                  else{
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  }
                var newPrice    = item.tokenowners_current.tokenPrice*config.decimalvalues 
                var per         = (newPrice * fee) / 1e20;
                var mulWei      = newPrice + per;
                Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
            }else{
              var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                if(PurchaseCurrency==config.currencySymbol){
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  } else if(PurchaseCurrency==config.owntokenSymbol){
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  } else if(PurchaseCurrency==config.usdtokenSymbol){
                    var fee = await CoursetroContract.methods.getBUSDFee().call();
                  }
                  else{
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                  }
                  var newPrice    = NoOfToken *(item.tokenowners_current.tokenPrice *config.decimalvalues);
                    var per         = (newPrice * fee) / 1e20;
                    var mulWei      = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
            }
            var feeValue    = fee/config.decimalvalues;
            setservicefee(feeValue);
          }
        }catch(err){
        }
      }

    useEffect(() => {
        getTokendet()
        getServicefees()
        Set_ValidateError({});
        ItemValidation({TokenPrice:TokenPrice});
    }, [
        TokenPrice
    ]);

    async function getTokendet(){
        try{
            if (window.ethereum) {
                var web3           = new Web3(window.ethereum);
                var curAddr        = await getCurAddr();
                var BOUIContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
                var tokenBal       = await BOUIContract.methods.balanceOf(curAddr).call();
                var fee            = await BUSDContract.methods.getBUSDFee().call();
                var busdval=fee/config.decimalvalues;;
                var val=tokenBal/config.decimalvalues;
                setBusdbal(busdval);
                Set_dethBln(val)
            }
        
        }catch(err){}
    }

    useImperativeHandle(
        ref,
        () => ({
            async PurchaseNow_Click(item, BuyOwnerDetail={}) {
                // console.log("BuyOwnerDetail",BuyOwnerDetail)
                var connectwallet=localStorage.getItem("tfniuobnft");
                
                if(!connectwallet){
                  toast.error("Please connect to a Metamask wallet", toasterOption);
                  return false;
                }
                var ReqData = { addr : UserAccountAddr }
                console.log(UserAccountAddr,"useraccount");
                var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
                console.log(Resp.data.data.User.allowstatus,'status')
                if (Resp && Resp.data && Resp.data.data) {      
                    if(Resp.data.data.User.allowstatus==false){
                        window.$('#profile_modal').modal('show');
                    }else{
                        setTimeout(() => window.$('#PurchaseNow_modal').modal('show')
                        , 600);

                    }
                }

                if (BuyOwnerDetail && typeof BuyOwnerDetail.tokenOwner != 'undefined') {
                    item.tokenowners_current = {};
                    item.tokenowners_current = BuyOwnerDetail;
                    setPurchaseCurrency(BuyOwnerDetail.biddingtoken)
                    setSaletoken(BuyOwnerDetail.balance)
                }
                setItemtype(BuyOwnerDetail.type)
                PriceCalculate({quantity:1,
                    price:BuyOwnerDetail.tokenPrice,
                    itemtype:BuyOwnerDetail.type,
                    PurchaseCurrency:BuyOwnerDetail.biddingtoken});
                var configFee = 0
                if (BuyOwnerDetail.biddingtoken == config.currencySymbol ) {
                    if(BuyOwnerDetail.type==721){
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    = BuyOwnerDetail.tokenPrice*config.decimalvalues 
                        var per         = (newPrice * fee) / 1e20;
                        var mulWei      = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                      }else{
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    = NoOfToken *(BuyOwnerDetail.tokenPrice *config.decimalvalues);
                        var per         = (newPrice * fee) / 1e20;
                        var mulWei      = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                      }
                    
                    setservicefee(configFee);
                    set_feeperc(configFee);
                    Set_MultipleWei(mulWei);
                    Set_Price(newPrice)
                }else  if (BuyOwnerDetail.biddingtoken == config.owntokenSymbol ) {
                    if(BuyOwnerDetail.type==721){
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                        var fee = await CoursetroContract.methods.getBouiFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    = BuyOwnerDetail.tokenPrice*config.decimalvalues
                        var per         = (newPrice * fee) / 1e20;
                        var mulWei      = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues));
                      }else{
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                        var fee = await CoursetroContract.methods.getBouiFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    = NoOfToken *(BuyOwnerDetail.tokenPrice*config.decimalvalues);
                    var per         = (newPrice * fee) / 1e20;
                    var mulWei      = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues));
                      }
                    setservicefee(configFee);
                    set_feeperc(configFee);
                   
                }else  if (BuyOwnerDetail.biddingtoken == config.usdtokenSymbol ) {
                    if(BuyOwnerDetail.type==721){
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                        var fee = await CoursetroContract.methods.getBUSDFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    = BuyOwnerDetail.tokenPrice*config.decimalvalues;
                        var per         = (newPrice * fee) / 1e20;
                        var mulWei      = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues));
                      }else{
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                        var fee = await CoursetroContract.methods.getBUSDFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    = NoOfToken *(BuyOwnerDetail.tokenPrice*config.decimalvalues);
                        var per         = (newPrice * fee) / 1e20;
                        var mulWei      = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues));
                      }
                    
                    setservicefee(configFee);
                    set_feeperc(configFee);
                    
                }else  {
                    if(BuyOwnerDetail.type==721){
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                          if(BuyOwnerDetail.biddingtoken==config.currencySymbol){
                              var fee = await CoursetroContract.methods.getServiceFee().call();
                            } else if(BuyOwnerDetail.biddingtoken==config.owntokenSymbol){
                              var fee = await CoursetroContract.methods.getBouiFee().call();
                            } else if(BuyOwnerDetail.biddingtoken==config.usdtokenSymbol){
                                var fee = await CoursetroContract.methods.getBUSDFee().call();
                              }
                            else{
                              var fee = await CoursetroContract.methods.getServiceFee().call();
                            }
                      }else{
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                          if(BuyOwnerDetail==config.currencySymbol){
                              var fee = await CoursetroContract.methods.getServiceFee().call();
                            } else if(BuyOwnerDetail.biddingtoken==config.owntokenSymbol){
                              var fee = await CoursetroContract.methods.getBouiFee().call();
                            }else if(BuyOwnerDetail.biddingtoken==config.usdtokenSymbol){
                                var fee = await CoursetroContract.methods.getBUSDFee().call();
                              }
                            else{
                              var fee = await CoursetroContract.methods.getServiceFee().call();
                            }
                      }
                    configFee = fee / config.decimalvalues;
                    setservicefee(configFee);
                    set_feeperc(configFee);
                    var newPrice    = BuyOwnerDetail.type == 721 ? BuyOwnerDetail.tokenPrice*config.decimalvalues :
                    NoOfToken *(BuyOwnerDetail.tokenPrice*config.decimalvalues);
                    var per         = (newPrice * fee) / 1e20;
                    var mulWei      = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
                } 
                if (BuyOwnerDetail.biddingtoken == config.tokenSymbol) {
                    var web3                = new Web3(window.ethereum);
                    const DETHContract      = new web3.eth.Contract(DETH, config.tokenAddress);
                    var aidibalanceinwei = await DETHContract.methods.balanceOf(props.Accounts).call()
                    set_PurchaseBalance(aidibalanceinwei);
                    var tokenbal=aidibalanceinwei/config.decimalvalues;
                    setBusdbal(tokenbal)
                } else if (BuyOwnerDetail.biddingtoken == config.owntokenSymbol) {
                    var web3             = new Web3(window.ethereum);
                    const BOUI_Contract  = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
                    var wethbalanceinwei = await BOUI_Contract.methods.balanceOf(props.Accounts).call()
                    var wethbalance = wethbalanceinwei / config.decimalvalues
                    set_PurchaseBalance(wethbalance);
                    Set_dethBln(wethbalance)
                }else if (BuyOwnerDetail.biddingtoken == config.usdtokenSymbol) {
                   
                    var web3             = new Web3(window.ethereum);
                    const BUSD_Contract  = new web3.eth.Contract(BUSD_ABI, config.busdtokenAddr);
                    var wethbalanceinwei = await BUSD_Contract.methods.balanceOf(props.Accounts).call()
                    var wethbalance = wethbalanceinwei / config.decimalvalues
                    set_PurchaseBalance(wethbalance)
                    setBusdbal(wethbalance)
                }
                else{
                    var web3             = new Web3(window.ethereum);
                    const BOUI_Contract  = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
                    var tokenbal         = await BOUI_Contract.methods.balanceOf(props.Accounts).call()
                    
                    Set_dethBln(tokenbal/config.decimalvalues)
                    web3.eth.getBalance(props.Accounts)
                    .then(val => {
                        var balance = val / config.decimalvalues;
                        set_PurchaseBalance(balance);
                      
                    })
                }
                if(BuyOwnerDetail && typeof BuyOwnerDetail.tokenOwner != 'undefined') {
                    item.tokenowners_current = {};
                    item.tokenowners_current = BuyOwnerDetail;
                }

                if(item && item.tokenowners_current && item.tokenowners_current.tokenPrice) {
                    Set_item(item);
                    Set_TokenPrice(item.tokenowners_current.tokenPrice);
                    Set_NoOfToken(1);
                    if(item.type==721){
                        var tempbid = item.tokenowners_current.biddingtoken == config.owntokenSymbol?
                                    config.bouitokenAddr
                                    :
                                    item.tokenowners_current.biddingtoken == config.usdtokenSymbol?
                                    config.busdtokenAddr
                                    :
                                    item.tokenowners_current.biddingtoken == config.currencySymbol?
                                    config.singleContract:""    
                    }else{
                        var tempbid = item.tokenowners_current.biddingtoken == config.owntokenSymbol?
                                    config.bouitokenAddr
                                    :
                                    item.tokenowners_current.biddingtoken == config.usdtokenSymbol?
                                    config.busdtokenAddr
                                    :
                                    item.tokenowners_current.biddingtoken == config.currencySymbol?
                                    config.multipleContract:""
    
                    }
                set_biddingtokenaddress(tempbid)
                var configFee = 0
                if (item.tokenowners_current.biddingtoken == config.currencySymbol ) {
                    if(item.tokenowners_current.type==721){
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    =item.tokenowners_current.tokenPrice*config.decimalvalues;
                        var per         = (newPrice * fee) / 1e20;
                        var mulWei      = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues));
                      }else{
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    = NoOfToken *(item.tokenowners_current.tokenPrice*config.decimalvalues);
                        var per         = (newPrice * fee) / 1e20;
                        var mulWei      = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues));
                      }
                    setservicefee(configFee);
                    set_feeperc(configFee);
                    
                } 
                else if (item.tokenowners_current.biddingtoken == config.owntokenSymbol){
                    if(item.tokenowners_current.type==721){
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                        var fee = await CoursetroContract.methods.getBouiFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    =item.tokenowners_current.tokenPrice*config.decimalvalues;
                        var per         = (newPrice * fee) / 1e20;
                        var mulWei      = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues));
                      }else{
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                        var fee = await CoursetroContract.methods.getBouiFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    = NoOfToken *(item.tokenowners_current.tokenPrice*config.decimalvalues);
                        var per         = (newPrice * fee) / 1e20;
                        var mulWei      = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues));
                      }
                    setservicefee(configFee);
                    set_feeperc(configFee);
                } else if (item.tokenowners_current.biddingtoken == config.usdtokenSymbol){
                    if(item.tokenowners_current.type==721){
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                        var fee = await CoursetroContract.methods.getBUSDFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    =item.tokenowners_current.tokenPrice*config.decimalvalues;
                        var per         = (newPrice * fee) / 1e20;
                        var mulWei      = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues));
                      }else{
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                        var fee = await CoursetroContract.methods.getBUSDFee().call();
                        configFee = fee / config.decimalvalues;
                        var newPrice    = NoOfToken *(item.tokenowners_current.tokenPrice*config.decimalvalues);
                        var per         = (newPrice * fee) / 1e20;
                        var mulWei      = newPrice + per;
                        Set_YouWillPay((mulWei / config.decimalvalues));
                      }
                    setservicefee(configFee);
                    set_feeperc(configFee);
                   
                }
                else {
                    if(item.tokenowners_current.type==721){
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                          if(item.tokenowners_current.biddingtoken==config.currencySymbol){
                              var fee = await CoursetroContract.methods.getServiceFee().call();
                            } else if(item.tokenowners_current.biddingtoken==config.owntokenSymbol){
                              var fee = await CoursetroContract.methods.getBouiFee().call();
                            } else if(item.tokenowners_current.biddingtoken==config.usdtokenSymbol){
                                var fee = await CoursetroContract.methods.getBUSDFee().call();
                              }
                            else{
                              var fee = await CoursetroContract.methods.getServiceFee().call();
                            }
                      }else{
                        var web3           = new Web3(window.ethereum);
                        var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
                          if(item.tokenowners_current.biddingtoken==config.currencySymbol){
                              var fee = await CoursetroContract.methods.getServiceFee().call();
                            } else if(item.tokenowners_current.biddingtoken==config.owntokenSymbol){
                              var fee = await CoursetroContract.methods.getBouiFee().call();
                            } else if(item.tokenowners_current.biddingtoken==config.usdtokenSymbol){
                                var fee = await CoursetroContract.methods.getBUSDFee().call();
                              }
                            else{
                              var fee = await CoursetroContract.methods.getServiceFee().call();
                            }
                      }
                    configFee = fee / config.decimalvalues;
                    setservicefee(configFee);
                    set_feeperc(configFee);
                    var newPrice    = item.tokenowners_current.type == 721 ?
                    item.tokenowners_current.tokenPrice*config.decimalvalues
                    :
                    NoOfToken *(item.tokenowners_current.tokenPrice*config.decimalvalues);
                    var per         = (newPrice * fee) / 1e20;
                    var mulWei      = newPrice + per;
                    Set_YouWillPay((mulWei / config.decimalvalues));
                }
               
                if (item.tokenowners_current.biddingtoken == config.tokenSymbol) {
                    var web3                = new Web3(window.ethereum);
                    const DETHContract      = new web3.eth.Contract(DETH, config.tokenAddress);
                    var aidibalanceinwei = await DETHContract.methods.balanceOf(props.Accounts).call()
                    set_PurchaseBalance(aidibalanceinwei)
                } else if (item.tokenowners_current.biddingtoken == config.owntokenSymbol) {
                    var web3             = new Web3(window.ethereum);
                    const BOUI_Contract  = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
                    var wethbalanceinwei = await BOUI_Contract.methods.balanceOf(props.Accounts).call()
                    var wethbalance = wethbalanceinwei / config.decimalvalues
                    set_PurchaseBalance(wethbalance)
                }else if (item.tokenowners_current.biddingtoken == config.usdtokenSymbol) {
                    var web3             = new Web3(window.ethereum);
                    const BUSD_Contract  = new web3.eth.Contract(BUSD_ABI, config.busdtokenAddr);
                    var wethbalanceinwei = await BUSD_Contract.methods.balanceOf(props.Accounts).call()
                    var wethbalance = wethbalanceinwei / config.decimalvalues
                    set_PurchaseBalance(wethbalance)
                }
                else{
                    var web3             = new Web3(window.ethereum);
                    web3.eth.getBalance(props.Accounts)
                    .then(val => {
                        var balance = val / config.decimalvalues;
                        set_PurchaseBalance(balance)
                    })
                }
                    // PriceCalculate({quantity:1,price:item.tokenowners_current.tokenPrice,type:item.type});
                   
                }
                
            }
        }),
    )

async function handleChange(event){
    setBurnorboui(event.target.value)
}

    return (
        <div>
            <div class="modal fade primary_modal PurchaseNow_modal" id="PurchaseNow_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center">
                    <h5 class="modal-title" id="buy_modalLabel">Checkout</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close9" onClick={(buymodal)}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body px-0">
                        <div className="row mx-0">
                            <div className="col-12 col-sm-3 px-4">
                                <p className="buy_title_sm">Owner</p>
                            </div>
                            <div className="col-12 col-md-6 px-4">
                            <p className="buy_title_sm">  {item.userprofile && item.userprofile.name? item.userprofile && 
                                item.userprofile.name : item&&item.tokenowners_current
                                &&halfAddrShow(item.tokenowners_current.tokenOwner)}</p>
                            </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-md-3 px-4">
                                <p className="buy_title_sm">Buyer</p>
                            </div>
                            <div className="col-12 col-md-6 px-4">
                            <p className="buy_title_sm"> {MyItemAccountAddr ? halfAddrShow(MyItemAccountAddr) : halfAddrShow(UserAccountAddr)}</p>
                            </div>
                        </div>
                        <div className="bor_bot_modal mb-3 px-4 "></div>
                        <form className="bid_form" action="#">
                                        {item.type == 721 ?(''):
                            <div className="bor_bot_modal mb-3 px-4 ">
                                <label for="qty">Quantity{" "}({saletokenbal} available)</label>
                                <div class="mb-3 input_grp_style_1">
                                    <input
                                        type="number"
                                        min="1"
                                        className="form-control primary_inp text-center"
                                        name="NoOfToken"
                                        id="NoOfToken"
                                        onChange={inputChange}
                                        placeholder="e.g. 2"
                                        autoComplete="off"
                                        value={NoOfToken}
                                        onKeyDown={(evt) => ["e", "E", "+", "-",".",","].includes(evt.key) && evt.preventDefault()}
                                    />
                                    {ValidateError.NoOfToken && <span className="text-danger">{ValidateError.NoOfToken}</span>}
                                    {!ValidateError.NoOfToken && ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                </div>
                            </div>}
                        </form>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Your balance</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{UserAccountBal.toFixed(8) } {config.currencySymbol}</p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Your token balance</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{
                                    PurchaseCurrency==config.currencySymbol?
                                    dethBln
                                    :
                                    PurchaseCurrency==config.owntokenSymbol?
                                    dethBln
                                     :
                                     busdbal
                                } 
                                {PurchaseCurrency==config.currencySymbol?
                                    config.owntokenSymbol
                                    :
                                PurchaseCurrency==config.usdtokenSymbol?
                                    config.usdtokenSymbol
                                    :
                                    PurchaseCurrency
                                }</p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Price</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{TokenPrice} {PurchaseCurrency}</p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Burn or Boui</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                               <Select onChange={handleChange} defaultValue={burnorboui}>
                                    <option value="burn">Burn</option>
                                    <option value="boui">Boui</option>
                                </Select>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Service fee</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{servicefee}% <span>{PurchaseCurrency}</span></p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">You will pay</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{YouWillPay} <span>{PurchaseCurrency}</span></p>
                            </div>
                        </div>

                    {/* <ul>
                      <li onClick={e => handleClick(e)} name="coffee" value="coffee">
                         <img src={require("../../assets/images/follower_1.png")} alt="logo" className="img-fluid" />
                        Coffee
                        </li>
                    </ul> */}
                        <form className="px-4">
                            <div className="text-center">
                                <Button
                                    type="button"
                                    className="primary_btn btn-block"
                                    onClick={() => FormSubmit()}
                                    disabled={(FormSubmitLoading=='processing')}
                                >
                                    {FormSubmitLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                    Proceed to payment
                                </Button>
                                <Button className="cancel_btn  btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>
            <div class="modal fade primary_modal PurchaseStep_modal" id="PurchaseStep_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="PurchaseStepCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                        <h5 class="modal-title" id="PurchaseStepLabel">Follow Steps</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="text-center">
                                    <p className="mt-3 purchase_desc text-center">Approve the transaction</p>
                                    <Button
                                        type="button"
                                        onClick={() => FormSubmit_StepOne()}
                                        className={"primary_btn " + ( (ApproveCallStatus=='processing' || ApproveCallStatus=='done') ? 'cancel_btn' : 'btn-block')}
                                        disabled={(ApproveCallStatus=='processing' || ApproveCallStatus=='done')}
                                    >
                                        {ApproveCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {ApproveCallStatus == 'init' && 'Approve'}
                                        {ApproveCallStatus == 'processing' && 'In-progress...'}
                                        {ApproveCallStatus == 'done' && 'Done'}
                                        {ApproveCallStatus == 'tryagain' && 'Try Again'}
                                    </Button>
                                </div>
                                <div className="text-center my-3">
                                <p className="mt-3 purchase_desc text-center">Send transaction with your wallet</p>
                                    <Button
                                        type="button"
                                        onClick={() => FormSubmit_StepTwo()}
                                        className={"primary_btn " + ( (ApproveCallStatus!='done' || PurchaseCallStatus=='processing' || PurchaseCallStatus=='done') ? 'cancel_btn' : 'btn-block')}
                                        disabled={(ApproveCallStatus!='done' || PurchaseCallStatus=='processing' || PurchaseCallStatus=='done')}
                                    >
                                        {PurchaseCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {PurchaseCallStatus == 'init' && 'Purchase'}
                                        {PurchaseCallStatus == 'processing' && 'In-progress...'}
                                        {PurchaseCallStatus == 'done' && 'Done'}
                                        {PurchaseCallStatus == 'tryagain' && 'Try Again'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

