import React, {useEffect,useState,useRef} from "react";
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom'

import Onboard from 'bnc-onboard'
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";

import $ from 'jquery';
import axios from 'axios';
import config from '../../lib/config';
import LoderImg from '../../assets/dev/images/loader.gif'
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Modal from 'react-modal';
// https://data-seed-prebsc-1-s1.binance.org:8545/
import {
  AddLikeAction,
  GetLikeDataAction
} from '../../actions/v1/token';
import {
  AddressUserDetails_GetOrSave_Action,
  Collectibles_Get_Action,
  changeReceiptStatus_Action
} from '../../actions/v1/user';
import { toast } from 'react-toastify';
import { AlternateEmail } from "@material-ui/icons";
import {getmyprovider} from "../../lib/walletconnect";
toast.configure();
let toasterOption = config.toasterOption;

const useStyles = makeStyles(styles);

export default function ConnectWallet(props) {
  const [Provider, setProvider] = useState();
  const classes = useStyles();
  const { ...rest } = props;
  var safeprovider="";

  const {
    WalletConnected
  } = props;

  const timerRef = useRef(null);

  useEffect(() => {
    getInit();
  }, []);
  async function getInit() {
    if(window.web3){
      let web3 = new Web3(window.web3.currentProvider)
      let chainid =await web3.eth.getChainId();
      chainid = chainid.toString()
      console.log(chainid,'version');
      if (
        chainid!=
        config.networkVersion
      ) {
        toast.warn("Please connect Binance Mainnet", toasterOption);
      }
    }
     safeprovider = await getmyprovider();
    console.log("proof")
    await setProvider(safeprovider.provider);
    
    connect_Wallet('metamask');
    
  }

  let web3
  // head to blocknative.com to create a key
  const BLOCKNATIVE_KEY = 'blocknative-api-key'
  // the network id that your dapp runs on
  const NETWORK_ID = 1

  async function connect_Wallet(type = 'metamask') {
    window.$('.modal').modal('hide');
    if(type == 'Onboard') {
      try {
        const onboard = Onboard({
          dappId: BLOCKNATIVE_KEY,
          networkId: NETWORK_ID,
          subscriptions: {
            wallet: wallet => {
              // instantiate web3 when the user has selected a wallet
              web3 = new Web3(wallet.provider)
              console.log(`${wallet.name} connected!`);
            }
          }
        })
        console.log('onboard', onboard);
      }
      catch(err) {
        console.log('err', err);
      }
    }
    else {
      if(type == 'walletconnect') {
        var provider = new WalletConnectProvider({
          infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
        });
      }
      else if(type == 'metamask') {
        console.log(">>>>>>>>>>>>>>>>>>>>>>metamask");
        console.log(safeprovider,'>>>>>>>>>>>>>>>>>>>>>>provider')
        var provider = safeprovider.provider;
        var web3 = new Web3(provider);
        if (typeof web3 !== 'undefined') {
          // 
          console.log(">>>>>>>>>>>>>>>>>>>>>>provider get");
        }
        else {
          props.Set_WalletConnected(false);
          toast.warning("Connect to Binance Network", toasterOption);
          return false;
        }
        
      }

      if (provider) {
        console.log(">>>>>>>>>>>>>>>>>>>>>>innerset");
        try {
          console.log(">>>>>>>>>>>>>>>>>>>>>>provider");
          // provider.enable()
          // .then(async function () {
            console.log(">>>>>>>>>>>>>>>>>>>>>>inner");
            const web3 = new Web3(provider)
            var network = await web3.eth.net.getId();
            if(network == config.networkVersion){
              // if (window.web3.currentProvider.isMetaMask === true) {
                if(web3 && web3.eth) {
                  var currAddr ="";
                  currAddr =await web3.eth.getAccounts();
                  if(currAddr){
                  currAddr = currAddr[0].toLowerCase();
                  }else{
                    currAddr = "";
                  }
                  console.log(currAddr,"currAddrwalllet")
                  props.Set_UserAccountAddr(currAddr);
                  props.Set_WalletConnected(true);
                  var result ="";
                  result = await web3.eth.getAccounts();
                  if(currAddr){
                    result = result[0];
                    }else{
                      result = "";
                    }
                  console.log(result,">>>>>>>>>>>>>>>>>>>>>>result")
                  var setacc = result.toLowerCase();
                  
                  props.Set_Accounts(setacc);
                  console.log(">>>>>>>>>>>>>>>>>>>>>>inner2",setacc)
                  var balance = 0;
                  if(result !=""){
                    console.log(">>>>>>>>>>>>>>result", result);
                    try{
                  var val= await web3.eth.getBalance(result)
                  console.log(">>>>>>>value",val)
                    }catch(err){
                      console.log(err,"error");
                    }
                     balance = val / 1000000000000000000;
                    console.log(">>>>>>>>>>>>>>>>>>>>>>balance",balance)
                  }
                    props.Set_UserAccountBal(balance);
                    AfterWalletConnected();
                  console.log(">>>>>>>>>>>>>>>>>>>>>>outer")

                }
              // }
            }
            else {
              props.Set_WalletConnected(false);
              toast.warning("Please Connect to binance Network", toasterOption);
            }
          // })
          // .catch((e) => {
          //   props.Set_WalletConnected(false);
          //   toast.warning("Please Connect to binance Network", toasterOption);
          // })
        } catch (err) {
          props.Set_WalletConnected(false);
        }
      }
      else {
        props.Set_WalletConnected(false);
        toast.warning("Please Add Metamask External", toasterOption);
      }
    }

  }
  async function AfterWalletConnected() {
    await AddressUserDetails_GetOrSave_Call();
    props.AfterWalletConnected();
  }
  async function AddressUserDetails_GetOrSave_Call() {
    var addr ="";
    if(window.web3){
    addr = window.web3.eth.getAccounts();
    if(addr){
      addr =addr[0].toLowerCase();
    }else{
      addr ="";
    }
    }
    var ReqData = { addr : addr }
    var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    if (Resp && Resp.data && Resp.data.data) {      
      props.Set_AddressUserDetails(Resp.data.data.User);
    } else {
      props.Set_AddressUserDetails({});
    }
    return true;
  }

  window.addEventListener('load', (event) => {
    event.preventDefault();
    if(window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.reload(false);
        if(timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          connect_Wallet();
        }, 1000);
      })

      window.ethereum.on('networkChanged', function (networkId) {
        if(networkId == config.networkVersion){
          window.location.reload(false);
          if(timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            connect_Wallet();
          }, 1000);
          props.Set_WalletConnected(true);
        }
        else {
          props.Set_WalletConnected(false);
        }
      })
    }
  })

  try{
    if(window.ethereum){
    window.ethereum.on('accountsChanged', function (accounts) {
      window.location.reload(false)
      if(timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        connect_Wallet();
      }, 1000);
    })
  }
  }catch(err){

  }
try{
  if(window.ethereum){
  window.ethereum.on('networkChanged', function (networkId) {
    if(networkId == config.networkVersion){
      window.location.reload(false)
      if(timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        connect_Wallet();
      }, 1000);
      props.Set_WalletConnected(true);
    }
    else {
      props.Set_WalletConnected(false);
    }
  })
}
}catch(err){

}
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  let subtitle;
  const [WalletConnectNotifyPopup, Set_WalletConnectNotifyPopup] = React.useState(false);

  function openModal() {
    Set_WalletConnectNotifyPopup(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    Set_WalletConnectNotifyPopup(false);
  }

  var WalletConnectNotifyPopup_test = '';

  if(WalletConnected) {
    WalletConnectNotifyPopup_test = false;
  }
  else {
    WalletConnectNotifyPopup_test = true;
  }

  var pathVal = '';

  const location = useLocation();
  if(location.pathname) {
    if(location.pathname.split('/').length >= 2) {
      pathVal = location.pathname.split('/')[1];
    }
  }

  const [location_pathname, Set_location_pathname] = useState(pathVal);

  
  return (
    <div>
   {props.fullLoader && <div class="loading"></div>}
      {/* {(
        (WalletConnected == false || WalletConnected == 'false')
        &&
        (
          location_pathname == 'my-items'
          || location_pathname == 'following'
          || location_pathname == 'activity'
          || location_pathname == 'info'
        )
      ) &&
      <Modal
        isOpen={true}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modaltest" style={{opacity:2, position:'relative'}}>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Network</h2>
          <div>Connect to Binance Network.</div>
        </div>
      </Modal>
      } */}
      <div id="connect_Wallet_call" ></div>

    </div>

    
  )
}