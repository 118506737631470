import React, {
    forwardRef,
    useImperativeHandle
} from 'react';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';

import {
    AddLikeAction,
    GetLikeDataAction
} from '../../actions/v1/token';
import BEP721 from '../../ABI/BEP721.json';
import BEP1155 from '../../ABI/BEP1155.json';
import {
  halfAddrShow,
    getCurAddr
} from '../../actions/v1/user';
import {
    convertion,
    BurnField,
    TokenCounts_Get_Detail_Action
  } from '../../actions/v1/token';
import { Button, TextField } from '@material-ui/core';
import {
    useHistory
} from "react-router-dom";
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
const singleContract = config.singleContract;
const multipleContract = config.multipleContract;

export const BurnRef = forwardRef((props, ref) => {
    const history = useHistory();
    const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
    const [onwer_price,set_owner_price]=React.useState({})
    const [burnLoading, setBurnLoading] = React.useState('empty');
    const[noofitems,setnoofitem]=React.useState(1);
    const [showingLoader, setshowingLoader] = React.useState(false);
    const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = React.useState({});
    const [OwnersDetailFirst, Set_OwnersDetailFirst] = React.useState({});
    const [tokenCounts_Detail, Set_tokenCounts_Detail] = React.useState({});
    const [Bids, Set_Bids] = React.useState({});
    const [MyTokenBalance, Set_MyTokenBalance] = React.useState(0);
    const [MyTokenDetail, Set_MyTokenDetail] = React.useState(0);
    const [Burndata,setBurndata] = React.useState(0);
    var  [item, Set_item] = React.useState({}); 
    var  [item1, Set_item1] = React.useState({}); 

    var {
        item,
        UserAccountAddr,
        GetUserBal,
       // Set_item
    } = props;

    async function FormSubmit(item,Burntoken){
        var connectwallet=localStorage.getItem("tfniuobnft");
        if(!connectwallet){
          window.location.href="/connect";
          return false;
        }
        if(window.ethereum) {
          var web3 = new Web3(window.ethereum);
          if (web3&&web3.eth) {
            if(noofitems == "" ){
              toast.error("Burn token is not allow to empty", toasterOption);
              return false;
            }else  if(noofitems == 0){
              toast.error("Burn token must be greater than zero", toasterOption);
              return false;
            }
           
            if(Burntoken.type==721){
              setBurnLoading('processing');
              var postData = {
                tokenOwner: UserAccountAddr,
                tokenCounts: Burntoken.tokenCounts,
                contractAddress:Burntoken.contractAddress,
                type:Burntoken.type,
                balance:noofitems,
                currAddr:UserAccountAddr,
                quant:Burntoken.balance,
                burnorboui:"burn"
                }
                console.log('postDatafrominfo',postData);
                var updateBurnField = await BurnField(postData)
                if(updateBurnField){
                toast.success('Burned successfully',toasterOption)
                document.getElementById('closeburn').click()
                }
            }else{
              var CoursetroContract = new web3.eth.Contract(BEP1155,multipleContract);
              let contract = (Burntoken.type === config.singleType) ? config.singleContract : config.multipleContract; 
              if(noofitems>MyTokenDetail.balance){
                toast.error('Available Tokens'+" "+MyTokenDetail.balance,toasterOption)
                return false;
              }
              setBurnLoading('processing');
              var postData = {
                tokenOwner: UserAccountAddr,
                tokenCounts: Burntoken.tokenCounts,
                contractAddress:Burntoken.contractAddress,
                type:Burntoken.type,
                balance:noofitems,
                currAddr:UserAccountAddr,
                quant:Burntoken.balance,
                burnorboui:"burn"
                }
                console.log('postDatafrominfo',postData);
                var updateBurnField = await BurnField(postData)
                if(updateBurnField){
                toast.success('Burned successfully',toasterOption)
                 document.getElementById('closeburn').click()
                // history.push('/')
                }
             
            }
             
          }
       }

    }
    const TokenCounts_Get_Detail_Call = async (payload) => {
      var curAddr = await getCurAddr();
      setshowingLoader(true)
      var Resp = await TokenCounts_Get_Detail_Action(payload);
      setTimeout(() => {
        setshowingLoader(false)
      }, 2000);
  
      if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
        var TokenResp = Resp.data.Detail.Resp;
        if (
          TokenResp
          && TokenResp.Token
          && TokenResp.Token[0]
          && TokenResp.Token[0].tokenowners_current
        ) {
          for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
            const element = TokenResp.Token[0].tokenowners_current[i];
            set_owner_price(element)
            if (element.tokenPrice > 0 && element.tokenOwner != curAddr) {
              Set_BuyOwnerDetailFirst(element);
              break;
            }
            if (element.tokenPrice > 0 && element.tokenOwner == curAddr) {
              Set_OwnersDetailFirst(element);
              break;
            }
          }
        }
        Set_tokenCounts_Detail(TokenResp);
        if (TokenResp.Bids) {
          Set_Bids(TokenResp.Bids);
        }
        var IndexVal = -1;
        if (TokenResp.Token[0].tokenowners_all && curAddr) {
          var tokenowners_all = TokenResp.Token[0].tokenowners_all;
          IndexVal = tokenowners_all.findIndex(val => (val.tokenOwner.toString() == curAddr.toString() && val.balance > 0));
        }
        if (IndexVal > -1) {
          Set_MyTokenBalance(tokenowners_all[IndexVal].balance);
          Set_MyTokenDetail(tokenowners_all[IndexVal])
        }
        else {
          Set_MyTokenDetail({});
          Set_MyTokenBalance(0);
        }
  
        if (TokenResp.Token && TokenResp.Token[0]) {
          Set_item1(TokenResp.Token[0]);
        }
      
    }
    }
    const handleChange = (e) => {      
        if(MyTokenDetail>=e && e.target && typeof e.target.value != 'undefined'){
          setnoofitem(e.target.value)
          // setBurnLoading('init');
        }
        else if(e.target.value == 0){
          // setBurnLoading('zero');
          setnoofitem(0)
        }
        else if(e.target.value == "" ){
          // setBurnLoading('empty');
          setnoofitem("")
        }else if(e.target.value == undefined ){
          // setBurnLoading('empty');
          setnoofitem("")
        }
        else{
          // setBurnLoading('errors');
          setnoofitem("")
        }
    }
    // const handleChange = (e) => {
    //   if (e.target && e.target.value)
    //     if(MyTokenDetail>=e.target.value){
    //     setnoofitem(e.target.value)
    //       setBurnLoading('init');}
    //     else if(e.target.value == 0){
    //       setBurnLoading('zero');
    //     }
    //     else if(e.target.value == "" ){
    //       setBurnLoading('empty');
    //     }
    //     else if(e.target.value == undefined ){
    //       setBurnLoading('empty');
    //     }
    //     else{
    //       setBurnLoading('errors');
    //     }
    // }
    useImperativeHandle(
        ref,
        () => ({
            async Burn_Click(item,burndata) {
                console.log('Burn_Click:',burndata);
                setBurndata(burndata);
                var curAddr = await getCurAddr();
                    var payload = {
                      curAddr: curAddr,
                      tokenCounts: item.tokenCounts
                    };
                    TokenCounts_Get_Detail_Call(payload);
                window.$('#burn_token_modal').modal('show');
               
            },
        }),
    )

    return (
        <div class="modal fade primary_modal" id="burn_token_modal" tabindex="-1" role="dialog" aria-labelledby="burn_token_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal" role="document">
		<div class="modal-content">
			<div class="modal-header text-center">
			<h5 class="modal-title" id="burn_token_modalLabel">Burn token</h5>
			<button type="button" id="closeburn" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>
				<span aria-hidden="true">&times;</span>
			</button>
			</div>
			<div class="modal-body px-0">
				<form className="bid_form">
        {Burndata.type!=721&&
        <div className="px-4">
						<p className="checkout_text_light_white text-center" style={{color:'black',fontWeight:'bold'}}>{MyTokenDetail.balance} Tokens Available</p>
					</div> 
}
					<div className="px-4">
						<p className="checkout_text_light_white">
            Are you sure you’d like to “burn”, thus redeeming, your NFT? This action will forfeit your ability to Boui the asset or list it for sale on the marketplace. Burning (redeeming) the NFT will initiate shipment of asset to the shipping address on file. This action cannot be undone
            </p>
					</div>  
					<div className="px-4 mt-4 pt-2">   
          {Burndata.type==721?    
         <div>     
						{/* <input 
							id = "burn" 
							name="burn" 
							class="form-control"
					     value={}
             // onChange = {(e) => { handleChange(e)}}
						/> */}
             <div className="text-center mt-3">
           
                <Button 
								className="burn_btn_red primary_btn btn-block" 
                onClick={(() => FormSubmit(item,MyTokenDetail))}
								>
                  {burnLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
									{burnLoading == 'init' && 'Continue'}
									{burnLoading == 'processing' && 'In-progress...'}
									{burnLoading == 'done' && 'Done'}
									{burnLoading == 'try' && 'Try Again'}
                  {/* {burnLoading == 'errors' && 'Check Balance'} */}
                  {/* {burnLoading == 'zero' && "Qty can't be Zero"} */}
                  {burnLoading == 'empty' && "Continue"} 
                  
								</Button>
              <Button className="btn_outline_grey cancel_btn btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
            </div>
            </div>
            :
            
            <div>
              <input 
              id = "burn" 
              name="burn" 
              type="number"
              autoComplete="off"
              min="1"
              value={noofitems}
              onKeyDown={(evt) => ["e", "E", "+", "-",".",","].includes(evt.key) && evt.preventDefault()}
              class="form-control px-3"
              onChange = {(e) => { handleChange(e)}}
            />
              <div className="text-center mt-3">
              <Button 
                className="burn_btn_red primary_btn btn-block" 
                onClick={(() => FormSubmit(item1,MyTokenDetail))}
                >
                  {burnLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                  {burnLoading == 'init' && 'Continue'}
                  {burnLoading == 'processing' && 'In-progress...'}
                  {burnLoading == 'done' && 'Done'}
                  {burnLoading == 'try' && 'Try Again'}
                  {/* {burnLoading == 'errors' && 'Check Balance'} */}
                  {/* {burnLoading == 'zero' && "Qty can't be Zero"} */}
                  {burnLoading == 'empty' && "Continue"}
                </Button>
              <Button className="btn_outline_grey cancel_btn btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
            </div>
          </div>
        
         }
					
					</div>
				</form>
			</div>
		</div>
		</div>
	</div>
    )
})

