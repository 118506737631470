import React, {useEffect,useState,forwardRef,useRef,useImperativeHandle } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CountUp from 'react-countup';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import DETH_ABI from '../ABI/DETH.json';

import Countdown, { zeroPad } from "react-countdown";
import $ from 'jquery';
import axios from 'axios';
import config from '../lib/config';

import {
    GetItemList,
    GetUserList,
  } from "../actions/items"

  import {
    getsettinglist
  } from '../actions/v1/token';

  import {  
    getCurAddr,
    getWallet,
  } from '../actions/v1/user'

import { CancelOrderRef } from './separate/CancelOrderRef';
import { LikeRef } from './separate/LikeRef';
import { BurnRef } from './separate/BurnRef';
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';

import TokenItem from './separate/Token-Item';
import BidPopup from './separate/Bid-Popup';
import ConnectWallet from './separate/Connect-Wallet';
import UserList from './separate/UserList';

import ReactLoading from 'react-loading';

import Web3 from 'web3';
import '@metamask/legacy-web3'

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;
const dashboardRoutes = [];

const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Search(props) {
  const tokenItemRef = useRef();
  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [Keyword, Set_Keyword] = React.useState('');
  const [itemTab, Set_itemTab] = React.useState(true);
  const [userTab, Set_userTab] = React.useState(false);
  const [itemPage, Set_itemPage] = React.useState(0);
  const [userPage, Set_userPage] = React.useState(0);
  const [itemload, Set_itemload] = React.useState(true);
  const [userload, Set_userload] = React.useState(true);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [Bids, Set_Bids] = useState([]);
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [item, Set_item] = useState({});
  const [Tabname, Set_Tabname] = useState('search');
  const [walletaddr, setWalletaddr]                           = useState("");
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [fullLoader, Set_fullLoader] = useState(false);
  const [providerss, set_providers] = useState(null)



  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');

  const [HitItem, Set_HitItem] = useState({});
  const [itemList, SetitemList] = useState([]);
  const [allUser, SetallUser] = useState([]);
  const [usdval, setusd] = useState(0);
  const [bouiusdvalue, setBouiusdval]             = useState(0);
  const [CatBasedTokenList, setCatBasedTokenList] = React.useState({'loader':false,'All':{page:1,list:[],onmore:true}});
  
  const [WalletConnected, Set_WalletConnected] = React.useState(false);

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const loadMoreItems = async () => {
    var nextPage= parseFloat(itemPage)+parseFloat(1);
    Set_itemPage(nextPage)
    var response = await GetItemList({search:Keyword,page:nextPage});
    if(response.result.length >0){
        SetitemList(response.result);
    }else{
        Set_itemload(false);
    }
    
  }

  const loadMoreUser = async () => {
    var nextPage= parseFloat(userPage)+parseFloat(1);
    Set_itemPage(nextPage)
    var response = await GetItemList({search:Keyword,page:nextPage});
    if(response.result.length >0){
        SetallUser(response.result);
    }else{
        Set_userload(false);
    }
    
  }

  async function SearchList() {
    var searchtxt = getParameterByName('query');
    var getcategory = getParameterByName('category');
    var response = await GetItemList({
      search:searchtxt,
      page:itemPage,
      category:getcategory
    });
    console.log("searchlist:",getcategory)
    SetitemList(response.result);
    
  }

  async function getBouiUsd() {
    try {
      var { result } = await getsettinglist();
      if (result && result.data) {
        setBouiusdval(result.data.bouiusd)
      }
    } catch (err) { }
  }

  async function getUsd() {
    let result = await axios.get(`${config.vUrl}/user/getUSD`);
    if(result){
      let currency = result.data;
      console.log(currency.data[0].Current_USD,'resss')
      let usd =currency.data[0].Current_USD;
      setusd(usd)
    }
  }

  async function  getwalletlist(){
    try{
      var currAddr = await getCurAddr();
      var payload={
        address:currAddr
      }
      var {result} = await getWallet(payload)
      if(result&&result.data){
        setWalletaddr(result.data.address)
      }
    }catch(err){}
  }

  useEffect(() => {
    SearchList();
    getUsd();
    getBouiUsd();
    SearchKeyword();
    getwalletlist();
  }, []);

  function SearchKeyword(){
    var keyword = getParameterByName('query');
    Set_Keyword(keyword)
  }

  async function getUser() {
    var searchtxt = getParameterByName('query');
    var response = await GetUserList({search:searchtxt,page:userPage});
    
    SetallUser(response.result)
    Set_itemTab(false)
    Set_userTab(true)
    
  }

  
  async function AfterWalletConnected() {
      
    try{
        LikeForwardRef.current.getLikesData();
      }catch(err){

      }
    
  }

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  const classes = useStyles();
  const { ...rest } = props;
 

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  async function getItems(){
    Set_itemTab(true)
    Set_userTab(false)
  }
  return (
    <div className="home_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
        set_providers={set_providers}
        providerss={providerss}
      />
      <BidPopup
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        TokenBalance={TokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        walletaddr={walletaddr}
        setWalletaddr={setWalletaddr}
        Set_AddressUserDetails={Set_AddressUserDetails}
        AddressUserDetails={AddressUserDetails}
        AllowedQuantity={AllowedQuantity}
        Set_AllowedQuantity={Set_AllowedQuantity}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
      <BurnRef
        ref={BurnForwardRef}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <ReportNowRef
        UserAccountAddr={UserAccountAddr}
        ref={ReportForwardRef}
      />
       <ShareNowRef
        ref={ShareForwardRef}
      />
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "white"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="bg_red_1">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="d-flex justify-content-between">
                  <h2 className="inner_title">Search</h2>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>

      <div className="main">
      <section className="explore_section section">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between mb-3">
              {Keyword && Keyword !="" && <span>Search results for {Keyword} </span>}
            </div>
            <nav className="masonry_tab_nav">
              <div className="nav nav-tabs masonry_tab" id="nav-tab" role="tablist">
                <a className="nav-link active" onClick={()=>{getItems()}} id="item-tab" data-tabName="all" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">Items</a>
                <a className="nav-link" onClick={()=>{getUser()}} id="user-tab" data-tabName="user-tab" data-toggle="tab" href="#user-tab" role="tab" aria-controls="user-tab" aria-selected="true">Users</a>
            </div>
            </nav>
            <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
            {itemTab && 
                <div className="tab-pane fade show active" id="onsale" role="tabpanel" aria-labelledby="onsale-tab">
                  <div className="proposal_panel_overall">
                {itemList && itemList.length> 0 && 
                  <div className="masonry">
                  {
                    itemList.map((item) => {
                    return ( 
                    <>
                          <TokenItem
                            ref={tokenItemRef}
                            item={item}
                            bnbusdvalue ={usdval}
                            bouiusdvalue={bouiusdvalue}
                            setBouiusdval={setBouiusdval}
                            walletaddr={walletaddr}
                            setWalletaddr={setWalletaddr}
                            Tabname={Tabname}
                            Set_Tabname={Set_Tabname}
                            Set_item={Set_item}
                            LikedTokenList={LikedTokenList}
                            Set_BuyOwnerDetailFirst ={Set_BuyOwnerDetailFirst}
                            Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                            Set_Bids={Set_Bids}
                            Set_MyTokenBalance={Set_MyTokenBalance}
                            Set_MyTokenDetail={Set_MyTokenDetail}
                            Set_AllowedQuantity={Set_AllowedQuantity}
                            Set_YouWillPay={Set_YouWillPay}
                            Set_YouWillPayFee={Set_YouWillPayFee}
                            Set_YouWillGet={Set_YouWillGet}
                            hitLike={LikeForwardRef.current.hitLike}
                            UserAccountAddr={UserAccountAddr}
                            UserAccountBal={UserAccountBal}
                            PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                            PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                            WalletConnected={WalletConnected}
                            SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                            ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                            Burn_Click={BurnForwardRef.current.Burn_Click}
                            CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                        />
                      </>
                    )
                    })
                }
                  </div>
               }
                {itemList && itemList.length== 0 && 
                    <div className="masonry">
                        No result found
                    </div>
                }
                 
                </div>
                {itemload && itemList && itemList.length > 0 &&
                    <div className="text-center" id="spinBut">
                    <Button className="create_btn" onClick={()=>{loadMoreItems()}} id="onmore" style={{ display: "flex" }}>Load More</Button>
                    </div>
                }
              </div>
              }
            {userTab && 
              <div className="tab-pane fade show active" id="user-tab" role="tabpanel" aria-labelledby="user-tab">
                {allUser && allUser.length> 0 && 
                    <div className="followers_overall py-5">
                    <div className="row">
                        {
                            allUser.map((item) => {
                            return (
                                <UserList
                                    item={item}
                                />
                            )
                            })
                        }
                    </div>
                    {userload && allUser && allUser.length > 0 &&
                        <div className="text-center" id="spinBut">
                        <Button className="create_btn" onClick={()=>{loadMoreUser()}} id="onmore" style={{ display: "flex" }}>Load More</Button>
                        </div>
                    }
                    </div>
                }      
                 
            {allUser && allUser.length== 0 && 
            <div className="followers_overall py-5">
                <div className="row">
                No result found
                </div>
            </div>
            }
                  
                
              </div>
            }
              
            
            </div>
          </div>
        </section>
    </div>
    </div>
    {/* new modal*/}
<div class="modal fade primary_modal profile_modal" id="profile_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="PurchaseStepCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="text-center">
                                    
                                      <a href={config.Front_URL+"/edit-profile"} className="primary_btn">
                                      
                                       Please fill the profile
                                       </a>
                                    
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
      {/* new modal*/}
    </div>
  );
}
