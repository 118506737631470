import React, {
    forwardRef,
    useImperativeHandle, useState,useEffect
} from 'react';
import { Button, TextField } from '@material-ui/core';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';

import {
    AddLikeAction,
    GetLikeDataAction
} from '../../actions/v1/token';

import {
    getCurAddr
} from '../../actions/v1/user';

import { toast } from 'react-toastify';
import {getmyprovider} from "../../lib/walletconnect";

toast.configure();

let toasterOption = config.toasterOption;

export const WalletRef = forwardRef((props, ref) => {
const [web3,setweb3] = useState();
const [Provider, setProvider] = useState();


useEffect(()=>{
init()
},[])

const init = async()=>{
    let providers = await getmyprovider();
    let web3 = new Web3(providers.provider);
    setweb3(web3);

}
    useImperativeHandle(
        ref,
        () => ({
            async GetUserBal() {
                var network = await web3.eth.net.getId();
                if (
                    typeof web3 !== 'undefined'
                    && web3
                    && web3.eth
                    && web3.currentProvider
                    // && web3.currentProvider.isMetaMask === true
                    && network == config.networkVersion
                ) {
                    var currAddr = Provider.address.toLowerCase();
                    console.log("=>address",currAddr);
                    props.Set_UserAccountAddr(currAddr);
                    props.Set_WalletConnected(true);
                    var setacc = Provider.address;
                    var val = await web3.eth.getBalance(setacc);
                    console.log('vvvv',val);
                    var balance = val / 1000000000000000000;
                    props.Set_UserAccountBal(balance);
                } else {
                    props.Set_UserAccountBal(0);
                }
            }
        }),
    )

})