import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinksCommunity from "components/Header/HeaderLinksCommunity.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link,useParams } from "react-router-dom";
import CKEditor from 'ckeditor4-react';
import { 
  replytopic, 
  paginatediscussion, 
  likeCommunity, 
  discussionCategory, 
  updateViewCount,
  mostLikes,
  topicdetail
} from "./../actions/community";
import { toast } from 'react-toastify';
import config from '../lib/config';
import ReactHtmlParser from 'react-html-parser'; 
import $ from 'jquery';

toast.configure();
  let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  }
const Icon = styled(props => (
  <div {...props}>
    <div className="minus">
      <i class="fas fa-angle-up"></i>
    </div>
    <div className="plus">
      <i class="fas fa-angle-down"></i>
    </div>
  </div>
))`
  & > .plus {
    display: block;
    color: #4dc0e9;
    font-size: 20px;
  }
  & > .minus {
    display: none;
    color: #4dc0e9;
    font-size: 20px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function DiscussionDetail(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel1');
  const { id } = useParams();

  const [topicdetails, settopicdetails] = useState({topic:"",description:""});
  const [istopic, setistopic] = useState({topic:"",description:""});
  const [reply, setreply] = useState("");
  const [replyList, setreplyList] = useState([]);
  const [replycnt, setreplycnt] = useState(0);
  const [error, seterror] = useState("");
  const [page, setpage] = useState(1);
  const [days, setdays] = useState('-');
  const [isLike, setisLike] = useState('unlike');
  const [likecnt, setlikecnt] = useState(0);
  const [catList, setcatList] = useState([]);
  const [likeList, setlikeList] = useState([]);

  useEffect(() => {
    gettopicdetails();
    getCategory();
    updateView();
    setTimeout(function(){
      getLikes();
    },1000);
  }, []);
  const getCategory = async (e) => {
    let { categories } = await discussionCategory();
    setcatList(categories)
  }
  const getLikes = async (e) => {
    let { list } = await mostLikes();
    setlikeList(list);
  }
  const linkUnlike = async (e) => {

    let reqData = {
      discussionid:id
    };
    console.log("disconnet:",id)

    let { status } = await likeCommunity(reqData);
    console.log("setisLike",status)
    var cnt =0;
    if(status=="like"){
      cnt = parseFloat(likecnt)+parseFloat(1);
    }else{
      cnt = parseFloat(likecnt)-parseFloat(1);
    }

    setisLike(status);
    setlikecnt(cnt);
  
  }
  const handleScroll = async (e) => {
    var pos = $('#scrolltop').scrollTop();
    if(pos==0) {
      let {details, skip} = await paginatediscussion({id:id,page:page});
      if(details && details.length > 0) {
        var totalList = details.concat(replyList);
        setpage(skip);
        setreplyList(totalList);
        var elem = document.getElementById('scrolltop');
        elem.scrollTop = elem.scrollHeight;
      }
    }
  }
  async function logintolike(){
    window.location.href="/community"
     // document.getElementById("login_modal").click();
   }

  const updateView = async (e) => {
    let { status } = await updateViewCount({discussionid:id});
  }
  const gettopicdetails = async (e) => {

    let {result, details, count,like} = await topicdetail({id:id});

    if(result && result._id) {

      const oneDay = 24 * 60 * 60 * 1000;
      const firstDate = new Date(result.created_date);
      const secondDate = new Date();
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

      if(diffDays > 0){
        setdays(diffDays+' days');
      }else{
        setdays('Recent');
      }

      setisLike(like);
      settopicdetails(result);
      setreplyList(details);
      setistopic(true);
      setreplycnt(count);
      setlikecnt(result.likes_count);
      var elem = document.getElementById('scrolltop');
      elem.scrollTop = elem.scrollHeight;

    } else {


    }
  }
  const replyTopic = async (e) => {
   
    e.preventDefault();
    seterror("")
    if(reply && reply !=""){
      let reqData = {
        reply,
        discussionid:id
      };
      
      let { status, result, count } = await replytopic(reqData);
   window.location.reload()
      if(result.reply && result.reply !=""){
        replyList.push(result);
        setreplyList(replyList);
        setreplycnt(count);
      
        var objDiv = document.getElementById("scrolltop");
        objDiv.scrollTop = objDiv.scrollHeight;
      }
      
    }else{
      seterror("Please enter message")
       toast.error("Please enter message", toasterOption)
    }
   
  };
  const onEditorChange = (evt) => {
    var description_text = evt.editor.getData() 
    setreply(description_text);
  }
  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinksCommunity />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader pb-0"}>
        <div>
          <div className={classes.container}>
            <div className="row">
              <div className="col-12 col-md-8 pb-3 pb-md-5">
                <h2 className="inner_title">{topicdetails.topic}</h2>
                <Link to="/category"><p className="cat_link cat_link_blue mb-0">{topicdetails.category_name}</p></Link>
                <div className="mt-3">
                  <div className="faq_panel">
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeFaq('panel1')} className="mt-5 dis_accordion">
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel1bh-content" id="panel1bh-header" className="m-0 panel_header_dis px-2">
                        <div className="accordian_head w-100 dis_head">

                          <h2 className="inner_title d-flex justify-content-between">
                            <div class="media p-0">
                            <img src={(topicdetails.profileImage && topicdetails.profileImage !="")?
                              config.Back_URL+"images/communityprofile/"+topicdetails.profileImage:config.Back_URL+"images/communityprofile/avatar.png"}  width="100px" height="100px" alt="User" className="img-fluid mr-3 align-self-center" />

                              {/* <img src={require("../assets/images/follower_1.png")} alt="User" className="img-fluid mr-3 align-self-center" /> */}
                              <div class="media-body">
                                <p className="mb-0 user_lh">{topicdetails.username}</p>
                                <p className="mb-0 text_gry">{days} |
                                  <span className="masonry_likes">
                                    {/* <i className="fas fa-heart mx-2"></i> */}
                                    |  {localStorage.community_token && 
                                  <i className={(isLike=="like")?"fa-heart mr-2 fa":"far fa-heart mr-2"} 
                                  onClick={(event) => { event.stopPropagation();linkUnlike() }}
                                  onFocus={(event) => event.stopPropagation()}></i>
                                }
                                {!localStorage.community_token && 
                                  <i className={(isLike=="like")?"fa-heart mr-2 fa":"far fa-heart mr-2"}
                                  onClick={(event) => { event.stopPropagation();logintolike()}}
                                  onFocus={(event) => event.stopPropagation()}
                                  >
                                  </i>
                                }
                                    <span className="mr-2 text_gry">{likecnt}</span>
                                  </span>|
                                 <i className="fas fa-link ml-2 icon_red"></i>
                                </p>
                              </div>
                            </div>
                            <div>
                              <span className="text_gry reply_count">{replycnt} replies</span>
                            </div>
                          </h2>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accordian_para">
                          <div className="desc_discussion pl-3 pl-lg-cus">
                          {topicdetails.description && <div>{ReactHtmlParser(topicdetails.description)}</div>}
                            {/* <p className="mb-0">I want to help out with some of you new artist but I can't afford, some of your asking prices.</p>
                            <p className="mb-0">I'm waiting to buy a couple of NFT for about $90 dollars each.</p>
                            <p className="mb-0">If you would like me to buy your art then please post here.</p> */}
                          </div>
                          <div className="scrollable-content" id="scrolltop" style={{"overflow" : "auto" }} onScroll={handleScroll}>

                          {replyList && replyList.map((row,index) => {
                            return (
                           <div className="desc_discussion pl-3 pl-lg-cus">
                            <div class="media p-0">
                          
                            <img src={(row.profile_image && row.profile_image !="")?
                              config.Back_URL+"images/communityprofile/"+row.profile_image:config.Back_URL+"images/communityprofile/"+"avatar.png"} alt="User" 
                              className="img-fluid mr-3 align-self-center" width="100px" height="100px" />
                              <div class="media-body">
                                <p className="mb-0 reply_uer_name">{row.username}</p>
                                {topicdetails.reply && <p className="mb-0">{ReactHtmlParser(row.reply)}</p>}

                                {/* <p className="mb-0">What ever takes my fancy, I can't buy everyting but will you buy a couple that are goood or intersting.</p>
                                <p className="mb-0">If you would like me to buy your art then please post here.</p> */}

                              </div>
                            </div>
                          </div>
                          )})}
                          </div>
                         {/* <div className="desc_discussion pl-3 pl-lg-cus">
                            <div class="media p-0">
                              <img src={require("../assets/images/follower_3.png")} alt="User" className="img-fluid mr-3 align-self-center" />
                              <div class="media-body">
                                <p className="mb-0 reply_uer_name">MacroLab3D</p>
                                <p className="mb-0">What ever takes my fancy, I can't buy everyting but will you buy a couple that are goood or intersting.</p>
                                <p className="mb-0">If you would like me to buy your art then please post here.</p>

                              </div>
                            </div>
                          </div> */}
                          <div className="accordion_form p-3 px-lg-5 py-lg-4">
                            <p className="reply_txt">Reply</p>
                            <form className="login_form mt-3">
                              {/* <label for="reply_email">Usename / Email Address</label>
                              <div class="mb-3">
                                <input type="text" id="reply_email" class="form-control" placeholder="Usename / Email Address" />

                              </div> */}
                              <label for="password">Message</label>
                              <div class="my-3">
                                <CKEditor
                                  data=""
                                  onChange={onEditorChange}
                                />

                              </div>
                              <div className="mt-3">
                              {localStorage.community_token && 
                                <Button className="create_btn" onClick={replyTopic}>Reply</Button>
                              }
                              </div>
                            </form>
                          </div>


                        </div>
                      </AccordionDetails>
                    </Accordion>

                    {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChangeFaq('panel2')} className="mt-5 dis_accordion">
                      <AccordionSummary expandIcon={<Icon />} aria-controls="panel2bh-content" id="panel2bh-header" className="m-0 panel_header_dis px-2">
                        <div className="accordian_head w-100 dis_head">

                          <h2 className="inner_title d-flex justify-content-between">
                            <div class="media p-0">
                              <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid mr-3 align-self-center" />
                              <div class="media-body">
                                <p className="mb-0 user_lh">Deepblue</p>
                                <p className="mb-0 text_gry">26 days |
  <span className="masonry_likes">
                                    <i className="fas fa-heart mx-2"></i>
                                    <span className="mr-2 text_gry">7</span>
                                  </span>
  |
  <i className="fas fa-link ml-2 icon_red"></i>
                                </p>
                              </div>
                            </div>
                            <div>
                              <span className="text_gry reply_count">0 replies</span>

                            </div>


                          </h2>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accordian_para">
                          <div className="desc_discussion  pl-3 pl-lg-cus">
                            <p className="mb-0">Please make a bid. Will sell for 0.01ETH. This is one sample of the 12 Apocalypse gif's.</p>
                            <img src={require("../assets/images/collections_02.png")} alt="User" className="img-fluid py-2" />

                            <p className="mb-0">The Apocalypse Series #3 (Morocco)</p>
                            <p className="mb-0">Or visit https://nft.io/myolivia Thanks.</p>
                          </div>

                          <div className="accordion_form p-3 px-lg-5 py-lg-4">
                            <p className="reply_txt">Reply</p>
                            <form className="login_form mt-3">
                              <label for="reply_email">Usename / Email Address</label>
                              <div class="mb-3">
                                <input type="text" id="reply_email" class="form-control" placeholder="Usename / Email Address" />

                              </div>
                              <label for="password">Message</label>
                              <div class="my-3">
                                <CKEditor
                                  data="<p>Hello</p>"
                                  onChange={evt => console.log(evt)}
                                />

                              </div>
                              <div className="mt-3">
                                <Button className="create_btn">Reply</Button>
                              </div>
                            </form>
                          </div>


                        </div>
                      </AccordionDetails>
                    </Accordion> */}

                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 border_left_md pb-3 pb-md-5">
                <p className="reply_txt">Category</p>
                <div className="pt-2 pb-5">
                {catList && catList.map((row,index) => {
                    return (
                  <Link to={"/category/"+row._id}><p className="cat_link cat_link_blue mb-0">{row.name}</p></Link>
                  )})}
                   {/* <Link to="/category"><p className="cat_link cat_link_green mb-0">General Community</p></Link>
                   <Link to="/category"><p className="cat_link cat_link_blue mb-0">Technical upgrades</p></Link>
                  <Link to="/category"><p className="cat_link cat_link_green mb-0">General Community</p></Link> */}
                </div>

                <p className="reply_txt">Recent Post</p>
                <div className="pt-2 pb-5">
                <Link to={"/topic-list#today"}>
                  <p className="post_link mb-0">Within 24 Hrs</p>
                  </Link>
                  <Link to={"/topic-list#week"}>
                  <p className="post_link active mb-0">This Week</p>
                  </Link>
                  <Link to={"/topic-list#month"}>
                  <p className="post_link mb-0">This month</p>
                  </Link>
                  <Link to={"/topic-list#3month"}>
                  <p className="post_link mb-0">Within 3 months</p>
                  </Link>
                </div>

                <p className="reply_txt">Most Likes</p>
                <div className="pt-2 pb-5">
                {likeList && likeList.map((row,index) => {
                    return (
                      <a href={"/discussion-detail/"+row._id}>
                  <p className="post_link mb-0">{row.topic} - {row.likes_count}</p>
                </a>
                  )})}
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}
