import React, {useEffect,useState} from "react";
import { Link } from "react-router-dom";
import { Button } from '@material-ui/core';
import config from  "../../lib/config"

export default function UserList(props) {

    var {
        item
    } = props;
console.log("usert_list",item)
    var imageUrl = (item.image && item.image !="")?
    config.Back_URL+"/images/noimage.png":config.Back_URL+"/images/noimage.png";

    return (
        <div className="col-12 col-md-6 col-xl-4 mb-4">
            <div className="card my-0">
            <div className="card-body">
                <a href={config.Front_URL+"/user/"+item.curraddress}>
                <div className="media follow_media">
                <img src={imageUrl} alt="User" height="50px" width="50px" className="img-fluid mr-3" />
                <div className="media-body flex_body">
                    <div>
                    <p className="mt-0 media_num mb-0">{item.followers} followers</p>
                    <p className="mt-0 media_text">{item.name}</p>
                    </div>
                    {/* <div className="ml-2">
                    <Button className="create_btn">Follow</Button>
                    </div> */}
                </div>
                </div>
                </a>
            </div>
        </div>
        </div>
    )
}