import DETH_ABI from '../ABI/DETH.json';
let EnvName            = 'production';
let Front_URL          = '';
let Back_URL           = '';
let v1Url              = '';
let Img_URL            ='';
let limit              = '12';
let limitMax           = '3000';
let fee                = 2500000000000000000;
let decimalvalues      = 1000000000000000000;
let toFixed            = 2;
let IPFS_IMG           = "https://ipfs.io/ipfs";
const singleType       = 721;
const multipleType     = 1155;
var providercon        = null;
let networkVersion     = '56'; //testnet 97, mainnet 56
let currencySymbol     = 'BNB';
let tokenSymbol        = 'WBNB';
let owntokenSymbol     = 'BOUI';
let usdtokenSymbol     = "BUSD";
const adminAddress     = "0x26f9f0c809779fea862b28a106fd883fac4a3fb7";
let provider ="";
// mainnet
const singleContract   = "0x548D2bc89C1D1e265b3B6320F9CCd0ECE4E501ed"; //0x90F55E1D09b530B0C308b9fa0ca5d2947abcC47a
const multipleContract = "0x9843525Eb2524bE3c885b6E9B5F81eE9A6A4bad5"; //0x9843525Eb2524bE3c885b6E9B5F81eE9A6A4bad5
const tokenAddress     = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; //0xbbCFB071367ef3305Af18aDe88D4a66E0dCC42C9
const bouitokenAddr    = "0x945b4812Cf50FC29F6D65382763Bbc96fcA87dd5"; //0x85980B51588f6821e8A7850927D3C2b5ddBE59C1
const busdtokenAddr    = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"; //0x5CAEdc3814fbBE66C10B133D50b244cc9716338c
//testnet
// const singleContract   = "0x2c6D1e52B0D352412afB27BD0160d6f80a1e4912"; 
// const multipleContract = "0x9843525Eb2524bE3c885b6E9B5F81eE9A6A4bad5"; 
// const tokenAddress     = "0xbbCFB071367ef3305Af18aDe88D4a66E0dCC42C9"; 
// const bouitokenAddr    = "0x85980B51588f6821e8A7850927D3C2b5ddBE59C1"; 
// const busdtokenAddr    = "0x5CAEdc3814fbBE66C10B133D50b244cc9716338c"; 
const userContract721  = "";
const userContract1155 = "";
let toasterOption = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
if (EnvName === "production") {
    Front_URL = 'https://boui.io';
    Back_URL  = 'https://api.boui.io/';
    v1Url     = 'https://api.boui.io/v1';
    IPFS_IMG  = "https://ipfs.io/ipfs";
    Img_URL   ="https://api.boui.io/nftcompressImg/";
    provider ="https://bsc-dataseed1.binance.org:443";
}
else if (EnvName === "demo") {
    Front_URL = 'https://bouinft.wealwin.com';
    Back_URL  = 'https://bouinftapi.wealwin.com/';
    v1Url     = 'https://bouinftapi.wealwin.com/v1';
    // Front_URL = 'http://52.44.247.93';
    // Back_URL  = 'http://52.44.247.93:2053/';
    // v1Url     = 'http://52.44.247.93:2053/v1';
    IPFS_IMG  = "https://ipfs.io/ipfs";
    Img_URL   ="https://bouinftapi.wealwin.com/nftcompressImg/";
    provider ="https://data-seed-prebsc-1-s1.binance.org:8545/";
}
else {
    Front_URL = 'http://localhost:3000';
    Back_URL  = 'http://localhost:3001/';
    v1Url     = 'http://localhost:3001/v1';
    // Back_URL  = 'https://bouinftapi.wealwin.com/';
    // v1Url     = 'https://bouinftapi.wealwin.com/v1';
    // Back_URL  = 'http://52.44.247.93:2053/';
    // v1Url     = 'http://52.44.247.93:2053/v1';
    // Back_URL  = 'https://api.boui.io/';
    // v1Url     = 'https://api.boui.io/v1';
    IPFS_IMG  = "https://ipfs.io/ipfs";
    Img_URL ="http://localhost:3001/nftcompressImg/";
    provider ="https://data-seed-prebsc-1-s1.binance.org:8545/";
}

let key = {
    Front_URL: Front_URL,
    Back_URL : Back_URL,
    vUrl:v1Url,
    Img_URL:Img_URL,
    decimalvalues:decimalvalues,
    toFixed:toFixed,
    networkVersion: networkVersion,
    currencySymbol: currencySymbol,
    tokenSymbol: tokenSymbol,
    toasterOption: toasterOption,
    limit: limit,
    limitMax: limitMax,
    singleContract: singleContract,
    multipleContract: multipleContract,
    tokenAddress:tokenAddress,
    userContract721:userContract721,
    userContract1155:userContract1155,
    adminAddress: adminAddress,
    singleType: singleType,
    multipleType: multipleType,
    IPFS_IMG: IPFS_IMG,
    providercon:providercon,
    usdtokenSymbol:usdtokenSymbol,
    owntokenSymbol:owntokenSymbol,
    bouitokenAddr:bouitokenAddr,
    busdtokenAddr:busdtokenAddr,
    fee:fee,
    provider:provider
};

export default key;
