import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link,useHistory } from "react-router-dom";
import $ from 'jquery';
import Web3 from 'web3';
import config from '../lib/config';

import {
  getCurAddr,
  UserProfile_Update_Action,
  ParamAccountAddr_Detail_Get,
  verificationuser,
  AddressUserDetails_GetOrSave_Action
} from '../actions/v1/user';

import ConnectWallet from './separate/Connect-Wallet';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify';
import { VerifiedUser } from "@material-ui/icons";
toast.configure();
let toasterOption = config.toasterOption;


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function EditProfile(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const history = useHistory();

  const [name, Set_name]                 = useState('');
  const [mobile, setphoneNumber]         = useState("");
  const [personalsite, Set_personalsite] = useState('');
  const [customurl, Set_customurl]       = useState('');
  const [email, Set_email]               = useState('');
  const [bio, Set_bio]                   = useState('');
  const [twitter, Set_twitter]           = useState('');
  const [youtube, Set_youtube]           = useState('');
  const [facebook, Set_facebook]         = useState('');
  const [instagram, Set_instagram]       = useState('');
  const [validateError, setValidateError] = useState({});
  const [WalletConnected, Set_WalletConnected] = useState('false');
  const [UserAccountAddr, Set_UserAccountAddr] = useState('');
  const [UserAccountBal, Set_UserAccountBal] = useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = useState('');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState('');
  const [TokenFile, setTokenFile] = React.useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = React.useState("");
  const [TokenFilePreUrl, setTokenFilePreUrl] = React.useState("");

  const selectFileChange = (e) => {
    var validExtensions = ["png", 'gif', 'webp', 'mp4', 'mp3']; //array of valid extensions
    if(e.target && e.target.files) {
      var reader = new FileReader()
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) == -1) {
        toast.error("Only these file types are accepted : "+validExtensions.join(', '), toasterOption);
        $("input[name='image']").val("");
        return false;
      }
      else {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 30) {
          toast.error("File size exceeds 30 MB", toasterOption);
          $("input[name='image']").val("");
          return false;
        } else {
          setTokenFile(file);
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            if(reader.result) {
              setTokenFilePreReader(reader.result);
            }
          }.bind(this);
          setTokenFilePreUrl(e.target.value);
        }
      }
    }
  }

  const inputChange = (e) => {
 
    if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      var value = e.target.value;
      console.log("phoneno",e.target.value)
      AddressUserDetails[e.target.name] = value
      Set_AddressUserDetails(AddressUserDetails);
      CreateItemValidation();
    }
  }
  const CreateItemValidation = () => {
  }

  async function AfterWalletConnected() {
    var currAddr = await getCurAddr();
    try{
      var addr = window.web3.eth.defaultAccount;
      var ReqData = { addr : addr }
      var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
      if (Resp && Resp.data && Resp.data.data) { 
        Set_AddressUserDetails(Resp.data.data.User);
        if(Resp.data.data.User.mobile) {
        setphoneNumber(Resp.data.data.User.mobile)
        }
        if(Resp.data.data.User.name){
          Set_name(Resp.data.data.User.name)
        }
        if(Resp.data.data.User.personalsite){
          Set_personalsite(Resp.data.data.User.personalsite)
        }
        if(Resp.data.data.User.customurl){
          Set_customurl(Resp.data.data.User.customurl)
        }
        if(Resp.data.data.User.email){
          Set_email(Resp.data.data.User.email)
        }
      }
    }catch(err){}
  }
  const Uservalidation=(chk)=>{
    if (chk) {
      var validateError = {};
      if(AddressUserDetails.email==""){
        validateError.email = "E-mail field is required";
      }
      if (AddressUserDetails.plotno=="") {
        validateError.plotno = "First and Last Name field is required";
      }
      if(AddressUserDetails.addressline==""){
        validateError.addressline = "Address Line field is required";
      }
       if(AddressUserDetails.city==""){
        validateError.city = "City field is required";
      }
      if(AddressUserDetails.postalcode==""){
        validateError.postalcode = "Postal code field is required";
      }
      if(AddressUserDetails.state==""){
        validateError.state = "State field is required";
      }
      if(AddressUserDetails.country==""){
        validateError.country = "Country field is required";
      }
      if(mobile==""||mobile==undefined){
        validateError.mobile = "Mobile number field is required";
      }
      setValidateError(validateError);
     return validateError; 
    }
  } 

  const FormSubmit = async () => {
    var currAddr = await getCurAddr();
    if(currAddr) {
      var errors = await Uservalidation(true);
      var errorsSize = Object.keys(errors).length;
      if(errorsSize != 0) {
      }else{
        var web3 = new Web3(window.ethereum)
        web3.eth.personal
        .sign(
          `Your Created profile as ${AddressUserDetails.name} and Your url is ${config.Front_URL}/${customurl}`,
          currAddr,
          `Your Created profile as ${AddressUserDetails.name} and Your url is ${config.Front_URL}/${customurl}`
        )
        .then(async()=>{
          var formData = new FormData();
          if(TokenFile) {
            formData.append('Image', TokenFile);
          }
          formData.append('addr', currAddr);
          formData.append('plotno', AddressUserDetails.plotno);
          formData.append('addressline', AddressUserDetails.addressline);
          formData.append('country', AddressUserDetails.country);
          formData.append('state', AddressUserDetails.state);
          formData.append('city', AddressUserDetails.city);
          formData.append('postalcode', AddressUserDetails.postalcode);
          setphoneNumber(AddressUserDetails.mobile)
          formData.append('name', AddressUserDetails.name);
          formData.append('mobile', mobile);
          formData.append('personalsite', AddressUserDetails.personalsite);
          formData.append('customurl', AddressUserDetails.customurl);
          formData.append('email', AddressUserDetails.email);
          formData.append('bio', AddressUserDetails.bio);
          formData.append('twitter', AddressUserDetails.twitter);
          formData.append('youtube', AddressUserDetails.youtube);
          formData.append('facebook', AddressUserDetails.facebook);
          formData.append('instagram', AddressUserDetails.instagram);
          var Resp = await UserProfile_Update_Action(formData);
          if (Resp && Resp.data && Resp.data.toast && Resp.data.toast.type && Resp.data.toast.type == 'success') {
            setTimeout(() => { 
              history.push('/my-items'); 
              
            }, 2000)
          }
        })
      }
    }
  }
  async function submitmail(){
    if(AddressUserDetails.email!="" && AddressUserDetails.email!= undefined){
      var currAddr = await getCurAddr();
      var data={
        email:AddressUserDetails.email,
        useraddress:currAddr
      }

    var Resp = await verificationuser(data)
    console.log("message",Resp)
    if (Resp && Resp.result && Resp.result.message == "Updated Successfully") {
      toast.success("Request Submitted Successfully", toasterOption);
    }
    }else{
        toast.error("Invalid Mail Id", toasterOption);
    }
  }
  console.log("AddressUserDetails",AddressUserDetails)
  
  return (
    <div className="inner_header">
        <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
     <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <div className="d-flex align-items-center mb-4">
            <h2 className="inner_title">Edit Profile</h2>
          </div>

           <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
                <div class="holder">
                {
                  AddressUserDetails && AddressUserDetails.image && AddressUserDetails.image != ''
                    ?
                    <img src={config.Back_URL + '/profile/' + AddressUserDetails.image} alt="logo" id="imgPreview" className="img-fluid" />
                    :
                    <img src={TokenFilePreReader!=""?TokenFilePreReader:require("../assets/images/BO6b.png")} alt="logo" id="imgPreview" className="img-fluid" />
                }
                </div>
              <div className="profile_edit_panel">
                <div className="profile_edit_icon">
                  <i class="fas fa-pencil-alt"></i>
                </div>
                <input type="file" name="image" id="photo" required="true"  onChange={selectFileChange}/>
              </div>
            </GridItem>
            <GridItem xs={12} sm={9} md={9}>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Display Name</label>
                    <input type="text"
                     className="form-control primary_inp"
                     id="name" 
                     name="name"
                     placeholder="Enter your display name"
                     autoComplete="off"
                     onChange={inputChange}
                     defaultValue={AddressUserDetails.name!='undefined'?AddressUserDetails.name:""}
                     />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Personal site or portfolio(http://{personalsite})</label>
                    <input type="text" className="form-control primary_inp"
                     id="personalsite" 
                     name="personalsite"
                     autoComplete="off"
                     onChange={inputChange}
                     defaultValue={AddressUserDetails.personalsite!="undefined"?AddressUserDetails.personalsite:""}
                     />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Custom URL({config.Front_URL}/{customurl})</label>
                    <input type="text" className="form-control primary_inp" 
                     id="customurl" 
                     name="customurl"
                     autoComplete="off"
                     onChange={inputChange}
                     defaultValue={AddressUserDetails.customurl!='undefined'?AddressUserDetails.customurl:""}
                     />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Email</label>
                    <input type="text" className="form-control primary_inp" 
                    id="email" 
                    name="email"
                    autoComplete="off"
                    onChange={inputChange}
                    defaultValue={AddressUserDetails.email!='undefined'?AddressUserDetails.email:""}
                    placeholder="Your email for marketplace notifications"/>
                    {validateError.email && <span className="text-danger"><br/>{validateError.email}</span>}

                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Bio</label>
                    <input type="text" className="form-control primary_inp"
                     id="bio" 
                     name="bio"
                     autoComplete="off"
                     placeholder="Tell about yourself in a few words"
                     onChange={inputChange}
                     defaultValue={AddressUserDetails.bio!="undefined"?AddressUserDetails.bio:""}
                     />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Mobile</label>
                    <PhoneInput placeholder="Enter phone number" 
                    defaultCountry="IN"
                    value={mobile} 
                     onChange={setphoneNumber}/>
                    {validateError.mobile && <span className="text-danger"><br/>{validateError.mobile}</span>}

                  </div>
                </div>
                 <div className="form-row">
                   <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="desccription">Verification</label>
                    <div className="d-flex align-items-start">
                    {AddressUserDetails.emailverified==true?
                      <Button className="primary_btn my-2" >Verified</Button>
                    :
                      <Button className="primary_btn my-2" onClick={()=>submitmail()}> Get verified</Button>
                    }
                      </div>
                     <p className="text-muted m-0 noteText"><small>Procceed with verification proccess to get more visibility and gain trust on Boui Marketplace. Please allow up to several weeks for the process.</small></p>
                  </div>
                 </div>
                 <div className="form-row mt-3">
                  <div className="form-group col-md-6">
                    <label className="primary_label mb-0" htmlFor="name">Contact Address</label>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input type="text"  
                     onChange={inputChange}
                     defaultValue={AddressUserDetails.plotno!="undefined"?AddressUserDetails.plotno:""}
                     autoComplete="off"
                     name="plotno" id="plotno" className="form-control primary_inp" placeholder="First Name / Last Name "/>
                    {validateError.plotno && <span className="text-danger"><br/>{validateError.plotno}</span>}

                  </div>
                  <div className="form-group col-md-6">
                    <input type="text"  
                    autoComplete="off"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.addressline!="undefined"?AddressUserDetails.addressline:""}
                    name="addressline" id="addressline" className="form-control primary_inp" placeholder="Address Line"/>
                    {validateError.addressline && <span className="text-danger"><br/>{validateError.addressline}</span>}
                  
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text"  
                     autoComplete="off"
                     onChange={inputChange}
                     defaultValue={AddressUserDetails.country!="undefined"?AddressUserDetails.country:""}
                    name="country" id="country" className="form-control primary_inp" placeholder="Country"/>
                    {validateError.country && <span className="text-danger"><br/>{validateError.country}</span>}
                 
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text"  
                     autoComplete="off"
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.state!="undefined"?AddressUserDetails.state:""}
                    name="state" id="state" className="form-control primary_inp" placeholder="State"/>
                    {validateError.state && <span className="text-danger"><br/>{validateError.state}</span>}
                 
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text" 
                     autoComplete="off" 
                      onChange={inputChange}
                      defaultValue={AddressUserDetails.city!="undefined"?AddressUserDetails.city:""}
                    name="city" id="city" className="form-control primary_inp" placeholder="City"/>
                    {validateError.city && <span className="text-danger"><br/>{validateError.city}</span>}

                  </div>
                  <div className="form-group col-md-6">
                    <input type="number" min="0"  autoComplete="off"
                    onChange={inputChange}
                    defaultValue={AddressUserDetails.postalcode!="undefined"?AddressUserDetails.postalcode:""}
                    name="postalcode" id="postalcode" className="form-control primary_inp" placeholder="Postal Code"/>
                    {validateError.postalcode && <span className="text-danger"><br/>{validateError.postalcode}</span>}
                 
                  </div>
                </div>
                <div className="form-row mt-4">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Social Media Links</label>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control primary_inp" 
                    id="twitter" name="twitter"
                    placeholder="Twitter account address"
                    onChange={inputChange}
                    autoComplete="off"
                    defaultValue={AddressUserDetails.twitter!="undefined"?AddressUserDetails.twitter:""}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control primary_inp"
                     id="youtube"
                     name="youtube"
                     placeholder="Youtube channel address"
                     onChange={inputChange}
                     autoComplete="off"
                     defaultValue={AddressUserDetails.youtube!="undefined"?AddressUserDetails.youtube:""}
                     />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control primary_inp"
                     id="facebook" 
                     name="facebook"
                     onChange={inputChange}
                     autoComplete="off"
                     defaultValue={AddressUserDetails.facebook!="undefined"?AddressUserDetails.facebook:""}
                     placeholder="Facebook account address"/>
                  </div>
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control primary_inp"
                     id="instagram" name="instagram" placeholder="Instagram address"
                     onChange={inputChange}
                     autoComplete="off"
                     defaultValue={AddressUserDetails.instagram!="undefined"?AddressUserDetails.instagram:""}
                     />
                  </div>
                </div>
                <div className="mt-3">
                  <Button type="button" className="create_btn" onClick={() => FormSubmit()}>Update Profile</Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
