import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Following(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className="bg_red_1">
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="inner_title">Following</h2>
                  <div className="custom_filter border_none" >
                    <span><i class="fas fa-sliders-h"></i></span>
                    <select class="custom-select ">
                      <option selected>Filter</option>
                      <option value="1">Low Price</option>
                      <option value="2">High Price</option>
                      <option value="3">Recently Added</option>
                    </select>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className="container mt-3 following_container">


        <div className="masonry">
                    <div className="item">    
                    <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids p-3">
                        <div className="d-flex creators_details">
                          <a href="#"><img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid imgRound" /></a>
                          <a href="#">
                            <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid imgRound" />
                            <img src={require("../assets/images/verifiedTick.png")} alt="User" className="img-fluid imgVerify" />
                          </a>
                          <a href="#"><img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid imgRound" /></a>
                        </div>
                        <div class="dropdown">
                          <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                          </div>
                        </div>
                      </div> 
                      <div className="nft_img_panel">
                        <a href="#"><img src={require("../assets/images/explore-image-1.jpg")} alt="Collections" className="img-fluid explore_img" /></a>
                      </div>
                      <div className="pt-0 p-3 paddin_but_152">
                          <h2><a href="#">NexusNFT #05</a></h2>
                          <h3>From <span>0.08 ETH</span> 49/100</h3>
                          <div className="exploreTitle">
                          <button className="exploreBidLink" data-toggle="modal" data-target="#place_bid_modal">Place a Bid</button>
                            <a href="javascript:void(0)" className="masonry_likes">
                              <i className="far fa-heart mr-1"></i> <span>20</span>
                            </a>
                        </div>
                       
                      
                      </div>
                    </div>
                    <div className="item">    
                    <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids p-3">
                        <div className="d-flex creators_details">
                          <a href="#"><img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid imgRound" /></a>
                          <a href="#">
                            <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid imgRound" />
                            <img src={require("../assets/images/verifiedTick.png")} alt="User" className="img-fluid imgVerify" />
                          </a>
                          <a href="#"><img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid imgRound" /></a>
                        </div>
                        <div class="dropdown">
                          <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                          </div>
                        </div>
                      </div> 
                      <div className="nft_img_panel">
                        <a href="#"><img src={require("../assets/images/explore-image-2.jpg")} alt="Collections" className="img-fluid explore_img" /></a>
                      </div>
                      <div className="pt-0 p-3 paddin_but_152">
                          <h2><a href="#">NexusNFT #05</a></h2>
                          <h3>From <span>0.08 ETH</span> 49/100</h3>
                          <div className="exploreTitle">
                          <button className="exploreBidLink" data-toggle="modal" data-target="#place_bid_modal">Place a Bid</button>
                            <a href="javascript:void(0)" className="masonry_likes">
                              <i className="far fa-heart mr-1"></i> <span>20</span>
                            </a>
                        </div>
                       
                      
                      </div>
                    </div>
                    <div className="item">    
                    <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids p-3">
                        <div className="d-flex creators_details">
                          <a href="#"><img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid imgRound" /></a>
                          <a href="#">
                            <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid imgRound" />
                            <img src={require("../assets/images/verifiedTick.png")} alt="User" className="img-fluid imgVerify" />
                          </a>
                          <a href="#"><img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid imgRound" /></a>
                        </div>
                        <div class="dropdown">
                          <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                          </div>
                        </div>
                      </div> 
                      <div className="nft_img_panel">
                        <a href="#"><img src={require("../assets/images/explore-image-3.jpg")} alt="Collections" className="img-fluid explore_img" /></a>
                      </div>
                      <div className="pt-0 p-3 paddin_but_152">
                          <h2><a href="#">NexusNFT #05</a></h2>
                          <h3>From <span>0.08 ETH</span> 49/100</h3>
                          <div className="exploreTitle">
                          <button className="exploreBidLink" data-toggle="modal" data-target="#place_bid_modal">Place a Bid</button>
                            <a href="javascript:void(0)" className="masonry_likes">
                              <i className="far fa-heart mr-1"></i> <span>20</span>
                            </a>
                        </div>
                       
                      
                      </div>
                    </div>
                    <div className="item">    
                    <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids p-3">
                        <div className="d-flex creators_details">
                          <a href="#"><img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid imgRound" /></a>
                          <a href="#">
                            <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid imgRound" />
                            <img src={require("../assets/images/verifiedTick.png")} alt="User" className="img-fluid imgVerify" />
                          </a>
                          <a href="#"><img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid imgRound" /></a>
                        </div>
                        <div class="dropdown">
                          <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                          </div>
                        </div>
                      </div> 
                      <div className="nft_img_panel">
                        <a href="#"><img src={require("../assets/images/explore-image-4.jpg")} alt="Collections" className="img-fluid explore_img" /></a>
                      </div>
                      <div className="pt-0 p-3 paddin_but_152">
                          <h2><a href="#">NexusNFT #05</a></h2>
                          <h3>From <span>0.08 ETH</span> 49/100</h3>
                          <div className="exploreTitle">
                          <button className="exploreBidLink" data-toggle="modal" data-target="#place_bid_modal">Place a Bid</button>
                            <a href="javascript:void(0)" className="masonry_likes">
                              <i className="far fa-heart mr-1"></i> <span>20</span>
                            </a>
                        </div>
                       
                      
                      </div>
                    </div>
                    <div className="item">    
                    <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids p-3">
                        <div className="d-flex creators_details">
                          <a href="#"><img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid imgRound" /></a>
                          <a href="#">
                            <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid imgRound" />
                            <img src={require("../assets/images/verifiedTick.png")} alt="User" className="img-fluid imgVerify" />
                          </a>
                          <a href="#"><img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid imgRound" /></a>
                        </div>
                        <div class="dropdown">
                          <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                          </div>
                        </div>
                      </div> 
                      <div className="nft_img_panel">
                        <a href="#"><img src={require("../assets/images/explore-image-5.jpg")} alt="Collections" className="img-fluid explore_img" /></a>
                      </div>
                      <div className="pt-0 p-3 paddin_but_152">
                          <h2><a href="#">NexusNFT #05</a></h2>
                          <h3>From <span>0.08 ETH</span> 49/100</h3>
                          <div className="exploreTitle">
                          <button className="exploreBidLink" data-toggle="modal" data-target="#place_bid_modal">Place a Bid</button>
                            <a href="javascript:void(0)" className="masonry_likes">
                              <i className="far fa-heart mr-1"></i> <span>20</span>
                            </a>
                        </div>
                       
                      
                      </div>
                    </div>
                    <div className="item">    
                    <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids p-3">
                        <div className="d-flex creators_details">
                          <a href="#"><img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid imgRound" /></a>
                          <a href="#">
                            <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid imgRound" />
                            <img src={require("../assets/images/verifiedTick.png")} alt="User" className="img-fluid imgVerify" />
                          </a>
                          <a href="#"><img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid imgRound" /></a>
                        </div>
                        <div class="dropdown">
                          <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                          </div>
                        </div>
                      </div> 
                      <div className="nft_img_panel">
                        <a href="#"><img src={require("../assets/images/explore-image-6.jpg")} alt="Collections" className="img-fluid explore_img" /></a>
                      </div>
                      <div className="pt-0 p-3 paddin_but_152">
                          <h2><a href="#">NexusNFT #05</a></h2>
                          <h3>From <span>0.08 ETH</span> 49/100</h3>
                          <div className="exploreTitle">
                          <button className="exploreBidLink" data-toggle="modal" data-target="#place_bid_modal">Place a Bid</button>
                            <a href="javascript:void(0)" className="masonry_likes">
                              <i className="far fa-heart mr-1"></i> <span>20</span>
                            </a>
                        </div>
                       
                      
                      </div>
                    </div>
                    <div className="item">    
                    <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids p-3">
                        <div className="d-flex creators_details">
                          <a href="#"><img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid imgRound" /></a>
                          <a href="#">
                            <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid imgRound" />
                            <img src={require("../assets/images/verifiedTick.png")} alt="User" className="img-fluid imgVerify" />
                          </a>
                          <a href="#"><img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid imgRound" /></a>
                        </div>
                        <div class="dropdown">
                          <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                          </div>
                        </div>
                      </div> 
                      <div className="nft_img_panel">
                        <a href="#"><img src={require("../assets/images/explore-image-7.jpg")} alt="Collections" className="img-fluid explore_img" /></a>
                      </div>
                      <div className="pt-0 p-3 paddin_but_152">
                          <h2><a href="#">NexusNFT #05</a></h2>
                          <h3>From <span>0.08 ETH</span> 49/100</h3>
                          <div className="exploreTitle">
                          <button className="exploreBidLink" data-toggle="modal" data-target="#place_bid_modal">Place a Bid</button>
                            <a href="javascript:void(0)" className="masonry_likes">
                              <i className="far fa-heart mr-1"></i> <span>20</span>
                            </a>
                        </div>
                       
                      
                      </div>
                    </div>
                    <div className="item">    
                    <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids p-3">
                        <div className="d-flex creators_details">
                          <a href="#"><img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid imgRound" /></a>
                          <a href="#">
                            <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid imgRound" />
                            <img src={require("../assets/images/verifiedTick.png")} alt="User" className="img-fluid imgVerify" />
                          </a>
                          <a href="#"><img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid imgRound" /></a>
                        </div>
                        <div class="dropdown">
                          <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                            <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                          </div>
                        </div>
                      </div> 
                      <div className="nft_img_panel">
                        <a href="#"><img src={require("../assets/images/explore-image-8.jpg")} alt="Collections" className="img-fluid explore_img" /></a>
                      </div>
                      <div className="pt-0 p-3 paddin_but_152">
                          <h2><a href="#">NexusNFT #05</a></h2>
                          <h3>From <span>0.08 ETH</span> 49/100</h3>
                          <div className="exploreTitle">
                          <button className="exploreBidLink" data-toggle="modal" data-target="#place_bid_modal">Place a Bid</button>
                            <a href="javascript:void(0)" className="masonry_likes">
                              <i className="far fa-heart mr-1"></i> <span>20</span>
                            </a>
                        </div>
                       
                      
                      </div>
                    </div>
                  </div>






























          {/* <div className="masonry">

            <div className="item">
              
              <div className="nft_img_panel">
                <img src={require("../assets/images/masonary_01.png")} alt="Collections" className="img-fluid explore_img" />
                <div className="countdown_panel">
                        <div className="masonry_likes">
                          <i className="far fa-heart mr-1"></i>
                          
                        </div>
                        </div>
              </div>
              
              <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids">
                <div className="d-flex creators_details">
                  <img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid" />
                  <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid" />
                  <img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid" />
                </div>
                <div class="dropdown">
                  <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                  </div>
                </div>
              </div>
              <div class="pt-0 p-3 paddin_but_152">
                <h2>NexusNFT #05</h2>
                <div class="d-flex justify-content-between align-items-end">
                  <div>
                    <h3><span>0.061 BNB</span>  4 of 7</h3>
                    <h3 class="cla_buttom_link">bid <span>2.55 ETH</span></h3>
                    </div>
                    </div>
                    </div>
              </div>

            <div className="item">
              
              <div className="nft_img_panel">
                <img src={require("../assets/images/masonary_02.png")} alt="Collections" className="img-fluid explore_img" />
                <div className="countdown_panel">
                        <div className="masonry_likes">
                          <i className="far fa-heart mr-1"></i>
                          
                        </div>
                        </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids">
                <div className="d-flex creators_details">
                  <img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid" />
                  <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid" />
                  <img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid" />
                </div>
                <div class="dropdown">
                  <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                  </div>
                </div>
              </div>
            
              <div class="pt-0 p-3 paddin_but_152">
                <h2>NexusNFT #05</h2>
                <div class="d-flex justify-content-between align-items-end">
                  <div>
                    <h3><span>0.061 BNB</span>  4 of 7</h3>
                    <h3 class="cla_buttom_link">bid <span>2.55 ETH</span></h3>
                    </div>
                    </div>
                    </div>
              
            </div>

            <div className="item">
              
              <div className="nft_img_panel">
                <img src={require("../assets/images/masonary_03.png")} alt="Collections" className="img-fluid explore_img" />
                <div className="countdown_panel">
                        <div className="masonry_likes">
                          <i className="far fa-heart mr-1"></i>
                          
                        </div>
                        </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids">
                <div className="d-flex creators_details">
                  <img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid" />
                  <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid" />
                  <img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid" />
                </div>
                <div class="dropdown">
                  <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                  </div>
                </div>
              </div>
              <div class="pt-0 p-3 paddin_but_152">
              <h2>NexusNFT #05</h2>
                <div class="d-flex justify-content-between align-items-end">
                  <div>
                    <h3><span>0.061 BNB</span>  4 of 7</h3>
                    <h3 class="cla_buttom_link">bid <span>2.55 ETH</span></h3>
                    </div>
                    </div>
                    </div>
            </div>

            <div className="item">
              
              <div className="nft_img_panel">
                <img src={require("../assets/images/masonary_04.png")} alt="Collections" className="img-fluid explore_img" />
                <div className="countdown_panel">
                        <div className="masonry_likes">
                          <i className="far fa-heart mr-1"></i>
                          
                        </div>
                        </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids">
                <div className="d-flex creators_details">
                  <img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid" />
                  <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid" />
                  <img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid" />
                </div>
                <div class="dropdown">
                  <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                  </div>
                </div>
              </div>
              <div class="pt-0 p-3 paddin_but_152">
                <h2>NexusNFT #05</h2>
                <div class="d-flex justify-content-between align-items-end">
                  <div>
                    <h3><span>0.061 BNB</span>  4 of 7</h3>
                    <h3 class="cla_buttom_link">bid <span>2.55 ETH</span></h3>
                    </div>
                    </div>
                    </div>
            </div>
            <div className="item">
              
              <div className="nft_img_panel">
                <img src={require("../assets/images/masonary_04.png")} alt="Collections" className="img-fluid explore_img" />
                <div className="countdown_panel">
                        <div className="masonry_likes">
                          <i className="far fa-heart mr-1"></i>
                          
                        </div>
                        </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2 menue_toglr_hot_bids">
                <div className="d-flex creators_details">
                  <img src={require("../assets/images/seller_01.jpg")} alt="User" className="img-fluid" />
                  <img src={require("../assets/images/seller_02.jpg")} alt="User" className="img-fluid" />
                  <img src={require("../assets/images/seller_03.jpg")} alt="User" className="img-fluid" />
                </div>
                <div class="dropdown">
                  <span className="nft_more_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuButton"><i class="bi bi-three-dots"></i></span>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                  </div>
                </div>
              </div>
              <div class="pt-0 p-3 paddin_but_152">
                <h2>NexusNFT #05</h2>
                <div class="d-flex justify-content-between align-items-end">
                  <div>
                    <h3><span>0.061 BNB</span>  4 of 7</h3>
                    <h3 class="cla_buttom_link">bid <span>2.55 ETH</span></h3>
                    </div>
                    </div>
                    </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
