import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import HeaderLinks from "components/Header/HeaderLinks.js";
import HeaderLinksCommunity from "components/Header/HeaderLinksCommunity.js";

import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ShippingPolicy(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/></Link>}
        rightLinks={<HeaderLinksCommunity />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            
          </GridContainer>
        </div>
        <div className="container mt-4">
          <GridContainer className="privecy_12">
            <GridItem xs={12} sm={12} md={12}>
              <h1>Free Ground Shipping in the Continental US and abroad</h1>
              <p className="terms_des">All orders qualify for free ground shipping. With Second Day Air and Overnight used solely at our discretion.</p>
              <p className="info_title">International Shipping</p>
            <p className="terms_des">Boui Corporation ships internationally via FedEx's international economy service type. </p>
           <p className="terms_des">Please note: Boui Corporation only covers the cost of shipping the item from the US to your country. You may be subject to separate import fees such as taxes or duties that are assessed after the shipment reaches your country. Import fees vary greatly from country to country, and in some cases may even prohibit the import of certain types of used equipment. Please check with your local government for a breakdown of import and duty costs prior to placing an order. </p> 
            <p className="terms_des">Any additional taxes, duties, or fees assessed for import are the responsibility of the customer, and are normally collected by the courier upon import into your country. If a package arrives and you find the import fees prohibitive, we can contact FedEx and request that the package be returned to the shipper, however any fees to return the package would have to coordinated by contacting-us and will incur fees that must be covered by you. </p>
            <p className="terms_des">If you need further assistance our Sales Professionals can be reached at <a href="mailto:info@boui.io">info@boui.io</a></p>

            
            
            
            
            
            
            
            
            
            
           
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
