import React,{useState,useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { Link,useHistory } from "react-router-dom";
import { setCurrentUser,register,login,forgotPassword } from '../../actions/community';
import isEmpty from '../../lib/isEmpty';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {getmyprovider} from "../../lib/walletconnect";
import init from "bnc-onboard";

const useStyles = makeStyles(styles);

const initialFormValue = {
  'email': "",
  'username': "",
  'name': "",
  'password': "",
  'loginemail':"",
  'loginpassword':""
}
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};
export default function Header(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen]      = useState(false);
  const history                          = useHistory();
  const [formValue, setFormValue]        = useState(initialFormValue);
  const [validateError, setValidateError]= useState({});
  const [Provider, setProvider] = useState();

  const onChange = (e) => {
    e.preventDefault();
   const { id, value } = e.target;
   let formData = { ...formValue, ...{ [id]: value } }
   setFormValue(formData)
 }
 const {
  name,
  username,
  password,
  email
} = formValue

useEffect(()=>{
  init()
},[])

const init = async()=>{
  let provider = await getmyprovider();
  setProvider(provider)
}

const loginFormSubmit= async(e)=>{
  e.preventDefault();
  axios
     .get("https://pro.ip-api.com/json?key=VT6Pto4zNt4X0vF")
     .then(async (response) => {
       var data= response.data;
     let reqData = {
     email,
     password,
    data
     }
     const {error, result,status,active,token } = await login(reqData);
     console.log("Logged:",active,result,status)
     if (isEmpty(error)) {
       if (active!="Not Activated") {
         setFormValue(initialFormValue); 
         toast.success('Logged in Successfully.', toasterOption);
         setTimeout(() => window.location.href="/community", 1200);
         dispatch(setCurrentUser(token))
       }else{
         toast.success('Your account has been not activated.please contact admin', toasterOption);
         setValidateError("");
       }
   } else { 
     setValidateError(error);
   }
 })
}
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const currAddr = Provider.address;
    let reqData = {
      name,
      username,
      password,
      email,
    }
    const {error} = await register(reqData);
 console.log("error:",error)
    if (isEmpty(error)) {
      setValidateError("");
      setFormValue('')
      toast.success("Thank you for signing up. Please check your email account and click on the activation link.Thank you", toasterOption);
      setTimeout(() => document.getElementById('register_modal').click(), 600);
      setTimeout(() => window.location.reload(false), 1200);
    } else {
      setValidateError(error);
    }
   }
  
   async function handleforgotFormSubmit(e){
    e.preventDefault();
     let reqData = {
      email
  }
  let {  error } = await forgotPassword(reqData);
  console.log("forgot:",error)
  if (isEmpty(error)) {
    setFormValue(initialFormValue)     
    toast.success('We have sent you a password reset email', toasterOption);
    setTimeout(() => document.getElementById('pwd_modal').click(), 600);
    setTimeout(() => window.location.reload(false), 1200);
    setValidateError('')
  } else {
      setValidateError(error);
  }
  }
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Link className={classes.title} to="/home">{brand}</Link>;
  return (
    <div>
      <AppBar className={appBarClasses + " bouiNavbar"}>
        <Toolbar className="container">
          {leftLinks !== undefined ? brandComponent : null}
          <div className={classes.flex}>
            <div className="headerLeft">
            {leftLinks !== undefined ? (
              <Hidden mdDown implementation="css">
                {leftLinks}
              </Hidden>
            ) : (
              brandComponent
            )}
            {/*<Hidden mdDown implementation="css">
                 <div className="search_inp_group">
                  <input type="text" className="search_inp" placeholder="Balaitems & collections" />
                  <div className="search_inp_group_append">
                    <i className="fas fa-search"></i>
                  </div>
                </div>
            </Hidden>*/}
            </div>
          </div>
          <Hidden mdDown implementation="css">
            {rightLinks}

          </Hidden>
          <Hidden lgUp>
            <IconButton
              className="hamburger_icon"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden lgUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            onClose={handleDrawerToggle}
          >
            <div className={classes.appResponsive}>
              {leftLinks}
              {rightLinks}
            </div>
          </Drawer>
        </Hidden>
      </AppBar>


       {/* Login Modal */}
       <div class="modal fade primary_modal" id="login_modal" tabindex="-1" role="dialog" aria-labelledby="login_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="login_modalLabel">Login</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="login_form" onSubmit={loginFormSubmit}>
                <label for="email">Email Address</label>
                <div class="mb-3">
                  <input type="email" id="email" name="email"
                  autoComplete="off" onChange={onChange}
                  class="form-control" placeholder="Email Address" />
  {
                  validateError.email && <span className="text-danger">{validateError.email}</span>
                  }
                </div>
                <label for="password">Password</label>
                <div class="mb-3">
                  <input type="password" id="password" name="password" onChange={onChange} autoComplete="off" class="form-control" placeholder="************" />
                  {
                  validateError.password && <span className="text-danger">{validateError.password}</span>
                  }
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" type="submit">Login</Button>
                </div>
                <div className="text-center mt-3">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <span className="pwd_link" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#pwd_modal">Forgot Password</span>
                    </div>
                    <div className="col-12 col-md-6">
                      <span className="create_link text-md-right" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#register_modal">Create Account</span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Create Account Modal */}
      <div class="modal fade primary_modal" id="register_modal" tabindex="-1" role="dialog" aria-labelledby="register_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="register_modalLabel">Create Account</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="login_form" onSubmit={handleFormSubmit}>
                <label for="email_reg">Email Address</label>
                <div class="mb-3">
                  <input type="email" id="email" name="email" class="form-control" onChange={onChange} autoComplete="off" placeholder="Never shown to the public" />
                  {
                    validateError.email && <span className="text-danger">{validateError.email}</span>
                  }
                </div>
                <label for="user_reg">Username</label>
                <div class="mb-3">
                  <input type="text" id="username" name="username" class="form-control" onChange={onChange} autoComplete="off" placeholder="Enter the username" />
                  {
                    validateError.username && <span className="text-danger">{validateError.username}</span>
                  }
                </div>
                <label for="username_reg">Name</label>
                <div class="mb-3">
                  <input type="text" id="name" name="name" class="form-control"
                   onChange={onChange} autoComplete="off" placeholder="Enter the name" />
                  {
                    validateError.name && <span className="text-danger">{validateError.name}</span>
                 }
                </div>
                <label for="password_reg">Password</label>
                <div class="mb-3">
                  <input type="password" id="password" name="password" class="form-control" onChange={onChange} autoComplete="off" placeholder="Atleast 5 characters" />
                  {
                    validateError.password && <span className="text-danger">{validateError.password}</span>
                 }
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" type="submit">Create New Account</Button>
                </div>
                <div className="text-center mt-3">
                  <span className="privacy_link" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#login_modal">Login</span>
                </div>
                <div className="text-center">
                  <p className="reg_desc mb-0 text-dark">By registering, you agree to the <span className="d-block"><Link to="/privacy" target="_blank" className="privacy_link px-1">Privacy Policy</Link> and <Link to="/terms" target="_blank" className="privacy_link pl-1">Terms of service</Link></span></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Forgot Password Modal */}
      <div class="modal fade primary_modal" id="pwd_modal" tabindex="-1" role="dialog" aria-labelledby="pwd_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="pwd_modalLabel">Forgot Password</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p className="forgot_desc">Enter your username / email address, and we'll send you a password reset email.</p>
              <form className="login_form" onSubmit={handleforgotFormSubmit}>
                <label for="email_username">Username / Email Address</label>
                <div class="mb-3">
                  <input type="email" id="email" name="email" onChange={onChange} autoComplete="off" class="form-control" placeholder="Enter username / email" />
                  {
                    validateError.email && <span className="text-danger">{validateError.email}</span>
                 }
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" type="submit">Submit</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>

  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
