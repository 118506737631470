/*eslint-disable*/
import React,{useState,useEffect} from "react";
import { Notifications, AccountBalanceWallet } from '@material-ui/icons';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link,NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { setCurrentUser, logout,currentUser } from "../../actions/community";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles(styles);
toast.configure();
  let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  }
export default function HeaderLinksCommunity(props) {
  const classes = useStyles();
  const [userstatus, setUser] = useState('');


  useEffect(() => {
    getcurrentUser();
  }, []);

  async function getcurrentUser(){
    var userDetails = await currentUser(); 
    console.log("currentuser:",userDetails)
     if (userDetails&&userDetails.result) {
       setUser(userDetails.result.active)
     }else{
       localStorage.removeItem('ljmkasdh');
     }
 }

 const handlelogout = (e) => {
  logout(history);
  toast.success('Logout Successfully.', toasterOption);
  window.location.href="/discussion"
}
  return (
    <List className={classes.list + " main_navbar commun_navbar"}>
      <ListItem className={classes.listItem}>
      {/* <div className="search_inp_group search_input_community">
        <input type="text" className="search_inp" placeholder="Search collections / creators" />
        <div className="search_inp_group_append">
          <i className="fas fa-search"></i>
        </div>
      </div> */}
      </ListItem>
      {(!localStorage.community_token ||userstatus=="Not Activated") && 

      <ListItem className={classes.listItem}>
        <Button className={classes.navLink + " create_btn btn_header mr-3"} data-toggle="modal" data-target="#login_modal">
          Login
        </Button>
      </ListItem>
}
{ (!localStorage.community_token || userstatus=="Not Activated") && 

      <ListItem className={classes.listItem}>
        <Button className={classes.navLink + " create_btn btn_header"} data-toggle="modal" data-target="#register_modal">
         Signup
        </Button>
      </ListItem>
}
{localStorage.community_token && userstatus!="Not Activated" &&
   <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul noti_ul"}>
   <CustomDropdown
    noLiPadding
    className="balance_check"
    buttonText={ <div id="clickButton" ><AccountBalanceWallet  className="menu_icons balance_check"/></div>}        
    dropdownList={[
    <div className="balance_check">
      <hr/>
      <div className="noti_parent">
        <NavLink to="/edit-profile-community" className={classes.dropdownLink}>
          Profile
        </NavLink>
        <NavLink to="/community" className={classes.dropdownLink}>
          Community
        </NavLink>
      <NavLink  to="#" onClick={handlelogout}  className={classes.dropdownLink}>
      Logout
      </NavLink>
      </div>
    </div>
    ]}
  />
{/*   
<ListItem className={classes.listItem} onClick={handlelogout}>
<Button className={classes.navLink + " create_btn btn_header mr-3"} data-toggle="modal" data-target="#login_modal">
          Logout
        </Button> */}
    </ListItem>
}
    </List>

    
  );
}
