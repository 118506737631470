import React from "react";
import { Link } from "react-router-dom";
import config from "../../lib/config";
import { SecondsToDay } from '../../helper/dateFormat';
import { halfAddrShow } from "actions/v1/user";

export default function ActivityTab(props) {
// console.log("activity_list",item)
    var {
        item,
        UserAccountAddr
    } = props;

    var imageUrl =config.Back_URL+"/images/noimage.png";
    var text = "";
    var name = "";
    
    try{
        var UserAccountAddr = window.web3.eth.defaultAccount;
      }catch(err){
        var UserAccountAddr =""
      }
      if(item.actiontype=="following"){
        var followTxt = halfAddrShow(item.touseraddress);
        if(item.touseraddress==UserAccountAddr){
            followTxt = "You ";
        }
        text = "started following "+halfAddrShow(item.touseraddress);
        name = (item.userdetail && item.userdetail.name !="")?item&&item.userdetail&&item.userdetail.name:halfAddrShow(item&&item.userdetail&&item.userdetail.curraddress);
      }
      else if(item.actiontype=="listings"){
            if ((item&&item.tokenuser&&item.tokenuser.PutOnSaleType)=="FixedPrice") {
            text = "Listed for "+item.price+" "+(item.currency)+ " by "+(item&&item.name);
            }else if ((item&&item.tokenuser&&item.tokenuser.PutOnSaleType)=="TimedAuction") {
            text = "Listed for "+item.price+" "+(item.currency)+ " by "+(item&&item.name);
            }else{
            text = "Listed "+"by "+" "+(item&&item.name);
            }
            // name = (item.name)?item.name:""
      } else if(item.actiontype=="edit bid"){
            text="edit bid for "+(item.price)+" "+(item.currency)+" by "+(item&&item.name)
            // name = (item.name)?item.name:""
      }
      else if(item.actiontype=="place bid"){
        text="place bid for "+(item.price)+" "+(item.currency)+" by "+(item&&item.name)
        // name = (item.name)?item.name:""
      }
      else if(item.actiontype=="sales"){
        text = "sales for "+item.price+" "+(item&&item.tokendetail&&item.tokendetail.biddingtoken)+ " by "+
        (item&&item.tokenuser&&item.tokenuser.tokenName);
        name = (item.name)?item.name:""
      } 
      else if(item.actiontype=="acceptBid"){
            text = "acceptBid for "+(item.price)+" "+(item.currency)+ " to "+(item&&item.tokenuser&&item.tokenuser.tokenName);
            name = (item.name)?item.name:""
      }
     
      else if(item.actiontype=="purchase"){
            text = "purchased for "+item.price+" "+(item.currency)+ " by "+(item&&item.tokenuser&&item.tokenuser.tokenName);
            name = (item.name)?item.name:""
      }           
     
      else if(item.actiontype=="burn"){
            text = "Burn by "+(item&&item.tokenuser&&item.tokenuser.tokenName);
            name = (item.name)?item.name:""
      }
      else if(item.actiontype=="like"){
          text = "liked by "+(item&&item.name);
        //   name = (item.name)?item.name:""
      }
      else if(item.actiontype=="unlike"){
          text = "unliked by "+(item&&item.name);
        //   name = (item.name)?item.name:""
      }
      else if(item.actiontype=="reporting"){
        text = "reporting by "+(item&&item.name);
        // name = (item.name)?item.name:""
      }
      else if(item.actiontype=="sharing"){
        text = "Shared by "+(item&&item.name);
        // name = (item.name)?item.name:""
      }
      
      var linkUrl = "/info/"+item.tokenCounts;
      if(item.itemtype=="users"){
          linkUrl = "/user/"+(item&&item.tokenuser&&item.tokenuser.tokenName);
      }
      if(name.length > 15) {
          name = name.substring(0, 18) + "...";
      }

    return (
        <div className="col-12 col-md-12 mb-4">
            <div className="card my-0">
                <div className="card-body px-3">
                    <div className="media follow_media">
                        <Link to={linkUrl}>
                            <img src={imageUrl} height="50px" width="50px" alt="User" className="img-fluid mr-3" />
                        </Link>
                        <div className="media-body flex_body">
                            <div>
                                <p className="my-0 media_text">{name}</p>
                                <p className="mt-0 media_num mt-0">{text}</p>
                            </div>
                        </div>
                    </div>
                    <div className="ml-2">
                        <p className="mt-0 media_num mb-0 dur_text">{SecondsToDay(item.timestamp)}</p>
                    </div>

                </div>
            </div>
        </div>
    )
}