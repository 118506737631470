import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link,useHistory } from "react-router-dom";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import config from '../lib/config'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};
const dashboardRoutes = [];
const networkId       = config.networkVersion;
const useStyles       = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ConnectWallet(props) {
  const classes                            = useStyles();
  const { ...rest }                        = props;
  const history                            = useHistory();
  const [accounts, setAccounts]            = useState();
  const [connectstatus, setconnectstatus]  = useState(false);

  useEffect(()=>{
    WalletConnect()
  })
  const provider = new WalletConnectProvider({
    rpc: {
      97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      56: "https://bsc-dataseed.binance.org/",
    },
  });
  async function WalletConnect(){
    if(localStorage.getItem("tfniuobnft")){
      history.push("/home")
    }else{
    }
  }
  async function connectMetamask(){
    if (window.ethereum) {
    var web3 = new Web3(window.ethereum);
    try{
      if (typeof web3 !== 'undefined') {
        window.ethereum.enable().then(async function() {
        const web3  = new Web3(window.web3.currentProvider)
        // if (window.web3.currentProvider.isMetaMask === true) {
          await web3.eth.getAccounts(async function(error, result) {
            setAccounts(result[0]);
            localStorage.setItem("tfniuobnft",'yes');
            window.location.href="/home"
          })
        // }else{
        //   toast.error("Please Add Metamask External", toasterOption);
        // }
      })
      }else{
        toast.error("Please Add Metamask External", toasterOption);
      }
    }catch(err){
    }
  }else{
    toast.error("Please Add Metamask External", toasterOption);
  }
  }
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center" >
                <Link to="/home">
                  <img src={require("../assets/images/arrow_icon.png")} alt="logo" className="arrow_icon" />
                </Link>
                <h2 className="inner_title">Connect your wallet</h2>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container mt-4">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <p className="create_para">Connect with one of available wallet providers or create a new wallet.</p>
              <div className="create_box_panel row connect_row">
                <div className="col-12 col-sm-6 col-lg-4 mb-3" onClick={connectMetamask}>
                  <Link to="" className="create_box connect_wallet_box">
                    <h2>Metamask</h2>
                    <img src={require("../assets/images/metamask.svg")} alt="logo" className="img-fluid mt-3" />
                    <p className="mb-0 text-center mt-3">One of the most secure wallets with great flexibility</p>
                  </Link>
                </div>
               {/* <div className="col-12 col-sm-6 col-lg-4 mb-3">
                  <Link to="/create-multiple" className="create_box connect_wallet_box">
                    <h2>WalletConnect</h2>
                    <div className="d-flex align-items-center justify-content-center">
                      <img src={require("../assets/images/metamask.svg")} alt="logo" className="img-fluid mt-3 mr-2" />
                      <img src={require("../assets/images/wallet.svg")} alt="logo" className="img-fluid mt-3 mr-2" />
                      <img src={require("../assets/images/wallet2.svg")} alt="logo" className="img-fluid mt-3" />
                    </div>
                    <p className="mb-0 text-center mt-3">Connect with Rainbow, Trust, Argent and more</p>

                  </Link>
                </div>*/}
              </div>
              <p className="create_para mt-3">We do not own your private keys and cannot access your funds without your confirmation.</p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
