import axios from 'axios';

// import action
import { decodeJwt } from './jsonWebToken';

// import lib
import config from '../lib/config';
import setAuthToken from '../lib/setAuthToken';

import {
    SET_CURRENT_USER
} from '../constant';
import { ModeComment } from '@material-ui/icons';


// import constant
// import {
//     SET_CURRENT_USER
// } from '../constant';

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const login = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.Back_URL}community/login`,        
            data
        });
  console.log("login@@@@@",respData)
        localStorage.setItem('community_token', respData.data.token);
        setAuthToken(respData.data.token)
        return {
            loading: false,
            token:respData.data.token,
            result: respData.data.result,
            status:respData.data.result.status,
            active:respData.data.result.active
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const register = async (data) => {

    try {
        console.log(data, "checking data");
        let respData = await axios({
            'method': 'post',
            'url': `${config.Back_URL}community/register`,
            data
        });
        
        return {
            loading: true,
            result: respData.data.result
        }

    }
    catch (err) {
     
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const updateuser = async (data) => {

  try {
      console.log(data.photo, "updateuser data");
      const bodyFormData = new FormData();
      bodyFormData.append("name", data.name);
      bodyFormData.append("username", data.username);
      bodyFormData.append("email", data.email);
      bodyFormData.append("photo", data.photo);
      bodyFormData.append("userid", data.userid);
      let respData = await axios({
          'method': 'post',
          'url': `${config.Back_URL}community/updateuser`,
          'headers': {
            'Authorization': localStorage.community_token
        },
        data: bodyFormData
      });
      
      return {
          loading: true,
          result: respData.data.result
      }

  }
  catch (err) {
   
      return {
          loading: false,
          error: err.response.data.errors
      }
  }
}

export const logout = (history) => {
    localStorage.removeItem('community_token');
    // history.push('/discussion')
    setAuthToken('')
  }
export const forgotPassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.Back_URL}community/forgotPassword`,
            data
        });

        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const changepassword = async (data) => {

    try {
    
        let respData = await axios({
            'method': 'post',
            'url': `${config.Back_URL}community/changepassword`,
              'headers': {
                'Authorization': localStorage.user_token
            },
            data: data
        });
      return {
            loading: false,
            userValue: respData.data.userValue
        }
        
    }catch (err) {
        console.log("passwprd",err)
         return {
            loading: false,
            error: err.response.data
        }
    }
}
export const activateuser = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.Back_URL}community/activateuser`,   
            'headers': {
                'Authorization': localStorage.user_token
            },        
            data: data
        });
        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
    
  export const currentUser = async (token, dispatch) => {

    console.log(token,'token')

    console.log(dispatch,'dispatch')
    try {
      let respData = await axios({
        method: "get",
        'url': `${config.Back_URL}community/currentUser`,
      'headers': {
                'Authorization': localStorage.community_token
            },
      });
      console.log("respData.data",respData.data)
     return {
            loading: true,
            result: respData.data.userValue
        }
    } catch (err) {
      return false;
    }
  };
export const lastestdiscussion = async (data) => {

    try {
      let respData = await axios({
        method: "post",
        'url': `${config.Back_URL}community/latest-community`,
        headers: {
          Authorization: localStorage.community_token,
        },
        data
      });
      return {
        loading: true,
        list: respData.data.list
    }
    } catch (err) {
      return false;
    }
  };
   export const likeCommunity = async (data) => {

    try {
      let respData = await axios({
        method: "post",
        'url': `${config.Back_URL}community/like-community`,
        headers: {
          Authorization: localStorage.community_token,
        },
        data
      });
       
      return {
        loading: true,
        status: respData.data.status
    }
    } catch (err) {
      return false;
    }
  };
   export const getTopices = async (data) => {

    try {
      let respData = await axios({
        method: "post",
        'url': `${config.Back_URL}community/topic-list`,
        headers: {
          Authorization: localStorage.community_token,
        },
        data
      });
      return {
        loading: true,
        list: respData.data.list,
        skip:respData.data.skip,
    }
    } catch (err) {
      return false;
    }
  };
    export const mostLikes = async (data) => {

    try {
      let respData = await axios({
        method: "post",
        'url': `${config.Back_URL}community/most-likes`,
        headers: {
          Authorization: localStorage.community_token,
        },
        data
      });
      return {
        loading: true,
        list: respData.data.list,
        skip:respData.data.skip,
    }
    } catch (err) {
      return false;
    }
  };
 export const paginatediscussion = async (data) => {

    try {
      let respData = await axios({
        method: "post",
        'url': `${config.baseUrl}/community/next-details`,
        headers: {
          Authorization: localStorage.community_token,
        },
        data
      });
       
      return {
        loading: true,
        details: respData.data.details,
        skip:respData.data.page,
    }
    } catch (err) {
      return false;
    }
  };

  export const replytopic = async (data) => {

    try {
      let respData = await axios({
        method: "post",
        'url': `${config.Back_URL}community/reply-topic`,
        headers: {
          Authorization: localStorage.community_token,
        },
        data
      });
       
      return {
        loading: true,
        result: respData.data.result,
        count:respData.data.count,
    }
    } catch (err) {
      return false;
    }
  };


  

  export const updateViewCount = async (data) => {

    try {
      let respData = await axios({
        method: "post",
        'url': `${config.Back_URL}community/update-view`,
        headers: {
          Authorization: localStorage.community_token,
        },
        data
      });
      return {
        loading: true,
        categories: respData.data.categories
    }
    } catch (err) {
      return false;
    }
  };
    export const discussionCategory = async () => {

    try {
      let respData = await axios({
        method: "get",
        'url': `${config.Back_URL}community/discussion-category`,
        headers: {
          Authorization: localStorage.community_token,
        },
      });
      return {
        loading: true,
        categories: respData.data.categories
    }
    } catch (err) {
      return false;
    }
  };
export const communityCategory = async (data) => {

    try {
      let respData = await axios({
        method: "post",
        'url': `${config.Back_URL}community/community-category`,
        headers: {
          Authorization: localStorage.community_token,
        },
        data
      });
      return {
        loading: true,
        list: respData.data.list
    }
    } catch (err) {
      return false;
    }
  };
export const latestComunityCategory = async (data) => {

    try {
      let respData = await axios({
        method: "post",
        'url': `${config.Back_URL}community/latest-category-community`,
        headers: {
          Authorization: localStorage.community_token,
        },
        data
      });
      return {
        loading: true,
        list: respData.data.list
    }
    } catch (err) {
      return false;
    }
  };
    export const topdiscussion = async (data) => {

    try {
      let respData = await axios({
        method: "post",
        'url': `${config.Back_URL}community/top-community`,
        headers: {
          Authorization: localStorage.community_token,
        },
        data
      });
      return {
        loading: true,
        list: respData.data.list
    }
    } catch (err) {
      return false;
    }
  };
  export const createtopic = async (data) => {

    try {
        console.log(data, "checking data");
        let respData = await axios({
            'method': 'post',
            'url': `${config.Back_URL}community/createtopic`,
            'headers': {
              'Authorization': localStorage.community_token
          },
            data
        });
        
        return {
            loading: true,
            result: respData.data.result
        }

    }
    catch (err) {
     
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const deleteaccount = async (data) => {

  try {
      console.log(data, "checking data");
      let respData = await axios({
          'method': 'post',
          'url': `${config.Back_URL}community/deleteaccount`,
          'headers': {
            'Authorization': localStorage.community_token
        },
          data
      });
      
      return {
          loading: true,
          result: respData.data.result
      }

  }
  catch (err) {
   
      return {
          loading: false,
          error: err.response.data.errors
      }
  }
}

export const updateFaq = async (data) => {

    try {
        console.log(data, "checking data");
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/adminapi/updateFaq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.categories
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getcategory = async () => {

    try {
      let respData = await axios({
        method: "get",
        'url': `${config.Back_URL}community/getcategorydet`,
        headers: {
          Authorization: localStorage.community_token,
        },
      });
      console.log(respData, 'checking ResponseData');   
      return {
        loading: true,
        result: respData.data.categories
    }
    } catch (err) {
      return false;
    }
  };


  export const lastesttopic = async () => {

    try {
      let respData = await axios({
        method: "get",
        'url': `${config.Back_URL}community/lastettopic`,
        headers: {
          Authorization: localStorage.community_token,
        },
      });
      console.log(respData, 'checking ResponseData');   
      return {
        loading: true,
        result: respData.data.result
    }
    } catch (err) {
      return false;
    }
  };
export const getuser = async (id, dispatch) => {
  
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}community/getuser/`,
            headers: {
                Authorization: localStorage.community_token,
              },
        });
        return {
            loading: false,
            userValue: respData.data.userValue
        }
    }
    catch (err) {
        return {
            loading: false,
            error: (err.response && err.response.data && err.response.data.errors)?err.response.data.errors:''
        }
    }
}

  export const toptopic = async () => {

    try {
      let respData = await axios({
        method: "get",
        'url': `${config.Back_URL}community/toptopic`,
        headers: {
          Authorization: localStorage.community_token,
        },
      });
      console.log(respData, 'checking ResponseData');   
      return {
        loading: true,
        result: respData.data.result
    }
    } catch (err) {
      return false;
    }
  };


  export const lastesttopicbycategory = async () => {

    try {
      let respData = await axios({
        method: "get",
        'url': `${config.baseUrl}/community/lastettopic`,
        headers: {
          Authorization: localStorage.community_token,
        },
      });
      console.log(respData, 'checking ResponseData');   
      return {
        loading: true,
        result: respData.data.result
    }
    } catch (err) {
      return false;
    }
  };


  export const toptopicbycategory = async () => {

    try {
      let respData = await axios({
        method: "get",
        'url': `${config.baseUrl}/community/toptopic`,
        headers: {
          Authorization: localStorage.community_token,
        },
      });
      console.log(respData, 'checking ResponseData');   
      return {
        loading: true,
        result: respData.data.result
    }
    } catch (err) {
      return false;
    }
  };

  

  export const topicdetail = async (data) => {

    try {
      let respData = await axios({
        method: "post",
        'url': `${config.Back_URL}community/topicdetails`,
        headers: {
          Authorization: localStorage.community_token,
        },
        data
      });
      console.log(respData, 'checking ResponseData');   
      return {
        loading: true,
        result: respData.data.result,
        details: respData.data.details,
        count: respData.data.count,
        like: respData.data.like
    }
    } catch (err) {
      return false;
    }
  };



  

  