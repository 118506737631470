import React, { useEffect,useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksCommunity from "components/Header/HeaderLinksCommunity.js";

import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link,useParams } from "react-router-dom";
import CKEditor from 'ckeditor4-react';
import isEmpty from "./../lib/isEmpty";
import { toast } from 'react-toastify';
import config from '../lib/config';
import { SecondsToDay } from '../helper/dateFormat';
import Select from 'react-select';


import axios from "axios";
import { 
  createtopic,
  getcategory,
  lastesttopic,
  toptopic,
  lastestdiscussion,
  topdiscussion,
  latestComunityCategory,
  getuser,
  activateuser
} from "./../actions/community";
const dashboardRoutes = [];
const initialFormValue = {
  topic:"",
  description:"",
  cate:"",
  catename:"",
};
toast.configure();
  let toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  }

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Discussion(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { userId }           = useParams();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [description, setdescription] = useState({description:""});
  const { topic, cate, catename} = formValue;
  const [validateError, setValidateError] = useState({});
  const [categories, setcategories] = useState([]);
  const [toptopics, settoptopics] = useState([]);
  const [latesttopics, setlatesttopics] = useState([]);
  const [catdrop, setcatdrop] = useState([]);
  const [catList, setcatList] = useState([]);
  const [catCommunity, setcatCommunity] = useState([]);

  useEffect(() => {
    getallcategory();
    getlastesttopic();
    gettoptopic();
    getLatestcat();
    getUserData();
    getActivelogin()
  }, []);

  const handlecat = e => {
  let formData = { ...formValue, ...{ ['cate']: e.value,['catename']: e.label } };
    setFormValue(formData);
  }

  async function getActivelogin(){
    var activate_id = userId;
console.log("passdata",userId)
    if (typeof activate_id != "undefined" && activate_id != "") {
  
      let passdata = { userid: activate_id };
      console.log("passdata:",passdata)
      var res = await activateuser(passdata);
      toast.success('Your account has been activated', 'success')
      if (res.data && res.data.errormsg) {
        toast.success(res.data.errormsg, 'success')
      }
      if (res.data && res.data.message) {
        console.log(res.data, "datadatadatadatadata")
        toast.success(res.data.message, 'success')
      }
  
    }
  }
  const getallcategory = async (e) => {
    let { error, result } = await getcategory();
    if (isEmpty(error)) {
     setcategories(result); 
     setcatList(result);
     if(result){
      var cat = result.map(opt => ({ label: opt.name, value: opt._id }));
      setcatdrop(cat);
     }
 
    } else { 
    }
    }
     const getLatestcat = async (e) => {
      let { list } = await latestComunityCategory({});
      console.log("list:",list)
      setcatCommunity(list); 
     }

    const getlastesttopic = async (e) => {

      let { list } = await lastestdiscussion({id:""});
       setlatesttopics(list); 
     }

      const gettoptopic = async (e) => {
        let { error, list } = await topdiscussion();
        
         settoptopics(list); 
        
      }
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;

    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
  };
     const onEditorChange = (evt) => {
    var description_text = evt.editor.getData();   
    setdescription(description_text)
    
}
 const getUserData = async () => {
    var test = await getuser();
   let formdata = {};
   console.log("getuser",test)
 //   if (test.userValue!=undefined) {
 //   setUserID(test.userValue._id);
 // }else{

 // }
}
const handletopicSubmit = async (e) => {
    e.preventDefault();
    if(validation()){
    let reqData = {
      topic,
      description,
      cate,
      catename
      
    };
    console.log(reqData,'reqDatareqData')
    let { error, result } = await createtopic(reqData);
    console.log(error,'errr');
    console.log(result, "result");

    if (isEmpty(error)) {
     
      setFormValue(initialFormValue); 

      toast.success("Topic Created Successfully.", toasterOption);
      window.location.href="/community"
    } else { 
      setValidateError(error);
    
    }
  }
  };
  const onChangePeriod = async (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let { list } = await topdiscussion({value:value});
    settoptopics(list); 

  };
  const onChangeCat = async (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    console.log("changecat:",value)
    let { list } = await lastestdiscussion({id:value});
    console.log("lastestdiscussion",list)
    setlatesttopics(list); 
  };
  function validation(){

    var isForm = true;
    var errors ={};

    if(topic==""){
      isForm = false;
      errors.topic ="Title is requird"
    }
    else if(cate==""){
      isForm = false;
      errors.cate ="Category is requird"
    }else if(description==""){
      isForm = false;
      errors.description ="Description is requird"
    }
    setValidateError(errors);
    return isForm;

  }

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinksCommunity />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
        {localStorage.community_token  &&
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="inner_title">General Community</h2>
                <Button className="create_btn" data-toggle="modal" data-target="#new_top_modal">New Topic</Button>
              </div>
            </GridItem>
          </GridContainer>
}
        </div>
        <div className="container mt-3">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <nav className="masonry_tab_nav mt-4 items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab dis_tab px-0 mx-0" id="nav-tab" role="tablist">
                  <a className="nav-link active ml-0" id="categories-tab" data-toggle="tab" href="#categories" role="tab" aria-controls="categories" aria-selected="true">
                    Categories
                  </a>

                  <a className="nav-link" id="latest-tab" data-toggle="tab" href="#latest" role="tab" aria-controls="latest" aria-selected="false">
                    Latest
                  </a>
                  <a className="nav-link" id="top-tab" data-toggle="tab" href="#top" role="tab" aria-controls="top" aria-selected="false">
                    Top
                  </a>
                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-0" id="nav-tabContent">
                <div className="tab-pane fade show active" id="categories" role="tabpanel" aria-labelledby="all-tab">
                  <div className="proposal_panel_overall">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="title_div">
                          <div className="row">
                            <div className="col-12">
                              <p className="title_discussion mb-0">Category</p>
                            </div>
                            
                          </div>
                        </div>
                        <div className="content_div">
                          <ul class="list-group list_grp_style_1 p-0">
                          {catCommunity && catCommunity.map((row,index) => {
                            return (
                            <li class="list-group-item">
                              <div className="row">
                                <div className="col-9">
                                  <Link to={"/category/"+row.id}><p className="cat_title">{row.name}</p></Link>
                                  <p className="cat_desc mb-0">{row.description}</p>

                                </div>
                                {/* <div className="col-3 px-0">
                                  <p className="cat_dur mb-0 text-right">
                                    <span>96 /</span>
                                    <span className="pl-1">week</span>

                                  </p>
                                </div> */}
                              </div>
                            </li>)}) }
                            {/* <li class="list-group-item">
                              <div className="row">
                                <div className="col-9">
                                  <Link to="/category"><p className="cat_title">Technical upgrades</p></Link>
                                  <p className="cat_desc mb-0">Technical improvements, support of new formats, bug fixes, and other tech-related questions</p>

                                </div>
                                <div className="col-3 px-0">
                                  <p className="cat_dur mb-0 text-right">
                                    <span>6 /</span>
                                    <span className="pl-1">week</span>

                                  </p>
                                </div>
                              </div>
                            </li>
                            <li class="list-group-item">
                              <div className="row">
                                <div className="col-9">
                                  <Link to="/category"><p className="cat_title">Platform development / new features</p></Link>
                                  <p className="cat_desc mb-0">Feature requests, additions, new tabs & functions, and other questions on platform development.</p>

                                </div>
                                <div className="col-3 px-0">
                                  <p className="cat_dur mb-0 text-right">
                                    <span>2 /</span>
                                    <span className="pl-1">week</span>

                                  </p>
                                </div>
                              </div>
                            </li>
                            <li class="list-group-item">
                              <div className="row">
                                <div className="col-9">
                                  <Link to="/category"><p className="cat_title">General Community</p></Link>
                                  <p className="cat_desc mb-0">General community discussion topics and posts</p>

                                </div>
                                <div className="col-3 px-0">
                                  <p className="cat_dur mb-0 text-right">
                                    <span>96 /</span>
                                    <span className="pl-1">week</span>

                                  </p>
                                </div>
                              </div>
                            </li> */}

                          </ul>
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="title_div">
                          <div className="row">
                            <div className="col-9">
                              <p className="title_discussion mb-0">Latest</p>
                            </div>
                            <div className="col-3">
                              <p className="title_discussion mb-0 text-right"></p>
                            </div>
                          </div>
                        </div>
                        <div className="content_div">
                          <ul class="list-group list_grp_style_1 p-0">
                          {catCommunity && catCommunity.map((row,index) => {
                            console.log("Latest",row)
                          return (
                            <li class="list-group-item">
                              
                              {row && row.topic && row.topic._id &&
                                <div className="contentLoop">
                                  <Link to={"/discussion-detail/"+row.id}><p className="cat_title">{row.topic.topic}</p></Link>
                                  <Link to={"/category/"+row.id}><p className="cat_link cat_link_green mb-0">{row.name}</p></Link>
                                </div>
                          }
                          {row && row.topic =="" &&
                                <div className="col-3 px-0">
                                              <Link to="/disussion-detail"><p className="cat_title">{'-'}</p></Link>

                                </div>
                          }
                             
                            </li>)})}



                            {/* // <li class="list-group-item">
                            //   <div className="row">
                            //     <div className="col-9">
                            //       <Link to="/disussion-detail"><p className="cat_title">Is Metamask still be able to connect with Rarible?</p></Link>
                            //       <Link to="/category"><p className="cat_link cat_link_blue mb-0">Technical upgrades</p></Link>

                            //     </div>
                            //     <div className="col-3 px-0">
                            //       <p className="cat_dur mb-0 text-right">
                            //         <span>24 /</span>
                            //         <span className="pl-1">3hr</span>

                            //       </p>
                            //     </div>
                            //   </div>
                            // </li>
                            // <li class="list-group-item">
                            //   <div className="row">
                            //     <div className="col-9">
                            //       <Link to="/disussion-detail"><p className="cat_title">GIVEAWAY TIME-420 by 4/20</p></Link>
                            //       <Link to="/category"><p className="cat_link cat_link_green mb-0">General Community</p></Link>

                            //     </div>
                            //     <div className="col-3 px-0">
                            //       <p className="cat_dur mb-0 text-right">
                            //         <span>10 /</span>
                            //         <span className="pl-1">32m</span>

                            //       </p>
                            //     </div>
                            //   </div>
                            // </li>
                            // <li class="list-group-item">
                            //   <div className="row">
                            //     <div className="col-9">
                            //       <Link to="/disussion-detail"><p className="cat_title">Is Metamask still be able to connect with Rarible?</p></Link>
                            //       <Link to="/category"><p className="cat_link cat_link_blue mb-0">Technical upgrades</p></Link>

                            //     </div>
                            //     <div className="col-3 px-0">
                            //       <p className="cat_dur mb-0 text-right">
                            //         <span>24 /</span>
                            //         <span className="pl-1">3hr</span>

                            //       </p>
                            //     </div>
                            //   </div>
                            // </li> */}


                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="latest" role="tabpanel" aria-labelledby="following-tab">
                  <div className="proposal_panel_overall">

                    <div className="table-responsive">
                      <table class="table table_style_1">
                        <thead>
                          <tr>
                            <th>
                              <div class="form-group mb-0">
                                <div className="select_sectO_s sel_period">
                                <select onChange={onChangeCat} class="form-control " id="exampleFormControlSelect1">
                                  <option>All catrgories</option>
                                  {catList && catList.map((row,index) => {
                                  return (
                                      <option value={row._id}>{row.name}</option>
                                  )})}
                                </select>
                                </div>
                               
                              </div>
                            </th>
                            <th className="text-right"></th>
                            <th className="text-center"> Replies</th>
                            <th className="text-center"> Views </th>
                            <th className="text-left"> Activity </th>

                          </tr>
                        </thead>
                        <tbody>
                        {latesttopics && latesttopics.length > 0 && latesttopics.map((row,index) => {
                          console.log("@!!$$",row)
                            return (
                          <tr>
                            <td>
                              <Link to={"/discussion-detail/"+row._id}><p className="cat_title mb-0">{row.topic}</p></Link>
                              <Link to={"/category/"+row.category_id}><p className="cat_link cat_link_green mb-0">{row.category_name}</p></Link>
                            </td>
                            <td className="text-left">
                              <div className="d-flex creators_details_td justify-content-end">
                              { 
                                 row.userList && row.userList.length > 0 &&  row.userList.map((sub, subindex) =>
                                
                                 <img src={(sub.profileImage && sub.profileImage !="")?
                                 config.Back_URL+"images/communityprofile/"+sub.profileImage:config.Back_URL+"images/communityprofile/"+"avatar.png"} alt="User" className="img-fluid" />

                                )}
                           
                              </div>
                            </td>
                            <td className="text-center"> {row.reply_count} </td>
                            <td className="text-center">{row.views_count} </td>
                            <td className="text-left"> {SecondsToDay(row.created_date)}  </td>
                          </tr>
                          )})}
                           {latesttopics && latesttopics.length == 0 &&
                              <tr>No results found..!</tr>
                          }
                          {/* <tr>
                            <td>
                              <Link to="/disussion-detail"><p className="cat_title mb-0">I want to buy some NFT art</p></Link>
                              <Link to="/category"><p className="cat_link cat_link_blue mb-0">Technical upgrades</p></Link>
                            </td>
                            <td className="text-right">
                              <div className="d-flex creators_details_td justify-content-end">
                                <img src={require("../assets/images/follower_1.png")} alt="User" className="img-fluid" />
                                <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid" />

                              </div>
                            </td>
                            <td className="text-right"> 2133 </td>
                            <td className="text-right"> 1.7k </td>
                            <td className="text-right"> 30m </td>

                          </tr>
                          <tr>
                            <td>
                              <Link to="/disussion-detail"><p className="cat_title mb-0">Drop Your NFT - I Will List It For Free</p></Link>
                              <Link to="/category"><p className="cat_link cat_link_green mb-0">General Community</p></Link>

                            </td>
                            <td className="text-right">
                              <div className="d-flex creators_details_td justify-content-end">
                                <img src={require("../assets/images/follower_1.png")} alt="User" className="img-fluid" />
                                <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid" />
                                <img src={require("../assets/images/follower_3.png")} alt="User" className="img-fluid" />
                              </div>
                            </td>
                            <td className="text-right"> 89 </td>
                            <td className="text-right"> 1.2k </td>
                            <td className="text-right"> 20h </td>

                          </tr>

                          <tr>
                            <td>
                              <Link to="/disussion-detail"><p className="cat_title mb-0">Sorting through bad art</p></Link>
                              <Link to="/category"><p className="cat_link cat_link_blue mb-0">Technical upgrades</p></Link>

                            </td>
                            <td className="text-right">
                              <div className="d-flex creators_details_td justify-content-end">
                                <img src={require("../assets/images/follower_1.png")} alt="User" className="img-fluid" />
                                <img src={require("../assets/images/follower_2.png")} alt="User" className="img-fluid" />
                                <img src={require("../assets/images/follower_3.png")} alt="User" className="img-fluid" />
                                <img src={require("../assets/images/follower_4.png")} alt="User" className="img-fluid" />

                              </div>
                            </td>
                            <td className="text-right"> 541 </td>
                            <td className="text-right"> 719 </td>
                            <td className="text-right"> 4h </td>

                          </tr> */}
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
                <div className="tab-pane fade" id="top" role="tabpanel" aria-labelledby="activity-tab">
                  <div className="proposal_panel_overall">
                    <div className="table-responsive">
                      <table class="table table_style_1">
                        <thead>
                          <tr>
                            <th>
                              <div class="form-group mb-0 1">
                                <div className="select_sectO_s sel_period">
                                <select onChange={onChangePeriod}  class="form-control " id="exampleFormControlSelect1">
                                  <option>Select Period</option>
                                  {catList && catList.map((row,index) => {
                                  return (
                                      <option value={row._id}>{row.name}</option>
                                  )})}
                                </select>
                                  </div>
                               
                              </div>
                            </th>
                            <th className="text-right"></th>
                            <th className="text-center">Replies</th>
                            <th className="text-center">Views</th>
                            <th className="text-left">Activity</th>
                          </tr>
                        </thead>
                        <tbody>
                        {toptopics && toptopics.map((row,index) => {
                            return (
                          <tr>
                            <td>
                              <Link to={"/discussion-detail/"+row._id}><p className="cat_title mb-0">{row.topic}</p></Link>
                              <Link to={"/category/"+row.category_id}><p className="cat_link cat_link_green mb-0">{row.category_name}</p></Link>
                            </td>
                            <td className="text-right">
                              <div className="d-flex creators_details_td justify-content-end">
                              { 
                                 row.userList && row.userList.length > 0 &&  row.userList.map((sub, subindex) =>

                                      <img src={(sub.profileImage && sub.profileImage !="")?
                                      config.Back_URL+"/images/profile/"+sub.profileImage: config.Back_URL+"/images/communityprofile/"+"avatar.png"} alt="User" className="img-fluid" />
                               
                                )}
                              </div>
                            </td>
                            <td className="text-center"> {row.reply_count} </td>
                            <td className="text-center">  {row.views_count}</td>
                            <td className="text-left"> {SecondsToDay(row.created_date)} </td>

                          </tr>)})}

                          {toptopics && toptopics.length == 0 &&
                              <tr>No results found..!</tr>
                          }

                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>

      {/* New Topic Modal */}
      <div class="modal fade primary_modal" id="new_top_modal" tabindex="-1" role="dialog" aria-labelledby="new_top_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content" id="hide"  >
            <div class="modal-header text-center">
              <h5 class="modal-title" id="new_top_modalLabel_1">Create New Topic</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form className="login_form mt-3" onSubmit={handletopicSubmit}>
                <div className="form-row">
                  <div className="col-12">
                    <label for="title">Title</label>
                    <div class="mb-3">
                      <input type="text" id="topic" name="topic" onChange={onChange}  class="form-control" placeholder="Title" />
                    </div>
                  </div>
                  <div className="col-12">
                    <label for="category">Choose Category</label>
                    {/* <select className="form-control">
                      <option>Art</option>
                      <option>Video</option>
                    </select> */}
                    <Select id="category" options={catdrop}
                    onChange={handlecat}
                    />
                  </div>
                </div>
                <label className="mt-2" for="message">Message</label>
                <div class="my-3">
                  <CKEditor
                   id="description"
                   data="<p></p>"
                    // onChange={evt => console.log(evt)}
                    onChange={onEditorChange}
                  />
                </div>
                <div className="mt-3">
                  <Button className="create_btn" type="submit">Create Topic</Button>
                  {validateError && validateError.topic !="" && <p style={{'color':'red'}}>{validateError.topic}</p>}
                  {validateError && validateError.cate !="" && <p style={{'color':'red'}}>{validateError.cate}</p>}
                  {validateError && validateError.description !="" && <p style={{'color':'red'}}>{validateError.description}</p>}
                  {validateError && validateError.username !="" && <p style={{'color':'red'}}>{validateError.username}</p>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </div>
  );
}
