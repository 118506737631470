import React, { useEffect,useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinksCommunity from "components/Header/HeaderLinksCommunity.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import isEmpty from '../lib/isEmpty';
import config from '../lib/config.js';
import { setCurrentUser, logout,currentUser,forgotPassword,updateuser,deleteaccount } from "../actions/community";
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
const initialFormValue = {
  'name': "",
  'email': "",
  'username': "",
  'customurl': "",
  'bio': "",
  'twitter': "",
  'facebook': "",
  'instagram': "",
  'youtube': "",
  'description': "",
  'photo': ""
}
const baseurl=config.baseUrl
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function EditProfileCommunity(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [loginuser, setUser]             = useState('');
  const [formValue, setFormValue]        = useState(initialFormValue);
  const [validateError, setValidateError]= useState({});   
  const [profileimg, setProfile]         = useState("")
  const [onchangeimg, setOnchangeimg]     = useState("")
  useEffect(() => {
    getcurrentUser();
  }, [])

  const {
    name,
    email,
    username,
    photo,
    userid
  } = formValue
  async function getcurrentUser(){
     var userDetails = await currentUser(); 
     console.log("currentuser:",userDetails)
      if (userDetails&&userDetails.result) {
        let formdata = {};
        if (userDetails.result.profile_image != '') {
          var profileimage = config.Back_URL + 'images/communityprofile/'+ userDetails.result.profile_image;
          setProfile(profileimage);
        } else {
          var profileimage = config.Back_URL + 'images/communityprofile/avatar.png';
          setProfile(profileimage);
        }
        formdata['userid']    = userDetails.result._id;
        formdata['email']     = userDetails.result.email;
        formdata['name']      = userDetails.result.name;
        formdata['username']  = userDetails.result.username;
        setFormValue(formdata)
        setUser(userDetails.result.active)
      }else{
        var profileimage = config.Back_URL + 'images/communityprofile/avatar.png';
        setProfile(profileimage);
      }
  }
  const onChange = (e) => {
    e.preventDefault();
   const { id, value } = e.target;
   let formData = { ...formValue, ...{ [id]: value } }
   setFormValue(formData)
 }
 async function handleforgotFormSubmit(){
   let reqData = {
    email
    }
let {  error } = await forgotPassword(reqData);
if (isEmpty(error)) {
  // setFormValue(initialFormValue)     
  toast.success('We have sent you a password reset email', toasterOption);
} else {
    setValidateError(error);
}
}
const handleSubmitsave = async (e) => {
    e.preventDefault();
    let reqData = {
      name,
      username,
      email,
      photo,
      userid
    }
    let {error} = await updateuser(reqData);
    if (isEmpty(error)) {
      setFormValue(initialFormValue)  
      setValidateError('')   
      toast.success('Profile has been updated successfully', toasterOption);
      getcurrentUser()
    } else {
        setValidateError(error);
    }
    }
    async function deleteAccount(){
      let reqData = {
        userid
      }
      let result = await deleteaccount(reqData);
      if(result){
        setTimeout(() => {
        toast.success('Profile has been deleted successfully', toasterOption);
      }, 500);
      setTimeout(() => {
        window.location.href="/community";
        localStorage.removeItem('community_token');
      }, 1000);
      }
    }
    const handleFile = (event) => {
      event.preventDefault();
      const { id, files } = event.target;
     if (event.target.files && event.target.files[0]) {
       setOnchangeimg(event.target.files[0]);
     }
      let formData = { ...formValue, ...{ [id]: files[0] } }
      setFormValue(formData)
    }
  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinksCommunity />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
          {/*<GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <h2 className="inner_title">Edit Profile</h2>
              </div>
            </GridItem>
          </GridContainer>*/}
        </div>
        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
              <div class="holder">
              {onchangeimg==''?
                <img src={profileimg} alt="logo" id="imgPreview" className="img-fluid"/>
                :
                <img src={onchangeimg? URL.createObjectURL(onchangeimg) : null} alt={onchangeimg? onchangeimg.name : null} id="imgPreview" className="img-fluid"/>
                }
                </div>
              <div className="profile_edit_panel">
                <div className="profile_edit_icon">
                  <i class="fas fa-pencil-alt"></i>
                </div>
                <input type="file" name="photo" id="photo" onChange={handleFile} required="true" />
              </div>
            </GridItem>
            <GridItem xs={12} sm={9} md={9}>
              <form onSubmit={handleSubmitsave}>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Username</label>
                    <input type="text" className="form-control primary_inp" id="username" name="username" value={username} onChange={onChange} placeholder="Please enter the name"/>
                    {
                       validateError.username && <span className="text-danger">{validateError.username}</span>
                    }
                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Name</label>
                    <input type="text" className="form-control primary_inp" id="name" name="name" value={name} onChange={onChange} placeholder="Please enter the name"/>
                    {
                       validateError.name && <span className="text-danger">{validateError.name}</span>
                    }
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Email</label>
                    <input type="email" className="form-control primary_inp" id="email" value={email} name="email" onChange={onChange} placeholder="Please enter the email address"/>
                    {
                       validateError.email && <span className="text-danger">{validateError.email}</span>
                    }
                  </div>
                 {/*  <div className="form-group col-md-6">
                    <Button className="primary_btn">Add Alternate Email</Button>
                  </div>*/}
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Password</label>
                    <Button className="primary_btn d-block mt-3" type="button" onClick={handleforgotFormSubmit}>Send Password Reset Email</Button>
                  </div>                  
                </div>                
                <div className="mt-3">
                  <Button className="create_btn" type="submit">Save Changes</Button>
                  <Button className="create_btn btn_danger ml-3" data-toggle="modal" data-target="#delete_account">Delete My Account</Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer/>

       {/* Delete My Account Modal */}
       <div class="modal fade primary_modal" id="delete_account" tabindex="-1" role="dialog" aria-labelledby="delete_accountCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="delete_accountLabel">Delete My Account</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => setTimeout(() => window.location.reload(), 500)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p className="forgot_desc">Are you sure you want to permanently delete your account? This action cannot be undone!</p>              
              <div class="report_btn_grp mt-3">
                <Button className="create_btn btn_danger" onClick={deleteAccount}>Delete My Account</Button>
                <Button className="cancel_btn mt-3" type="submit" data-dismiss="modal" onClick={() => setTimeout(() => window.location.reload(), 500)}>Cancel</Button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
