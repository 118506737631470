import Web3 from "web3";
import "@metamask/legacy-web3";
import config from '../lib/config';
import { toast } from 'react-toastify';

toast.configure();
let toasterOption      = config.toasterOption;

export async function getmyprovider() {
  if (window.web3 || window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          await window.ethereum
            .enable()

          const web3 = new Web3(window.web3.currentProvider);
          
          if (web3.currentProvider.isMetaMask === true) {
            if (
              web3 &&
              web3.eth
            ) {
              if (
                web3.currentProvider.networkVersion ==
                config.networkVersion
              ) {
                var result = await web3.eth.getAccounts();
                let provider = window.ethereum;
                var data = {
                  address: result[0].toLowerCase(),
                  provider: provider
                }
                
                return data
              }else{
                var result = await web3.eth.getAccounts();
                let provider = window.ethereum;
                var data = {
                  address: result[0].toLowerCase(),
                  provider: config.provider
                }
                console.log("please connect bnb main")
                return data;
              }
            }
          }
        }
      } catch (err) {
        console.log("your browser not support web3")
      }
  }else{
        let web3 = new Web3(config.provider);
        var network = await web3.eth.net.getId();
        var result = await web3.eth.getAccounts();
          var data = {
            address : result[0]?result[0].toLowerCase():"",
            provider : config.provider
          }
          return data 
  }

}