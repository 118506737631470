/*eslint-disable*/
import React,{useEffect,useState,useRef} from "react";
import { Notifications, AccountBalanceWallet, FileCopy } from '@material-ui/icons';
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Button } from "@material-ui/core";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import config from '../../lib/config';
import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3';
import { toast } from 'react-toastify';
import wallet from '../../assets/images/wallet_icon.png'
import DETH from '../../ABI/DETH.json';
import HeaderSearch from '../../views/separate/HeaderSearch';
import { TimeAgo } from "@n1ru4l/react-time-ago";
import {
  getCurAddr,
  getWallet,
  Activity_List_Action,
  Activity_View_Action,
  halfAddrShow
} from '../../actions/v1/user';
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const [HeaderSearchInputVal, setHeaderSearchInputVal] = useState('');
  const [tokenlist, setTokenlist]                       = useState([]);
  const [ActivityList, Set_ActivityList]                = useState([]);
  const [Activitycount, Set_Activitycount]              = useState('');
  const [accounts, setAccounts]                         = useState("");
  const [currAddr, setcurrAddr]                         = useState("");
  const [walletaddr, setWalletaddr]                      = useState("");
  const [dethBln, setDethBln]                           = useState(0);
  const [blns, setBln]                                  = useState(0);
  const [lightdark, setlightDark]                       = useState(false);
  


  useEffect(() => {
    Get_Activity_list();
    getconnect();
  }, []);

  async function disconnectMetamask(){
      if (localStorage.getItem("tfniuobnft")) {
        localStorage.removeItem("tfniuobnft");
        window.location.reload(false)
    } 
  }
  const HeaderSearchLoad = (data) => {
    if(data.searchVal != '') {
      axios
      .get(`${config.Back_URL}/user/search/` + data.searchVal)
      .then(response => {
        if (response && response.data) {
          if (response.data.itemdata.length >0) {
            setTokenlist(response.data.itemdata);
            $("#usemneu_dd").show();
          } else {
            setTokenlist(response.data.data);
            $("#usemneu_dd").show();
          }
        }
      })
      .catch(e => console.log(e))
    }else{
      $("#usemneu_dd").hide();
    }
  }
  $('.search_inp').bind('click', function(e){ 
    $('.topSearchPopup').fadeIn();
    e.stopPropagation();
  });
  $(document).bind('click', function(){
    $('.topSearchPopup').fadeOut();
  });

  const HeaderSearchInputClear = (e) => {
    setHeaderSearchInputVal('');
  }
  const timerRef = useRef(null);

  const inputChange = (e) => {
    if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
      var value = e.target.value;
      switch(e.target.name) {
        case 'HeaderSearchInput':
          setHeaderSearchInputVal(value);
          var data = {
            searchVal: value
          }
          if(timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            HeaderSearchLoad(data)
          }, 1000);
          break;
        default:
          // code block
      }
    }
  }
  async function getconnect() {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          window.ethereum.enable().then(async function () {
            const web3 = new Web3(window.web3.currentProvider);
            // if (window.web3.currentProvider.isMetaMask === true) {
              await web3.eth.getAccounts(async function(error, result) {
                await  web3.eth.getBalance(result[0],async (error, balance) => {
                  setBln(balance/config.decimalvalues)
                  var currAddr = window.web3.eth.defaultAccount;
                  await setcurrAddr(currAddr);
                  await getTokenbal();
                 await getwalletlist();
                })
              })
            // }
          });
        } else {
        //  toast.success("Please Add Metamask External", toasterOption);
        }
      } catch (err) {}
    } else {
     // toast.success("Please Add Metamask External", toasterOption);
    }
  }

  const connect_Wallet_call = () => {
    window.$('#connect_Wallet_call').click();
  }
  async function getTokenbal(){
    try{
      var web3              = new Web3(window.ethereum);
      var currAddr          = window.web3.eth.defaultAccount;
      var CoursetroContract = new web3.eth.Contract(DETH,config.tokenAddress);
      var tokenDethBln      = await CoursetroContract.methods.balanceOf(currAddr).call();
      var value             = tokenDethBln / config.decimalvalues;
      setDethBln(value.toFixed(8));
    }catch(err){}
  }
  async function  getwalletlist(){
    try{
      var currAddr = window.web3.eth.defaultAccount;
      var payload={
        address:currAddr
      }
      var {result} = await getWallet(payload)
      if(result&&result.data){
        setWalletaddr(result.data.address)
      }
    }catch(err){}
  }
  async function viewNotification(){
    try{
      var currAddr = window.web3.eth.defaultAccount;
      if(typeof currAddr!='undefined'){
        var resp = await Activity_View_Action({
          addr:currAddr
        });    
        Get_Activity_list()
    if(
      resp
      && resp.data.message=="Notification viewed"
    ) {
    
        Set_Activitycount('')
    }
  }
    }catch(err){}
  }
  async function Get_Activity_list(){
    try{
      if(!localStorage.getItem("light") || localStorage.getItem("light")){
        setlightDark(false)
        localStorage.setItem("light",'yes');
        document.body.classList.add('light');
      }
      if(localStorage.getItem("dark")){
        setlightDark(true)
        localStorage.setItem("dark",'yes');
        document.body.classList.remove('light');
        localStorage.removeItem("light");
      }
      
      var currAddr = window.web3.eth.defaultAccount;
      if(typeof currAddr!='undefined'){
        var resp = await Activity_List_Action({
          addr:currAddr
        });    
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var List = resp.data.list;
      // console.log("view_list",List)
      if (List.length>0) {
       var countList=[];
        for (let i = 0; i < List.length; i++) { 
          if(List[i].viewstatus!="viewed"){
            // console.log("count",List[i])
            countList.push(List[i]);
          }
        }
        // console.log("countList",countList)
        Set_Activitycount(countList.length)
      }
      Set_ActivityList(List);
    }else {
    
      Set_ActivityList([]);
    }
  }

    }catch(err){}
  }
  const CheckedChange = (e) => {
      if(lightdark==false){
        setlightDark(true)
        localStorage.setItem("dark",'yes');
        document.body.classList.remove('light');
        localStorage.removeItem("light");
      }else{
        setlightDark(false)
        localStorage.setItem("light",'yes');
        document.body.classList.add('light');
        localStorage.removeItem("dark");

      }
    }

  

  console.log(walletaddr,'====',currAddr, 'addresssurain');
  return (
    <List className={classes.list + " main_navbar"}>   
    <ListItem className={classes.listItem}>
      
      <HeaderSearch/>
      </ListItem>                
      {/* <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/home">Explore</NavLink>
      </ListItem> */}

      {currAddr&&<ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/my-items" >My items</NavLink>
      </ListItem>}

      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/how-it-works">How it Works</NavLink>
      </ListItem>
      {/*<ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/community">Community</NavLink>
      </ListItem>
       <ListItem className={classes.listItem + " menu_dropdown topMenuDropdown"}>
        <CustomDropdown
          noLiPadding
          buttonText="More"
          dropdownList={[
            <NavLink to="/community" className="mb-3 d-block">
              Community
            </NavLink>,
            // <NavLink to="/activity" className="mb-3 d-block">
            //   Activity
            // </NavLink>,
            // <NavLink to="/community" className="mb-0 d-block">
            //   Discussion
            // </NavLink>,
          ]}
        />
        </ListItem> */}

      {localStorage.getItem("tfniuobnft")  ? (
          <ListItem className={classes.listItem}>
       {walletaddr==currAddr && 
       <Button className={classes.navLink + " create_btn"}>
           <Link to="/create-single">Create</Link>
          </Button>}
        </ListItem>
      ):(
        <ListItem className={classes.listItem}>
        <Button className={classes.navLink + " create_btn"}>
          <Link to="/connect">Connect Wallet</Link>
        </Button>
      </ListItem>
      )}
    
      {/* <ListItem className={classes.listItem + " "}>
        <Button className={classes.navLink + "icon_header"}>
          <Link to="/connect"><i class="fas fa-bell"></i></Link>
        </Button>
      </ListItem> */}
      <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul noti_ul wallet_drop icon_header"}>
        <CustomDropdown
            noLiPadding
            buttonText={ <div onClick={()=>viewNotification()}><i class="fas fa-bell" ></i> {Activitycount}</div>}        
            dropdownList={[
            <div className="notification">
               {ActivityList.length == 0 &&
                <div className="noti_parent">
                  <p className="no_noti">No records</p>
                </div>
            }{
               ActivityList.slice(0, 5).map((item) => {
                var imageUrl =config.Back_URL+"/images/noimage.png";
                var text = "";
                var name = "";
                if(window.web3){
                try{
                  var UserAccountAddr = window.web3.eth.defaultAccount;
                }catch(err){
                  var UserAccountAddr =""
                }
              }
                if(item.actiontype=="following"){
                  var followTxt = halfAddrShow(item.touseraddress);
                  if(item.touseraddress==UserAccountAddr){
                      followTxt = "You ";
                  }
                  text = "started following "+halfAddrShow(item.touseraddress);
                  name = (item.userdetail && item.userdetail.name !="")?item.userdetail.name:halfAddrShow(item.userdetail.curraddress);
                }
                else if(item.actiontype=="listings"){
                      if ((item&&item.tokenuser&&item.tokenuser.PutOnSaleType)=="FixedPrice") {
                      text = "Listed for "+item.price+" "+(item.currency)+ " by "+(item&&item.name);
                      }else if ((item&&item.tokenuser&&item.tokenuser.PutOnSaleType)=="TimedAuction") {
                      text = "Listed for "+item.price+" "+(item.currency)+ " by "+(item&&item.name);
                      }else{
                      text = "Listed "+"by "+" "+(item&&item.name);
                      }
                      // name = (item.name)?item.name:""
                } else if(item.actiontype=="edit bid"){
                      text="edit bid for "+(item.price)+" "+(item.currency)+" by "+(item&&item.name)
                      // name = (item.name)?item.name:""
                }
                else if(item.actiontype=="place bid"){
                  text="place bid for "+(item.price)+" "+(item.currency)+" by "+(item&&item.name)
                  // name = (item.name)?item.name:""
                }
                else if(item.actiontype=="sales"){
                  text = "sales for "+item.price+" "+(item&&item.tokendetail&&item.tokendetail.biddingtoken)+ " by "+
                  (item&&item.tokenuser&&item.tokenuser.tokenName);
                  name = (item.name)?item.name:""
                } 
                else if(item.actiontype=="acceptBid"){
                      text = "acceptBid for "+(item.price)+" "+(item.currency)+ " to "+(item&&item.tokenuser&&item.tokenuser.tokenName);
                      name = (item.name)?item.name:""
                }
               
                else if(item.actiontype=="purchase"){
                      text = "purchased for "+item.price+" "+(item.currency)+ " by "+(item&&item.tokenuser&&item.tokenuser.tokenName);
                      name = (item.name)?item.name:""
                }           
               
                else if(item.actiontype=="burn"){
                      text = "Burn by "+(item&&item.tokenuser&&item.tokenuser.tokenName);
                      name = (item.name)?item.name:""
                }
                else if(item.actiontype=="like"){
                    text = "liked by "+(item&&item.name);
                    // name = (item.name)?item.name:""
                }
                else if(item.actiontype=="unlike"){
                    text = "unliked by "+(item&&item.name);
                    // name = (item.name)?item.name:""
                }
                else if(item.actiontype=="reporting"){
                  text = "reporting by "+(item&&item.name);
                  // name = (item.name)?item.name:""
                }
                else if(item.actiontype=="sharing"){
                  text = "Shared by "+(item&&item.name);
                  // name = (item.name)?item.name:""
                }
                else if(item.actiontype=="burn"){
                    text = "Burn by "+(item&&item.tokenuser&&item.tokenuser.tokenName);
                    name = (item.name)?item.name:""
                }
                var linkUrl = "/info/"+item.tokenCounts;
                if(item.itemtype=="users"){
                    linkUrl = "/user/"+(item&&item.tokenuser&&item.tokenuser.tokenName);
                }
                if(name.length > 15) {
                    name = name.substring(0, 18) + "...";
                }
          
                 return(
                  <div className="noti_parent">
                  <div className="noti_head">
                    <Link to={linkUrl}>
                      {/*  <img src={imageUrl} height="50px" width="50px" alt="User" className="img-fluid mr-3" />
                       */}
                    </Link>
                    <div className="notifitime">
                    <p className="mt-0 media_num mb-0 time">
                      {
                        <TimeAgo date={new Date(item.timestamp)}>
                          {({ value }) => value}
                        </TimeAgo>
                      }
                    </p>
                  </div>
                    <div className="media-body">
                      <div>
                        <p className="my-0 media_text">{name}</p>
                        <p className="mt-0 media_num mt-0">{text}</p>
                      </div>
                    </div>
                  </div>
                  
                 
                  <p className="no_noti"></p>
                </div>
                  // <>
                  // <p>{name}{text} <span>Now</span></p>
                  // </>
                 )
               })
              }
             <Link to="/activity"> <Button className="px-3 viewAllButton">View all</Button></Link>
            </div>
            ]}
          />
      </ListItem>
      {localStorage.getItem("tfniuobnft")  ? (
      <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul noti_ul wallet_drop icon_header pl-0"}>
        <CustomDropdown
            noLiPadding
            buttonText={ <div><AccountBalanceWallet className="menu_icons"/></div>}        
            dropdownList={[
            <div>
              <div className="noti_parent">
                <div className="d-flex align-items-center">
                  <p className="wallet_address">{currAddr}</p> <FileCopy className="ml-2 wllet_copy_icon"/>
                </div>
                <div className="wallet_balance">
                  <h4>Balance</h4>
                  <p>{blns} {config.currencySymbol}</p>
                </div>
                <div className="wallet_balance">
                  <h4>Token Balance</h4>
                  <p>{dethBln} {config.tokenSymbol}</p>
                </div>
              </div>
              <hr/>
              <div className="noti_parent">
                <NavLink to="/my-items" className={classes.dropdownLink}>
                  My Items
                </NavLink>
                <NavLink to="/edit-profile" className={classes.dropdownLink}>
                  Edit Profile
                </NavLink>
                <Button type="submit" onClick={disconnectMetamask} className={classes.dropdownLink + " p-0"}>
                  Disconnect
                </Button>
              </div>
            </div>
            ]}
          />
      </ListItem>
      ):(
        ""
      )}
      <ListItem className={classes.listItem}>
        <div className="toggleMode" title="toggle dark mode">
          <label>
            <input type="checkbox" 
              id="light_dark"
              name="light_dark" 
              onChange={CheckedChange}
              checked={lightdark} 
            />
            <span></span>
          </label>
        </div>
      </ListItem>
    </List>
  );
}
