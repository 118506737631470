import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState
} from 'react';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';
import DETH_ABI from '../../ABI/DETH.json';
import BOUI_ABI from '../../ABI/BOUI.json';
import BUSD_ABI from '../../ABI/BUSD.json';
import BEP721 from '../../ABI/BEP721.json';
import BEP1155 from '../../ABI/BEP1155.json';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import isEmpty from "../../lib/isEmpty";
import {
  useHistory
} from "react-router-dom";
import { Button, TextField } from '@material-ui/core';
import {
  getCurAddr, halfAddrShow,AddressUserDetails_GetOrSave_Action
} from '../../actions/v1/user';
import Select from "react-select";
import {
  TokenCounts_Get_Detail_Action,
  BidApply_ApproveAction,
  acceptBId_Action,
  CancelBid_Action
} from '../../actions/v1/token';
import {getmyprovider} from "../../lib/walletconnect";

import { toast } from 'react-toastify';
toast.configure();
let toasterOption       = config.toasterOption;
const singleContract    = config.singleContract;
const multipleContract  = config.multipleContract;
const IPFS_IMGurl       = config.IPFS_IMG;
var web3                = new Web3(window.ethereum);
const BOUIContract = new web3.eth.Contract(BOUI_ABI,config.bouitokenAddr);
const BUSDContract = new web3.eth.Contract(BUSD_ABI,config.busdtokenAddr)
const BEP721Contract    = new web3.eth.Contract(BEP721, config.singleContract)
const BEP1155Contract   = new web3.eth.Contract(BEP1155, config.multipleContract)

const bidprice = [
{ value: config.owntokenSymbol, label: config.owntokenSymbol },
{ value: config.tokenSymbol, label: config.tokenSymbol },
{ value: config.usdtokenSymbol, label: config.usdtokenSymbol }
]


export const PlaceAndAcceptBidRef = forwardRef((props, ref) => {
const history = useHistory();
  const [BidformSubmit, Set_BidformSubmit] = React.useState(false);
  const [NoOfToken_NeedToSend, Set_NoOfToken_NeedToSend] = React.useState(false);
  const [MetaMaskAmt, setMetaMaskAmt]                = React.useState(false);
  const [FormSubmitLoading, Set_FormSubmitLoading]   = React.useState('');
  const [AcceptCallStatus, setAccaptCallStatus]      = React.useState('init'); 
  const [servicefee, setservicefee]                  = React.useState(0);
  const [biddingtoken, set_biddingtoken]             = React.useState("BOUI")
  const [tokenWill, setTokenWill]                    = React.useState(0);
  const [showFees, Set_showFees]                     = useState(config.fee/1e18);
  const [PurchaseCurrency, setPurchaseCurrency]      = useState("BOUI");
  const [biddingfee, set_biddingfee]                 = React.useState(config.fee/1e18);
  const [burnorboui, setBurnorboui]                  = useState("boui");
  const [currentowner, setCurrentOwner]                  = useState("");
  const [web3, setweb3] = useState();
  const [BOUIContract, setBOUIContract] = useState();
  const [BUSDContract, setBUSDContract] = useState();
  const [BEP721Contract, setBEP721Contract] = useState();
  const [BEP1155Contract, setBEP1155Contract] = useState();

  useEffect(()=>{
    init()
  },[])

  const init= async()=>{
    let providers = await getmyprovider();
    let web3                = new Web3(providers.provider);
    setweb3(web3)
    let BOUIContract = new web3.eth.Contract(BOUI_ABI,config.bouitokenAddr);
    setBOUIContract(BOUIContract);
    let BUSDContract = new web3.eth.Contract(BUSD_ABI,config.busdtokenAddr);
    setBUSDContract(BUSDContract);
    let BEP721Contract    = new web3.eth.Contract(BEP721, config.singleContract);
    setBEP1155Contract(BEP721Contract);
    let BEP1155Contract   = new web3.eth.Contract(BEP1155, config.multipleContract);
    setBEP1155Contract(BEP1155Contract);
  }
  var {
    buytoken,
      Set_WalletConnected,
      Set_UserAccountAddr,
      Set_UserAccountBal,
      Set_AddressUserDetails,
      Set_Accounts,
      Set_MyItemAccountAddr,
      Set_tokenCounts,
      Set_item,
      Set_tokenCounts_Detail,
      Set_MyTokenBalance,
      Set_Bids,
      Set_AccepBidSelect,
      Set_tokenBidAmt,
      Set_NoOfToken,
      Set_ValidateError,
      Set_TokenBalance,
      Set_YouWillPay,
      Set_YouWillPayFee,
      Set_YouWillGet,
      Set_BidApply_ApproveCallStatus,
      Set_BidApply_SignCallStatus,
      setTotaluserbidAmt,
      BidApply_ApproveCallStatus,
      totaluserbidAmt,
      WalletConnected,
      UserAccountAddr,
      UserAccountBal,
      AddressUserDetails,
      Accounts,
      MyItemAccountAddr,
      tokenCounts,
      item,
      tokenCounts_Detail,
      MyTokenBalance,
      Bids,
      AccepBidSelect,
      tokenBidAmt,
      NoOfToken,
      ValidateError,
      TokenBalance,
      YouWillPay,
      YouWillPayFee,
      YouWillGet,
      BidApply_ApproveCallStatus,
      BidApply_SignCallStatus,
      AllowedQuantity,
      Set_AllowedQuantity,
      Set_Tabname,
      Tabname
  } = props;

  function convert(n){
    var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
    if (!/e/i.test(toStr)) {
        return n;
    }
    var [lead,decimal,pow] = n.toString()
        .replace(/^-/,"")
        .replace(/^([0-9]+)(e.*)/,"$1.$2")
        .split(/e|\./);
    return +pow < 0
        ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
        : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
}

 async function PriceCalculate_this(data={}) {
    try{
      var price = (typeof data.tokenBidAmt != 'undefined') ? data.tokenBidAmt : tokenBidAmt;
      var quantity = (typeof data.NoOfToken != 'undefined') ? data.NoOfToken : NoOfToken;
      if(price == '') { price = 0; }
      if(quantity == '') { quantity = 0; }
      if(isNaN(price) != true && isNaN(quantity) != true) {
        if(item.type == 721) {
          var totalPrice = price;
          var web3 = new Web3(window.ethereum);
          var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
          if(biddingtoken==config.currencySymbol){
              var fee = await CoursetroContract.methods.getServiceFee().call();
            } else if(biddingtoken==config.owntokenSymbol){
              var fee = await CoursetroContract.methods.getBouiFee().call();
            }else if(biddingtoken==config.usdtokenSymbol){
              var fee = await CoursetroContract.methods.getBUSDFee().call();
            }
            else{
              var fee = await CoursetroContract.methods.getServiceFee().call();
            }
           
            totalPrice = parseFloat(totalPrice);
            var per = (totalPrice*config.decimalvalues * fee) / 1e20;
            var sendMMAmt=(totalPrice*config.decimalvalues) + per;
            setMetaMaskAmt(sendMMAmt)
            var finalPrice = sendMMAmt/config.decimalvalues;
            var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
            Set_YouWillPay(totalPriceWithFee);
        }
        else {
          var totalPrice = price * quantity;
          console.log("multi",price,quantity)
          var web3 = new Web3(window.ethereum);
          var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
          if(biddingtoken==config.currencySymbol){
            var fee = await CoursetroContract.methods.getServiceFee().call();
          } else if(biddingtoken==config.owntokenSymbol){
            var fee = await CoursetroContract.methods.getBouiFee().call();
          }else if(biddingtoken==config.usdtokenSymbol){
            var fee = await CoursetroContract.methods.getBUSDFee().call();
          }
          else{
            var fee = await CoursetroContract.methods.getServiceFee().call();
          }
            totalPrice = parseFloat(totalPrice);
            var per = (totalPrice*config.decimalvalues * fee) / 1e20;
            var sendMMAmt=(totalPrice*config.decimalvalues) + per;
            setMetaMaskAmt(sendMMAmt)
            var finalPrice = sendMMAmt/config.decimalvalues;
            var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
            Set_YouWillPay(totalPriceWithFee);
        }
        
       
      }
      else {
        Set_YouWillPay(0);
      }
    }catch(err){
    }
  }

  const Validation_PlaceABid = (chk) => {
      if(chk) {
        var ValidateError = {};
    
        if(NoOfToken == '') {
          ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
        }
        else if(isNaN(NoOfToken) == true) {
          ValidateError.NoOfToken = '"Quantity" must be a number';
        }
        else if(NoOfToken == 0) {
          ValidateError.NoOfToken = '"Quantity" must be greater than 0';
        }
        else if(NoOfToken > AllowedQuantity) {
          ValidateError.NoOfToken = '"Quantity" must be less than or equal to '+AllowedQuantity;
        }
    
        if(tokenBidAmt == '') {
          ValidateError.tokenBidAmt = '"Offer amount" is not allowed to be empty';
        }
        else if(isNaN(tokenBidAmt) == true) {
          ValidateError.tokenBidAmt = '"Offer amount" must be a number';
        }
        else if(tokenBidAmt == 0) {
          ValidateError.tokenBidAmt = '"Offer amount" must be greater than 0';
        }
        // else if(tokenBidAmt > tokenCounts_Detail.TotalQuantity) {
        //   ValidateError.tokenBidAmt = '"Bid amount" must be less than or equal to '+tokenCounts_Detail.TotalQuantity;
        // }
        else if(item.minimumBid > tokenBidAmt) {
          ValidateError.tokenBidAmt = '"Offer amount" must be higher than or equal to '+item.minimumBid;
        }

        // else if(YouWillPay > TokenBalance) {
        //   ValidateError.tokenBidAmt = 'Insufficient balance, Check your wallet balance';
        // }
        // console.log('ValidateError', ValidateError);
        Set_ValidateError(ValidateError);
        return ValidateError;
      }
  }
  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if((charCode>47 && charCode <58) || (charCode>95 && charCode <106)){
      var ValidateError = {};
      Set_ValidateError(ValidateError);
    }else{
      var ValidateError = {};
      ValidateError.NoOfToken = '"Quantity" must be a number';
      Set_NoOfToken("");
      Set_ValidateError(ValidateError);
    }
  }
  const inputChange = (e) => {
      console.log('inputChange',e.target.value);
      if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
        var value = e.target.value;
        switch(e.target.name) {
          case 'tokenBidAmt':
            if(value != '' && isNaN(value) == false && value > 0) {
              Set_tokenBidAmt(value);
              PriceCalculate_this({tokenBidAmt:value,biddingtoken:biddingtoken});
              ValidateError.tokenBidAmt = '';
              Set_ValidateError(ValidateError);
            } 
            else {
              if(value==0){
                ValidateError.tokenBidAmt = 'price must be greater than 0';
                Set_ValidateError(ValidateError);
                Set_tokenBidAmt(value);
                PriceCalculate_this({tokenBidAmt:value,biddingtoken:biddingtoken});
              }
              else{
                ValidateError.tokenBidAmt = 'Enter Valid price';
                Set_ValidateError(ValidateError);
                //Set_tokenBidAmt('0');
                PriceCalculate_this({tokenBidAmt:value,biddingtoken:biddingtoken});

              }
             
            }
            break;
            case 'NoOfToken':
              Set_NoOfToken(value);
              PriceCalculate_this({NoOfToken:value});
              break;
            // code block
        }
        // window.$('#Validation_PlaceABid').click();
      }
  }

  async function FormSubmit_PlaceABid (e) {
      Set_BidformSubmit(true);
      var connectwallet=localStorage.getItem("tfniuobnft");
      if(!connectwallet){
        window.location.href="/connect";
        return false;
      }

      var errors = await Validation_PlaceABid(true);
      var errorsSize = Object.keys(errors).length;
      if(errorsSize != 0) {
        toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
        return false;
      }
      if (TokenBalance >= parseFloat(YouWillPay)) {
        window.$('#place_bid_modal').modal('hide');
        window.$('#proceed_bid_modal').modal('show');
      } else {
        toast.error("Insufficient Token Balance", toasterOption);
      }

  }

  async function BidApply_ApproveCall() {
    // console.log("bidding_amount11:",tokenBidAmt,MetaMaskAmt,YouWillPayFee,YouWillGet,Bids.myBid.tokenBidAmt,TokenBalance)

      if (!window.ethereum) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return false;
      }
     
      var web3 = new Web3(window.ethereum);
      var currAddr = window.web3.eth.defaultAccount;
      if (!currAddr) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
      }

    
      Set_BidApply_ApproveCallStatus('processing');

      if(biddingtoken==config.currencySymbol){
        var CoursetroContract = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
      }else  if(biddingtoken==config.owntokenSymbol){
        var CoursetroContract = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
      }else  if(biddingtoken==config.usdtokenSymbol){
        var CoursetroContract = new web3.eth.Contract(BUSD_ABI, config.busdtokenAddr);
      }
      else{
        var CoursetroContract = new web3.eth.Contract(DETH_ABI, config.tokenAddress);
      }

   

      // console.log("allowance:",CoursetroContract)
      var currAddr =await getCurAddr();
      if(item.type==721){
        var getAllowance = await CoursetroContract.methods.allowance(currAddr,singleContract).call();
      }else{
        var getAllowance = await CoursetroContract.methods.allowance(currAddr,multipleContract).call();
      }
      var sendVal=parseFloat(MetaMaskAmt)
      if(getAllowance > 0 ) {
        var sendVal=parseFloat(MetaMaskAmt)+parseFloat(getAllowance);
      }
      
      console.log("placebidAmt:",getAllowance,MetaMaskAmt,sendVal)
      console.log(sendVal,"===1")
      sendVal = sendVal*4;
      console.log(sendVal,"===2")
      sendVal= convert(sendVal)
      CoursetroContract
      .methods
      .approve(
        (item.type==721)?singleContract:multipleContract,
        sendVal.toString()
      )
      .send({from: Accounts})
      .then(async (result) => {
        toast.success("Approve Successfully", toasterOption);
        var BidData = {
          tokenCounts: item.tokenCounts,
          tokenBidAddress: UserAccountAddr,
          tokenBidAmt: tokenBidAmt.toString(),
          NoOfToken: item.type == 721 ? 1 : NoOfToken,
          BidToken: biddingtoken,
          burnorboui:burnorboui
        }
        var Resp = await BidApply_ApproveAction(BidData);
      
        if(Resp.data && Resp.data.type && Resp.data.type == 'success') {
        
          Set_BidApply_ApproveCallStatus('done');
        }
        else {
          toast.error("Approve failed", toasterOption);
          Set_BidApply_ApproveCallStatus('tryagain');
        }
      })
      .catch((error) => {
        toast.error("Approve failed", toasterOption);
        Set_BidApply_ApproveCallStatus('tryagain');
      })
  }
  async function BidApply_SignCall() {
      if (!window.ethereum) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return;
      }
      var web3 = new Web3(window.ethereum);
      var currAddr = window.web3.eth.defaultAccount;
      if (!currAddr) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        return;
      }
  
      Set_BidApply_SignCallStatus('processing');
  
      web3.eth.personal.sign("Bidding a Art", currAddr, "Bid Placed")
      .then(async (result) => {
        toast.success("Offer sign successfully", toasterOption);
        Set_BidApply_SignCallStatus('done');
        setTimeout(() => window.$('#proceed_bid_modal').modal('hide'), 600);
        setTimeout(() => window.location.reload(), 1200);
      })
      .catch(() => {
        toast.error("Sign failed", toasterOption);
        Set_BidApply_SignCallStatus('tryagain');
      })
  }

  async function CancelBid_Proceed(curBid_val) {
    var payload = {
      tokenCounts: curBid_val.tokenCounts,
      tokenBidAddress: curBid_val.tokenBidAddress
    }
    var Resp = await CancelBid_Action(payload);
    if(Resp && Resp.data && Resp.data.toast && Resp.data.toast.type && Resp.data.toast.message) {
      if(Resp.data.toast.type == 'error') {
        toast.error(Resp.data.toast.message, toasterOption);
      }
      else if(Resp.data.toast.type == 'success') {
        toast.success(Resp.data.toast.message, toasterOption);
      }
    }
    setTimeout(() => window.$('.modal').modal('hide'), 600);
    window.location.reload();
  }

  async function AcceptBid_Proceed() {
      var curAddr = await getCurAddr();
      if (window.ethereum) {
        var web3    = new Web3(window.ethereum);
   
        if(NoOfToken_NeedToSend) {
          if(item.type==721){

            if (AccepBidSelect.BidToken==config.tokenSymbol) {
              var web3              = new Web3(window.ethereum);
                var WBNBContract   = new web3.eth.Contract(DETH_ABI, config.tokenAddress);
                var tokenBal = await WBNBContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call()
                var tokenBalance = tokenBal / config.decimalvalues;

              }else if (AccepBidSelect.BidToken==config.owntokenSymbol) {
              var web3              = new Web3(window.ethereum);
              var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
              var tokenBal = await BouiContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call()
              var tokenBalance = tokenBal / config.decimalvalues;

            }else if(AccepBidSelect.BidToken==config.usdtokenSymbol){
              var web3              = new Web3(window.ethereum);
              var BusdContract   = new web3.eth.Contract(BUSD_ABI, config.busdtokenAddr);
              var tokenBal = await BusdContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call()
              var tokenBalance = tokenBal / config.decimalvalues;
            }
            else{
              var web3              = new Web3(window.ethereum);
              var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
              var tokenBal = await BouiContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call()                
              var tokenBalance = tokenBal / config.decimalvalues;

            }

            var bidtokenaddress= AccepBidSelect.BidToken==config.tokenSymbol?
                            config.tokenSymbol
                            :
                            AccepBidSelect.BidToken==config.usdtokenSymbol?
                            config.usdtokenSymbol
                            :
                            AccepBidSelect.BidToken==config.owntokenSymbol?
                            config.owntokenSymbol:"";

            var tokenaddress= AccepBidSelect.BidToken==config.tokenSymbol?
                    config.tokenAddress
                    :
                    AccepBidSelect.BidToken==config.usdtokenSymbol?
                    config.busdtokenAddr
                    :
                    AccepBidSelect.BidToken==config.owntokenSymbol?
                    config.bouitokenAddr:"";

              var value= (parseFloat(YouWillPayFee)
                            *parseFloat(AccepBidSelect.NoOfToken))+
                            (parseFloat(AccepBidSelect.tokenBidAmt)
                            *parseFloat(AccepBidSelect.NoOfToken))
           
            if(tokenBalance>=value){
              var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
            setAccaptCallStatus('processing');
            // var passAmt = parseFloat(YouWillPayFee) + parseFloat(YouWillGet);
            //  passAmt     = (passAmt).toString();
            var passAmt = parseFloat(YouWillPayFee) + parseFloat(YouWillGet);
            passAmt = passAmt.toFixed(config.toFixed);
            passAmt = (passAmt * config.decimalvalues).toString();
            console.log(convert(passAmt),"==>")
            CoursetroContract
            .methods
            .acceptBId(
              bidtokenaddress,
              AccepBidSelect.tokenBidAddress,
              convert(passAmt),
              item.tokenCounts,
              tokenaddress
            )
            .send({from:Accounts})
            .then(async (result) => {
              
              // console.log('result', result);
              var acceptBId_Payload = {
                tokenCounts: item.tokenCounts,
                NoOfToken : NoOfToken_NeedToSend, //AccepBidSelect.NoOfToken,
                tokenBidAddress: AccepBidSelect.tokenBidAddress,
                UserAccountAddr_byaccepter: curAddr,
                transactionHash: result.transactionHash,
                PurchaseCurrency: AccepBidSelect.BidToken,
                burnorboui:AccepBidSelect.burnorboui
              }
              var Resp = await acceptBId_Action(acceptBId_Payload);
              setAccaptCallStatus('done');
              setTimeout(() => window.$('.modal').modal('hide'), 600);
              setTimeout(() => history.push("/user/"+AccepBidSelect.tokenBidAddress), 1200);
            })
            .catch((err) => {
              console.log('err', err)
              toast.error("Accept failed", toasterOption);
              setAccaptCallStatus('tryagain');
            })
        }else{
          toast.error("Bidder doesnt have Enough balance", toasterOption)
          return false;
        }
          

          }else{
            var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
          
            var bidtokenaddress= AccepBidSelect.BidToken==config.tokenSymbol?
            config.tokenSymbol
            :
            AccepBidSelect.BidToken==config.usdtokenSymbol?
            config.usdtokenSymbol
            :
            AccepBidSelect.BidToken==config.owntokenSymbol?
            config.owntokenSymbol:""

            var tokenaddress= AccepBidSelect.BidToken==config.tokenSymbol?
            config.tokenAddress
            :
            AccepBidSelect.BidToken==config.usdtokenSymbol?
                    config.busdtokenAddr
                    :
            AccepBidSelect.BidToken==config.owntokenSymbol?
            config.bouitokenAddr:"";
           
            if (AccepBidSelect.BidToken==config.tokenSymbol) {
              var web3              = new Web3(window.ethereum);
                var WBNBContract   = new web3.eth.Contract(DETH_ABI, config.tokenAddress);
                var tokenBal = await WBNBContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call()
                var tokenBalance = tokenBal / config.decimalvalues;

              }else if (AccepBidSelect.BidToken==config.owntokenSymbol) {
              var web3              = new Web3(window.ethereum);
              var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
              var tokenBal = await BouiContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call()
              var tokenBalance = tokenBal / config.decimalvalues;

            }else if(AccepBidSelect.BidToken==config.usdtokenSymbol){
              var web3              = new Web3(window.ethereum);
              var BusdContract   = new web3.eth.Contract(BUSD_ABI, config.busdtokenAddr);
              var tokenBal = await BusdContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call()
              var tokenBalance = tokenBal / config.decimalvalues;
            }
            else{
              var web3              = new Web3(window.ethereum);
              var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
              var tokenBal = await BouiContract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call()                
              var tokenBalance = tokenBal / config.decimalvalues;
            }
            var value= (parseFloat(YouWillPayFee)
            *parseFloat(AccepBidSelect.NoOfToken))+
            (parseFloat(AccepBidSelect.tokenBidAmt)
            *parseFloat(AccepBidSelect.NoOfToken))
            
            var passAmt = parseFloat(YouWillPayFee) + parseFloat(YouWillGet);
            passAmt = passAmt.toFixed(config.toFixed);
            passAmt = (passAmt * config.decimalvalues).toString();

            if (tokenBalance >= value) {
              setAccaptCallStatus('processing');
              CoursetroContract
              .methods
              .acceptBId(
                bidtokenaddress,
                AccepBidSelect.tokenBidAddress,
                convert(passAmt),
                item.tokenCounts,
                NoOfToken_NeedToSend,
                tokenaddress
              )
              .send({from:Accounts})
              .then(async (result) => {
              
                var acceptBId_Payload = {
                  tokenCounts: item.tokenCounts,
                  NoOfToken : NoOfToken_NeedToSend, //AccepBidSelect.NoOfToken,
                  tokenBidAddress: AccepBidSelect.tokenBidAddress,
                  UserAccountAddr_byaccepter: curAddr,
                  transactionHash: result.transactionHash,
                  PurchaseCurrency: AccepBidSelect.BidToken,
                  burnorboui:AccepBidSelect.burnorboui
                }
                var Resp = await acceptBId_Action(acceptBId_Payload);
                setAccaptCallStatus('done');
                setTimeout(() => window.$('.modal').modal('hide'), 600);
                setTimeout(() => history.push("/my-items"), 1200);
              })
              .catch((err) => {
                console.log('err', err)
                toast.error("Accept failed", toasterOption);
                setAccaptCallStatus('tryagain');
              })
            }else{
              toast.error("Bidder does't have enough balance", toasterOption)
              return false;
            }
           
          }
         
        }
      }
  }
async function getServicefees(){
try{
  if (window.ethereum) {
    var web3 = new Web3(window.ethereum);
    // console.log("getServicefees",item)
    if(item.type==721){
      var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
      if(biddingtoken==config.currencySymbol){
        var fee = await CoursetroContract.methods.getServiceFee().call();
      } else if(biddingtoken==config.owntokenSymbol){
        var fee = await CoursetroContract.methods.getBouiFee().call();
      }else if(biddingtoken==config.usdtokenSymbol){
        var fee = await CoursetroContract.methods.getBUSDFee().call();
      }
      else{
        var fee = await CoursetroContract.methods.getServiceFee().call();
      }
    }else{
      var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
      if(biddingtoken==config.currencySymbol){
        var fee = await CoursetroContract.methods.getServiceFee().call();
      } else if(biddingtoken==config.owntokenSymbol){
        var fee = await CoursetroContract.methods.getBouiFee().call();
      }else if(biddingtoken==config.usdtokenSymbol){
        var fee = await CoursetroContract.methods.getBUSDFee().call();
      }
      else{
        var fee = await CoursetroContract.methods.getServiceFee().call();
      }
    }
    // var fee = await CoursetroContract.methods.getServiceFee().call();
    var feeValue = fee/config.decimalvalues;
    setservicefee(feeValue);
  }
}catch(err){
}
}
  async function Placebidmodal(){ 
    setTimeout(() => window.$('#place_bid_modal').modal('hide'), 600);
    setTimeout(() => window.location.reload(false), 900);
  }
  useImperativeHandle(
    ref,
    () => ({
      async PlaceABid_Click(item,MyTokenDetail) {
        Set_BidformSubmit(false);
        var connectwallet=localStorage.getItem("tfniuobnft");
        if(!connectwallet){
          window.location.href="/connect";
          return false;
        }
        console.log("PlaceABid_Click",item)
      //   let ageSum = 0;
      //   var tokenowners_all = item.tokenowners_all;
      //    console.log("balnce++:",tokenowners_all)
      //   if (tokenowners_all && tokenowners_all.length > 0) {
      //     for (let i = 0; i < tokenowners_all.length; i++) {
      //       if(tokenowners_all[i].balance>0&&tokenowners_all[i].burnorboui!="burn"){
      //         ageSum += tokenowners_all[i].balance;
      //       }
      //     }
      //   }
      //   var curAddr = await getCurAddr();

      //   var IndexVal = -1;
      //   if (item.tokenowners_all && curAddr) {
      //     var tokenowners_all = item.tokenowners_all;
      //     IndexVal = tokenowners_all.findIndex(val => val.tokenOwner.toString() == curAddr.toString());
      //   }
      //   var newMyTokenBalance = 0;
      //   if (IndexVal > -1) {
      //     newMyTokenBalance = tokenowners_all[IndexVal].balance
      //     Set_MyTokenBalance(newMyTokenBalance);
      //   }
      //   else {
      //     newMyTokenBalance = 0;
      //     Set_MyTokenBalance(0);
      //   }
      //   console.log("bid_bal:",ageSum,newMyTokenBalance)
      // if (ageSum) {
      //   Set_AllowedQuantity(ageSum - newMyTokenBalance);
      // }
      // else {
      //   Set_AllowedQuantity(0);
      // }
        // Set_AllowedQuantity(ageSum)
        var ReqData = { addr : UserAccountAddr }
                var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
                console.log("result___",Resp.data.data)
                if (Resp && Resp.data && Resp.data.data) {      
                    if(Resp.data.data.User.allowstatus==false){
                        window.$('#profile_modal').modal('show');
                    }else{
                        setTimeout(() => window.$('#place_bid_modal').modal('show')
                        , 600);

                    }
                }
        if(Tabname=='Info'){
          setCurrentOwner(item && item.tokenowners_current && item.tokenowners_current[0])
            if(item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken) {
              setPurchaseCurrency(item.tokenowners_current[0].biddingtoken)
              set_biddingtoken(item.tokenowners_current[0].biddingtoken)
              try{
                var web3              = new Web3(window.ethereum);
              if(item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken==config.currencySymbol){
                    var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
                    var currAddr = window.web3.eth.defaultAccount;
                    var decimal        = await BouiContract.methods.decimals().call();
                    var tokenBal       = await BouiContract.methods.balanceOf(currAddr).call();
                    var tokenBalance      = tokenBal / config.decimalvalues;
                    Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                    setPurchaseCurrency(config.owntokenSymbol)
                    set_biddingtoken(config.owntokenSymbol)
                    if(item.type==721){
                      var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
                      var fee = await CoursetroContract.methods.getBouiFee().call();
                    }else{
                      var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
                      var fee = await CoursetroContract.methods.getBouiFee().call();
                    }
                    var feeValue = fee/config.decimalvalues;
                    setservicefee(feeValue);
              }else if(item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken==config.owntokenSymbol){
                    var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
                    var currAddr = window.web3.eth.defaultAccount;
                    var decimal        = await BouiContract.methods.decimals().call();
                    var tokenBal       = await BouiContract.methods.balanceOf(currAddr).call();
                    var tokenBalance      = tokenBal / config.decimalvalues;
                    Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                    setPurchaseCurrency(config.owntokenSymbol)
                    set_biddingtoken(config.owntokenSymbol)
                    if(item.type==721){
                      var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
                      if(item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken==config.currencySymbol){
                        var fee = await CoursetroContract.methods.getBouiFee().call();
                      } else if(item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken==config.owntokenSymbol){
                        var fee = await CoursetroContract.methods.getBouiFee().call();
                      }else{
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                      }
                    }else{
                      var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
                      if(item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken==config.currencySymbol){
                        var fee = await CoursetroContract.methods.getBouiFee().call();
                      } else if(item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken==config.owntokenSymbol){
                        var fee = await CoursetroContract.methods.getBouiFee().call();
                      }else{
                        var fee = await CoursetroContract.methods.getServiceFee().call();
                      }
                    }
                    var feeValue = fee/config.decimalvalues;
                    setservicefee(feeValue);
              } else if(item && item.tokenowners_current && item.tokenowners_current[0].biddingtoken==config.usdtokenSymbol){
                var BusdContract   = new web3.eth.Contract(BUSD_ABI, config.busdtokenAddr);
                var currAddr = window.web3.eth.defaultAccount;
                var decimal        = await BusdContract.methods.decimals().call();
                var tokenBal       = await BusdContract.methods.balanceOf(currAddr).call();
                var tokenBalance      = tokenBal / config.decimalvalues;
                Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                setPurchaseCurrency(config.owntokenSymbol)
                set_biddingtoken(config.owntokenSymbol)
                if(item.type==721){
                  var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
                  var fee = await CoursetroContract.methods.getBUSDFee().call();
                }else{
                  var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
                  var fee = await CoursetroContract.methods.getBUSDFee().call();
                }
                var feeValue = fee/config.decimalvalues;
                setservicefee(feeValue);
              }else{
                var DETHContract   = new web3.eth.Contract(DETH_ABI, config.tokenAddress);
                var currAddr       = window.web3.eth.defaultAccount;
                var decimal        = await DETHContract.methods.decimals().call();
                var tokenBal       = await DETHContract.methods.balanceOf(currAddr).call();
                var tokenBalance   = tokenBal / config.decimalvalues;
                Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                setPurchaseCurrency(config.tokenSymbol)
                set_biddingtoken(config.tokenSymbol)
                if(item.type==721){
                  var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
                  var fee = await CoursetroContract.methods.getBouiFee().call();
                  var feeValue = fee/config.decimalvalues;
                  setservicefee(feeValue);
                }else{
                  var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
                  var fee = await CoursetroContract.methods.getBouiFee().call();
                  var feeValue = fee/config.decimalvalues;
                  setservicefee(feeValue);
                }              
              }
              }catch(err){}
            }
        }else{
          
          if(item && item.tokenowners_current && item.tokenowners_current.biddingtoken) {
            setPurchaseCurrency(item.tokenowners_current.biddingtoken)
            set_biddingtoken(item.tokenowners_current.biddingtoken)
            setCurrentOwner(item && item.tokenowners_current)
            try{
              var web3              = new Web3(window.ethereum);
            if(item && item.tokenowners_current && item.tokenowners_current.biddingtoken==config.currencySymbol){
                  var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
                  var currAddr = window.web3.eth.defaultAccount;
                  var decimal        = await BouiContract.methods.decimals().call();
                  var tokenBal       = await BouiContract.methods.balanceOf(currAddr).call();
                  var tokenBalance      = tokenBal / config.decimalvalues;
                  Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                  setPurchaseCurrency(config.owntokenSymbol)
                  set_biddingtoken(config.owntokenSymbol)
                  if(item.type==721){
                    var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  }else{
                    var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  }
                  var feeValue = fee/config.decimalvalues;
                  setservicefee(feeValue);
            }else if(item && item.tokenowners_current && item.tokenowners_current.biddingtoken==config.owntokenSymbol){
                  var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
                  var currAddr = window.web3.eth.defaultAccount;
                  var decimal        = await BouiContract.methods.decimals().call();
                  var tokenBal       = await BouiContract.methods.balanceOf(currAddr).call();
                  var tokenBalance      = tokenBal / config.decimalvalues;
                  Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
                  setPurchaseCurrency(config.owntokenSymbol)
                  set_biddingtoken(config.owntokenSymbol)
                  if(item.type==721){
                    var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  }else{
                    var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
                    var fee = await CoursetroContract.methods.getBouiFee().call();
                  }
                  var feeValue = fee/config.decimalvalues;
                  setservicefee(feeValue);
            } else if(item && item.tokenowners_current && item.tokenowners_current.biddingtoken==config.usdtokenSymbol){
              var BusdContract   = new web3.eth.Contract(BUSD_ABI, config.busdtokenAddr);
              var currAddr = window.web3.eth.defaultAccount;
              var decimal        = await BusdContract.methods.decimals().call();
              var tokenBal       = await BusdContract.methods.balanceOf(currAddr).call();
              var tokenBalance      = tokenBal / config.decimalvalues;
              Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
              setPurchaseCurrency(config.usdtokenSymbol)
              set_biddingtoken(config.usdtokenSymbol)
              if(item.type==721){
                var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
                var fee = await CoursetroContract.methods.getBUSDFee().call();
              }else{
                var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
                var fee = await CoursetroContract.methods.getBUSDFee().call();
              }
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
        } 
            else{
              var DETHContract   = new web3.eth.Contract(DETH_ABI, config.tokenAddress);
              var currAddr       = window.web3.eth.defaultAccount;
              var decimal        = await DETHContract.methods.decimals().call();
              var tokenBal       = await DETHContract.methods.balanceOf(currAddr).call();
              var tokenBalance   = tokenBal / config.decimalvalues;
              Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
              setPurchaseCurrency(config.tokenSymbol)
              set_biddingtoken(config.tokenSymbol)
              if(item.type==721){
                var CoursetroContract = new web3.eth.Contract(BEP721, singleContract);
                if(item && item.tokenowners_current && item.tokenowners_current.biddingtoken==config.currencySymbol){
                  var fee = await CoursetroContract.methods.getServiceFee().call();
                } else if(item && item.tokenowners_current && item.tokenowners_current.biddingtoken==config.owntokenSymbol){
                  var fee = await CoursetroContract.methods.getBouiFee().call();
                }else{
                  var fee = await CoursetroContract.methods.getServiceFee().call();
                }
                var feeValue = fee/config.decimalvalues;
                setservicefee(feeValue);
              }else{
                var CoursetroContract = new web3.eth.Contract(BEP1155, multipleContract);
                if(item && item.tokenowners_current && item.tokenowners_current.biddingtoken==config.currencySymbol){
                  var fee = await CoursetroContract.methods.getServiceFee().call();
                } else if(item && item.tokenowners_current && item.tokenowners_current.biddingtoken==config.owntokenSymbol){
                  var fee = await CoursetroContract.methods.getBouiFee().call();
                }else{
                  var fee = await CoursetroContract.methods.getServiceFee().call();
                }
                var feeValue = fee/config.decimalvalues;
                setservicefee(feeValue);
              }             
            }
            }catch(err){}
          }
        }
       
        if(Bids && Bids.myBid && Bids.myBid.tokenBidAmt) {
         set_biddingtoken(Bids.myBid.BidToken)
         setPurchaseCurrency(Bids.myBid.BidToken)
          Set_NoOfToken(Bids.myBid.NoOfToken);
          console.log("bidsss",item.type)
          if(Bids.myBid.BidToken==config.currencySymbol){
            var web3              = new Web3(window.ethereum);
            if(item.type==721){
              var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
              var fee = await CoursetroContract.methods.getBouiFee().call();
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
            }else{
              var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
              var fee = await CoursetroContract.methods.getBouiFee().call();
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
            }
            var BOUIContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
            var currAddr       = window.web3.eth.defaultAccount;
            var decimal        = await BOUIContract.methods.decimals().call();
            var tokenBal       = await BOUIContract.methods.balanceOf(currAddr).call();
            var tokenBalance   = tokenBal / config.decimalvalues;
            Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
            }
            else if(Bids.myBid.BidToken==config.owntokenSymbol){
            var web3              = new Web3(window.ethereum);
            if(item.type==721){
              var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
              var fee = await CoursetroContract.methods.getBouiFee().call();
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
            }else{
              var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
              var fee = await CoursetroContract.methods.getBouiFee().call();
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
            }
            var BOUIContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
            var currAddr       = window.web3.eth.defaultAccount;
            var decimal        = await BOUIContract.methods.decimals().call();
            var tokenBal       = await BOUIContract.methods.balanceOf(currAddr).call();
            var tokenBalance   = tokenBal / config.decimalvalues;
            Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
          }
          else if(Bids.myBid.BidToken==config.usdtokenSymbol){
            var web3              = new Web3(window.ethereum);
            if(item.type==721){
              var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
              var fee = await CoursetroContract.methods.getBUSDFee().call();
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
            }else{
              var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
              var fee = await CoursetroContract.methods.getBUSDFee().call();
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
            }
            var BOUIContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
            var currAddr       = window.web3.eth.defaultAccount;
            var decimal        = await BOUIContract.methods.decimals().call();
            var tokenBal       = await BOUIContract.methods.balanceOf(currAddr).call();
            var tokenBalance   = tokenBal / config.decimalvalues;
            Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
          }
          else{
            var web3              = new Web3(window.ethereum);
            if(item.type==721){
              var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
              var fee = await CoursetroContract.methods.getServiceFee().call();
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
            }else{
              var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
              var fee = await CoursetroContract.methods.getServiceFee().call();
              var feeValue = fee/config.decimalvalues;
              setservicefee(feeValue);
            }
            var DETHContract   = new web3.eth.Contract(DETH_ABI, config.tokenAddress);
            var currAddr       = window.web3.eth.defaultAccount;
            var decimal        = await DETHContract.methods.decimals().call();
            var tokenBal       = await DETHContract.methods.balanceOf(currAddr).call();
            var tokenBalance   = tokenBal / config.decimalvalues;
            Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
          }
        }
        
      },
      async PriceCalculate(data={}) {
        PriceCalculate_this(data);
      },
      async AcceptBid_Select(curBid_val,item) {
        if(window.ethereum){
          console.log("acceptlist",curBid_val)
          if(curBid_val && curBid_val.tokenBidAmt) {
            window.$('#accept_modal').modal('show');
            Set_AccepBidSelect(curBid_val);

            if(MyTokenBalance < curBid_val.pending) {
              Set_NoOfToken_NeedToSend(MyTokenBalance);
              var totalAmt = MyTokenBalance * curBid_val.tokenBidAmt;
            }
            else {
              Set_NoOfToken_NeedToSend(curBid_val.pending);
              var totalAmt = curBid_val.pending * curBid_val.tokenBidAmt;
            }
            try{
              if (window.ethereum) {
                var web3 = new Web3(window.ethereum);
                if(item.type==721){
                  var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
                  if(curBid_val.BidToken==config.currencySymbol){
                      var fee = await CoursetroContract.methods.getServiceFee().call();
                      var ServiceFee_val = (totalAmt * fee) / 1e20;
                      Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
                      set_biddingfee(fee/1e18)
                      var YouWillGet_Val = totalAmt - ServiceFee_val;
                      Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
                      var feeValue = fee/config.decimalvalues;
                      setservicefee(feeValue);
                  } else if(curBid_val.BidToken==config.owntokenSymbol){
                      var fee = await CoursetroContract.methods.getBouiFee().call();
                      var ServiceFee_val = (totalAmt * fee) / 1e20;
                      var YouWillGet_Val = totalAmt  - ServiceFee_val;
                      Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
                      Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
                      set_biddingfee(fee/1e18)
                      var feeValue = fee/config.decimalvalues;
                      setservicefee(feeValue);
                  }
                  else if(curBid_val.BidToken==config.usdtokenSymbol){
                    var fee = await CoursetroContract.methods.getBUSDFee().call();
                    var ServiceFee_val = (totalAmt * fee) / 1e20;
                    var YouWillGet_Val = totalAmt  - ServiceFee_val;
                    Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
                    Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
                    set_biddingfee(fee/1e18)
                    var feeValue = fee/config.decimalvalues;
                    setservicefee(feeValue);
                }
                  else{
                      var fee = await CoursetroContract.methods.getServiceFee().call();
                      var ServiceFee_val = (totalAmt * fee) / 1e20;
                      var YouWillGet_Val = totalAmt  - ServiceFee_val;
                      Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
                      set_biddingfee(fee/1e18)
                      Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
                      var feeValue = fee/config.decimalvalues;
                      setservicefee(feeValue);
                  }
                   
                }else{
                  var CoursetroContract = new web3.eth.Contract(BEP1155,config.multipleContract);
                  if(curBid_val.BidToken==config.currencySymbol){
                      var fee = await CoursetroContract.methods.getServiceFee().call();
                      var ServiceFee_val = (totalAmt * fee) / 1e20;
                      var YouWillGet_Val = totalAmt  - ServiceFee_val;
                      Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
                      set_biddingfee(fee/1e18)
                      Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
                      var feeValue = fee/config.decimalvalues;
                      setservicefee(feeValue);
                  } else if(curBid_val.BidToken==config.owntokenSymbol){
                      var fee = await CoursetroContract.methods.getBouiFee().call();
                      var ServiceFee_val = (totalAmt * fee) / 1e20;
                      var YouWillGet_Val = totalAmt - ServiceFee_val;
                      Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
                      set_biddingfee(fee/1e18)
                      Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
                      var feeValue = fee/config.decimalvalues;
                      setservicefee(feeValue);
                  }else if(curBid_val.BidToken==config.usdtokenSymbol){
                    var fee = await CoursetroContract.methods.getBUSDFee().call();
                    var ServiceFee_val = (totalAmt * fee) / 1e20;
                    var YouWillGet_Val = totalAmt  - ServiceFee_val;
                    Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
                    Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
                    set_biddingfee(fee/1e18)
                    var feeValue = fee/config.decimalvalues;
                    setservicefee(feeValue);
                }
                  else{
                    var fee = await CoursetroContract.methods.getServiceFee().call();
                    var ServiceFee_val = (totalAmt * fee) / 1e20;
                    var YouWillGet_Val = totalAmt - ServiceFee_val;
                    Set_YouWillPayFee(ServiceFee_val.toFixed(config.toFixed));
                    set_biddingfee(fee/1e18)
                    Set_YouWillGet(YouWillGet_Val.toFixed(config.toFixed));
                    var feeValue = fee/config.decimalvalues;
                    setservicefee(feeValue);
                  }
                 
                }
               
              }
            }catch(err){
            }

            
          }
        }
        else{
          // console.log("called")
          window.$('#connect_modal').modal('show')
        }
        },
      async CancelBid_Select(curBid_val) {
        if(
          curBid_val
          && curBid_val.pending > 0
          &&
          (
            curBid_val.status == 'pending'
            || curBid_val.status == 'partiallyCompleted'
          )
        ) {
          Set_AccepBidSelect(curBid_val);
          window.$('#cancel_modal').modal('show');
        }
        else {
          window.$('.modal').modal('hide')
        }
      }
    }),
  )
   
const SelectBidcurrency = (e) => {
var filter=e.target.value;
set_biddingtoken(filter)
youWillPay(filter)
getTokenval(filter)
}
async function youWillPay(bid, amount) {
  var bidper;
  if (bid == config.tokenSymbol) {
    bidper = config.fee/config.decimalvalues
  } 
  else if (bid==config.owntokenSymbol){
    bidper = config.fee/config.decimalvalues
  }
  else {
    bidper = config.fee/config.decimalvalues
  }
  var youwill = (bidper / 100) * amount
  var finalwill = amount - youwill
  setTokenWill(finalwill)
}
async function getTokenval(filter){
  try{
    var web3              = new Web3(window.ethereum);
  if(filter==config.tokenSymbol){
    if(item.type==721){
      var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
      var fee = await CoursetroContract.methods.getServiceFee().call();
      var feeValue    = fee/config.decimalvalues;
      setservicefee(feeValue)
      var totalPrice = parseFloat(tokenBidAmt);
      var per = (totalPrice*config.decimalvalues * fee) / 1e20;
      var sendMMAmt=(totalPrice*config.decimalvalues) + per;
      setMetaMaskAmt(sendMMAmt)
      var finalPrice = sendMMAmt/config.decimalvalues;
      var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      Set_YouWillPay(totalPriceWithFee);
    }else{
      var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
      var fee = await CoursetroContract.methods.getServiceFee().call();
      var feeValue    = fee/config.decimalvalues;
      setservicefee(feeValue)
      var totalPrice = tokenBidAmt*NoOfToken;
      var per = (totalPrice*config.decimalvalues * fee) / 1e20;
      var sendMMAmt=(totalPrice*config.decimalvalues) + per;
      setMetaMaskAmt(sendMMAmt)
      var finalPrice = sendMMAmt/config.decimalvalues;
      var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      Set_YouWillPay(totalPriceWithFee);
    }
       
    var DETHContract   = new web3.eth.Contract(DETH_ABI, config.tokenAddress);
    var currAddr       = window.web3.eth.defaultAccount;
    var decimal        = await DETHContract.methods.decimals().call();
    var tokenBal       = await DETHContract.methods.balanceOf(currAddr).call();
    var tokenBalance   = tokenBal / config.decimalvalues;
    Set_TokenBalance(tokenBalance.toFixed(config.toFixed));

  }else if(filter==config.owntokenSymbol){
    if(item.type==721){
      var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
      var fee = await CoursetroContract.methods.getBouiFee().call();
       var feeValue    = fee/config.decimalvalues;
    setservicefee(feeValue)
    var totalPrice = parseFloat(tokenBidAmt);
    var per = (totalPrice*config.decimalvalues * fee) / 1e20;
    var sendMMAmt=(totalPrice*config.decimalvalues) + per;
    setMetaMaskAmt(sendMMAmt)
    var finalPrice = sendMMAmt/config.decimalvalues;
    var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
    Set_YouWillPay(totalPriceWithFee);
    }else{
      var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
      var fee = await CoursetroContract.methods.getBouiFee().call();
      var feeValue    = fee/config.decimalvalues;
      setservicefee(feeValue)
      var totalPrice = tokenBidAmt*NoOfToken;
      var per = (totalPrice*config.decimalvalues * fee) / 1e20;
      var sendMMAmt=(totalPrice*config.decimalvalues) + per;
      setMetaMaskAmt(sendMMAmt)
      var finalPrice = sendMMAmt/config.decimalvalues;
      var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      Set_YouWillPay(totalPriceWithFee);
    }
   
    var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
    var currAddr = window.web3.eth.defaultAccount;
    var decimal        = await BouiContract.methods.decimals().call();
    var tokenBal       = await BouiContract.methods.balanceOf(currAddr).call();
    var tokenBalance      = tokenBal / config.decimalvalues;
    Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
  }else if(filter==config.usdtokenSymbol){
    if(item.type==721){
      var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
      var fee = await CoursetroContract.methods.getBUSDFee().call();
       var feeValue    = fee/config.decimalvalues;
    setservicefee(feeValue)
    var totalPrice = parseFloat(tokenBidAmt);
    var per = (totalPrice*config.decimalvalues * fee) / 1e20;
    var sendMMAmt=(totalPrice*config.decimalvalues) + per;
    setMetaMaskAmt(sendMMAmt)
    var finalPrice = sendMMAmt/config.decimalvalues;
    var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
    Set_YouWillPay(totalPriceWithFee);
    }else{
      var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
      var fee = await CoursetroContract.methods.getBUSDFee().call();
      var feeValue    = fee/config.decimalvalues;
      setservicefee(feeValue)
      var totalPrice = tokenBidAmt*NoOfToken;
      var per = (totalPrice*config.decimalvalues * fee) / 1e20;
      var sendMMAmt=(totalPrice*config.decimalvalues) + per;
      setMetaMaskAmt(sendMMAmt)
      var finalPrice = sendMMAmt/config.decimalvalues;
      var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      Set_YouWillPay(totalPriceWithFee);
    }
   
    var BusdContract   = new web3.eth.Contract(BUSD_ABI, config.busdtokenAddr);
    var currAddr = window.web3.eth.defaultAccount;
    var decimal        = await BusdContract.methods.decimals().call();
    var tokenBal       = await BusdContract.methods.balanceOf(currAddr).call();
    var tokenBalance      = tokenBal / config.decimalvalues;
    Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
  }
  else{
    if(item.type==721){
      var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
      if(filter==config.currencySymbol){
        var fee = await CoursetroContract.methods.getServiceFee().call();
      } else if(filter==config.owntokenSymbol){
        var fee = await CoursetroContract.methods.getBouiFee().call();
      }else{
        var fee = await CoursetroContract.methods.getServiceFee().call();
      }
      var feeValue    = fee/config.decimalvalues;
      setservicefee(feeValue)
      var totalPrice = parseFloat(tokenBidAmt);
      var per = (totalPrice*config.decimalvalues * fee) / 1e20;
      var sendMMAmt=(totalPrice*config.decimalvalues) + per;
      setMetaMaskAmt(sendMMAmt)
      var finalPrice = sendMMAmt/config.decimalvalues;
      var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      Set_YouWillPay(totalPriceWithFee);
    }else{
      var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
      if(filter==config.currencySymbol){
        var fee = await CoursetroContract.methods.getServiceFee().call();
      } else if(filter==config.owntokenSymbol){
        var fee = await CoursetroContract.methods.getBouiFee().call();
      }else{
        var fee = await CoursetroContract.methods.getServiceFee().call();
      }
      var feeValue    = fee/config.decimalvalues;
      setservicefee(feeValue)
      var totalPrice = tokenBidAmt*NoOfToken;
      var per = (totalPrice*config.decimalvalues * fee) / 1e20;
      var sendMMAmt=(totalPrice*config.decimalvalues) + per;
      setMetaMaskAmt(sendMMAmt)
      var finalPrice = sendMMAmt/config.decimalvalues;
      var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
      Set_YouWillPay(totalPriceWithFee);
    }
   
    var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
    var currAddr = window.web3.eth.defaultAccount;
    var decimal        = await BouiContract.methods.decimals().call();
    var tokenBal       = await BouiContract.methods.balanceOf(currAddr).call();
    var tokenBalance      = tokenBal / config.decimalvalues;
    Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
  }
  }catch(err){}
}
async function  getTokenbal(){
try{
  var web3              = new Web3(window.ethereum);
if(biddingtoken==config.currencySymbol){
  if(item.type==721){
    var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
    if(biddingtoken==config.currencySymbol){
      var fee = await CoursetroContract.methods.getServiceFee().call();
    } else if(biddingtoken==config.owntokenSymbol){
      var fee = await CoursetroContract.methods.getBouiFee().call();
    }else if(biddingtoken==config.usdtokenSymbol){
      var fee = await CoursetroContract.methods.getBUSDFee().call();
    }
    else{
      var fee = await CoursetroContract.methods.getServiceFee().call();
    }
    
  }else{
    var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
    if(biddingtoken==config.currencySymbol){
      var fee = await CoursetroContract.methods.getServiceFee().call();
    } else if(biddingtoken==config.owntokenSymbol){
      var fee = await CoursetroContract.methods.getBouiFee().call();
    }else if(biddingtoken==config.usdtokenSymbol){
      var fee = await CoursetroContract.methods.getBUSDFee().call();
    }
    else{
      var fee = await CoursetroContract.methods.getServiceFee().call();
    }
  }
  var feeValue    = fee/config.decimalvalues;
  setservicefee(feeValue)
  var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
  var currAddr = window.web3.eth.defaultAccount;
  var decimal        = await BouiContract.methods.decimals().call();
  var tokenBal       = await BouiContract.methods.balanceOf(currAddr).call();
  var tokenBalance      = tokenBal / config.decimalvalues;
  Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
}else if(biddingtoken==config.owntokenSymbol){
  if(item.type==721){
    var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
    if(biddingtoken==config.currencySymbol){
      var fee = await CoursetroContract.methods.getServiceFee().call();
    } else if(biddingtoken==config.owntokenSymbol){
      var fee = await CoursetroContract.methods.getBouiFee().call();
    }else if(biddingtoken==config.usdtokenSymbol){
      var fee = await CoursetroContract.methods.getBUSDFee().call();
    }
    else{
      var fee = await CoursetroContract.methods.getServiceFee().call();
    }
    
  }else{
    var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
    if(biddingtoken==config.currencySymbol){
      var fee = await CoursetroContract.methods.getServiceFee().call();
    } else if(biddingtoken==config.owntokenSymbol){
      var fee = await CoursetroContract.methods.getBouiFee().call();
    }else if(biddingtoken==config.usdtokenSymbol){
      var fee = await CoursetroContract.methods.getBUSDFee().call();
    }
    else{
      var fee = await CoursetroContract.methods.getServiceFee().call();
    }
  }
  var feeValue    = fee/config.decimalvalues;
  setservicefee(feeValue)
  var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
  var currAddr = window.web3.eth.defaultAccount;
  var decimal        = await BouiContract.methods.decimals().call();
  var tokenBal       = await BouiContract.methods.balanceOf(currAddr).call();
  var tokenBalance      = tokenBal / config.decimalvalues;
  Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
}else if(biddingtoken==config.usdtokenSymbol){
  var BUSDContract         = new web3.eth.Contract(BUSD_ABI, config.busdtokenAddr);

  if(item.type==721){
    var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
    if(biddingtoken==config.currencySymbol){
      var fee = await CoursetroContract.methods.getServiceFee().call();
    } else if(biddingtoken==config.owntokenSymbol){
      var fee = await CoursetroContract.methods.getBouiFee().call();
    }else if(biddingtoken==config.usdtokenSymbol){
      var fee = await BUSDContract.methods.getBUSDFee().call();
    }
    else{
      var fee = await CoursetroContract.methods.getServiceFee().call();
    }
    
  }else{
    var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
    if(biddingtoken==config.currencySymbol){
      var fee = await CoursetroContract.methods.getServiceFee().call();
    } else if(biddingtoken==config.owntokenSymbol){
      var fee = await CoursetroContract.methods.getBouiFee().call();
    }else if(biddingtoken==config.usdtokenSymbol){
      var fee = await BUSDContract.methods.getBUSDFee().call();
    }
    else{
      var fee = await CoursetroContract.methods.getServiceFee().call();
    }
  }
  var feeValue    = fee/config.decimalvalues;
  setservicefee(feeValue)
  var BouiContract   = new web3.eth.Contract(BOUI_ABI, config.bouitokenAddr);
  var currAddr = window.web3.eth.defaultAccount;
  var decimal        = await BouiContract.methods.decimals().call();
  var tokenBal       = await BUSDContract.methods.getBUSDFee().call();
  var tokenBalance      = tokenBal / config.decimalvalues;
  Set_TokenBalance(tokenBalance.toFixed(config.toFixed));
}
else{
  if(item.type==721){
    var CoursetroContract = new web3.eth.Contract(BEP721, config.singleContract);
    if(biddingtoken==config.currencySymbol){
      var fee = await CoursetroContract.methods.getServiceFee().call();
    } else if(biddingtoken==config.owntokenSymbol){
      var fee = await CoursetroContract.methods.getBouiFee().call();
    }else if(biddingtoken==config.usdtokenSymbol){
      var fee = await CoursetroContract.methods.getBUSDFee().call();
    }
    else{
      var fee = await CoursetroContract.methods.getServiceFee().call();
    }
    
  }else{
    var CoursetroContract = new web3.eth.Contract(BEP1155, config.multipleContract);
    if(biddingtoken==config.currencySymbol){
      var fee = await CoursetroContract.methods.getServiceFee().call();
    } else if(biddingtoken==config.owntokenSymbol){
      var fee = await CoursetroContract.methods.getBouiFee().call();
    }else if(biddingtoken==config.usdtokenSymbol){
      var fee = await CoursetroContract.methods.getBUSDFee().call();
    }
    else{
      var fee = await CoursetroContract.methods.getServiceFee().call();
    }
  }
  var feeValue    = fee/config.decimalvalues;
  setservicefee(feeValue)
  var DETHContract   = new web3.eth.Contract(DETH_ABI, config.tokenAddress);
  var currAddr       = window.web3.eth.defaultAccount;
  var decimal        = await DETHContract.methods.decimals().call();
  var tokenBal       = await DETHContract.methods.balanceOf(currAddr).call();
  var tokenBalance   = tokenBal / config.decimalvalues;
  Set_TokenBalance(tokenBalance.toFixed(8));
}
}catch(err){}
}
async function handleChange(event){
  setBurnorboui(event.target.value)
}
  useEffect(() => {
     getServicefees();
     getTokenbal();
    Validation_PlaceABid(BidformSubmit);
  }, [
    tokenBidAmt,
    NoOfToken
  ])

  return (
    <div>
      <div id="Validation_PlaceABid" onClick={() => Validation_PlaceABid(BidformSubmit)}></div>
      {/* place_bid Modal */}
      <div class="modal fade primary_modal" id="place_bid_modal" tabindex="-1" role="dialog" aria-labelledby="place_bid_modalCenteredLabel" aria-hidden="true" data-backdrop="static">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
              <div class="modal-header text-center">
              <h5 class="modal-title" id="place_bid_modalLabel">Place a Offer</h5>
              <p className="text-center place_bit_desc">You are about to place a Offer for</p>
              <p className="place_bit_desc_2"><span className="text_red mr-2">{halfAddrShow(currentowner&&currentowner.tokenOwner)}</span>by<span className="text_red ml-2">{halfAddrShow(UserAccountAddr)}</span></p>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={(Placebidmodal)}>
                  <span aria-hidden="true">&times;</span>
              </button>
              </div>
              <div class="modal-body px-0 pt-0">
                <form className="px-4 bid_form">
                  <label for="bid">Your Offer</label>
                  <div class="input-group mb-3 input_grp_style_1">
                    <input
                        type="number"
                        name="tokenBidAmt"
                        id="tokenBidAmt"
                        class="form-control"
                        placeholder="Enter your Offer amount"
                        min="0"
                        aria-label="bid"
                        aria-describedby="basic-addon2"
                        onChange={inputChange}
                        onKeyDown={(evt) => ["e", "E", "+", "-",","].includes(evt.key) && evt.preventDefault()} 
                        autoComplete="off"
                    />
                    <div class="input-group-append">
                      <select id="TokenCategory1" value={biddingtoken} name="TokenCategory" onChange={SelectBidcurrency}>
                       
                          {bidprice && bidprice.length > 0 && bidprice.map((option, i) => {

                            // console.log(option.value,biddingtoken,'>>>>>>')
                            return(
                          <option value={option.value}>
                            {option.label}
                          </option>)
                        })}
                      </select>
                     </div>
                  </div>
                  {ValidateError.tokenBidAmt && <span className="text-danger"><br/>{ValidateError.tokenBidAmt}</span>}
                  {item.type == config.multipleType && <label for="qty">Enter quantity <span className="label_muted pl-2">({AllowedQuantity} available)</span></label> }
                  {item.type == config.multipleType && <div class="mb-3 input_grp_style_1">
                  <input
                      type="number"
                      name="NoOfToken"
                      id="NoOfToken"
                      min="1"
                      class="form-control"
                      placeholder="Enter your bit quantity"
                      onChange={inputChange}
                      autoComplete="off"
                      value={NoOfToken}
                      onKeyDown={(evt) => ["e", "E", "+", "-",".",","].includes(evt.key) && evt.preventDefault()}
                  />
                {ValidateError.NoOfToken && <span className="text-danger"><br/>{ValidateError.NoOfToken}</span>}
                  </div>}
                  <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{UserAccountBal.toFixed(8)} {config.currencySymbol}</p>
                  </div>
                  </div>
                  <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">Your Offering balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{TokenBalance} 
                      { biddingtoken==config.currencySymbol?config.owntokenSymbol:biddingtoken}</p>
                  </div>
                  </div>
                  <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">Burn or Boui</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">
                      <select onChange={handleChange} defaultValue={burnorboui}>
                                  <option value="burn">Burn</option>
                                  <option value="boui">Boui</option>
                              </select>
                      </p>
                  </div>
                  </div>
                  {item.PutOnSaleType=="TimedAuction"&&
                  <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">Minimum Offer</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{item.minimumBid} {biddingtoken==config.currencySymbol?config.owntokenSymbol:biddingtoken}</p>
                  </div>
                  </div>
                  }
                  <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{servicefee}% <span>{biddingtoken==config.currencySymbol?config.owntokenSymbol:biddingtoken}</span></p>
                  </div>
                  </div>
                  <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                      <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                      <p className="buy_desc_sm_bold">{YouWillPay}{biddingtoken==config.currencySymbol?config.owntokenSymbol:biddingtoken}</p>
                  </div>
                  </div>

                  <div className="text-center">
                  {/* data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal" */}
                  <Button className="create_btn btn-block" onClick={() => FormSubmit_PlaceABid()} >Place a Offer</Button>
                  </div>

              </form>
              </div>
          </div>
          </div>
      </div>
      {/* end place_bid modal */}

      {/* proceed_bid Modal */}
      <div class="modal fade primary_modal" id="proceed_bid_modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="proceed_bid_modalCenteredLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
              <div class="modal-header text-center">
              <h5 class="modal-title" id="proceed_bid_modalLabel">Follow Steps</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>window.location.reload(false)}>
                  <span aria-hidden="true">&times;</span>
              </button>
              </div>
              <div class="modal-body">
              <form>
                  <div className="media approve_media">
                  {BidApply_ApproveCallStatus == 'init'?
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  :
                  BidApply_ApproveCallStatus == 'processing'?
                  <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                  :
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                }
                  {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                      <p className="mt-0 approve_text">Approve</p>
                      <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                  </div>
                  <div className="text-center my-3">
                  <Button
                      className="btn-block btn_outline_red"
                      disabled={(BidApply_ApproveCallStatus=='processing' || BidApply_ApproveCallStatus=='done')}
                      onClick={BidApply_ApproveCall}
                      >
                      {/* {BidApply_ApproveCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >} */}
                      {BidApply_ApproveCallStatus == 'init' && 'Approve'}
                      {BidApply_ApproveCallStatus == 'processing' && 'In-progress...'}
                      {BidApply_ApproveCallStatus == 'done' && 'Done'}
                      {BidApply_ApproveCallStatus == 'tryagain' && 'Try Again'}
                      </Button>
                  </div>
                  <div className="media approve_media">
                  {BidApply_SignCallStatus == 'init'?
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  :
                  BidApply_SignCallStatus == 'processing'?
                  <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >
                  :
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                }
                  <div className="media-body">
                      <p className="mt-0 approve_text">Signature</p>
                      <p className="mt-0 approve_desc">Create a signatute to place a Offer</p>
                  </div>
                  </div>
                  <div className="text-center mt-3">
                  <Button
                      className="btn-block btn_outline_red"
                      disabled={(BidApply_ApproveCallStatus!='done' || BidApply_SignCallStatus=='processing' || BidApply_SignCallStatus=='done')}
                      onClick={BidApply_SignCall}
                  >
                      {/* {BidApply_SignCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >} */}
                      {BidApply_SignCallStatus == 'init' && 'Start'}
                      {BidApply_SignCallStatus == 'processing' && 'In-progress...'}
                      {BidApply_SignCallStatus == 'done' && 'Done'}
                      {BidApply_SignCallStatus == 'tryagain' && 'Try Again'}
                  </Button>
                  </div>
              </form>
              </div>
          </div>
          </div>
      </div>
      {/* end proceed_bid modal */}

      {/* accept bid Modal */}
      <div class="modal fade primary_modal" id="accept_modal" tabindex="-1" data-backdrop="static" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
              <div class="modal-header text-center">
              <h5 class="modal-title" id="accept_modalLabel">Accept Offer</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
              </div>
              <div class="modal-body px-0">
              <div className="img_accept text-center">
              {
                  item && item.multiimage &&item.multiimage[0]&& item.multiimage[0].name.split('.').pop() == 'mp4' ?
                    <video src={`${IPFS_IMGurl}/${item.ipfsimage}`} type="video/mp4" alt="Collections" className="img-fluid img_info" controls />
                    :
                    item && item.multiimage &&item.multiimage[0]&& item.multiimage[0].name.split('.').pop() == "mp3" ?
                    <div className="music_img_info_div">
                      <img src={`${config.Back_URL}/images/music.png`} alt="Collections" className="img-fluid img_info music_img_info " />
                      <audio src={`${IPFS_IMGurl}/${item.ipfsimage}`} type="audio/mp3" controls className="audio" autostart="off">
                      </audio>
                    </div>
                    :
                   <img src={`${IPFS_IMGurl}/${item.ipfsimage}`} alt="Collections" className="img-fluid img_info" />
              }
              </div>
              <p className="text-center accept_desc">
                  <span className="buy_desc_sm">You are about to accept Offer for</span>
                  <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
                  <span className="buy_desc_sm pl-2">from</span>
                  <span className="buy_desc_sm_bold pl-2">{halfAddrShow(AccepBidSelect.tokenBidAddress)}</span>
              </p>
              <p className="info_title text-center">{AccepBidSelect.tokenBidAmt} {AccepBidSelect.BidToken} for {AccepBidSelect.NoOfToken} edition(s)</p>
              <div className="row mx-0 pb-3">
                  <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee in %</p>
                  </div>
                  <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">{biddingfee}%</p>
                  </div>
              </div>
              <div className="row mx-0 pb-3">
                  <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee in {AccepBidSelect.BidToken}</p>
                  </div>
                  <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">{YouWillPayFee}</p>
                  </div>
              </div>
              <div className="row mx-0 pb-3">
                  <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will get</p>
                  </div>
                  <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">{YouWillGet} {AccepBidSelect.BidToken}</p>
                  </div>
              </div>
              <form className="px-4">
                  <div className="text-center">
                  <Button 
                  className="create_btn btn-block" 
                  onClick={() => AcceptBid_Proceed()}
                  disabled={(AcceptCallStatus=='processing')}
                  >
                    {AcceptCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                  Accept Offer</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                  </div>
              </form>
              </div>
          </div>
          </div>
      </div>
      {/* end accept bid modal */}

      {/* accept bid Modal */}
      <div class="modal fade primary_modal" id="cancel_modal" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
              <div class="modal-header text-center">
              <h5 class="modal-title" id="accept_modalLabel">Cancel Offer</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
              </div>
              <div class="modal-body px-0">
              {/* <div className="img_accept text-center">
                  {
                  item && item.image && item.image.split('.').pop() == "mp4" ?
                  <video src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} type="video/mp4" alt="Collections" className="img-fluid" autoPlay controls playsInline loop />
                  :
                  <img src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`} alt="Collections" className="img-fluid " />
                  }
              </div> */}
              <p className="text-center accept_desc">
                  <span className="buy_desc_sm">You are about to cancel Offer for</span>
                  <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
              </p>
              <p className="info_title text-center">{AccepBidSelect.tokenBidAmt} {config.tokenSymbol} for 1 edition(s)</p>
              <form className="px-4">
                  <div className="text-center">
                  <Button className="create_btn btn-block" onClick={() => CancelBid_Proceed(AccepBidSelect)}>Cancel Offer</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                  </div>
              </form>
              </div>
          </div>
          </div>
      </div>
      {/* end accept bid modal */}
    </div>
  )
})

